import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import BrowserRouter
import LoginPage from "./Pages/Login/LoginPage";
import DashboardPage from "./Pages/Dashboard/DashboardPage";
import "./materialUiOverlaped.css";
import "./styles.css";
import { accountInfoActions } from "./store/slice/accountInfo-slice";
import { useDispatch } from "react-redux";

function App() {
  const [data, setData] = useState(sessionStorage.getItem("isloggedIn"));
  const [content, setContent] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleBrowserBack = () => {
      dispatch(accountInfoActions.reset());
    };

    window.addEventListener("popstate", handleBrowserBack);

    return () => {
      window.removeEventListener("popstate", handleBrowserBack);
    };
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      setContent(
        <Routes>
          <Route
            path="/dashboard"
            element={<DashboardPage setData={setData} />}
          />
          <Route path="*" element={<DashboardPage setData={setData} />} />
        </Routes>
      );
    } else {
      setContent(
        <Routes>
          <Route path="/" element={<LoginPage setData={setData} />} />
          <Route path="*" element={<LoginPage setData={setData} />} />
        </Routes>
      );
    }
  }, [data]);

  return (
    <Router>
      {" "}
      {/* Wrap your entire application with the Router component */}
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      <>{content}</>
    </Router>
  );
}
export default App;
