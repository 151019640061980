import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import styles from "./CommonTable.module.css";

const RenderAssesmentTable = ({ data, header, height, jobSelected }) => {
  const normalizedHeaders = Array.isArray(header) ? header : [header];

  if (data) {
    const assesmentData = data.map((row, index) => {
      const rowData = {
        sn: index + 1,
        jobName: row[0], // First field is jobName
        xyz: row[1], // Second field is xyz
      };
      if (normalizedHeaders.length > 1) {
        rowData.entity3 = row[2]; // Add grp if it exists
      }
      if (normalizedHeaders.length > 2) {
        rowData.entity4 = row[3]; // Add grp if it exists
      }
      if (normalizedHeaders.length > 3) {
        rowData.entity5 = row[4]; // Add grp if it exists
      }

      return rowData;
    });

    return (
      <TableContainer
        sx={{
          height: height || "auto",
          maxHeight: "400px",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      >
        <Table stickyHeader sx={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell className={styles["table"]}>S.No.</TableCell>
              {!jobSelected && (
                <TableCell className={styles["table"]}>Job Name</TableCell>
              )}
              {normalizedHeaders.map((header, index) => (
                <TableCell className={styles["table"]} key={index + 1}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {assesmentData?.map((row, index) => (
              <TableRow key={index + 1}>
                <TableCell className="aligncell-top">{row.sn}</TableCell>
                {!jobSelected && (
                  <TableCell className="aligncell-top">
                    {row.jobName || "-"}
                  </TableCell>
                )}
                <TableCell className="aligncell-top">
                  {row.xyz || "-"}
                </TableCell>
                {
                  normalizedHeaders.length > 1 && (
                    <TableCell className="aligncell-top">
                      {row.entity3 || "-"}
                    </TableCell>
                  ) // Add grp if it exists
                }
                {
                  normalizedHeaders.length > 2 && (
                    <TableCell className="aligncell-top">
                      {row.entity4 || "-"}
                    </TableCell>
                  ) // Add grp if it exists
                }
                {
                  normalizedHeaders.length > 3 && (
                    <TableCell className="aligncell-top">
                      {row.entity5 || "-"}
                    </TableCell>
                  ) // Add grp if it exists
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};

export default RenderAssesmentTable;
