import styles from "../UcMigration.module.css";
import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPermission,
  fetchAccess,
} from "../../store/thunk/mountPointsoints-thunk";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";
import jsonData from "../../Assets/i18n/en.json";
import { awsArnPattern, azureConnectorPattern } from "../../utils/regex";
import LoadingSpinner from "../UI/LoadingSpinner";
import { ucMigrationCall } from "../../Resources/apicalls";
import { listToObjectVal } from "../Common/ucxFunction/getAccessMode";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import { v4 as uuidv4 } from "uuid";
import HeadingWithInfo from "../Common/HeadingWithInfo/HeadingWithInfo";
import UcmaTextField from "../UI/Form/TextField";

const MountPoints = (props) => {
  const { selectedList, startMigration, setDisableNext, setId } = props;
  const [access, setAccess] = useState({});
  const accountInfoData = useSelector((state) => state.accountInfo);
  const arnSelected = useSelector((state) => state.mountPointsReducer.arn);
  const [roleArnStorageCred, setRoleArnStorageCred] = useState(arnSelected);
  const [roleArnStorageCredErrorMsg, setRoleArnStorageCredErrorMsg] =
    useState("");

  const [azureConnector, setAzureConnector] = useState(arnSelected);
  const [azureConnectorErrorMsg, setAzureConnectorErrorMsg] = useState("");
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const s3AccessVal = useSelector((state) => state.mountPointsReducer.access);
  const [makingApiCall, setMakingApiCall] = useState(false);

  const dispatch = useDispatch();
  const [selectPermissions, setSelectPermissions] = useState(false);
  const handleChange = (event) => {
    setSelectPermissions(event.target.checked);
    dispatch(selectPermission(event.target.checked));
  };
  const tableHeadings = ["Source", "Mount Points", "Access"];

  const s3Access = useCallback(
    (sourceArray) => {
      let body = {
        iamArn: roleArnStorageCred,
        s3url: sourceArray,
        ucxCredentialsData: {
          accId: workspaceSelected.account_id,
          databricksEmail: accountInfoData.userId,
          databricksPassword: accountInfoData.password,
          databricksClientId: accountInfoData.oAuthToken,
          databricksSecret: accountInfoData.oAuthSecret,
        },
        workspaceUrl: workspaceSelected.workspace_url,
        cloudType: accountInfoData.cloudType,
        patAccess: accountInfoData.patAccess,
        OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      };

      if (awsArnPattern.test(roleArnStorageCred)) {
        setMakingApiCall(true);
        dispatch(fetchAccess(body));
      }
    },
    [
      selectedList,
      accountInfoData,
      workspaceSelected.workspace_url,
      roleArnStorageCred,
    ]
  );

  useEffect(() => {
    setAccess(s3AccessVal?.ucxApiResponse);

    setMakingApiCall(false);
  }, [s3AccessVal]);

  useEffect(() => {
    const sourceArray = selectedList?.reduce((res, item) => {
      res.push(item.source);
      return res;
    }, []);

    if (sourceArray?.length > 0 && awsArnPattern.test(roleArnStorageCred)) {
      setMakingApiCall(true);
      s3Access(sourceArray);
    }
  }, [selectedList, roleArnStorageCred]);

  function enableNext() {
    if (
      (accountInfoData.cloudType === jsonData.awsTxt
        ? roleArnStorageCred === ""
        : azureConnector === "") ||
      azureConnectorErrorMsg
    )
      setDisableNext(true);
    else setDisableNext(false);
  }

  useEffect(() => {
    enableNext();
  }, [roleArnStorageCred, azureConnector]);

  async function submitMigration() {
    let triggerId = uuidv4().replace(/-/g, "").substring(0, 10);
    setId(triggerId);
    const postBody = JSON.stringify({
      ucxCredentialsData: {
        accId: accountInfoData.accountId,
      },
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,

      ucxPayload: {
        data: null,
        warehouseID: [],
        migrateGroups: false,
        upgradeClusters: null,
        workflows: null,
        jobs: null,
        runJobsAs: accountInfoData.userId
          ? accountInfoData.userId
          : accountInfoData.OAuthUser.userName,
        mountPointsUpgrade: selectedList
          ? {
              storageCredentialArn: roleArnStorageCred,
              azureAccessConnectorId: azureConnector,
              mountpoints: listToObjectVal(selectedList),
              upgradeSecurityModelToUc: selectPermissions,
            }
          : null,
      },
      workspaceName: workspaceSelected.workspace_name,
      workspaceUrl: workspaceSelected.workspace_url,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      region: workspaceSelected.region,
      triggerId: triggerId,
    });
    setMakingApiCall(true);
    await ucMigrationCall(workspaceSelected.workspace_id, postBody);
    setMakingApiCall(false);
  }

  useEffect(() => {
    if (startMigration) submitMigration();
  }, [startMigration]);

  return (
    <div>
      <LoadingSpinner open={makingApiCall} />

      {accountInfoData.cloudType === jsonData.awsTxt && (
        <>
          {" "}
          <div>
            <UcmaTextField
              name={jsonData.roleArnStorageCredText}
              info={jsonData.roleArnStorageCredTooltip}
              value={roleArnStorageCred}
              onChangeValue={setRoleArnStorageCred}
              placeholder={jsonData.arnPlaceholder}
              setDisableSubmit={setRoleArnStorageCredErrorMsg}
              required
              isDialog
              pattern={awsArnPattern}
            />
          </div>
          <div className={`${styles["icon"]} ${styles["margin-top-26"]}`}>
            <div className={`${styles["button-border"]} `}>
              <Checkbox
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: "black",
                  },
                }}
                checked={selectPermissions}
                onChange={handleChange}
              />
              <label> {jsonData.upgradeSecurityModelText}</label>
            </div>
            <div className={styles["margin-top-icon"]}>
              <Tooltip title={jsonData.upgradeSecurityModelTooltip}>
                <InfoOutlinedIcon />
              </Tooltip>
            </div>
          </div>
        </>
      )}

      {accountInfoData.cloudType === jsonData.azureTxt && (
        <>
          {" "}
          <UcmaTextField
            name={jsonData.accessAzureConnector}
            info={jsonData.accessAzureConnectorInfoText}
            value={azureConnector}
            onChangeValue={setAzureConnector}
            placeholder={jsonData.accessAzureConnectorPlaceholder}
            setDisableSubmit={setAzureConnectorErrorMsg}
            required
            isDialog
            pattern={azureConnectorPattern}
          />
        </>
      )}

      {selectedList &&
        awsArnPattern.test(roleArnStorageCred) &&
        selectedList?.length > 0 && (
          <TableContainer className={styles["table-paper"]} component={Paper}>
            <Table
              className={styles["min-width-650"]}
              stickyHeader
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {tableHeadings.map((label) => {
                    return (
                      <TableCell
                        className={styles["table-row-heading"]}
                        key={label}
                      >
                        {label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedList?.map((item) => (
                  <TableRow key={item.source}>
                    <TableCell className={styles["table-val"]}>
                      {item.source}
                    </TableCell>
                    <TableCell className={styles["table-val"]}>
                      {item.mount_point}
                    </TableCell>
                    <TableCell
                      className={styles["table-val"]}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {access && (
                        <>
                          {access[item.source]}{" "}
                          {access[item.source] === jsonData.overLappngText && (
                            <i
                              className="fa fa-info-circle"
                              title={jsonData.overlappingInfoText}
                              aria-hidden="true"
                            />
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
    </div>
  );
};

export default MountPoints;
