import styles from "../NewComponent.module.css";
import { useEffect, useState } from "react";
import jsonData from "../../Assets/i18n/en.json";
import AcrossWorkspaceInfo from "../Common/AccrossWorkspaceInfo.jsx/AcrossWorkspaceInfo";
import { cloneMountpointAcrossCall } from "../../Resources/apicalls";
import { useSelector } from "react-redux";
import LoadingSpinner from "../UI/LoadingSpinner";
import { listToValObject } from "../Common/ucxFunction/getAccessMode";
import { GenerateUid } from "../../utils/GenerateUid/GenerateUid";

function CloneMountPoint(props) {
  const {
    startCloning,
    setDisableNext,
    selectedList,
    setClonedata,
    setId,
    disableNext,
  } = props;
  const accountInfoData = useSelector((state) => state.accountInfo);
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice.selectedWorkspace[0]
  );
  const workspaceConfig = useSelector(
    (state) => state.workspaceConfig.data[workspaceSelected.workspace_id]
  );
  const [externalWsToken, setExternalWsToken] = useState("");
  const [externalWsUrl, setExternalWsUrl] = useState("");
  const [makingApiCall, setMakingApiCall] = useState(false);

  let payload = {
    accId: workspaceSelected.account_id,
    workspaceIdentifier: workspaceSelected.workspace_id,
    workspaceName: workspaceSelected.workspace_name,
    OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
    workspaceUrl: workspaceSelected.workspace_url,
    cloudType: accountInfoData.cloudType,
    patAccess: accountInfoData.patAccess,
    crossWorkspaceUrl: externalWsUrl,
    crossWorkspacePatToken: externalWsToken,
    mountpointsClone: {
      mountpoints: listToValObject(selectedList),
      instanceProfileArn: workspaceConfig.awsInstanceProfileArn,
      upgradeSecurityModelToUc: false,
      clientId: workspaceConfig.clientId,
      clientSecret: workspaceConfig.clientSecret,
      directoryId: workspaceConfig.tenantId,
      azureContainerName: workspaceConfig.azureStorageAccountName,
      azureStorageAccountKey: workspaceConfig.azureStorageAccountKey,
    },
    runJobAs: accountInfoData.userId
      ? accountInfoData.userId
      : accountInfoData.OAuthUser.userName,
  };

  const CloneMountPoint = async () => {
    let triggerId = GenerateUid();
    setId(triggerId);
    cloneMountpointAcrossCall({
      ...payload,
      triggerId: triggerId,
    });

    setClonedata(true);
    setMakingApiCall(false);
  };

  const enableNext = () => {
    if (externalWsToken === "" || externalWsUrl === "") setDisableNext(true);
    else setDisableNext(false);
  };

  useEffect(() => {
    if (startCloning) CloneMountPoint();
  }, [startCloning]);

  useEffect(() => {
    enableNext();
  }, [externalWsToken, externalWsUrl, disableNext]);

  return (
    <>
      <LoadingSpinner open={makingApiCall} />
      <div className={styles["margin-top-20"]} />

      <AcrossWorkspaceInfo
        setDisableNext={setDisableNext}
        setExternalWsToken={setExternalWsToken}
        setExternalWsUrl={setExternalWsUrl}
      />
    </>
  );
}

export default CloneMountPoint;
