import { useEffect, useState } from "react";
import jsonData from "../../Assets/i18n/en.json";
import classes1 from "./LineageUtility.module.css";
import { useSelector } from "react-redux";
import HeadingWithInfo from "../Common/HeadingWithInfo/HeadingWithInfo";
import UcmaSubmitButtons from "../UI/Form/ButtonsContainer";
import { useCommonPayload } from "../../Resources/useCommonPayload";
import { triggerLineageUtilityCall } from "./LineageUtility.service";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import BasicAlerts from "../Common/MessageBanner.jsx/MessageBanner";
import { formattedDbfsName } from "../Common/Migration/formattedTableNames";
import constants from "../../utils/constant";
import { Close } from "@mui/icons-material";
import { HmsGlueSelection } from "../Common/HmsGlueSelection/HmsGlueSelection";
import { GenerateUid } from "../../utils/GenerateUid/GenerateUid";

export default function LineageUtility(props) {
  const [workflowType, setWorkflowType] = useState([]);
  const [disableNext, setDisableNext] = useState(true);
  const [loading, setLoading] = useState(false);
  const [migrationType, setMigrationType] = useState("GLUE");
  const accountInfoData = useSelector((state) => state.accountInfo);
  const [id, setId] = useState(null);
  const [showAlertMigration, setShowAlertMigration] = useState(false);
  const commonPayload = useCommonPayload();
  const selectedWorkspaceIdentifier = useSelector(
    (state) => state.workspaceConfig.selectedValue
  );
  const workspaceConfig = useSelector((state) => state.workspaceConfig.data);
  const config = workspaceConfig[selectedWorkspaceIdentifier.selectedValue];

  const handleSubmitClick = () => {
    let triggerId = GenerateUid();
    setId(triggerId);
    let workflowTypeString = formattedDbfsName(workflowType);
    workflowTypeString = workflowTypeString.replace("DATABRICKS", "NORMAL");

    let payload = {
      ...commonPayload,
      ucxPayload: {
        lineageUtilityRequest: {
          dataSource: "", //migrationType,
          dataRoleArn: config?.awsRoleArn,
          airflowDetails: workflowType.includes("AIRFLOW_WORKFLOW")
            ? {
                airflowUrl: config.airflowUrl,
                username: config.airflowUsername,
                password: config.airflowPassword,
              }
            : null,
          adfDetails: workflowType.includes("ADF")
            ? {
                accessToken: "",
                resourceGroup: config.resourceGroup,
                subscriptionId: config.subscriptionId,
                datafactoryName: config.dataFactoryName,
              }
            : null,
          workflowType: workflowTypeString,
        },
        runJobsAs: accountInfoData.userId
          ? accountInfoData.userId
          : accountInfoData.OAuthUser.userName,
      },
      existingAllPurposeClusterId: "",
      triggerId: triggerId,
    };
    setLoading(false);
    setShowAlertMigration(true);
    triggerLineageUtilityCall(payload);
  };
  useEffect(() => {
    disableSubmit();
  }, [workflowType]);

  const oChangeWorkflowType = (e) => {
    let value = e.target.value;
    setWorkflowType(value);
  };
  const disableSubmit = (e) => {
    if (!workflowType?.length) {
      setDisableNext(true);
      return;
    }

    setDisableNext(false);
  };
  const renderLineageDropdown = (selected) => {
    let arr = selected.map(
      (el) =>
        constants.LineageKeyValuePair.find((kvp) => kvp.passValue === el).name
    );
    return arr.join(",");
  };

  return (
    <>
      <div className={classes1["headingContainer"]}>
        <span className={classes1["header"]}>{jsonData.lineageUtility}</span>
        <Close onClick={() => props.onclose(false)} />
      </div>
      {showAlertMigration && !loading && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.lineageTriggered}
              <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertMigration}
          showReview={showAlertMigration}
          selectComponent={props.selectComponent}
          showSummaryButton="true"
        />
      )}

      {/* <HmsGlueSelection
        selectedValue={migrationType}
        setSelectedValue={setMigrationType}
      /> */}
      <FormControl
        sx={{
          "& > :not(style)": { width: "40.75rem" },
        }}
        size="small"
      >
        <HeadingWithInfo
          label={jsonData.workflowType}
          info={jsonData.workflowTypeInfo}
          isDialog
        />
        <Select
          style={{ marginTop: "12px" }}
          id="demo-multiple-checkbox"
          multiple
          value={workflowType}
          onChange={oChangeWorkflowType}
          input={<OutlinedInput />}
          renderValue={(selected) => renderLineageDropdown(selected)}
        >
          {constants.LineageKeyValuePair.filter(
            (item) =>
              !(
                item.passValue === "ADF" &&
                accountInfoData.cloudType === jsonData.awsTxt
              )
          ).map((item) => (
            <MenuItem key={item.name} value={item.passValue}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <UcmaSubmitButtons
        secondaryButtonLabel={jsonData.canceltxt}
        primaryButtonLabel={jsonData.startText}
        handleReset={() => props.onclose(false)}
        disableSubmit={disableNext}
        handleSubmit={handleSubmitClick}
      />
    </>
  );
}
