import {
  Checkbox,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import styles from "../NewComponent.module.css";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import jsonData from "../../Assets/i18n/en.json";
import CachedIcon from "@mui/icons-material/Cached";
import LoadingSpinner from "../UI/LoadingSpinner";
import CommonMigration from "../Common/Migration/Migration";
import { dbfslistCall } from "../../Resources/apicalls";
import SearchIcon from "@mui/icons-material/Search";
import BasicAlerts from "../Common/MessageBanner.jsx/MessageBanner";

function Dbfs(props) {
  const { selectComponent } = props;
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice.selectedWorkspace[0]
  );
  const [makingApiCall, setMakingApiCall] = useState(false);
  const accountInfoData = useSelector((state) => state.accountInfo);
  const [apiTime, setApiTime] = useState("");
  const [listSelected, setListSelected] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [dbfsListData, setDbfsListData] = useState([]);
  const [filteredDbfsListData, setFilteredDbfsListData] = useState([]);
  const [close, setClose] = useState(false);
  const [showCloneSummary, setShowCloneSummary] = useState(false);
  const [clonedata, setClonedata] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showAlertMigration, setShowAlertMigration] = useState(false);
  const [id, setId] = useState("");
  const [validatedData, setValidatedData] = useState({});
  const [showFailedResponse, setShowFailedResponse] = useState(false);
  const [validateBtnDisable, setValidateBtnDisable] = useState(true);
  const [showAlertValidate, setShowAlertValidate] = useState(false);

  const getDbfs = async () => {
    let payload = {
      ucxCredentialsData: {
        accId: workspaceSelected.account_id,
      },
      workspaceName: workspaceSelected.workspace_name,
      workspaceUrl: workspaceSelected.workspace_url,
      workspaceIdentifier: workspaceSelected.workspace_id,
      OAuth: accountInfoData.credentialType === "Oauth",
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      dbfsRootPath: "/",
    };

    setMakingApiCall(true);
    let response = await dbfslistCall(payload);
    setMakingApiCall(false);
    setDbfsListData(response?.ucxApiResponse);
    setApiTime(new Date());
  };

  const hadleRefresh = () => {
    getDbfs();
    setListSelected(false);
    setSelectedList([]);
  };

  useEffect(() => {
    if (close || showCloneSummary) hadleRefresh();
    setClose(false);
  }, [close, showCloneSummary]);

  useEffect(() => {
    getDbfs();
  }, [workspaceSelected]);

  const handleAllCheckboxClick = (event) => {
    if (event.target.checked) {
      setSelectedList(dbfsListData?.map((item) => item.path) || []);
      setListSelected(true);
    } else {
      setSelectedList([]);
      setListSelected(false);
    }
  };

  const handleCheckboxClick = (name) => {
    const isSelected = selectedList.includes(name);

    if (isSelected) {
      setSelectedList((prevSelected) =>
        prevSelected.filter((item) => item !== name)
      );
      setListSelected(!listSelected);
    } else {
      setSelectedList((prevSelected) => [...prevSelected, name]);
      setListSelected(true);
    }
  };

  useEffect(() => {
    if (selectedList?.length > 0) setListSelected(true);
    else setListSelected(false);
  }, [selectedList]);

  useEffect(() => {
    if (validatedData[0] === "VALIDATION_JOB_FAILED") {
      setShowFailedResponse(true);
      setTimeout(() => {
        setShowFailedResponse(false);
      }, 6000);
    }
  }, [validatedData]);

  useEffect(() => {
    let validateBtn = false;
    for (let index in selectedList) {
      dbfsListData.forEach((item) => {
        if (
          item.path === selectedList[index] &&
          !item.StatesList.includes("MIGRATED")
        ) {
          validateBtn = true;
        }
      });
    }
    setValidateBtnDisable(validateBtn);
  }, [selectedList]);

  useEffect(() => {
    if (searchKeyword?.length < 1) setFilteredDbfsListData(dbfsListData);
    else
      setFilteredDbfsListData(
        dbfsListData.filter(
          (item) =>
            item?.path.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item?.path.includes(searchKeyword)
        )
      );
  }, [searchKeyword, dbfsListData]);

  return (
    <>
      {showAlertMigration && !makingApiCall && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startMigrationmsg}
              <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertMigration}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      {showFailedResponse && (
        <BasicAlerts
          severity="error"
          label={<>{jsonData.validationJobFailed}&nbsp;</>}
          setShowReview={setShowFailedResponse}
          showReview={showFailedResponse}
          selectComponent={selectComponent}
          showSummaryButton={false}
        />
      )}

      {showCloneSummary && !makingApiCall && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startClonemsg} <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowCloneSummary}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      {showAlertValidate && !makingApiCall && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startValidatemsg} <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertValidate}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      <LoadingSpinner open={makingApiCall} />
      <div className={styles["display"]}>
        <div className={styles["button-container"]}>
          <div className={styles["basic-details-heading"]}>
            {jsonData.listofDbfstext} ( {filteredDbfsListData?.length} )
          </div>
        </div>

        <div className={styles["float-right"]}>
          <Paper className={`${styles["input-box"]}`}>
            <IconButton
              type="button"
              className={`${styles["padding"]}`}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              className={`${styles["search-input"]}`}
              placeholder={jsonData.srchPath}
              type="text"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </Paper>

          <CommonMigration
            setValidatedData={setValidatedData}
            migrationSelected={jsonData.dbfstoDbfsVal}
            selectedList={selectedList}
            showMigrate={true}
            validateBtnDisable={validateBtnDisable}
            title={jsonData.dbfstoVolumeMigration}
            titleValidation={jsonData.dbfstoVolumeMigrationVal}
            setClose={setClose}
            makingApiCall={makingApiCall}
            listSelected={listSelected}
            setListSelected={setListSelected}
            titleClone={jsonData.dbfsCloneheading}
            setShowCloneSummary={setShowCloneSummary}
            setShowAlertValidate={setShowAlertValidate}
            setShowReview={false}
            setShowAlertMigration={setShowAlertMigration}
            setClonedata={setClonedata}
            showClone={true}
            setId={setId}
          />
          <Tooltip title={`${jsonData.lastR} ${apiTime}`}>
            <CachedIcon onClick={hadleRefresh} />
          </Tooltip>
        </div>
      </div>

      {filteredDbfsListData?.length > 0 && (
        <TableContainer className={styles["table-container"]} component={Paper}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell className={styles["table-row-heading"]}>
                  <Checkbox
                    onClick={handleAllCheckboxClick}
                    checked={
                      selectedList?.length === filteredDbfsListData?.length
                    }
                  />
                  {jsonData.dbfsPath}
                </TableCell>
                <TableCell>{jsonData.stateList}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDbfsListData?.map((item, index) => (
                <TableRow key={item.source}>
                  <TableCell>
                    <Checkbox
                      onClick={() => handleCheckboxClick(item?.path)}
                      checked={selectedList?.includes(item?.path)}
                    />{" "}
                    {item.path}
                  </TableCell>
                  <TableCell>
                    {item?.StatesList[item?.StatesList?.length - 1]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default Dbfs;
