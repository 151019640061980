import DashboardTable from "../UI/CustomTable/DashboardTable";
import Typography from "@mui/material/Typography";
import jsonData from "../../Assets/i18n/en.json";
import styles from "./Dashboard.module.css";
import { Paper, Grid } from "@mui/material";
import {
  getAllPurposeCluster,
  getCall,
  getDbsqlClusterList,
  noteBookCountCall,
} from "../../Resources/apicalls";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import LoadingSpinner from "../UI/LoadingSpinner";
import BasicAlerts from "../Common/MessageBanner.jsx/MessageBanner";
import RenderAssesmentTable from "./AssesmentTable";
import constants from "../../utils/constant";
import CardComp from "./CardComponent";
import { dashBoardAssessmentApi } from "../../Resources/apiurls";

const Dashboard = (props) => {
  const {
    setDashboardRefresh,
    selectComponent,
    dashboardRefresh,
    setApiTime,
    startUCXAssessment,
    id,
  } = props;
  const [data, setData] = useState({});
  const [noteBookData, setNoteBookData] = useState(0);
  const [clusterData, setClusterData] = useState(0);
  const [dbsqlData, setDbsqlData] = useState(0);
  const [showReview, setShowReview] = useState(true);
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const [makingApiCall, setMakingApiCall] = useState(false);
  const accountInfoData = useSelector((state) => state.accountInfo);
  const workspaceInfo = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );

  const DashboardAssessmentCall = async () => {
    let api = `${dashBoardAssessmentApi}${workspaceInfo?.workspace_id}`;
    let response = await getCall(api);
    setData(response?.ucxApiResponse);
  };

  const NoteBookCall = async () => {
    let payload = {
      ucxCredentialsData: {
        id: null,
        accId: accountInfoData.accountId,
        databricksEmail: "",
        databricksPassword: "",
        databricksClientId: "",
        databricksSecret: "",
      },
      ucxPayload: null,
      workspaceIdentifier: null,
      workspaceName: null,
      workspaceUrl: workspaceSelected.workspace_url,
      workspaceBearerToken: null,
      region: null,
      ucxSummaryInfo: null,
      isUcxInstalled: false,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      jobInfo: null,
    };

    let response = await noteBookCountCall(payload);
    setNoteBookData(response?.ucxApiResponse?.noteBookCount);
  };

  const getAllPurposeClusterList = async () => {
    let postbody = {
      ucxCredentialsData: {
        accId: accountInfoData.accountId,
      },
      workspaceUrl: workspaceSelected.workspace_url,
      workspaceIdentifier: workspaceSelected.workspace_id,
      ucxPayload: null,
      ucxInstalled: false,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
    };
    setMakingApiCall(true);
    let response = await getAllPurposeCluster(postbody);
    setClusterData(response?.length);
    setMakingApiCall(false);
  };

  const dbsqlList = async () => {
    let postbody = {
      ucxCredentialsData: {
        accId: workspaceSelected.account_id,
      },
      workspaceUrl: workspaceSelected.workspace_url,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      workspaceIdentifier: workspaceSelected.workspace_id,
      ucxPayload: null,
    };

    let response = await getDbsqlClusterList(postbody);
    setDbsqlData(response?.ucxApiResponse?.warehouses?.length);
  };

  useEffect(() => {
    if (workspaceInfo?.workspace_id) {
      setMakingApiCall(true);
      DashboardAssessmentCall();
      NoteBookCall();
      dbsqlList();
      getAllPurposeClusterList();
      setMakingApiCall(false);
      setDashboardRefresh(false);
      setApiTime(new Date());
    }
  }, [workspaceInfo, dashboardRefresh]);

  const renderDashboardTable = (data, header, height) => {
    return (
      <DashboardTable
        tableHeight={height}
        data={{ header: header, data: data }}
      ></DashboardTable>
    );
  };

  const externalLocationData = () => {
    const tempData = data?.external_loactions?.reduce((acc, location) => {
      acc[location] = "";
      return acc;
    }, {});

    return renderTableComponent(
      tempData,
      constants.headerExternalLoactions,
      jsonData.externalLocation,
      "300px"
    );
  };

  const handleClick = (name) => {
    selectComponent(name);
  };

  const renderTableComponent = (data, header, title, height) => {
    return (
      <Paper className={`${styles["paper-padding"]}`}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        {RenderAssesmentTable(data, header, height)}
      </Paper>
    );
  };
  const renderDashboardTableComponent = (data, header, height, title) => {
    return (
      <Paper className={`${styles["paper-padding"]}`}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        {renderDashboardTable(data, header, height)}
      </Paper>
    );
  };

  if (!(Object.keys(data) || !noteBookData) && !makingApiCall) {
    return (
      <Grid item xs={8} mt={16}>
        <div className={`${styles["display-centre"]}`}>
          {jsonData.NoAssessMentResponse}
        </div>
      </Grid>
    );
  }
  return (
    <>
      <LoadingSpinner open={makingApiCall} />
      {startUCXAssessment && showReview && (
        <div className={styles["margin-bottom-16"]}>
          <BasicAlerts
            severity="info"
            setShowReview={setShowReview}
            showReview={showReview}
            label={
              <>
                {" "}
                {jsonData.dashboardUcxAssStart} <strong>{id}</strong>
                {jsonData.migrationTrack}&nbsp;
              </>
            }
            selectComponent={selectComponent}
            showSummaryButton="true"
          />
        </div>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              {CardComp(data.uc_readiness, jsonData.uc_readiness,false)}
            </Grid>

            <Grid item xs={2}>
              <div onClick={() => handleClick("Schema/Table")}>
                {CardComp(data.count_total_databases, jsonData.total_databases,true)}
              </div>
            </Grid>
            <Grid item xs={2}>
              <div onClick={() => handleClick("Schema/Table")}>
                {CardComp(data.count_total_tables, jsonData.total_tables,true)}
              </div>
            </Grid>
            <Grid item xs={2}>
              {CardComp(data.count_failures, jsonData.total_failures,false)}
            </Grid>
            <Grid item xs={2}>
              {CardComp(data.count_total, jsonData.location,false)}
            </Grid>
            <Grid item xs={2}>
              <div onClick={() => handleClick("Schema/Table")}>
                {CardComp(data.count_total_views, jsonData.total_views,true)}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid container item spacing={2} xs={12}>
                  <Grid onClick={() => handleClick(jsonData.jobs)} item xs={6}>
                    {CardComp(data.count_total_jobs, jsonData.total_jobs,true)}
                  </Grid>
                  <Grid onClick={() => handleClick(jsonData.notebookText)} item xs={6}>
                    {CardComp(noteBookData, jsonData.notebook_count,true)}
                  </Grid>
                  <Grid onClick={() => handleClick(jsonData.clusters)} item xs={6}>
                    {CardComp(clusterData, jsonData.clusterCount,true)}
                  </Grid>
                  <Grid onClick={() => handleClick(jsonData.dbsqlClusters)} item xs={6}>
                    {CardComp(dbsqlData, jsonData.sqlWarehouses,true)}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {renderTableComponent(
                    data.readiness,
                    constants.headerReadiness,
                    jsonData.readiness,
                    "430px"
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {renderTableComponent(
                    data.assesment_summary,
                    constants.headerAssesmentSummary,
                    jsonData.assessmentSummary,
                    "320px"
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      {renderTableComponent(
                        data.table_count_by_storage,
                        constants.headerTableCountStorage,
                        jsonData.tableCountStorage,
                        "300px"
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {renderDashboardTableComponent(
                        data.table_count_by_schema_and_format,
                        constants.headerTableCountSchemaFormat,
                        "300px",
                        jsonData.tableSchemaFormat
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {renderDashboardTableComponent(
            data.database_summary,
            constants.headerDatabaseSummary,
            "420px",
            jsonData.databaseSummary
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {externalLocationData()}
            </Grid>
            <Grid item xs={6}>
              {renderTableComponent(
                data.mount_points,
                constants.headerMountPoints,
                jsonData.mountPoints,
                "300px"
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Dashboard;
