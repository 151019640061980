import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import classes from "./WorkspaceSummary.module.css";
import constants from "../../utils/constant";
import EllipsisTooltip from "../UI/Tooltip/Tooltip";
import { useState, useEffect } from "react";
import {
  functionDepreciated1Api,
  paginationCount,
} from "../../Resources/apiurls";
import { getCall } from "../../Resources/apicalls";
import NoData from "../Common/NoData/NoData";
import jsonData from "../../Assets/i18n/en.json";
import Pagination from "../Common/Pagination/Pagination";

const DepreciatedFunction = (props) => {
  const { id } = props;
  const [pageNo, setPageNo] = useState(0);
  const [depreciatedFunctionData, setDepreciatedFunctionData] = useState([]);
  const [pageSize] = useState(5);
  const [count, setCount] = useState(0);
  const [makingApiCall, setMakingApiCall] = useState(false);

  const featchData = async () => {
    const api = `${functionDepreciated1Api}${id}?pageNo=${pageNo}&pageSize=${pageSize}`;
    setMakingApiCall(true);
    const depreciatedData = await getCall(api);
    setMakingApiCall(false);
    setDepreciatedFunctionData(depreciatedData?.ucxApiResponse);
  };

  const handleChange = (event) => {
    setPageNo(event - 1);
  };

  const pagination = async () => {
    const api = `${paginationCount}${id}`;
    const count = await getCall(api);
    setCount(count?.ucxApiResponse?.deprecatedFunctionsCount);
  };

  useEffect(() => {
    pagination();
  }, []);

  useEffect(() => {
    featchData();
  }, [pageNo, pageSize]);



  return (
    <>
      <div>        
        <hr />

        <div className={classes['notebook-header']}>{jsonData.depreciatedFunction} ({count})</div>
        {depreciatedFunctionData?.length ? (
          <div>
            <Paper className={classes["paper"]}>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow className={classes["table-row-height"]}>
                      {constants.DepreciatedFunctionHeader.map((head) => (
                        <TableCell className={classes["table-heading"]} key={head}>
                          {head}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {depreciatedFunctionData?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <EllipsisTooltip>{item.notebookPath}</EllipsisTooltip>
                        </TableCell>
                        <TableCell>
                          <EllipsisTooltip>{item.missingFunction}</EllipsisTooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Pagination
              totalPages={Math.ceil(count / pageSize)}
              currentPage={pageNo + 1}
              onPageChange={handleChange}
            />
          </div>) : null}
      </div>
    </>
  );
};

export default DepreciatedFunction;
