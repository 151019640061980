import classes from "./Migration.module.css";
import ButtonStyled from "../../UI/NavigationButtons/Buttons";
import jsonData from "../../../Assets/i18n/en.json";
import LoadingSpinner from "../../UI/LoadingSpinner";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    backgroundColor: "#fff",
    color: "black",
    padding: "16px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

function DeleteDailog({
  open,
  onClose,
  makingApiCall,
  migrationSelected,
  handleDeleteClick,
  setShowReview,
}) {
  const [startValidation, setStartValidation] = useState(false);
  const [disableNext, setDisableNext] = useState(true);


  useEffect(() => {
    if (startValidation) {
      setStartValidation(false);
      setShowReview(true);
    }
  }, [startValidation]);

  useEffect(() => {
    if (migrationSelected !== "table") {
      setDisableNext(false);
    }
  }, [migrationSelected, setDisableNext]);

  let contentComponent;
  switch (migrationSelected) {
    case "table":
      contentComponent = "";
      break;

    default:
      contentComponent = <div>{jsonData.deleteMsg}</div>;
      break;
  }

  function handleDeleteClickClose() {
    handleDeleteClick();
    onClose();
  }

  return (
    <BootstrapDialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{
        style: {
          minWidth: "500px",
          minHeight: "150px",
        },
      }}
    >
      <LoadingSpinner open={makingApiCall} />
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className={classes["dialog-heading"]}>
        
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
       </IconButton>
      <DialogContent>{contentComponent}</DialogContent>
      <DialogActions>
        <div className={classes["button-container"]}>
          <ButtonStyled
            label={jsonData.canceltxt}
            background="transparent"
            variant="text"
            border="none"
            color="#24262C"
            onclick={onClose}
          />
          <ButtonStyled
            label={jsonData.delete}
            background="#457ECF"
            variant="contained"
            border="1px solid #457ECF"
            disabled={disableNext}
            onclick={handleDeleteClickClose}
          />
        </div>
      </DialogActions>
    </BootstrapDialog>
  );
}

export default DeleteDailog;
