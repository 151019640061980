import { useEffect, useState } from "react";
import ValidationResult from "../UcValidation/ValidationResult";
import constants from "../../utils/constant";
import NoData from "../Common/NoData/NoData";
import jsonData from "../../Assets/i18n/en.json";
import ValidationResultTable from "../UcValidation/ValidationResultTable";
import ValidationResultAutoloader from "../UcValidation/ValidationResultAutoloader";
import ValidationResultDbfs from "../UcValidation/ValidationResultDbfs";
import TableRealTime from "./TableRealtime";
import ScanResult from "../TableMapping/TableCreationResult";
import { LineageSummaryResult } from "../LineageCatalog/LineageSummaryResult";

export default function ResultDisplay(props) {
  const { result, id, entity, inputParameters, status } = props;
  const [tableHead, setTableHead] = useState([]);
  const [validatedData, setValidatedData] = useState({});

  const tableHeadMap = {
    JOB_VALIDATION: constants.jobTableHead,
    JOB_CLONING: constants.jobTableHead,
    JOB_CLONING_ACROSS_WORKSPACE: constants.jobTableHead,
    CLUSTER_CLONING_ACROSS_WORKSPACE: constants.clusterTableHead,
    SQL_WAREHOUSE_VALIDATION: constants.sqlTableHead,
    CLUSTER_CLONING: constants.clusterTableHead,
    CLUSTER_VALIDATION: constants.clusterTableHead,
    MOUNT_POINT_VALIDATION: constants.tableValidateHeadings,
    WORKSPACE_OBJECTS_CLONING_ACROSS_WORKSPACE: constants.dbfsCloneHead,
    MOUNT_POINTS_CLONING_ACROSS_WORKSPACE: constants.cloneTableHeadings,
    UC_PIPELINE_MIGRATION: constants.tableValidateHeadings,
    DBFS_CLONING_ACROSS_WORKSPACE: constants.cloneTableHeadings,
  };

  useEffect(() => {
    if (!result || result === "{}" ||Object.keys(result).length === 0) {
      setValidatedData([]);
      return;
    }

    let resultCluster;
    switch (entity) {
      case "CLUSTER_VALIDATION":
        resultCluster = Object.entries(JSON.parse(result)).map(
          ([id, value]) => {
            const values = value.match(/null/g);
            const isMatched = values && values.length === 3;
            return { [id]: isMatched ? "Matched" : "Unmatched" };
          }
        );
        setValidatedData(resultCluster);
        break;
      case "CLUSTER_CLONING":
      case "CLUSTER_CLONING_ACROSS_WORKSPACE":
      case "JOB_VALIDATION":
      case "JOB_CLONING":
      case "JOB_CLONING_ACROSS_WORKSPACE":
      case "UC_PIPELINE_MIGRATION":
        resultCluster = Object.entries(JSON.parse(result)).map(
          ([id, value]) => {
            return { [id]: value };
          }
        );
        setValidatedData(resultCluster);
        break;

      case "MOUNT_POINTS_CLONING_ACROSS_WORKSPACE":
      case "MOUNT_POINT_VALIDATION":
        setValidatedData(JSON.parse(result));
        break;

      case "DBFS_CLONING_ACROSS_WORKSPACE":
        resultCluster = JSON.parse(result).map(({ Path, status }) => {
          return { [Path]: status };
        });
        setValidatedData(resultCluster || []);
        break;

      case "SQL_WAREHOUSE_VALIDATION":
        resultCluster = Object.entries(JSON.parse(result)).map(
          ([id, value]) => {
            const isMatched = value?.includes("Upto date");
            return { [id]: isMatched ? "Matched" : "Unmatched" };
          }
        );
        setValidatedData(resultCluster);
        break;

      case "WORKSPACE_OBJECTS_CLONING_ACROSS_WORKSPACE":
        resultCluster = JSON.parse(result).map((obj, index) => {
          return { [index + 1]: obj };
        });
        setValidatedData(resultCluster);
        break;

      default:
        setValidatedData([]);
        break;
    }
    setTableHead(tableHeadMap[entity] || []);
  }, [result, entity]);

  if (validatedData.length > 0) {
    return (
      <ValidationResult
        headValues={tableHead}
        cellValuesAsObjects={validatedData}
      />
    );
  } else if (
    Object.keys(result).length !== 0 &&
    result !== "{}" &&
    entity === "TABLE_VIEW_CLONING"
  ) {
    return <ValidationResultTable result={result} />;
  } else if (
    Object.keys(result).length !== 0 &&
    result &&
    (entity === "AUTOLOADER_VALIDATION" || entity === "UC_AUTOLOADER_MIGRATION")
  ) {
    return <ValidationResultAutoloader result={result} />;
  } else if (
    entity === "UC_TABLES_MIGRATION" ||
    entity === "UC_VIEWS_MIGRATION"
  ) {
    return (
      <TableRealTime
        triggerId={id}
        inputParameters={inputParameters}
        status={status}
        entity={entity}
      />
    );
  } else if (
    Object.keys(result).length !== 0 &&
    (entity === "DBFS_TO_VOLUME_VALIDATION" ||
      entity === "UC_DBFS_TO_VOLUME_MIGRATION")
  ) {
    return <ValidationResultDbfs entity={entity} result={result} />;
  } else if (entity === "LINEAGE_UTILITY") {
    return <LineageSummaryResult id={id} status={status} />;
  } else if (
    entity === "NOTEBOOK_TABLE_CREATION" ||
    entity === "NOTEBOOK_CLOUD_LOCATIONS"
  ) {
    return <ScanResult triggerId={id} entity={entity} />;
  } else return <NoData label={jsonData.noData} />;
}
