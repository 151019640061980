import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import styles from "./HeadingWithInfo.module.css";

const HeadingWithInfo = (props) => {
  const { label, info, isDialog } = props;
  return (
    <div
      className={`${styles.display} ${
        isDialog ? styles.headings : styles.heading
      }`}
    >
      {label}
      {info && info !== null && (
        <div className={`${styles["margin-left"]}`}>
          <Tooltip
            title={info.split("\n").map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          >
            <InfoOutlinedIcon />
          </Tooltip>
        </div>
      )}
    </div>
  );
};
export default HeadingWithInfo;
