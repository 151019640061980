import React, { useEffect, useState } from "react";
import LoadingSpinner from "../UI/LoadingSpinner";
import jsonData from "../../Assets/i18n/en.json";
import classes from "./CreateMetastore.module.css";
import {
  TextField,
  MenuItem,
  Box,
  FormControl,
  InputLabel,
  Select,
  FormLabel,
} from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  NamePattern,
  awsArnPattern,
  storageRootPattern,
} from "../../utils/regex";
import {
  createMetaStore,
  getAccountUserList,
  getWorkSpaceList,
} from "../../Resources/apicalls";
import RadioGroup from "@mui/material/RadioGroup";
import StyledRadio from "../UI/StyledRadioButton/StyledRadio";
import ButtonStyled from "../UI/NavigationButtons/Buttons";
import { useFormValidation } from "../UI/Form/useFormValiditation";
import UcmaTextField from "../UI/Form/TextField";

function CreateMetastore(props) {
  const accountInfoData = useSelector((state) => state.accountInfo);
  const [metaStoreNm, setMetaStoreNm] = useState("");
  const [s3StorageRoot, setS3StorageRoot] = useState("");

  const [s3accessRoleArn, setS3accessRoleArn] = useState("");

  const [disableNext, setDisableNext] = useState(true);
  const [owner, setOwner] = useState("");
  const [ownerContent, setOwnerContent] = useState([]);
  const [region, setRegion] = useState("");
  const [makingApiCall, setMakingApiCall] = useState(false);
  const [availableRegion, setAvailableRegion] = useState([]);
  const [viewMetaStore, setViewMetaStore] = useState(false);
  const {isFormValid, setFieldValid} = useFormValidation(['metastoreName', 'storageRoot','accessrolearn'])

  async function fetchWorkspace() {
    setMakingApiCall(true);
    const body = {
      ucxCredentialsData: {
        accId: accountInfoData.accountId,
        databricksEmail: accountInfoData.userId,
        databricksPassword: accountInfoData.password,
        databricksClientId: accountInfoData.oAuthToken,
        databricksSecret: accountInfoData.oAuthSecret,
      },
    };

    let workspace = await getWorkSpaceList(body);
    setAvailableRegion(
      Array.from(new Set(workspace.map((item) => item.region)))
    );

    setMakingApiCall(false);
  }

  useEffect(() => {
    setOwnerContent(["Loading"]);
    if (accountInfoData.credentialType === "Oauth") {
      setMakingApiCall(true);
      fetchUsers();
      setMakingApiCall(false);
    }
  }, []);

  async function fetchUsers() {
    const body = {
      ucxCredentialsData: {
        accId: accountInfoData.accountId,
        databricksEmail: accountInfoData.userId,
        databricksPassword: accountInfoData.password,
        databricksClientId: accountInfoData.oAuthToken,
        databricksSecret: accountInfoData.oAuthSecret,
      },
    };
    const userData = await getAccountUserList(body);

    setOwnerContent(
      userData?.map((user) => (
        <MenuItem key={user?.id} value={user?.id}>
          {user?.userName}
        </MenuItem>
      ))
    );
  }

  function ownerDropdownHandler(event) {
    setOwner(event.target.value);
  }

  useEffect(() => {
    enableNext();
  }, [s3StorageRoot, metaStoreNm, s3accessRoleArn, owner]);

  function enableNext() {
    if (
      metaStoreNm === "" ||
      s3StorageRoot === "" ||
      s3accessRoleArn === "" ||
      (accountInfoData.credentialType === "Oauth" && owner === "")
    ) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  }

  function validateHandler(value, pattern, className, errorMessage, name) {
    if (value === "") {
      className("form-control is-invalid");
      errorMessage(`${name} is required`);
      setDisableNext(true);
    } else if (!pattern.test(value)) {
      className("form-control is-invalid");
      errorMessage(`Invalid ${name} name`);
      setDisableNext(true);
    } else {
      className("form-control is-valid");
      errorMessage(null);
    }
  }

  async function metaStoreAttach() {
    let postbody = {
      createMetastoreDetails: {
        name: metaStoreNm,
        region: region,
        s3BucketPath: s3StorageRoot,
        s3AccessRoleArn: s3accessRoleArn,
        metastorePrimaryOwnerId: owner,
      },
      ucxCredentialsData: {
        accId: accountInfoData.accountId,
        databricksEmail: accountInfoData.userId,
        databricksPassword: accountInfoData.password,
        databricksClientId: accountInfoData.oAuthToken,
        databricksSecret: accountInfoData.oAuthSecret,
      },
    };

    setMakingApiCall(true);
    await createMetaStore(postbody);
    setMakingApiCall(false);
    setViewMetaStore(true);
  }
  useEffect(() => {
    fetchWorkspace();
  }, []);

  useEffect(() => {
    if (viewMetaStore && props.selectedComponent !== "Dashboard") {
      props.selectComponent("Dashboard");
    }
  }, [viewMetaStore, props.selectedComponent]);

  return (
    <div>
      <LoadingSpinner open={makingApiCall} />
      <div className={classes["render-component-container"]}>
        <FormLabel className={classes["font-heading"]}>
          {jsonData.selectRegion}
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={region}
          onChange={(event) => setRegion(event.target.value)}
          className={classes["margin-top-14"]}
        >
          {availableRegion?.map((region) => (
            <StyledRadio key={region} label={region} value={region} />
          ))}
        </RadioGroup>
        <UcmaTextField
              name={jsonData.metaSoreNameText} 
              value={metaStoreNm}
              onChangeValue={setMetaStoreNm}
              required
              pattern={NamePattern}
              // placeholder={jsonData.notebookPlaceholder}
              setDisableSubmit={(inValid)=> setFieldValid('metastoreName', inValid)}
          />
      
        <UcmaTextField
              name={jsonData.s3StorageRootLabelText} 
              value={s3StorageRoot}
              onChangeValue={setS3StorageRoot}
              required
              pattern={storageRootPattern}
              // placeholder={jsonData.notebookPlaceholder}
              setDisableSubmit={(inValid)=> setFieldValid('storageRoot', inValid)}
          />
     
        <UcmaTextField
              name={jsonData.s3accessRoleArnLabelText} 
              // info={jsonData.sourceNotebookPathInfo}
              value={s3accessRoleArn}
              onChangeValue={setS3accessRoleArn}
              required
              pattern={awsArnPattern}
              // placeholder={jsonData.notebookPlaceholder}
              setDisableSubmit={(inValid)=> setFieldValid('accessrolearn', inValid)}
          />
       
        {accountInfoData?.credentialType === "Oauth" ? (
          <div className={`${classes["margin-top-34"]} `}>
            <FormControl
              title={jsonData.OwnerDbToolTip}
              className={`${classes["margin-top-34"]} ${classes["box-width"]}`}
              size="small"
            >
              <InputLabel id={jsonData.OwnerDbText}>
                {jsonData.OwnerDbText}
              </InputLabel>
              <Select
                labelId={jsonData.OwnerDbText}
                value={owner}
                onChange={(event) => ownerDropdownHandler(event)}
                label={jsonData.OwnerDbText}
              >
                {ownerContent || jsonData.listUnavailable}
              </Select>
            </FormControl>
          </div>
        ) : null}

        <div className={classes["button-container-end"]}>
          <ButtonStyled
            label={jsonData.proceed}
            background="#457ECF"
            variant="contained"
            border="1px solid #457ECF"
            disabled={disableNext}
            onclick={metaStoreAttach}
          />
          <ButtonStyled
            label={jsonData.canceltxt}
            background="#457ECF"
            variant="contained"
            border="1px solid #457ECF"
            onclick={() => setViewMetaStore(true)}
          />
        </div>
      </div>
    </div>
  );
}
CreateMetastore.propTypes = {
  selectComponent: PropTypes.func.isRequired,
  selectedComponent: PropTypes.func.isRequired,
};
export default CreateMetastore;
