import { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import jsonData from "../../Assets/i18n/en.json";
import Jobs from "./Jobs";
import DltPipeline from "./DltPipeline";
import { useSelector } from "react-redux";

export default function JobsFunction(props) {
  const accountInfoData = useSelector((state) => state.accountInfo);
  const { selectComponent } = props;
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label={jsonData.WorkFlwJobs} value="1" />
            <Tab label={jsonData.dltPipelineHeading} value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Jobs selectComponent={selectComponent} />
        </TabPanel>
        <TabPanel value="2">
          <DltPipeline selectComponent={selectComponent} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
