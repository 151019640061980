import classes from "./Migration.module.css";
import ButtonStyled from "../../UI/NavigationButtons/Buttons";
import jsonData from "../../../Assets/i18n/en.json";
import LoadingSpinner from "../../UI/LoadingSpinner";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import CloneCluster from "../../InteractiveCluster/CloneCluster";
import CloneTable from "../../Table/CloneTable";
import CloneMountPoint from "../../Mountpoints/CloneMountpoint";
import DbfsTodbfsClone from "../../Dbfs/DbfstodbfsClone";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    backgroundColor: "#fff",
    color: "black",
    padding: "16px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

function CloneDailog({
  open,
  onClose,
  makingApiCall,
  migrationSelected,
  handleCloneClick,
  setShowReview,
  setClonedata,
  setCloneType,
  titleClone,
  setExternalWsToken,
  setExternalWsUrl,
  selectedList,
  setId,
  databaseType,
  setShowCloneSummary,
  cloneType,
}) {
  const [startCloning, setStartCloning] = useState(false);
  const [disableNext, setDisableNext] = useState(true);
  const [cloneClicked, setCloneClicked] = useState(false);

  useEffect(() => {
    if (startCloning) {
      setStartCloning(false);
      if (migrationSelected === "mountpoints") {
        setShowReview(true);
      }
    }
  }, [startCloning]);

  const CloneClick = () => {
    if (handleCloneClick) {
      handleCloneClick();
    }
    setStartCloning(true);
    setCloneClicked(true);
    onClose();
  };

  let contentComponent;
  switch (migrationSelected) {
    case "interactiveCluster":
    case "jobs":
      contentComponent = (
        <CloneCluster
          setExternalWsToken={setExternalWsToken}
          setExternalWsUrl={setExternalWsUrl}
          setCloneType={setCloneType}
          setDisableNext={setDisableNext}
          cloneType={cloneType}
        />
      );
      break;

    case "table":
      contentComponent = (
        <CloneTable
          setDisableNext={setDisableNext}
          selectedList={selectedList}
          databaseType={databaseType}
          startCloning={startCloning}
          setId={setId}
          disableNext={disableNext}
          setShowCloneSummary={setShowCloneSummary}
          cloneClicked={cloneClicked}
        />
      );
      break;

    case "DbfsTodbfs":
      contentComponent = (
        <DbfsTodbfsClone
          selectedList={selectedList}
          setId={setId}
          startCloning={startCloning}
          setDisableNext={setDisableNext}
          setClonedata={setClonedata}
          setShowCloneSummary={setShowCloneSummary}
        />
      );
      break;

    case "mountpoints":
      contentComponent = (
        <CloneMountPoint
          setDisableNext={setDisableNext}
          disableNext={disableNext}
          selectedList={selectedList}
          setClonedata={setClonedata}
          setId={setId}
          startCloning={startCloning}
        />
      );
      break;

    default:
      contentComponent = <div>{jsonData.cloneMsg}</div>;
      break;
  }

  return (
    <BootstrapDialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{
        style: {
          minWidth: "550px",
          minHeight: "150px",
        },
      }}
    >
      <LoadingSpinner open={makingApiCall} />
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
        className={classes["dialog-heading"]}
      >
        {titleClone}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>{contentComponent}</DialogContent>
      <DialogActions>
        <div className={classes["button-container"]}>
          <ButtonStyled
            label={jsonData.canceltxt}
            background="transparent"
            variant="text"
            border="none"
            color="#24262C"
            onclick={onClose}
          />
          <ButtonStyled
            label={jsonData.cloneTxt}
            background="#457ECF"
            variant="contained"
            border="1px solid #457ECF"
            disabled={disableNext}
            onclick={CloneClick}
          />
        </div>
      </DialogActions>
    </BootstrapDialog>
  );
}

export default CloneDailog;
