import { Tooltip } from "@material-ui/core";
import { Cancel, CheckCircleOutline, HighlightOffOutlined, Pending, QuestionMarkOutlined, RemoveCircleOutline, RotateRight } from "@mui/icons-material";

export function StatusIndicator(props) {
    const { update_id, state, creation_time } = props;
    let icon;
    if (!state) {
        return null;
    }
    let formattedTime = "";
    if (creation_time) {
        let date = new Date(creation_time);
        formattedTime = date.toDateString() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
    }
    switch (state) {
        case 'COMPLETED':
            icon = <CheckCircleOutline color="success" stye={{ color: 'green' }} />;
            break;
        case 'RUNNING':
            icon = <RotateRight color="warning" />
            break;
        case 'PENDING':
            icon = <RemoveCircleOutline />
            break;
        case 'CANCELED':
            icon = <HighlightOffOutlined color="error" />
            break;
        case 'FAILED':
            icon = <Cancel color="error" />
            break;
        case 'WAITING_FOR_RESOURCES':
            icon = <Pending />
            break;
        default:
            icon = <QuestionMarkOutlined />;
    }
    return icon ? (<Tooltip title={<div style={{ fontSize: '14px' }}>
        <div>id: {update_id}</div>
        <div>created on : {formattedTime}</div>
        <div>status: {state}</div>

    </div>}>
        {icon}
    </Tooltip>) : null
}