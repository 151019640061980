import React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import style from "./MessageBanner.module.css";
import { Button } from "@mui/material";
import jsonData from '../../../Assets/i18n/en.json'

export default function BasicAlerts(props) {
  const { severity, label, setShowReview, selectComponent, showSummaryButton } =
    props;
  const handleClose = () => {
    setShowReview(false);
  };

  const handleClick = (name) => {
    selectComponent(name);
  };

  return (
    <Stack className={style["width"]} spacing={2}>
      <div className={style["alert-container"]}>
        <Alert
          severity={severity}
          className={style["alert"]}
          style={{ width: "100%" }}
          icon={false}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {label}&nbsp;
          {showSummaryButton && (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => handleClick("Summary")}
              className={style["margin-left"]}
            >
              {jsonData.summaryPage}{" "}
            </Button>
          )}
        </Alert>
      </div>
    </Stack>
  );
}
