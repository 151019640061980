import jsonData from "../../Assets/i18n/en.json";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NameWOhPattern } from "../../utils/regex";
import { ucMigrationCall } from "../../Resources/apicalls";
import getAccessMode, {
  ArrayToObject,
} from "../Common/ucxFunction/getAccessMode";
import LoadingSpinner from "../UI/LoadingSpinner";
import { v4 as uuidv4 } from "uuid";
import UcmaTextField from "../UI/Form/TextField";

const Jobs = (props) => {
  const { selectedList, startMigration, setDisableNext, setId } = props;
  const [defaultUnityCatalogName, setDefaultUnityCatalogName] = useState("");
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const accountInfoData = useSelector((state) => state.accountInfo);
  const [makingApiCalls, setMakingApiCalls] = useState(false);

  useEffect(() => {
    setDisableNext(false);
  }, []);

  let jobUpgradePayload = selectedList.map((job) => ({
    [job.job_id]: Object.fromEntries(
      job.settings.job_clusters?.map((cluster) => [
        cluster.job_cluster_key,
        getAccessMode(cluster.new_cluster),
      ]) || []
    ),
  }));

  async function submitMigration() {
    let triggerId = uuidv4().replace(/-/g, "").substring(0, 10);
    setId(triggerId);
    const postBody = JSON.stringify({
      ucxCredentialsData: {
        accId: accountInfoData.accountId,
        databricksEmail: accountInfoData.userId,
        databricksPassword: accountInfoData.password,
        databricksClientId: accountInfoData.oAuthToken,
        databricksSecret: accountInfoData.oAuthSecret,
      },
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      ucxPayload: {
        data: null,
        warehouseID: [],
        migrateGroups: false,
        upgradeClusters: null,
        workflows: null,

        jobs: selectedList
          ? {
              target_access_mode: ArrayToObject(jobUpgradePayload),
              initial_catalog_name: defaultUnityCatalogName,
            }
          : null,
        runJobsAs: accountInfoData.userId
          ? accountInfoData.userId
          : accountInfoData.OAuthUser.userName,
        mountPointsUpgrade: null,
      },
      workspaceName: workspaceSelected.workspace_name,
      workspaceUrl: workspaceSelected.workspace_url,
      workspaceIdentifier: workspaceSelected?.workspace_id,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      region: workspaceSelected.region,
      triggerId: triggerId,
    });
    setMakingApiCalls(true);
    await ucMigrationCall(workspaceSelected.workspace_id, postBody);
    setMakingApiCalls(false);
  }

  useEffect(() => {
    if (startMigration) submitMigration();
  }, [startMigration]);

  return (
    <div>
      <LoadingSpinner open={makingApiCalls} />
      <UcmaTextField
        name={jsonData.defaultUcClusterText}
        info={jsonData.defaultUcClusterInfo}
        value={defaultUnityCatalogName}
        onChangeValue={setDefaultUnityCatalogName}
        placeholder={jsonData.unityCatalogNamePlaceholder}
        setDisableSubmit={setDisableNext}
        pattern={NameWOhPattern}
        isDialog
      />
    </div>
  );
};
export default Jobs;
