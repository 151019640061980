import { LinearProgress } from "@mui/material";
import classes from './DynamicProgressBar.module.css'
function DynamicProgressBar(status) {
    let color;

    switch (status) {
      case "SUCCESS":
        color = "success";
        break;
      case "error":
        color = "warning";
        break;
      case "FAILED":
        color = "warning";
        break;  
      case "IN_PROGRESS":
        color = "info";
        break;

      default:
        color = "primary";
    }

    return (
      <LinearProgress
        color={color}
        variant="determinate"
        className={`${classes["mr-bt-20"]}`}
      />
    );
  }
  export default DynamicProgressBar;