import axios from "axios";
import {
  getWorkspacePipelinesCountApi,
  getWorkspacePipelinesListApi,
  migratePipelinesApi,
} from "../../Resources/apiurls";

export async function getWorkspacePipelineList(post) {
  let returnResponse = [];
  await axios
    .post(getWorkspacePipelinesListApi, post, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data.ucxApiResponse;
    })
    .catch((err) => {
      if (err.response && err.response.status === 302) {
        returnResponse = err.response.data;
      }
    });

  return returnResponse;
}

export async function getWorkspacePipelineCount(post) {
  let returnResponse = [];
  await axios
    .post(getWorkspacePipelinesCountApi, post, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data.ucxApiResponse;
    })
    .catch((err) => {
      if (err.response && err.response.status === 302) {
        returnResponse = err.response.data;
      }
    });

  return returnResponse;
}

export async function postPipelineMigrate(post) {
  let returnResponse = [];
  await axios
    .post(migratePipelinesApi, post, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data.ucxApiResponse;
    })
    .catch((err) => {
      if (err.response && err.response.status === 302) {
        returnResponse = err.response.data;
      }
    });

  return returnResponse;
}
