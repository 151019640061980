import { ListItem, ListItemButton, ListItemText, styled } from "@mui/material";
import styles from "./SideNav.module.css";
import GridIcon from "../../../Assets/Icons/grid.svg";

const StyledListItemText = styled(ListItemText)({
  paddingLeft: "1rem",
});

export const ListItemComponent = ({
  open,
  name,
  icon: Icon,
  text,
  selectedItem,
  handleListItemClick,
}) => {
  const isSelected = selectedItem === name;

  return (
    <ListItem
      disablePadding
      name={name}
      onClick={(e) =>
        handleListItemClick(e, e.currentTarget.getAttribute("name"))
      }
      style={{
        backgroundColor: isSelected ? "#1976d2" : "",
      }}
    >
      <ListItemButton>
        <Icon className={styles["color"]}>
          <img src={GridIcon} alt={name} />
        </Icon>
        <StyledListItemText
          primary={text}
          sx={{ opacity: open ? 1 : 0, color: "#fff" }}
        />
      </ListItemButton>
    </ListItem>
  );
};
