import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import classes from "./CustomTable.module.css";

export default function TableComponent(props) {
  const { header, data } = props.data;
  const tableHeight = props.tableHeight;

  const renderRowHaeader = () => {
    const cellList = header?.map((data) => {
      return <TableCell className={`${classes["table"]} `}>{data}</TableCell>;
    });

    return (
      <TableHead>
        <TableRow>{cellList}</TableRow>
      </TableHead>
    );
  };

  const returnCell = (data) => {
    const rowList = data?.map((cell, index) => {
      return (
        <TableCell component="th" scope="row">
          {cell}
        </TableCell>
      );
    });
    return rowList;
  };

  const retunRow = () => {
    const rowList = data?.map((rows, index) => {
      return (
        <TableRow
          key={index + 1}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          {returnCell(rows)}
        </TableRow>
      );
    });
    return rowList;
  };
  return (
    <TableContainer sx={{ height: tableHeight }}>
      <Table stickyHeader aria-label="sticky table">
        {renderRowHaeader()}
        <TableBody>{retunRow()}</TableBody>
      </Table>
    </TableContainer>
  );
}
