function getAccessMode(cluster) {
  let data_security_mode = cluster?.data_security_mode,
    custom_tags = cluster?.custom_tags;

  if (
    data_security_mode === "LEGACY_TABLE_ACL" ||
    data_security_mode === "LEGACY_PASSTHROUGH"
  ) {
    return "USER_ISOLATION";
  } else if (
    data_security_mode === "LEGACY_SINGLE_USER_STANDARD" ||
    data_security_mode === "LEGACY_SINGLE_USER" ||
    data_security_mode === null
  ) {
    return "SINGLE_USER";
  } else if (
    data_security_mode === "NONE" &&
    custom_tags?.ResourceClass === "SingleNode"
  ) {
    return "SINGLE_USER";
  } else if (data_security_mode === "NONE" && custom_tags === null) {
    return "USER_ISOLATION";
  } else {
    return "USER_ISOLATION";
  }
}

export default getAccessMode;

export function isDataSecurityMigrateEligible(cluster) {
  return (
    cluster === "NONE" || cluster === null || cluster?.startsWith("LEGACY_")
  );
}

export function JobMigrateEligiblityDataSecurityMode(securityModes) {
  return securityModes.some((mode) => isDataSecurityMigrateEligible(mode));
}

export function getDataSecurityModesList(clusters) {
  return clusters.map((cluster) => cluster?.new_cluster?.data_security_mode);
}

export function getDataSecurityMode(cluster){

  return cluster?.data_security_mode;
}

export function getDataSecurityModesList2(clusters){

  return clusters?.map((cluster)=>cluster?.data_security_mode);

}

export function ArrayToObject(list) {
  const mergedObj = {};
  list.forEach((obj) => {
    Object.assign(mergedObj, obj);
  });
  return mergedObj;
}

export function listToObjectVal(list) {
  return list.reduce((acc, obj) => {
    acc[obj.source] = obj.mount_point;
    return acc;
  }, {});
}

export function listToValObject(list) {
  return list.reduce((acc, obj) => {
    acc[obj.mount_point] = obj.source;
    return acc;
  }, {});
}
