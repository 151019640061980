import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import styles from "./StyledRadio.module.css";

const StyledRadio = (prop) => {
  const { label, value} = prop;
  return (
    <>
      <FormControlLabel
        sx={{
          "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
            color: "#000000 !important",
          },

          marginLeft: "-2px !important",
        }}
        className={`${styles["border-radio"]} `}
        value={value}
        control={<Radio />}
        label={
          <div  className={`${styles["icon"]} `}>
            <span>{label}</span>
          </div>
        }
      />
    </>
  );
};
export default StyledRadio;
