import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import styles from "./Dashboard.module.css";

const RenderAssesmentTable = (data, header, height) => {
  if (data) {
    const assesmantData = Object.keys(data).map((row, index) => {
      let label = row;
      return { rowLabel: label, rowValue: data[row] };
    });
    return (
      <TableContainer className={`${styles["maxHeight"]}`} sx={{ height }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow >
              {header?.map((head) => (
                <TableCell  className={`${styles["table"]}`} key={head}>{head}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {assesmantData?.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.rowLabel}</TableCell>
                <TableCell>{row.rowValue}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};
export default RenderAssesmentTable;
