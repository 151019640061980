import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import constants from "../../utils/constant";
import classes from "../NewComponent.module.css";
import EllipsisTooltip from "../UI/Tooltip/Tooltip";

const ValidationResultDbfs = (props) => {
  const { result, entity } = props;

  const resultParse = () => {
    let res = JSON.parse(result);
    return entity === "DBFS_TO_VOLUME_VALIDATION" ? res : JSON.parse(res);
  };

  const validatedData = resultParse();

  return (
    <TableContainer component={Paper} className={classes["table-container"]}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow className={classes["table-row-height"]}>
            {constants.dbfsToVolume.map((head) => (
              <TableCell className={classes["table-heading"]} key={head}>
                {head}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {validatedData?.length > 0 &&
            validatedData?.map((item, index) => (
              <TableRow key={index}>
                {entity === "DBFS_TO_VOLUME_VALIDATION" ? (
                  Object.entries(item).map(([key, value]) => (
                    <TableCell key={key} className={classes["table-cell-Dbfs"]}>
                      <EllipsisTooltip>
                        {Array.isArray(value) ? value.join(", ") : value}
                      </EllipsisTooltip>
                    </TableCell>
                  ))
                ) : (
                  <>
                    {" "}
                    <TableCell>{item.source_total_size}</TableCell>
                    <TableCell>{item.destination_total_size}</TableCell>
                    <TableCell>{item.source_files_count}</TableCell>
                    <TableCell>{item.destination_files_count}</TableCell>
                    <TableCell>{item.source_path}</TableCell>
                    <TableCell className={classes["table-cell-Dbfs"]}>
                      <EllipsisTooltip>{item.destination_path}</EllipsisTooltip>
                    </TableCell>
                    {/* <TableCell>NA</TableCell>
                    <TableCell>NA</TableCell>
                    <TableCell>NA</TableCell> */}
                  </>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default ValidationResultDbfs;
