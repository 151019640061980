import { useEffect, useState, useRef } from "react";
import jsonData from "../../Assets/i18n/en.json";
import classes from "../NewComponent.module.css";
import {
  Checkbox,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CommonMigration from "../Common/Migration/Migration";
import LoadingSpinner from "../UI/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import { getCall, listHmsTablecall } from "../../Resources/apicalls";
import PaginationComponent from "../Common/Pagination/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import { cloneTableDataActions } from "../../store/slice/tableCloneData-slice";
import DatabaseSelected from "./DatabaseSelected";
import { listGlueTablesApi } from "../../Resources/apiurls";
const NUM_OF_RECORD_PER_PAGE = 10;

function DatabaseList(props) {
  const {
    region,
    clusterId,
    databaseType,
    database,
    setId,
    setShowAlertMigration,
    setShowCloneSummary,
    setShowAlertValidate,
    clusterList,
    dataBaseListChecked,
    setDataBaseListChecked,
  } = props;
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const [makingApiCall, setMakingApiCall] = useState(false);
  const accountInfoData = useSelector((state) => state.accountInfo);
  const [selectedList, setSelectedList] = useState([]);
  const [selectedTable, setSelectedTable] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(NUM_OF_RECORD_PER_PAGE);
  const [listSelected, setListSelected] = useState(false);
  const [validateBtnDisable, setValidateBtnDisable] = useState(true);
  const [responseList, setResponseList] = useState([]);
  const [close, setClose] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [startValidation, setStartValidation] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousCurrent, setPreviousCurrent] = useState(null);
  const [filterTableList, setFilterTableList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const numberofElemenetsPerPage = NUM_OF_RECORD_PER_PAGE;
  let _totalPages = useRef(3);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cloneTableDataActions.resetCloneTableData());
  }, []);

  const hmsTableList = async () => {
    let Payload = {
      ucxCredentialsData: {
        accId: workspaceSelected.account_id,
      },
      workspaceIdentifier: workspaceSelected.workspace_id,
      workspaceUrl: workspaceSelected.workspace_url,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
    };
    setMakingApiCall(true);
    let response = await listHmsTablecall(Payload, database);
    setMakingApiCall(false);

    if (response?.ucxApiResponse) {
      setResponseList(response?.ucxApiResponse[database]);
      const res = response?.ucxApiResponse[database];
      const totalNumberOfElement = res?.length;
      _totalPages.current = Math.ceil(
        totalNumberOfElement / numberofElemenetsPerPage
      );
    }
  };

  const glueTableList = async () => {
    setMakingApiCall(true);
    let api = `${listGlueTablesApi}${workspaceSelected.workspace_id}/${region}/${database}`;
    let response = await getCall(api);
    setMakingApiCall(false);
    if (response) {
      setResponseList(response?.ucxApiResponse[database]);
    }
    const res = response?.ucxApiResponse[database];

    const totalNumberOfElement = res?.length;
    _totalPages.current = Math.ceil(
      totalNumberOfElement / numberofElemenetsPerPage
    );
  };

  const handlePageChange = (pageSize) => {
    setCurrentPage(pageSize);
  };

  useEffect(() => {
    const newStartIndex = (currentPage - 1) * itemsPerPage;
    const newEndIndex = Math.min(
      newStartIndex + itemsPerPage,
      filterTableList?.length
    );

    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
  }, [itemsPerPage, currentPage, filterTableList?.length]);

  useEffect(() => {
    if (!dataBaseListChecked?.length) {
      if (databaseType === jsonData.hmsValueText) hmsTableList();
      if (databaseType === jsonData.glueValueText) glueTableList();
    }

    setSelectedTable([]);
    setSelectedList([]);
    setCurrentPage(1);
  }, [
    databaseType,
    database,
    clusterId,
    workspaceSelected.workspace_id,
    dataBaseListChecked,
  ]);

  useEffect(() => {
    setSelectedTable([]);
    setSelectedList([]);
    setClose(false);
    setDataBaseListChecked([]);
  }, [close]);

  const handleAllCheckboxClick = (event) => {
    if (event.target.checked) {
      setSelectedTable(responseList?.map((item) => item?.name) || []);
    } else {
      setSelectedTable([]);
    }
  };

  const handleCheckboxClick = (name) => {
    const isSelected = selectedTable?.includes(name);
    if (isSelected) {
      setSelectedTable((prevSelected) =>
        prevSelected?.filter((item) => item !== name)
      );
    } else {
      setSelectedTable((prevSelected) => [...prevSelected, name]);
    }
  };

  useEffect(() => {
    if (selectedList?.length > 0) setListSelected(true);
    else setListSelected(false);
  }, [selectedTable, selectedList]);

  useEffect(() => {
    setSelectedList(
      responseList?.filter((item) => selectedTable?.includes(item.name))
    );
  }, [selectedTable]);

  useEffect(() => {
    if (searchKeyword?.length < 1) setFilterTableList(responseList);
    else {
      setCurrentPage(1);
      setFilterTableList(
        responseList?.filter((item) =>
          item?.name.toLowerCase()?.includes(searchKeyword.toLowerCase())
        )
      );
    }
  }, [searchKeyword, responseList]);

  useEffect(() => {
    if (searchKeyword.length === 1 && previousCurrent === null)
      setPreviousCurrent(currentPage);
    if (searchKeyword.length < 1 && previousCurrent !== currentPage) {
      setCurrentPage(previousCurrent);
      setPreviousCurrent(null);
    }
  }, [searchKeyword]);

  useEffect(() => {
    const validateBtn = selectedList?.some(
      (item) => !item?.StatesList?.includes("MIGRATED")
    );
    setValidateBtnDisable(validateBtn);
  }, [selectedList]);

  let pagWiseList = searchKeyword.length? filterTableList :filterTableList?.slice(startIndex,endIndex);
  return (
    <>
      <LoadingSpinner open={makingApiCall} />

      {responseList?.length ? (
        <>
          {" "}
          <div className={classes["display"]}>
            <div
              className={`${classes["button-container"]} ${classes["catlog-table-title"]}`}
            >
              <div className={classes["head-container"]}>
                {dataBaseListChecked?.length > 0 ? (
                  <>
                    {jsonData.databaseListTxt}
                    {`(${dataBaseListChecked?.length})`}
                  </>
                ) : (
                  <>
                    {jsonData.listOfTable}
                    {`(${filterTableList?.length || 0})`}
                  </>
                )}
              </div>
            </div>

            <div className={classes["srch-flex"]}>
              {!dataBaseListChecked?.length > 0 && (
                <Paper className={`${classes["input-box"]}`}>
                  <IconButton
                    type="button"
                    className={`${classes["padding"]}`}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    className={`${classes["search-input"]}`}
                    placeholder={jsonData.srchNm}
                    type="text"
                    value={searchKeyword}
                    onChange={(e) => setSearchKeyword(e.target.value)}
                  />
                </Paper>
              )}
              <CommonMigration
                migrationSelected="table"
                title={jsonData.tableMigration}
                titleClone={jsonData.cloneTableTxt}
                titleValidation={jsonData.tableValidation}
                setId={setId}
                selectedList={selectedList}
                setClose={setClose}
                databaseType={databaseType}
                makingApiCall={makingApiCall}
                listSelected={listSelected}
                setListSelected={setListSelected}
                setStartValidation={setStartValidation}
                startValidation={startValidation}
                validateBtnDisable={validateBtnDisable}
                setShowAlertMigration={setShowAlertMigration}
                setShowAlertValidate={setShowAlertValidate}
                setShowCloneSummary={setShowCloneSummary}
                showMigrate={true}
                showClone={dataBaseListChecked?.length > 0 ? false : true}
                clusterList={clusterList}
              />
            </div>
          </div>
          {dataBaseListChecked?.length > 0 ? (
            <DatabaseSelected
              dataBaseListChecked={dataBaseListChecked}
              clusterId={clusterId}
              setSelectedList={setSelectedList}
              databaseType={databaseType}
              region={region}
            />
          ) : (
            <>
              <div style={{ minHeight: "25rem" }}>
                <TableContainer component={Paper}>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            onClick={handleAllCheckboxClick}
                            checked={
                              selectedList?.length === responseList?.length
                            }
                          />
                          {"  "} {jsonData.tableText}
                        </TableCell>
                        <TableCell>{jsonData.tableTypetxt}</TableCell>
                        <TableCell>{jsonData.stateList}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pagWiseList
                        .map((item, index) => (
                          <TableRow
                            key={index}
                            style={{
                              opacity: item?.StatesList?.includes("IN_PROGRESS")
                                ? 0.5
                                : 1,
                            }}
                          >
                            <TableCell style={{ padding: "0px 16px" }}>
                              <Checkbox
                                onClick={() => handleCheckboxClick(item.name)}
                                checked={selectedTable?.includes(item.name)}
                                disabled={item?.StatesList?.includes(
                                  "IN_PROGRESS"
                                )}
                              />{" "}
                              {item.name}
                            </TableCell>
                            <TableCell>{item.object_type}</TableCell>
                            <TableCell>
                              {item?.StatesList[item?.StatesList?.length - 1]}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {(responseList?.length && searchKeyword.length < 1) && (
                <PaginationComponent
                  totalPages={_totalPages.current}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              )}

              
            </>
          )}
        </>
      ) : (
        <Grid item xs={8} mt={16}>
          <div className={`${classes["display-centre"]}`}>
            {jsonData.tableNotTxt}
          </div>
        </Grid>
      )}
    </>
  );
}

export default DatabaseList;
