import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import jsonData from "../../Assets/i18n/en.json";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { getCall, listHmsTablecall } from "../../Resources/apicalls";
import LoadingSpinner from "../UI/LoadingSpinner";
import PaginationComponent from "../Common/Pagination/Pagination";
import { listGlueTablesApi } from "../../Resources/apiurls";
const NUM_OF_RECORD_PER_PAGE = 12;

function DatabaseSelected(props) {
  const { dataBaseListChecked, region, setSelectedList, databaseType } = props;

  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const accountInfoData = useSelector((state) => state.accountInfo);
  const [makingApiCall, setMakingApiCall] = useState(false);
  const [responseList, setResponseList] = useState([]);
  const itemsPerPage = NUM_OF_RECORD_PER_PAGE;
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const numberofElemenetsPerPage = NUM_OF_RECORD_PER_PAGE;
  let _totalPages = useRef(3);

  const totalNumberOfElement = dataBaseListChecked?.length;
  _totalPages.current = Math.ceil(
    totalNumberOfElement / numberofElemenetsPerPage
  );

  useEffect(() => {
    const newStartIndex = (currentPage - 1) * itemsPerPage;
    const newEndIndex = Math.min(
      newStartIndex + itemsPerPage,
      responseList.length
    );

    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
  }, [itemsPerPage, currentPage, responseList.length]);

  const handlePageChange = (pageSize) => {
    setCurrentPage(pageSize);
  };

  const hmsTableList = async (database) => {
    let Payload = {
      ucxCredentialsData: {
        accId: workspaceSelected.account_id,
      },
      workspaceIdentifier: workspaceSelected.workspace_id,
      workspaceUrl: workspaceSelected.workspace_url,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
    };
    setMakingApiCall(true);
    let response = await listHmsTablecall(Payload, database);
    setMakingApiCall(false);

    if (response) {
      return response?.ucxApiResponse[database];
    }
  };

  const glueTableList = async (database) => {
    setMakingApiCall(true);
    let api = `${listGlueTablesApi}${workspaceSelected.workspace_id}/${region}/${database}`;
    let response = await getCall(api);
    setMakingApiCall(false);
    if (response) {
      return response?.ucxApiResponse[database];
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      const results = await Promise.all(
        dataBaseListChecked.map((database) =>
          databaseType === jsonData.glueValueText
            ? glueTableList(database)
            : hmsTableList(database)
        )
      );
      const combinedResults = results.flat(); 
      setSelectedList(combinedResults);
      setResponseList(combinedResults);
    };

    if (dataBaseListChecked?.length > 0) {
      fetchAllData();
    }
  }, [dataBaseListChecked]);

  return (
    <>
      <LoadingSpinner open={makingApiCall} />
      <div style={{ minHeight: "25rem" }}>
        <TableContainer component={Paper}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>{jsonData.DatabaseNmTxt}</TableCell>
                <TableCell>{jsonData.noOfTable}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataBaseListChecked
                ?.slice(startIndex, endIndex)
                ?.map((dbName) => (
                  <TableRow key={dbName}>
                    <TableCell>{dbName}</TableCell>
                    <TableCell>
                      {
                        responseList?.filter(
                          (item) => item?.database === dbName
                        ).length
                      }
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {dataBaseListChecked?.length && (
        <PaginationComponent
          totalPages={_totalPages.current}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
}

export default DatabaseSelected;
