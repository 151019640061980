import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  InputLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Paper,
  Alert,
  FormControl,
  FormLabel,
  Input,
  InputAdornment,
  IconButton,
  Box,
  MenuItem,
  Select,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedWorkspaceIdentifier } from "../../store/slice/workspaceConfigue-slice";
import LoadingSpinner from "../UI/LoadingSpinner";
import {
  awsDatabricksValidationCall,
  azureValidationCall,
  putOnSubmit,
  getAccountUserList,
} from "../../Resources/apicalls";
import jsonData from "../../Assets/i18n/en.json";
import {
  WorkspacetokenPattern,
  accountIdPattern,
  azureDatabricksUrlPattern,
  dbClientIdPattern,
  dbClientSecretPattern,
  passwordPattern,
  userNameEmailPattern,
} from "../../utils/regex";
import { getWorkSpaceList } from "../../store/thunk/workspace-thunk";
import { accountInfoStyle } from "./accountInfo_styles";
import { workspaceActions } from "../../store/slice/workspace-slice";
import { accountInfoActions } from "../../store/slice/accountInfo-slice";
import UcmaTextField from "../UI/Form/TextField";

const AccountInfo = (props) => {
  const dispatch = useDispatch();
  const accountInfoData = useSelector((state) => state.accountInfo);
  const [credentialType, setCredentialType] = useState(
    accountInfoData.credentialType
  );
  const [cloudType, setCloudType] = useState(accountInfoData.cloudType);
  const [disableNext, setDisableNext] = useState(true);
  const [accountId, setAccountId] = useState(accountInfoData.accountId);
  const [accountIdClassName, setAccountIdClassName] = useState("form-control");
  const [accountIdErrorMessage, setAccountIdErrorMessage] = useState(null);
  const [userId, setUserId] = useState(accountInfoData.userId);
  const [userIdClassName, setUserIdClassName] = useState("form-control");
  const [userIdErrorMessage, setUserIdErrorMessage] = useState(null);
  const [password, setPassword] = useState(accountInfoData.password);
  const [passwordClassName, setPasswordClassName] = useState("form-control");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);
  const [oAuthToken, setOAuthToken] = useState(accountInfoData.oAuthToken);
  const [oAuthTokenErrorMessage, setOAuthTokenErrorMessage] = useState(null);
  const [oAuthSecret, setOAuthSecret] = useState(accountInfoData.oAuthSecret);
  const [oAuthSecretClassName, setOAuthSecretClassName] =
    useState("form-control");
  const [oAuthSecretErrorMessage, setOAuthSecretErrorMessage] = useState(null);
  const [makingApiCall, setMakingApiCall] = useState(false);
  const [validationMessage, setValidationMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [externalWsUrl, setExternalWsUrl] = useState(
    accountInfoData.externalWsUrl
  );
  const [externalWsUrlClassName, setExternalWsUrlClassName] = useState("");
  const [externalWsUrlError, setExternalWsUrlError] = useState(null);
  const [externalWsToken, setExternalWsToken] = useState("");
  const [externalWsTokenClassName, setExternalWsTokenClassName] = useState("");
  const [externalWsTokenError, setExternalWsTokenError] = useState(null);
  const [dropdownData1, setDropdownData1] = useState([]);
  const [dropdownData2, setDropdownData2] = useState([]);
  const [selectedDropdown1, setSelectedDropdown1] = useState("");
  const [selectedDropdown2, setSelectedDropdown2] = useState("");
  const [dropdownDisable, setDropdownDisable] = useState(true);
  const [disableUserList, setDisableUserLIst] = useState(true);
  const [isUrlvalid, setIsUrlValid] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isConfigured, setIsConfigured] = useState(false);
  const [workspaceId, setSelectedWorkspaceId] = useState(null);

  const workspaceData = useSelector(
    (state) => state.workspaceSlice?.workspaceData
  );


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCredentialTypeChange = (event) => {
    setCredentialType(event.target.value);
    setUserId("");
    setPassword("");
    setOAuthSecret("");
    setOAuthToken("");
  };

  const handleCloudTypeChange = (event) => {
    setCloudType(event.target.value);
    setAccountId("");
    setUserId("");
    setPassword("");
    setOAuthSecret("");
    setOAuthToken("");
    setExternalWsToken("");
    setExternalWsUrl("");
  };

  async function infoValidation() {
    setValidationMessage("");
    setMakingApiCall(true);
    const saveBody = {
      accId: accountId,
      databricksEmail: userId,
      databricksPassword: password,
      databricksClientId: oAuthToken,
      databricksSecret: oAuthSecret,
    };

    if (credentialType === "Oauth") {
      window.sessionStorage.setItem("OAuth", true);
    } else window.sessionStorage.setItem("OAuth", false);

    let data, azureSubmit;

    if (cloudType === jsonData.awsTxt) {
      await putOnSubmit(saveBody);
      data = await awsDatabricksValidationCall(saveBody);
    }

    if (cloudType === jsonData.azureTxt) {
      let payload = {
        cloudType: cloudType,
        patToken: externalWsToken,
        workspaceUrl: externalWsUrl,
        patAccess: true,
      };
      azureSubmit = await azureValidationCall(payload);
    }

    if (cloudType === jsonData.awsTxt) {
      if (data === jsonData.invalidText || data.ucxApiResponse.valid === "false") {
        setValidationMessage(jsonData.invalidCredentialMsg);
        setMakingApiCall(false)
        setDropdownDisable(true);
        setDisableUserLIst(true);
        return "Error";
      } else {
        setValidationMessage("");
        setDropdownDisable(false);
        setDisableUserLIst(false);
        // submitHandler();
      }
    }

    if (cloudType === jsonData.azureTxt) {
      if (
        azureSubmit === jsonData.invalidText ||
        azureSubmit.success === "false"
      ) {
        setValidationMessage(jsonData.invalidCredentialMsg);
        setMakingApiCall(false)
        return "Error";
      } else {
        setValidationMessage("");
      }
    }
    setMakingApiCall(false);
  }

  useEffect(()=>{
    if(validationMessage) {
      setSelectedDropdown1("")
      setSelectedDropdown2("")
      setDropdownData1([])
      setDropdownData2([])
      setDropdownDisable(true)
    }
  },[validationMessage])
  async function handleBlurAzure() {
    const data = await infoValidation();
    if (data !== "Error") {
      setValidationMessage("")
      if (externalWsUrl && !externalWsUrlError) {
        setMakingApiCall(true);
        try {
          const body2 = {
            ucxCredentialsData: {
              accId: accountInfoData.accountId,
              databricksEmail: accountInfoData.userId,
              databricksPassword: accountInfoData.password,
              databricksClientId: accountInfoData.oAuthToken,
              databricksSecret: accountInfoData.oAuthSecret,
            },
            OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
            cloudType: cloudType,
            workspaceUrl: externalWsUrl,
            patAccess: true,
          };
          dispatch(getWorkSpaceList(body2));
          setMakingApiCall(false);
        } catch (error) {
          console.error("Error fetching dropdown data", error);
        }
      }
    }
  }

  async function handleBlurAws() {
    const data = await infoValidation();
    if (data !== "Error") {
      setMakingApiCall(true);
      try {
        let body = {};
        if (credentialType === jsonData.oauthTxt) {
          body = {
            ucxCredentialsData: {
              accId: accountId,
              databricksEmail: userId,
              databricksPassword: accountInfoData.password,
              databricksClientId: oAuthToken,
              databricksSecret: oAuthSecret,
            },
            OAuth: true,
            cloudType: cloudType,
            workspaceUrl: externalWsUrl,
            patAccess: false,
          };
        } else {
          body = {
            ucxCredentialsData: {
              accId: accountId,
              databricksEmail: userId,
              databricksPassword: accountInfoData.password,
              databricksClientId: oAuthToken,
              databricksSecret: oAuthSecret,
            },
            OAuth: false,
            cloudType: cloudType,
            workspaceUrl: externalWsUrl,
            patAccess: false,
          };
        }
        dispatch(getWorkSpaceList(body));
        setMakingApiCall(false);
      } catch (error) {
        console.error("Error fetching dropdown data", error);
      }
    }
  }

  function submitHandler() {
    let payload = {
      accountId,
      userId,
      password,
      credentialType,
      cloudType,
      oAuthSecret,
      oAuthToken,
      externalWsUrl,
      patAccess: externalWsToken !== "",
    };

    dispatch(accountInfoActions.setAccountInfoDetails(payload));
    props.setAccountId(userId);
    props.setAccountInfo(true);
    let workspacePayload = {
      selectedValue: workspaceId,
    };
    dispatch(setSelectedWorkspaceIdentifier(workspacePayload));
  }

  useEffect(() => {
    setValidationMessage("");
    enableNext();
  }, [
    accountId,
    userId,
    password,
    oAuthToken,
    oAuthSecret,
    cloudType,
    credentialType,
    externalWsUrl,
    externalWsTokenError,
    externalWsUrlError,
    externalWsToken,
    selectedDropdown1,
    selectedDropdown2,
  ]);

  useEffect(() => {
    setDropdownData2(workspaceData);
  }, [workspaceData, dropdownData2]);

  useEffect(() => {
    if (
      isTokenValid &&
      isUrlvalid &&
      azureDatabricksUrlPattern.test(externalWsUrl) &&
      WorkspacetokenPattern.test(externalWsToken)
    ) {
      handleBlurAzure();
      setDropdownDisable(false);
    } else {
      setDropdownDisable(true);
    }
  }, [isUrlvalid, isTokenValid, externalWsToken, externalWsUrl]);

  useEffect(() => {
    if (
      accountIdPattern.test(accountId) &&
      dbClientIdPattern.test(oAuthToken) &&
      dbClientSecretPattern.test(oAuthSecret)
    ) {
      handleBlurAws();
      setDropdownDisable(false);
    } else {
      setDropdownDisable(true);
    }
  }, [accountId, oAuthToken, oAuthSecret]);

  useEffect(() => {
    if (!selectedDropdown2) {
      setDisableUserLIst(true);
    } else {
      setDisableUserLIst(false);
    }
  }, [selectedDropdown2]);

  function enableNext() {
    if (cloudType === jsonData.awsTxt) {
      if (accountId === "") setDisableNext(true);
      else if (
        credentialType === "login" &&
        (userId === "" ||
          password === "" ||
          !selectedDropdown1 ||
          !selectedDropdown2)
      )
        setDisableNext(true);
      else if (
        credentialType === "Oauth" &&
        (oAuthToken === "" ||
          oAuthSecret === "" ||
          oAuthTokenErrorMessage ||
          oAuthSecretErrorMessage ||
          !selectedDropdown1 ||
          !selectedDropdown2)
      )
        setDisableNext(true);
      else setDisableNext(false);
    }

    if (cloudType === jsonData.azureTxt) {
      if (
        externalWsToken === "" ||
        externalWsUrl === "" ||
        externalWsTokenError ||
        externalWsUrlError ||
        !selectedDropdown1 ||
        !selectedDropdown2
      )
        setDisableNext(true);
      else setDisableNext(false);
    }
  }

  function validateHandler(value, pattern, className, errorMessage, name) {
    if (value === "" || value === undefined) {
      className("form-control is-invalid");
      errorMessage(`${name} is required`);
      setDisableNext(true);
    } else if (!pattern.test(value)) {
      className("form-control is-invalid");
      errorMessage(`Invalid ${name}`);
      setDisableNext(true);
    } else {
      className("form-control is-valid");
      errorMessage(null);
      if (name === jsonData.workspaceUrltxt) {
        setIsUrlValid(true);
      } else if (name === jsonData.workspaceTokentxt) {
        setIsTokenValid(true);
      }
    }
  }
  function checkDropdownList() {
    if (dropdownData1.includes(selectedDropdown1)) {
      setDisableNext(false);
    } else {
      setDisableNext(true);
      setSelectedDropdown1("");
    }
  }

  useEffect(() => {
    checkDropdownList();
  }, [selectedDropdown2]);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedDropdown2(selectedValue);
    const selectedItem = dropdownData2.find(
      (item) => item.workspace_name === selectedValue
    );

    if (selectedItem) {
      setSelectedWorkspaceId(selectedItem.workspace_id);
    } else {
      setSelectedWorkspaceId(null);
    }
    const payload = dropdownData2?.filter((data) => {
      return data.workspace_name === selectedValue;
    });
    dispatch(workspaceActions.setSelectedWorkspace(payload));
  };
  function setUser(item) {
    setSelectedDropdown1(item.userName);
    dispatch(accountInfoActions.setOAuthUser(item));
  }
  async function getuserlist(url) {
    let body1 = {};
    if (cloudType === jsonData.awsTxt) {
      if (credentialType === jsonData.oauthTxt) {
        body1 = {
          ucxCredentialsData: {
            accId: accountId,
            databricksEmail: userId,
            databricksPassword: accountInfoData.password,
            databricksClientId: oAuthToken,
            databricksSecret: oAuthSecret,
          },
          OAuth: true,
          cloudType: cloudType,
          workspaceUrl: url,
          patAccess: false,
        };
      } else {
        body1 = {
          ucxCredentialsData: {
            accId: accountId,
            databricksEmail: userId,
            databricksPassword: accountInfoData.password,
            databricksClientId: oAuthToken,
            databricksSecret: oAuthSecret,
          },
          OAuth: false,
          cloudType: cloudType,
          workspaceUrl: url,
          patAccess: false,
        };
      }
    } else {
      body1 = {
        ucxCredentialsData: {
          accId: accountInfoData.accountId,
          databricksEmail: accountInfoData.userId,
          databricksPassword: accountInfoData.password,
          databricksClientId: accountInfoData.oAuthToken,
          databricksSecret: accountInfoData.oAuthSecret,
        },
        OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
        cloudType: cloudType,
        workspaceUrl: url,
        patAccess: true,
      };
    }
    setMakingApiCall(true);
    const data1 = await getAccountUserList(body1);
    setDropdownData1(data1);
    setMakingApiCall(false);
  }
  useEffect(() => {
    dropdownData2.map((item) => {
      if (selectedDropdown2 === item.workspace_name) {
        getuserlist(item.workspace_url);
      }
    });
  }, [selectedDropdown2]);

  return (
    <>
      <LoadingSpinner open={makingApiCall} />

      <Paper elevation={3} style={accountInfoStyle.paperStyle}>
        <Grid mb={2}>
          <Typography style={accountInfoStyle.accountHeader} variant="h5">
            {jsonData.databricksInfo}{" "}
          </Typography>
        </Grid>
        <Grid containerstyle={accountInfoStyle.centre} spacing={2}>
          {validationMessage && (
            <Grid item xs={12} lg={12}>
              <Alert severity="error">{validationMessage}</Alert>
            </Grid>
          )}

          <Grid container item alignItems="center" mt={1}>
            <FormControl>
              <RadioGroup
                aria-label="Cloud-type"
                name="Cloud-type"
                value={cloudType}
                onChange={handleCloudTypeChange}
                row
              >
                <FormControlLabel
                  value={jsonData.awsTxt}
                  control={<Radio />}
                  label={jsonData.awsTxt}
                  sx={accountInfoStyle.radioStyle}
                />
                <FormControlLabel
                  value={jsonData.azureTxt}
                  control={<Radio />}
                  label={jsonData.azureTxt}
                  sx={accountInfoStyle.oAuthStyle}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {cloudType === jsonData.awsTxt && (
            <>
              <Grid item xs={12} lg={12} pl={0} mt={2}>
                <UcmaTextField
                  name={"Account ID"}
                  value={accountId}
                  onChangeValue={setAccountId}
                  setDisableSubmit={setAccountIdErrorMessage}
                  required
                  isDialog
                  pattern={accountIdPattern}
                  textFieldProps={{
                    id: "standard-basic",
                    variant: "standard",
                    label: "Account ID",
                  }}
                />
              </Grid>
              <Grid container item alignItems="center" mt={1}>
                <FormControl>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    style={accountInfoStyle.marginBottom}
                  >
                    {jsonData.credentialType}
                  </FormLabel>
                  <RadioGroup
                    aria-label="credential-type"
                    name="credential-type"
                    value={credentialType}
                    onChange={handleCredentialTypeChange}
                    row
                  >
                    <FormControlLabel
                      value="login"
                      control={<Radio />}
                      label="Login Credential"
                      sx={accountInfoStyle.radioStyle}
                    />
                    <FormControlLabel
                      value="Oauth"
                      control={<Radio />}
                      label="OAuth Credential  "
                      sx={accountInfoStyle.oAuthStyle}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {credentialType === "login" && (
                <>
                  <Grid item xs={12} lg={12} mt={1}>
                    <UcmaTextField
                      name={"Username"}
                      // info={jsonData.roleArnStorageCredTooltip}
                      value={userId}
                      onChangeValue={setUserId}
                      // placeholder={jsonData.arnPlaceholder}
                      setDisableSubmit={setUserIdErrorMessage}
                      required
                      isDialog
                      pattern={userNameEmailPattern}
                      textFieldProps={{
                        id: "standard-basic",
                        variant: "standard",
                        label: "Username",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormControl
                      sx={{ width: "100%", mb: 5, mt: 3 }}
                      variant="standard"
                    >
                      <InputLabel htmlFor="standard-adornment-password">
                        {jsonData.password}
                      </InputLabel>
                      <Input
                        id="standard-adornment-password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        error={passwordClassName === "form-control is-invalid"}
                        onBlur={() => {
                          validateHandler(
                            password,
                            passwordPattern,
                            setPasswordClassName,
                            setPasswordErrorMessage,
                            "Password "
                          );
                        }}
                        helperText={passwordErrorMessage}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={jsonData.passwordPlaceholder}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              {credentialType === jsonData.oauthTxt && (
                <>
                  <Grid item xs={12} mt={1} lg={12}>
                    <UcmaTextField
                      name={jsonData.clientIdTxt}
                      value={oAuthToken}
                      onChangeValue={setOAuthToken}
                      setDisableSubmit={setOAuthTokenErrorMessage}
                      required
                      isDialog
                      pattern={dbClientIdPattern}
                      textFieldProps={{
                        id: "standard-basic",
                        variant: "standard",
                        label: jsonData.clientIdTxt,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} mt={1} lg={12} >
                    <FormControl
                      sx={{ width: "100%", mb: 4, mt: 3 }}
                      variant="standard"
                    >
                      <InputLabel htmlFor="standard-adornment-password">
                        {jsonData.clientSecretTxt}
                      </InputLabel>
                      <Input
                        type={showPassword ? "text" : "password"}
                        id="standard-adornment-password"
                        label={jsonData.clientSecretTxt}
                        fullWidth
                        onChange={(e) => setOAuthSecret(e.target.value)}
                        error={
                          oAuthSecretClassName === "form-control is-invalid"
                        }
                        onBlur={() => {
                          validateHandler(
                            oAuthSecret,
                            dbClientSecretPattern,
                            setOAuthSecretClassName,
                            setOAuthSecretErrorMessage,
                            jsonData.clientSecretTxt
                          );
                        }}
                        helperText={oAuthSecretErrorMessage}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
            </>
          )}

          {cloudType === jsonData.azureTxt && (
            <>
              <Grid item xs={12} lg={12} pl={0} mt={2}>
                <UcmaTextField
                  name={jsonData.workspaceUrltxt}
                  value={externalWsUrl}
                  onChangeValue={setExternalWsUrl}
                  setDisableSubmit={setExternalWsUrlError}
                  required
                  isDialog
                  pattern={azureDatabricksUrlPattern}
                  textFieldProps={{
                    id: "standard-basic",
                    variant: "standard",
                    label: jsonData.workspaceUrltxt,
                  }}
                  onBlur={() => {
                    validateHandler(
                      externalWsUrl,
                      azureDatabricksUrlPattern,
                      setExternalWsUrlClassName,
                      setExternalWsUrlError,
                      jsonData.workspaceUrltxt
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={12} pl={0} mb={5} mt={2}>
                <UcmaTextField
                  name={jsonData.workspaceTokentxt}
                  value={externalWsToken}
                  onChangeValue={setExternalWsToken}
                  setDisableSubmit={setExternalWsTokenError}
                  required
                  isDialog
                  pattern={WorkspacetokenPattern}
                  textFieldProps={{
                    id: "standard-basic",
                    variant: "standard",
                    label: jsonData.workspaceTokentxt,
                  }}
                  onBlur={() => {
                    validateHandler(
                      externalWsToken,
                      WorkspacetokenPattern,
                      setExternalWsTokenClassName,
                      setExternalWsTokenError,
                      jsonData.workspaceTokentxt
                    );
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={6}>
            <FormControl  required fullWidth>
              <InputLabel id="dropdown2-label">Workspace</InputLabel>
              <Select
                disabled={dropdownDisable}
                labelId="dropdown2-label"
                id="dropdown2"
                value={selectedDropdown2}
                onChange={handleChange}
                label="Workspace"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight:360,
                      maxWidth:280,
                      marginTop: -16,
                    },
                  },
                  anchorOrigin: {
                    vertical: 'top',  // Position the menu below the select
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              >
                {dropdownData2
                  ?.filter((item) =>
                    item.workspace_status.includes("Workspace is running")
                  )
                  .map((item) => (
                    <MenuItem
                      key={item.workspace_id}
                      value={item.workspace_name}
                    >
                      {item.workspace_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl required fullWidth>
              <InputLabel id="dropdown1-label">Users</InputLabel>
              <Select
                disabled={disableUserList}
                labelId="dropdown1-label"
                id="dropdown1"
                value={selectedDropdown1 || ""} 
                label="Users"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight:360,
                      maxWidth:280,
                      marginTop: -16,
                    },
                  },
                  anchorOrigin: {
                    vertical: 'top',  // Position the menu below the select
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              >
                {dropdownData1?.map((item) => (
                  <MenuItem
                    key={item.userName}
                    value={item.userName}
                    onClick={() => setUser(item)}
                  >
                    {item.userName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box textAlign="center">
          <Button
            onClick={submitHandler}
            disabled={disableNext}
            variant="contained"
            style={accountInfoStyle.buttonBorder}
          >
            {jsonData.submitText}
          </Button>
        </Box>
      </Paper>
    </>
  );
};
AccountInfo.propTypes = {
  setAccountInfo: PropTypes.func.isRequired,
  setAccountId: PropTypes.func.isRequired,
};

export default AccountInfo;
