import {
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  styled,
} from "@mui/material";
import classes from "./CustomTable.module.css";
import CustomTableRow from "../CustomTableRow/CustomTableRow";

const StyledTableContainer = styled(TableContainer)({
  borderBottom: "none",
});

const StyledTableCell = styled(TableCell)({
  borderLeft: "none",
  borderRight: "none",
  height: "3.75rem",
  paddingTop: "10px",
  paddingBottom: "10px",
});

const CustomTable = (props) => {
  const { head, cellValuesAsObjects } = props;

 
  return (
    <Paper className={classes["paper"]}>
      <StyledTableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow className={classes["table-row-height"]}>
              {head?.map((head) => (
                <StyledTableCell
                  className={classes["table-heading"]}
                  key={head}
                >
                  {head}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
          <CustomTableRow data={cellValuesAsObjects} />
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Paper>
  );
};
export default CustomTable;
