import React, { useEffect, useState } from "react";
import NoData from "../Common/NoData/NoData";
import jsonData from "../../Assets/i18n/en.json";
import constants from "../../utils/constant";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Box,
  styled,
  Button,
} from "@mui/material";
import classes from "../../Components/Summary/WorkspaceSummary.module.css";
import StatusDialog from "./StatusDialog";

const StyledTableCell = styled(TableCell)({
  borderLeft: "none",
  borderRight: "none",
  height: "3.75rem",
  paddingTop: "10px",
  paddingBottom: "10px",
});

const ValidationResultTable = ({ result }) => {
  const [makingApiCall, setMakingApiCall] = useState(false);
  const [summaryValidationContent, setSummaryValidationContent] =
    useState(null);
  const SummaryData = JSON.parse(result);
  const _modalData = {
    title: "",
    bodyData: [],
  };
  const [openPopup, setOpenPopup] = useState(false);
  const [modalData, setModalData] = useState(_modalData);
  const openModal = (title, bodyData) => {
    setModalData({ title, bodyData });
    setOpenPopup(true);
  };
  const formatString = (inputString) => {
    return inputString
      .replace(/_/g, " ")
      .replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase());
  };
  const aggregationCloumn = (data) => {
    try {
      let parsedData;
      if (data.trim() !== "") {
        parsedData = JSON.parse(data.replace(/'/g, '"'));
      } else {
        parsedData = [];
      }
      const countArray = parsedData
        .map((item) => {
          const entries = Object.entries(item);
          return entries.map(([key, value]) => ({
            rowLabel: formatString(key),
            rowValue: value,
          }));
        })
        .flat();
      return countArray;
    } catch (error) {
      console.error("Error parsing data:", error);
      return [];
    }
  };

  const renderValidationTableRows = (summaryData) => {
    return summaryData?.map((resource) => {
      const cloneValues = [
        { label: "source_schema", value: resource.source_schema },
        { label: "source_name", value: resource.source_name },

        {
          label: "row_count_validation_status",
          value: resource.row_count_validation_status,
          count: [
            {
              rowLabel: "Source Row Count",
              rowValue: resource.source_row_count,
            },
            {
              rowLabel: "Target Row Count",
              rowValue: resource.target_row_count,
            },
            {
              rowLabel: "Percentage Difference Row Count",
              rowValue: resource.percentage_difference_row_count,
            },
          ],
        },
        {
          label: "source_target_column_status",
          value: resource.source_target_column_status,
          count: [
            {
              rowLabel: "Source Column Count",
              rowValue: resource.source_schema_column_cnt,
            },
            {
              rowLabel: "Target Column Count",
              rowValue: resource.target_schema_column_cnt,
            },
          ],
        },
        {
          label: "column_metadata_status",
          value: resource.column_metadata_status,
          count: aggregationCloumn(resource.column_datatype_changed),
        },
        {
          label: "aggeration_validation_status",
          value: resource.aggeration_validation_status,
          count: aggregationCloumn(resource.aggeration_column_validation),
        },
      ];

      const valueBody = cloneValues;

      return (
        <TableRow
          className={classes["table-row-height"]}
          key={resource.source_name}
        >
          {valueBody.map((cell, index) => (
            <StyledTableCell
              className={classes["table-val"]}
              key={index + 1}
              style={{ cursor: "pointer" }}
            >
              {cell.value === "NOT MATCHED" ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => openModal(cell.value, cell.count)}
                >
                  {cell.value}
                </Button>
              ) : cell.value === "MATCHED" ? (
                <div className={classes["matched-font-color"]}>
                  {cell.value}
                </div>
              ) : cell.value === "MATCHED" ? (
                <div className={classes["matched-font-color"]}>
                  {cell.value}
                </div>
              ) : (
                cell.value
              )}
            </StyledTableCell>
          ))}
        </TableRow>
      );
    });
  };

  const renderSummaryValidationTable = (summarValidationData) => {
    const headers = constants.SummaryCloneHeader;

    const summary = (
      <Paper className={classes["paper"]}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className={classes["table-row-height"]}>
                {headers.map((head) => (
                  <TableCell className={classes["table-heading"]} key={head}>
                    {head}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {renderValidationTableRows(summarValidationData)}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
    return summary;
  };

  useEffect(() => {
    setMakingApiCall(true);

    const newContent = SummaryData;
    const shouldSkipValidation = newContent === jsonData.skipCloneTxt;

    if (
      newContent &&
      !shouldSkipValidation &&
      JSON.stringify(newContent) !== JSON.stringify(summaryValidationContent)
    ) {
      setSummaryValidationContent(newContent);
    }

    setMakingApiCall(false);
  }, [SummaryData]);

  if (!summaryValidationContent && !makingApiCall) {
    return <NoData height="12rem" label={jsonData.skipCloneTxt} />;
  }

  return (
    <div className={classes["summary-container"]}>
      <Grid container item className={classes["grid-css"]}>
        <Box className={classes["box-css"]}>
          {summaryValidationContent &&
            renderSummaryValidationTable(summaryValidationContent)}
        </Box>
      </Grid>
      {/* <Pagination /> */}
      <StatusDialog
        openPopup={openPopup}
        setOpenStatus={setOpenPopup}
        modalData={modalData}
      />
    </div>
  );
};

export default ValidationResultTable;
