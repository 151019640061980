import React, { useEffect, useRef, useState } from "react";
import { jwtDecode } from "jwt-decode";
import PropTypes from "prop-types";
import classes from "./DashboardPage.module.css";
import UnAuthorizedOverlay from "../../Components/UI/UnAuthorizedOverlay/UnAuthorizedOverlay";
import { useSelector, useDispatch } from "react-redux";
import Image from "../../Assets/images/login_background.png";
import SideNav from "../../Components/UI/AppBar/SideNav";
import CreateMetastore from "../../Components/Metastore/CreateMetastore";
import WorkspaceSummary from "../../Components/Summary/WorkspaceSummary";
import NavigationHeader from "../../Components/UI/NavigationHeader/NavigationHeader";
import { getMetaStoreList } from "../../Resources/apicalls";
import Dashboard from "../../Components/Dashboard/Dashboard";
import UcxAssessment from "../../Components/Ucx Assessment/UcxAssessment";
import MountpointValidation from "../../Components/Mountpoints/MountPoint";
import InteractiveCluster from "../../Components/InteractiveCluster/InteractiveCluster";
import Dbsqlclusters from "../../Components/DbsqlCluster/DbsqlCluster";
import AutoLoaderPage from "../../Components/AutoLoader/AutoLoaderPage";
import { getWorkSpaceList } from "../../store/thunk/workspace-thunk";
import Table from "../../Components/Table/Table";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import Workspace from "../../Components/Workspace/Workspace";
import AccountInfo from "../../Components/AccountInfo/AccountInfo";
import Dbfsfunction from "../../Components/Dbfs/Dbfsfunction";
import jsonData from "../../Assets/i18n/en.json";
import Notebookfunction from "../../Components/Notebook/NotebookFunction";
import JobsFunction from "../../Components/Jobs/JobsFunction";
import { Grid } from "@mui/material";
import Lineagefunction from "../../Components/LineageCatalog/Lineage";

function DashboardPage(props) {
  const dispatch = useDispatch();
  const userName = window.sessionStorage.getItem(jsonData.userName);
  const [showOverlay, setShowOverlay] = useState(false);
  const [accountInfo, setAccountInfo] = useState(false);
  const [component, setComponent] = useState("");
  const [regionStatsData, setRegionStatsData] = useState([]);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [metastoreData, setMetastoreData] = useState([]);
  const accountInfoData = useSelector((state) => state.accountInfo);
  const [makingApicalls, setMakingApicalls] = useState(false);
  const backgroundImageStyle = !accountInfo
    ? {
        backgroundImage: `url(${Image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "auto",
        minheight: "100vh",
      }
    : { padding: "5rem", paddingBottom: "1rem", paddingRight: "1rem" };
  const [accountId, setAccountId] = useState(false);
  const [validationReview, setValidationReview] = useState(false);
  const workspace = useSelector((state) => state.workspaceSlice.workspaceData);
  const [dashboardRefresh, setDashboardRefresh] = useState(false);
  const [apiTime, setApiTime] = useState("");
  const [startUCXAssessment, setStartUCXAssessment] = useState(false);
  const [id, setId] = useState("");
  const [openAssessment, setOpenAssessment] = useState(false);

  useEffect(() => {
    if (startUCXAssessment) {
      setTimeout(() => {
        setStartUCXAssessment(false);
      }, 60000);
    }
  }, [startUCXAssessment]);

  const selectComponent = (e) => {
    setComponent(e);
  };

  useEffect(() => {
    if (accountInfo) {
      setComponent(jsonData.dashboard);
      fetchWorkspace();
    }
  }, [accountInfo]);

  useEffect(() => {
    if (validationReview) {
      setComponent(jsonData.reviewValidation);
      setValidationReview(false);
    }
  }, [validationReview]);

  async function fetchWorkspace() {
    try {
      setMakingApicalls(true);

      let body = {
        ucxCredentialsData: {
          accId: accountInfoData.accountId,
          databricksEmail: accountInfoData.userId,
          databricksPassword: accountInfoData.password,
          databricksClientId: accountInfoData.oAuthToken,
          databricksSecret: accountInfoData.oAuthSecret,
        },
        OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
        cloudType: accountInfoData.cloudType,
        workspaceUrl: accountInfoData.externalWsUrl,
        patAccess: accountInfoData.patAccess,
      };

      setMakingApicalls(true);
      dispatch(getWorkSpaceList(body));
      setWorkspaceData(workspace);

      let metaStorelist = await getMetaStoreList(body);
      setMetastoreData(metaStorelist);

      setMakingApicalls(false);

      const regionStats = [];
      let totalCount = 0;
      workspace.forEach((w) => {
        const { region } = w;
        const index = regionStats.findIndex((stat) => stat.region === region);

        if (index === -1) {
          regionStats.push({ region, count: 1, percent: 0 });
        } else {
          regionStats[index].count++;
        }
        totalCount++;
      });
      regionStats.forEach((stat) => {
        stat.percent = Math.round((stat.count / totalCount) * 100);
      });
      setRegionStatsData(regionStats);
      setMakingApicalls(false);
    } catch (e) {
      setMakingApicalls(false);
    }
  }

  const getComponent = () => {
    let comp;
    switch (component) {
      case jsonData.dashboard:
        comp = (
          <Dashboard
            selectComponent={selectComponent}
            regionStatsData={regionStatsData}
            workspaceData={workspaceData}
            makingApicalls={makingApicalls}
            dashboardRefresh={dashboardRefresh}
            setDashboardRefresh={setDashboardRefresh}
            setApiTime={setApiTime}
            startUCXAssessment={startUCXAssessment}
            id={id}
          />
        );
        break;

      case jsonData.workspace:
        comp = <Workspace selectComponent={selectComponent} />;
        break;
      case jsonData.dbfs:
        comp = <Dbfsfunction selectComponent={selectComponent} />;
        break;
      case jsonData.createMetastore:
        comp = <CreateMetastore selectComponent={selectComponent} />;
        break;

      case jsonData.workspaceSummary:
        comp = <WorkspaceSummary />;
        break;
      case "Schema/Table":
        comp = <Table selectComponent={selectComponent} />;
        break;

      case jsonData.jobs:
        comp = <JobsFunction selectComponent={selectComponent} />;
        break;
      case jsonData.lineageUtility:
        comp = <Lineagefunction selectComponent={selectComponent} />;
        break;
      case jsonData.clusters:
        comp = <InteractiveCluster selectComponent={selectComponent} />;
        break;

      case jsonData.dbsqlClusters:
        comp = <Dbsqlclusters selectComponent={selectComponent} />;
        break;

      case jsonData.assessmentText:
        comp = (
          <UcxAssessment
            selectComponent={selectComponent}
            setStartUCXAssessment={setStartUCXAssessment}
            setId={setId}
          />
        );
        break;
      case jsonData.mountPointValidation:
        comp = <MountpointValidation selectComponent={selectComponent} />;
        break;
      case jsonData.summary:
        comp = <WorkspaceSummary />;
        break;
      case jsonData.notebookText:
        comp = comp = <Notebookfunction selectComponent={selectComponent} />;
        break;
      case "Autoloader/Streaming":
        comp = <AutoLoaderPage selectComponent={selectComponent} />;
        break;

      default:
        comp = (
          <Dashboard
            regionStatsData={regionStatsData}
            workspaceData={workspaceData}
            makingApicalls={makingApicalls}
            dashboardRefresh={dashboardRefresh}
            setDashboardRefresh={setDashboardRefresh}
            setApiTime={setApiTime}
          />
        );
    }
    return comp;
  };

  useEffect(() => {
    if (userName) {
      const currentDate = new Date();
      const decoded = jwtDecode(window.sessionStorage.getItem("accessToken"));

      setTimeout(() => {
        setShowOverlay(true);
        setTimeout(() => {
          window.sessionStorage.clear();
          window.sessionStorage.setItem("unAuthorized", true);

          props.setData(false);
          setShowOverlay(false);
        }, 5000);
      }, decoded.exp * 1000 - currentDate.getTime());
    }
  }, []);

  const handleDeleteDialogClose = () => {
    setOpenAssessment(false);
  };

  const ref = useRef(null);

  if (showOverlay) {
    return <UnAuthorizedOverlay />;
  } else {
    return (
      <Grid className={classes.dashboard} sx={backgroundImageStyle}>
        <SideNav
          userName={userName}
          setData={props.setData}
          accountInfo={accountInfo}
          accountId={accountId}
          selectComponent={selectComponent}
          component={component}
          regionStatsData={regionStatsData}
          workspaceData={workspaceData}
        />
        <div ref={ref}>
          <NavigationHeader
            component={component}
            metastoreData={metastoreData}
            selectComponent={selectComponent}
            setDashboardRefresh={setDashboardRefresh}
            setOpenAssessment={setOpenAssessment}
            apiTime={apiTime}
            element={ref.current}
          />

          <div
            className={`${accountInfo ? classes["dashboard-section"] : ""} ${
              accountInfo ? classes["dashboard-padding"] : ""
            }`}
          >
            <LoadingSpinner open={makingApicalls} />
            {!accountInfo && (
              <div className={classes["displayCenter"]}>
                <AccountInfo
                  setAccountInfo={setAccountInfo}
                  setAccountId={setAccountId}
                />{" "}
              </div>
            )}
            {accountInfo && getComponent()}
          </div>
        </div>

        <UcxAssessment
          open={openAssessment}
          onClose={handleDeleteDialogClose}
          setId={setId}
          setStartUCXAssessment={setStartUCXAssessment}
        />
      </Grid>
    );
  }
}
DashboardPage.propTypes = {
  setData: PropTypes.func.isRequired,
};

export default DashboardPage;
