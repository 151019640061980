import React, { useState, useEffect } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import classes from "./LoginPage.module.css";
import { loginApi } from "../../Resources/apiurls";
import jsonData from "../../Assets/i18n/en.json";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import Image from "../../Assets/images/login_background.png";
// import packageJson from "../../../package.json";
import {
  Alert,
  Button,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import { postCall } from "../../Resources/apicalls";

function LoginPage(props) {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [makingApiCall, setMakingApiCall] = useState(false);
  let data = {};

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  function userNameHandler() {
    const errorMsg =
      userName.length < 5 || userName.length > 30 ? jsonData.userNameMsg : "";
    setErrorMessage(errorMsg);
  }

  function passwordHandler() {
    const errorMsg =
      password.length < 8 || password.length > 30 ? jsonData.passwordMsg : "";
    setErrorMessage(errorMsg);
  }
  function disable() {
    if (userName === "" || password === "" || makingApiCall) {
      setDisableSubmit(true);
    } else setDisableSubmit(false);
  }

  useEffect(() => {
    disable();
  }, [userName, password, makingApiCall]);

  useEffect(() => {
    setTimeout(() => {
      window.sessionStorage.removeItem(jsonData.unAuthorized);
    }, 1000);
  }, []);

  async function loginSubmitHandler(event) {
    event.preventDefault();
    let creds = {
      userName: userName,
      password: password,
    };
    let inputs = document.querySelectorAll("input");
    let button = document.querySelectorAll("button")[0];
    for (const element of inputs) {
      element.readOnly = true;
    }
    button.disabled = true;
    setMakingApiCall(true);
    let res = await postCall(loginApi, creds);
    setMakingApiCall(false);
    for (const element of inputs) {
      element.readOnly = false;
    }
    button.disabled = false;

    if (res.length !== 0) {
      window.sessionStorage.clear();
      window.sessionStorage.setItem(
        "accessToken",
        res.ucxApiResponse.accessToken
      );
      window.sessionStorage.setItem(jsonData.isloggedInText, true);
      window.sessionStorage.setItem(jsonData.userName, userName);
      props.setData(true);
    } else setErrorMessage(jsonData.invalidCredentialMsg);
  }

  return (
    <div
      style={{ backgroundImage: `url(${Image})` }}
      className={classes["height"]}
    >
      <Grid
        container
        justifyContent={"center"}
        alignContent={"space-between"}
        rowGap={10}
      >
        <LoadingSpinner open={makingApiCall} />
        <Grid item xs={12}>
          <h2 className={classes["login-text"]}>{jsonData.projectHeading}</h2>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={4} className={classes["border-20"]}>
            <Grid item container rowGap={2} justifyContent={"center"}>
              <Grid item xs={10} mt={2} sx={{ mb: 2, mt: 5 }}>
                <p className={classes["font-20"]}>{jsonData.loginHeading}</p>
              </Grid>

              {errorMessage && (
                <Grid item xs={10}>
                  <Alert severity={jsonData.errorText}>{errorMessage}</Alert>
                </Grid>
              )}

              {window.localStorage.getItem(jsonData.unAuthorized) && (
                <Grid item xs={10}>
                  <Alert severity={jsonData.errorText}>
                    {jsonData.sessionerr}
                  </Alert>
                </Grid>
              )}

              <Grid item xs={10}>
                <Grid className={classes["login-form"]}>
                  <Grid item container rowGap={2} mb={3}>
                    <Grid item xs={12} sx={{ mb: 1 }}>
                      <TextField
                        id="standard-basic"
                        fullWidth
                        variant="standard"
                        type="text"
                        label="Username"
                        onChange={(e) => setUserName(e.target.value)}
                        onBlur={userNameHandler}
                        placeholder={jsonData.userNamePlaceHolder}
                        minLength={5}
                        maxLength={30}
                      />
                    </Grid>
                    <FormControl
                      sx={{ mb: 5 }}
                      variant="standard"
                      className={classes["width"]}
                    >
                      <InputLabel htmlFor="standard-adornment-password">
                        {jsonData.password}
                      </InputLabel>
                      <Input
                        id="standard-adornment-password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onBlur={passwordHandler}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={jsonData.passwordPlaceholder}
                        minLength={8}
                        maxLength={30}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    </FormControl>
                    <Grid item xs={12}>
                      <Button
                        className={classes["border-radius"]}
                        fullWidth
                        variant={jsonData.containedButtonVariant}
                        disabled={disableSubmit}
                        onClick={loginSubmitHandler}
                      >
                        {jsonData.login}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {/* <div className={classes["versionInfo"]}>
        {jsonData.version} {packageJson.version}
      </div> */}
    </div>
    // </>
  );
}

export default LoginPage;
