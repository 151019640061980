import ButtonStyled from "../UI/NavigationButtons/Buttons";
import jsonData from "../../Assets/i18n/en.json";
import { useCommonPayload } from "../../Resources/useCommonPayload";
import { downloadCsvCall } from "./LineageUtility.service";
import { useState } from "react";
import LoadingSpinner from "../UI/LoadingSpinner";
import NoData from "../Common/NoData/NoData";
import { DownloadOutlined } from "@mui/icons-material";
import classes from "./LineageUtility.module.css";

export function LineageSummaryResult({ id, status }) {
  const commonPayload = useCommonPayload();
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    let payload = {
      ...commonPayload,
      triggerId: id,
      ucxPayload: {},
    };

    let res = await downloadCsvCall(payload);
    setLoading(false);
    const url = URL.createObjectURL(new Blob([res]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `lineage-${id}.csv`);
    link.click();
    link.remove();
  };
  return (
    <>
      <LoadingSpinner open={loading} />
      {status === "SUCCESS" ? (
        <div className={classes["display"]}>
          {jsonData.lineageDownloadText}
          {"  "}
          <ButtonStyled
            label={
              <>
                {jsonData.downloadcsv}
                <DownloadOutlined />
              </>
            }
            variant="contained"
            icon={<DownloadOutlined />}
            background="#457ECF"
            border="1px solid #457ECF"
            onclick={handleClick}
          />
        </div>
      ) : (
        <NoData label={jsonData.noData} />
      )}
    </>
  );
}
