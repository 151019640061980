import styles from "../UcMigration.module.css";
import jsonData from "../../Assets/i18n/en.json";
import {
  srcLocationPattern,
  targetLoactionPattern,
  targetLocationAzurePattern,
} from "../../utils/regex";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import CommonMigration from "../Common/Migration/Migration";
import {
  ValidateAutoLoaderCall,
  ucMigrationCall,
} from "../../Resources/apicalls";
import BasicAlerts from "../Common/MessageBanner.jsx/MessageBanner";
import { GenerateUid } from "../../utils/GenerateUid/GenerateUid";
import HeadingWithInfo from "../Common/HeadingWithInfo/HeadingWithInfo";
import UcmaTextField from "../UI/Form/TextField";

const AutoLoaderPage = (props) => {
  const { selectComponent } = props;
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const workspaceConfig = useSelector(
    (state) => state.workspaceConfig.data[workspaceSelected.workspace_id]
  );
  const accountInfoData = useSelector((state) => state.accountInfo);
  const [srcLocation, setSrcLocation] = useState("");

  const [trgLocation, setTrgLocation] = useState("");

  const [srcSchema, setSrcSchema] = useState("");

  const [trgSchema, setTrgSchema] = useState("");

  const [close, setClose] = useState(false);
  const [showAlertValidate, setShowAlertValidate] = useState(false);
  const [listSelected, setListSelected] = useState(true);
  const [makingApiCall, setMakingApiCall] = useState(false);
  const [showAlertMigration, setShowAlertMigration] = useState(false);
  const [id, setId] = useState("");

  function enableNext() {
    if (accountInfoData.cloudType === jsonData.awsTxt) {
      if (srcLocation !== "" && trgLocation !== "") {
        if (
          srcLocation !== "" &&
          trgLocation !== "" &&
          srcSchema === "" &&
          trgSchema === ""
        ) {
          setListSelected(true);
          return;
        }
        if (
          (srcSchema !== "" && trgSchema === "") ||
          (srcSchema === "" && trgSchema !== "")
        ) {
          setListSelected(false);
          return;
        } else setListSelected(true);
        return;
      }

      if (srcSchema !== "" && trgSchema !== "") {
        if (
          srcSchema !== "" &&
          trgSchema !== "" &&
          srcLocation === "" &&
          trgLocation === ""
        ) {
          setListSelected(true);
          return;
        }
        if (
          (srcLocation !== "" && trgLocation === "") ||
          (trgLocation !== "" && srcLocation === "")
        ) {
          setListSelected(false);
        } else setListSelected(true);
        return;
      } else setListSelected(false);
    }

    if (accountInfoData.cloudType === jsonData.azureTxt) {
      if (srcLocation !== "" && trgLocation !== "") {
        if (
          srcLocation !== "" &&
          trgLocation !== "" &&
          srcSchema === "" &&
          trgSchema === ""
        ) {
          setListSelected(true);
          return;
        }
        if (
          (srcSchema !== "" && trgSchema === "") ||
          (srcSchema === "" && trgSchema !== "")
        ) {
          setListSelected(false);
          return;
        } else setListSelected(true);
        return;
      }

      if (srcSchema !== "" && trgSchema !== "") {
        if (
          srcSchema !== "" &&
          trgSchema !== "" &&
          srcLocation === "" &&
          trgLocation === ""
        ) {
          setListSelected(true);
          return;
        }
        if (
          (srcLocation !== "" && trgLocation === "") ||
          (trgLocation !== "" && srcLocation === "")
        ) {
          setListSelected(false);
        } else setListSelected(true);
        return;
      } else setListSelected(false);
    }
  }

  useEffect(() => {
    enableNext();
  }, [srcLocation, srcSchema, trgLocation, trgSchema, listSelected]);

  useEffect(() => {
    if (showAlertMigration) {
      setTimeout(() => {
        setShowAlertMigration(false);
      }, 60000);
    }
  }, [showAlertMigration]);

  async function handleValidateClick() {
    let triggerId = GenerateUid();
    setId(triggerId);
    let payload = {
      accId: accountInfoData.accountId,
      workspaceIdentifier: workspaceSelected.workspace_id,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      workspaceUrl: workspaceSelected.workspace_url,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      triggerId: triggerId,
      runAsJob:accountInfoData.userId
        ? accountInfoData.userId
        : accountInfoData.OAuthUser.userName,
      workflows: {
        bookmarkLocation: {
          source: srcLocation,
          target: trgLocation,
        },
        schemaLocation: {
          source: srcSchema,
          target: trgSchema,
        },
        dataRoleArn: workspaceConfig.awsRoleArn,
        containerName: workspaceConfig.azureStorageAccountName,
        storageAccountKey: workspaceConfig.azureStorageAccountKey,       
        

      },
    };
    ValidateAutoLoaderCall(payload);
    setShowAlertValidate(true);
  }

  async function submitMigration() {
    let triggerId = GenerateUid();
    setId(triggerId);
    const postBody = JSON.stringify({
      ucxCredentialsData: {
        accId: workspaceSelected.account_id,
      },
      OAuth: accountInfoData.credentialType === "Oauth",
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      ucxPayload: {
        data: null,
        warehouseID: [],
        migrateGroups: false,
        upgradeClusters: null,
        workflows: workspaceSelected.workspace_url
          ? {
              bookmarkLocation: {
                source: srcLocation,
                target: trgLocation,
              },
              schemaLocation: {
                source: srcSchema,
                target: trgSchema,
              },
              dataRoleArn: workspaceConfig.awsRoleArn,
              containerName: workspaceConfig.azureStorageAccountName,
              storageAccountKey: workspaceConfig.azureStorageAccountKey,
            }
          : null,

        jobs: null,
 
        runJobsAs: accountInfoData.userId
          ? accountInfoData.userId
          : accountInfoData.OAuthUser.userName,
        mountPointsUpgrade: null,
      },
      workspaceName: workspaceSelected.workspace_name,
      workspaceUrl: workspaceSelected.workspace_url,
      region: workspaceSelected.region,
      triggerId: triggerId,
    });
    setMakingApiCall(true);
    await ucMigrationCall(workspaceSelected.workspace_id, postBody);
    setMakingApiCall(false);
  }

  const handleMigrateClick = async () => {
    submitMigration();
  };

  useEffect(() => {
    setSrcLocation("");
    setSrcSchema("");
    setTrgLocation("");
    setTrgSchema("");
    setClose(false);
  }, [workspaceSelected.workspace_url, close]);

  return (
    <div className={styles["render-component-container"]}>
      {showAlertMigration && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startMigrationmsg}
              <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertMigration}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}
      {showAlertValidate && !makingApiCall && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startValidatemsg} <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertValidate}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      <div className={styles["margin-top-16"]}>
        <div className={styles["display"]}>
          <div className={styles["button-container"]}>
            <div className={styles["basic-details-heading"]}>
              {jsonData.AutoloaderText}
            </div>
          </div>
          <div>
            <CommonMigration
              migrationSelected="noCloneDelete"
              handleValidateClick={handleValidateClick}
              listSelected={listSelected}
              setListSelected={setListSelected}
              handleMigrateClick={handleMigrateClick}
              makingApiCall={makingApiCall}
              setClose={setClose}
              setShowAlertMigration={setShowAlertMigration}
              showMigrate={true}
              showValidate={true}
            />
          </div>
        </div>
        <div className={styles["margin-top-16"]}>
          <div className={`${styles["display"]}`}>
            <HeadingWithInfo
              info={jsonData.copyCheckpointTooltip}
              label={jsonData.cpyCheckText}
            />
          </div>
          <div className={styles["margin-top-16"]} style = {{ width: "58.75rem" }}>
            <UcmaTextField
              name={jsonData.srcLocationText}
              value={srcLocation}
              isDialog
              onChangeValue={setSrcLocation}
              placeholder={jsonData.copyCheckpointSourcePlaceholder}
              setDisableSubmit={setListSelected}
              pattern={srcLocationPattern}
            />

            <UcmaTextField
              name={jsonData.trgLocationText}
              value={trgLocation}
              onChangeValue={setTrgLocation}
              isDialog
              placeholder={
                accountInfoData.cloudType === jsonData.awsTxt
                  ? jsonData.copyCheckpointDestinationPlaceholder
                  : jsonData.trgCheckLocAzurePlaceHolder
              }
              setDisableSubmit={setListSelected}
              pattern={
                accountInfoData.cloudType === jsonData.awsTxt
                  ? targetLoactionPattern
                  : targetLocationAzurePattern
              }
            />
          </div>

          <div className={` ${styles["margin-top-16"]} ${styles["display"]}`}>
            <HeadingWithInfo
              label={jsonData.cpySchemaText}
              info={jsonData.copySchemaTooltip}
            />
          </div>

          <div className={styles["margin-top-16"]} style = {{ width: "58.75rem" }}>
            <UcmaTextField
              name={jsonData.srcSchemaText}
              value={srcSchema}
              isDialog
              onChangeValue={setSrcSchema}
              placeholder={jsonData.CopySchemaLocationSourcePlaceholder}
              setDisableSubmit={setListSelected}
              pattern={srcLocationPattern}
            />

            <UcmaTextField
              name={jsonData.trgSchemaText}
              value={trgSchema}
              isDialog
              onChangeValue={setTrgSchema}
              placeholder={
                accountInfoData.cloudType === jsonData.awsTxt
                  ? jsonData.copySchemaLocationTargetPlaceholder
                  : jsonData.trgSchemaLocAzurePlaceHolder
              }
              setDisableSubmit={setListSelected}
              pattern={
                accountInfoData.cloudType === jsonData.awsTxt
                  ? targetLoactionPattern
                  : targetLocationAzurePattern
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

AutoLoaderPage.propTypes = {
  selectComponent: PropTypes.func.isRequired,
};

export default AutoLoaderPage;
