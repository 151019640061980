import Image from "../../Assets/images/login_background.png";

export const accountInfoStyle = {
  paperStyle: {
    paddingLeft: "35px",
    paddingRight: "35px",
    paddingTop: "20px",
    paddingBottom: "35px",
    marginBottom: "15px",
    width: "40%",
    borderRadius: "20px",
    zIndex: "4",
  },
  marginBottom: {
    marginBottom: "4px",
  },
  marginTop: {
    marginTop: "1.5rem",
  },

  width: {
    width: "100%",
  },
  centre: {
    alignItem: "centre",
    justifyContent: "centre",
  },
  buttonBorder: {
    borderRadius: 16,
    minWidth: "356px",
  },
  accountHeader: {
    display: "flex",
    fontSize: "28px",
    letterSpacing: 0,
    opacity: 1,
    color: "#24262C",
    fontWeight: 100,
  },
  radioStyle: {
    border: "1px solid #E8E8E8 !important",
    borderRadius: "10px",
    opacity: 1,
    marginRight: "40px",
    marginLeft: "2px",
    width: "172px",
  },
  oAuthStyle: {
    border: "1px solid #E8E8E8 !important",
    borderRadius: "10px",
    opacity: 1,
    width: "172px",
  },

  imageBg: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "100vh",
  },
};