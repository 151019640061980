import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import classes from "./LineageUtility.module.css";
import RenderAssesmentTable from "./CommonTable/RenderAssesmentTable";
import LoadingSpinner from "../UI/LoadingSpinner";
import CardComp from "../Dashboard/CardComponent";
import jsonData from "../../Assets/i18n/en.json";
import { useCommonPayload } from "../../Resources/useCommonPayload";
import {
  lineageDashboardApi,
  workFlowTypedApi,
  jobsListApi,
} from "../../Resources/apiurls";
import * as XLSX from "xlsx";
import { capitalizeFirstLetter } from "../../utils/CamelCase/CamelCase";
import { postCall } from "../../Resources/apicalls";
import { handleDownload } from "../../utils/DownloadRef/DownloadRef";

const LineageDashboard = (props) => {
  const {
    refresh,
    setRefresh,
    downloadasPdf,
    setDownloadasPdf,
    download,
    setDownload,
  } = props;
  const commonPayload = useCommonPayload();
  const [makingApiCall, setMakingApiCall] = useState(false);
  const [workflowType, setWorkflowType] = useState("All");
  const [jobId, setJobId] = useState("");
  const [apiData, setApiData] = useState(null);
  const [workflowTypes, setWorkflowTypes] = useState([]);
  const [jobsList, setJobsList] = useState([]);
  const [jobSelected, setJobSelected] = useState(false);
  const [lineageLoad, setLineageLoad] = useState(false);

  const handleDropdownTypeChange = (event) => {
    setWorkflowType(event.target.value);
  };
  const handleDropdownJobChange = (event) => {
    setJobId(event.target.value);
    if (event.target.value !== "All") setJobSelected(true);
    else setJobSelected(false);
  };
  useEffect(() => {
    if (refresh) setRefresh(false);
  }, [refresh]);

  useEffect(() => {
    if (downloadasPdf) {
      exportPDF();
      setDownloadasPdf(false);
    }
  }, [downloadasPdf]);

  useEffect(() => {
    if (download) {
      downloadExcel();
    }
  }, [download]);

  const captureRef = useRef();

  const exportPDF = () => {
    const input = captureRef.current;
    handleDownload(input, "LineageData");
  };

  const fetchWorkflowTypes = async () => {
    let postbody = {
      ...commonPayload,
      workflowType: workflowType === "All" ? "" : workflowType,
      jobId: jobId === "All" ? "" : jobId,
    };
    setMakingApiCall(true);
    const response = await postCall(workFlowTypedApi, postbody);
    setWorkflowTypes(response?.ucxApiResponse || []);
    setMakingApiCall(false);
  };

  const fetchJobList = async () => {
    let postbody = {
      ...commonPayload,
      workflowType: workflowType === "All" ? "" : workflowType,
      jobId: jobId === "All" ? "" : jobId,
    };
    setMakingApiCall(true);
    const response = await postCall(jobsListApi, postbody);
    const jobList = response?.ucxApiResponse || [];
    const formattedJobList = jobList.map((job) => ({
      jobId: job[0],
      jobName: job[2],
    }));
    setJobsList(formattedJobList);
    if (formattedJobList.length > 0) {
      setJobId("All"); // Always ensure 'All' is the default selection
    } else {
      setJobId("All"); // Set to "All" if no jobs are found
    }
    setMakingApiCall(false);
  };

  const fetchApiData = async () => {
    let postbody = {
      ...commonPayload,
      workflowType: workflowType === "All" ? "" : workflowType,
      jobId: jobId === "All" ? "" : jobId,
    };
    setLineageLoad(true);
    const response = await postCall(lineageDashboardApi, postbody);
    setApiData(response?.ucxApiResponse);
    setLineageLoad(false);
  };

  useEffect(() => {
    if (
      jobsList.length !== 0 &&
      jobId &&
      workflowTypes.length !== 0 &&
      workflowType
    )
      fetchApiData();
  }, [jobsList, jobId, workflowTypes, workflowType]);

  useEffect(() => {
    if (refresh) {
      setWorkflowType("All");
      fetchWorkflowTypes();
      fetchJobList();
      setApiData(null);
    }
  }, [refresh]);

  const common_compute = apiData?.common_computes || [];
  const notebook_table = apiData?.notebook_tables || [];
  const data_paths = apiData?.data_paths || [];
  const job_dependency = apiData?.job_dependency || [];
  const users_and_groups = apiData?.users_and_groups || [];
  const code_files_for_job = apiData?.code_files_for_job || [];
  const notebook_count = apiData?.notebook_count || 0;
  const anonymous_tables_count = apiData?.anonymous_tables_count || 0;
  const writeTable = apiData?.write_tables_count || 0;
  const readTable = apiData?.read_tables_count || 0;
  const mount_points = apiData?.mountpoints_count || 0;
  const sql_warehouse_count = apiData?.sql_warehouse_count || 0;
  const users_and_groups_count = apiData?.users_and_groups_count || 0;
  const jobs_count = apiData?.jobs_count || 0;
  const all_purpose_clusters_count = apiData?.all_purpose_clusters_count || 0;
  const dbfs_paths_count = apiData?.dbfs_paths_count || 0;
  const cloud_paths_count = apiData?.cloud_paths_count || 0;
  const job_computes_count = apiData?.job_computes_count || 0;

  const downloadExcel = () => {
    const wb = XLSX.utils.book_new();

    const common_compute_data = [...common_compute];
    common_compute_data.unshift([
      "Job Name",
      "Compute Name",
      "Compute Owner",
      "Compute Type",
      "Workflow Type",
    ]); // Add custom header
    const notebook_table_data = [...notebook_table];
    notebook_table_data.unshift([
      "Job Name",
      "Table Name",
      "Table Type",
      "Workflow Type",
    ]);
    const data_paths_data = [...data_paths];
    data_paths_data.unshift([
      "Job Name",
      "Storage Path",
      "Storage Type",
      "Type",
      "Workflow Type",
    ]);
    const job_dependency_data = [...job_dependency];
    job_dependency_data.unshift([
      "Job Name",
      "Job ID",
      "Job Dependency",
      "Workflow Type",
    ]);
    const users_and_groups_data = [...users_and_groups];
    users_and_groups_data.unshift([
      "Job Name",
      "User",
      "Group",
      "Service Principal",
    ]);
    const code_files_for_job_data = [...code_files_for_job];
    code_files_for_job_data.unshift([
      "Job Name",
      "Dependent Libraries",
      "Job Id",
      "Workflow Type",
    ]);
    const sheetsData = [
      { data: common_compute_data, sheetName: "Common Computes" },
      { data: notebook_table_data, sheetName: "Notebook Tables" },
      { data: data_paths_data, sheetName: "Storage Layer" },
      { data: job_dependency_data, sheetName: "Job Dependency" },
      { data: users_and_groups_data, sheetName: "Users And Groups" },
      { data: code_files_for_job_data, sheetName: "Dependent Libraries" },
    ];

    sheetsData.forEach(({ data, sheetName, headers }) => {
      let ws;
      if (headers && headers.length > 0) {
        const dataWithHeaders = [headers, ...data]; // Add headers as the first row
        ws = XLSX.utils.aoa_to_sheet(dataWithHeaders); // Array of arrays to sheet
      } else {
        ws = XLSX.utils.aoa_to_sheet(data); // Just the data
      }

      const columnWidths = [];
      if (headers) {
        headers.forEach((header, index) => {
          let maxLength = header.length; // Start with the header length
          data.forEach((row) => {
            const cellValue = row[header] ? row[header].toString() : ""; // Get the value of the current column
            maxLength = Math.max(maxLength, cellValue.length); // Get the max length
          });
          columnWidths[index] = maxLength; // Store the max width for this column
        });
      } else {
        data.forEach((row) => {
          row.forEach((cell, index) => {
            const cellValue = cell ? cell.toString() : ""; // Get the value of the current cell
            columnWidths[index] = Math.max(
              columnWidths[index] || 0,
              cellValue.length
            ); // Store the max width for each column
          });
        });
      }

      const colWidth = columnWidths.map((width) => ({ wpx: width * 10 })); // Multiplying by 10 to give some padding

      ws["!cols"] = colWidth; // Apply column widths to the worksheet
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
    });

    XLSX.writeFile(wb, "LineageData.xlsx");

    if (setDownload) {
      setDownload(false);
    }
  };

  return (
    <div ref={captureRef}>
      <LoadingSpinner open={makingApiCall || lineageLoad} />
      <div className={classes["padding-1"]}>
        <Typography mb={2} variant="h5">
          Databricks Objects Lineage Identifier{" "}
        </Typography>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Grid container>
            <Grid item xs={12}>
              <Paper
                sx={{
                  padding: 1.5,
                  backgroundColor: "#f5f5f5",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "8px",
                  minHeight: "50px",
                  maxHeight: "70px",
                  marginBottom: 2,
                }}
              >
                <Typography variant="h7" sx={{ flex: 1 }}>
                  Select Type
                </Typography>
                <FormControl size="small" sx={{ minWidth: "50%" }}>
                  <InputLabel id="select-label">Type</InputLabel>
                  <Select
                    size="small"
                    labelId="select-label"
                    id="select-type"
                    value={workflowType}
                    onChange={handleDropdownTypeChange}
                    label="Select"
                  >
                    {workflowTypes.length === 0 && (
                      <MenuItem value={""}>
                        {jsonData.notAvailableText}
                      </MenuItem>
                    )}
                    {workflowTypes.map((type, index) => (
                      <MenuItem key={index + 1} value={type}>
                        {capitalizeFirstLetter(type)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Paper>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                {CardComp(notebook_count, jsonData.notebook_count)}
              </Grid>
              <Grid item xs={12} sm={4}>
                {CardComp(writeTable, "Write Tables")}
              </Grid>
              <Grid item xs={12} sm={4}>
                {CardComp(mount_points, "Mount Points")}
              </Grid>
              <Grid item xs={12} sm={4}>
                {CardComp(anonymous_tables_count, "Anonymous Tables")}
              </Grid>
              <Grid item xs={12} sm={4}>
                {CardComp(readTable, "Read Tables")}
              </Grid>
              <Grid item xs={12} sm={4}>
                {CardComp(dbfs_paths_count, "DBFS Paths")}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid container>
            <Grid item xs={12}>
              <Paper
                sx={{
                  padding: 1.5,
                  backgroundColor: "#f5f5f5",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "8px",
                  minHeight: "50px",
                  maxHeight: "70px",
                  marginBottom: 2,
                }}
              >
                <Typography variant="h7" sx={{ flex: 1 }}>
                  Select By Job Name
                </Typography>
                <FormControl size="small" sx={{ minWidth: "50%" }}>
                  <InputLabel id="select-label">Job Name</InputLabel>
                  <Select
                    labelId="select-label"
                    id="select-job"
                    value={jobId}
                    size="small"
                    onChange={handleDropdownJobChange}
                    label="Job Name"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          width: 320,
                          maxHeight: 360,
                        },
                      },
                    }}
                  >
                    {jobsList.length === 0 && (
                      <MenuItem value={""}>
                        {jsonData.notAvailableText}
                      </MenuItem>
                    )}

                    {jobsList.length > 0 && (
                      <MenuItem value="All">All</MenuItem>
                    )}
                    {jobsList.map((job, index) => (
                      <MenuItem key={index + 1} value={job.jobId}>
                        {job.jobName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Paper>
            </Grid>

            {/* Render Cards */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                {CardComp(jobs_count, "Job Counts")}
              </Grid>
              <Grid item xs={12} sm={4}>
                {CardComp(cloud_paths_count, "Cloud Paths")}
              </Grid>
              <Grid item xs={12} sm={4}>
                {CardComp(all_purpose_clusters_count, "All Purpose Clusters")}
              </Grid>
              <Grid item xs={12} sm={4}>
                {CardComp(sql_warehouse_count, "SQL Warehouse")}
              </Grid>
              <Grid item xs={12} sm={4}>
                {CardComp(job_computes_count, "Job Computes")}
              </Grid>
              <Grid item xs={12} sm={4}>
                {CardComp(users_and_groups_count, "Users and Groups")}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={4}>
        {notebook_table?.length > 0 && (
          <Grid item xs={12} sm={12}>
            <Paper>
              <Typography variant="h6" ml={1} gutterBottom>
                Tables ({notebook_table?.length})
              </Typography>
              <RenderAssesmentTable
                jobSelected={jobSelected}
                data={notebook_table}
                header={["Table Name", "Table Type"]}
                height="calc(100% - 38px)"
              />
            </Paper>
          </Grid>
        )}

        {data_paths?.length > 0 && (
          <Grid item xs={12} sm={12}>
            <Paper>
              <Typography variant="h6" ml={1} gutterBottom>
                {jsonData.strglyr} ({data_paths?.length})
              </Typography>
              <RenderAssesmentTable
                jobSelected={jobSelected}
                data={data_paths}
                header={["Storage Path", "Storage Type"]}
                height="calc(100% - 38px)"
              />
            </Paper>
          </Grid>
        )}

        {common_compute?.length > 0 && (
          <Grid item xs={12} sm={12}>
            <Paper>
              <Typography variant="h6" ml={1} gutterBottom>
                Compute ({common_compute?.length})
              </Typography>
              <RenderAssesmentTable
                jobSelected={jobSelected}
                data={common_compute}
                header={["Compute Name", "Compute Owner", "Compute Type"]}
                height="calc(100% - 38px)"
              />
            </Paper>
          </Grid>
        )}

        {users_and_groups?.length > 0 && (
          <Grid item xs={12} sm={12}>
            <Paper>
              <Typography variant="h6" ml={1} gutterBottom>
                Users And Groups ({users_and_groups?.length})
              </Typography>
              <RenderAssesmentTable
                jobSelected={jobSelected}
                data={users_and_groups}
                header={["User", "Group", "Service Principal"]}
                height="calc(100% - 38px)"
              />
            </Paper>
          </Grid>
        )}

        {job_dependency?.length > 0 && (
          <Grid item xs={12} sm={12}>
            <Paper>
              <Typography variant="h6" ml={1} gutterBottom>
                Job Dependency ({job_dependency?.length})
              </Typography>
              <RenderAssesmentTable
                jobSelected={jobSelected}
                data={job_dependency}
                header={[
                  "Job ID",
                  "Job Dependency",
                  "Reference",
                  "Entity Type",
                ]}
                height="calc(100% - 38px)"
              />
            </Paper>
          </Grid>
        )}

        {code_files_for_job?.length > 0 && (
          <Grid item xs={12} sm={12}>
            <Paper>
              <Typography variant="h6" ml={1} gutterBottom>
                {jsonData.dependentLibTxt} ({code_files_for_job?.length})
              </Typography>
              <RenderAssesmentTable
                jobSelected={jobSelected}
                data={code_files_for_job}
                header="Dependent Libraries"
                height="calc(100% - 38px)"
              />
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default LineageDashboard;
