import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoadingSpinner from "../UI/LoadingSpinner";
import NoData from "../Common/NoData/NoData";
import Pagination from "../Common/Pagination/Pagination";
import jsonData from "../../Assets/i18n/en.json";
import constants from "../../utils/constant";
import { getCall } from "../../Resources/apicalls";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Box,
  styled,
  Button,
  IconButton,
  InputBase,
} from "@mui/material";
import classes from "./WorkspaceSummary.module.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DynamicProgressBar from "../Common/DynamicProgressBar/DynamicProgressBar";
import {
  convertEntityName,
  convertToCamelCase,
} from "../../utils/CamelCase/CamelCase";
import CachedIcon from "@mui/icons-material/Cached";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import formatEventLoggedTime from "../Common/FormatTime/FormatTime";
import SummaryDescription from "./SummaryDescription";
import { getUcxSummaryApi } from "../../Resources/apiurls";

const StyledTableCell = styled(TableCell)({
  borderLeft: "none",
  borderRight: "none",
  height: "3.75rem",
  paddingTop: "10px",
  paddingBottom: "10px",
});

const StyledTableContainer = styled(TableContainer)({
  borderBottom: "none",
});

const WorkspaceSummary = () => {
  const [makingApiCall, setMakingApiCall] = useState(false);
  const [summaryContent, setSummaryContent] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const workspaceId = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]?.workspace_id
  );
  const [summaryDescription, setSummaryDescription] = useState(false);
  const [id, setId] = useState(false);
  const [apiTime, setApiTime] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [inputParameters, setInputParameters] = useState("");
  const [result, setResult] = useState({});
  const [status, setStatus] = useState("");
  const [entity, setEntity] = useState("");
  const itemsPerPage = 8;

  const onSearchBoxChage = (key) => {
    setSearchKeyword(key);
  };

  const handleTableRowClick = (name, id, inputParameters, result, status) => {
    setId(id);
    setEntity(name);
    setInputParameters(inputParameters);
    setResult(result || {});
    setSummaryDescription(true);
    setStatus(status);
  };

  useEffect(() => {
    fetchDataFromApi();
  }, [workspaceId, currentPage, searchKeyword]);

  const renderTableRows = (summaryData) => {
    const createdby = summaryData?.createdBy;

    const groupedResources = summaryData.ucxResources.reduce(
      (acc, resource) => {
        resource.resourceStatusDetails.forEach((detail) => {
          const uniqueKey = `${detail.triggerId}_${resource.name}`;

          if (!acc[uniqueKey]) {
            acc[uniqueKey] = {
              startDetail: null,
              endDetail: null,
              resource,
              createdby,
            };
          }

          if (!acc[uniqueKey].startDetail) {
            acc[uniqueKey].startDetail = detail;
          } else {
            acc[uniqueKey].endDetail = detail;
          }
        });
        return acc;
      },
      {}
    );

    const sortedGroupedResources = Object.values(groupedResources).sort(
      (a, b) => {
        const startA = new Date(a.startDetail?.eventLoggedTime || 0);
        const startB = new Date(b.startDetail?.eventLoggedTime || 0);
        return startB - startA;
      }
    );

    const filteredResources = sortedGroupedResources.filter(
      (item) =>
        item.startDetail.triggerId.includes(searchKeyword) ||
        item.resource.name.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    const filteredTotalPages = Math.ceil(
      filteredResources.length / itemsPerPage
    );
    setTotalPages(filteredTotalPages);

    if (currentPage > filteredTotalPages) {
      setCurrentPage(1);
    }

    return filteredResources
      .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
      .map(({ startDetail, endDetail, resource, createdby }) => {
        const cellValues = [
          resource.name
            ? resource.name === "NOTEBOOK_CODE_SCAN"
              ? convertEntityName("notebook_Migration")
              : convertEntityName(resource.name)
            : null,

          startDetail?.triggerId || "",
          startDetail?.eventLoggedTime
            ? formatEventLoggedTime(startDetail.eventLoggedTime)
            : "",
          [
            resource.status
              ? convertToCamelCase(
                  endDetail ? endDetail.status : startDetail.status
                )
              : null,
            resource.status
              ? DynamicProgressBar(
                  endDetail ? endDetail.status : startDetail.status
                )
              : null,
          ],
          endDetail?.eventLoggedTime
            ? formatEventLoggedTime(endDetail.eventLoggedTime) ||
              jsonData.inProgress
            : "",

          createdby,
        ];

        return (
          <TableRow
            className={classes["table-row-height"]}
            key={resource.name + startDetail?.triggerId}
          >
            {cellValues.map((value, index) => (
              <StyledTableCell className={classes["table-val"]} key={index}>
                {value}
              </StyledTableCell>
            ))}
            <StyledTableCell>
              <Button
                onClick={() =>
                  handleTableRowClick(
                    resource.name,
                    startDetail?.triggerId,
                    resource?.resourceStatusDetails?.find(
                      (detail) => detail.triggerId === startDetail?.triggerId
                    ).inputParameters,
                    resource?.resourceStatusDetails?.find(
                      (detail) => detail.triggerId === startDetail?.triggerId
                    )?.triggerResult,
                    resource?.resourceStatusDetails
                      ?.filter(
                        (item) => item.triggerId === startDetail?.triggerId
                      )
                      .map((item) => item.status)
                      .at(-1)
                  )
                }
              >
                <ArrowForwardIosIcon />
              </Button>
            </StyledTableCell>
          </TableRow>
        );
      });
  };

  const fetchDataFromApi = async () => {
    try {
      setMakingApiCall(true);
      let api = `${getUcxSummaryApi}${workspaceId}`;
      const response = await getCall(api);
      const summaryData = response?.ucxApiResponse;
      setApiTime(new Date());
      if (summaryData !== null) {
        renderSummaryTable(summaryData);
      }
    } catch (error) {
      setSummaryContent(null);
      console.error(error);
    } finally {
      setMakingApiCall(false);
    }
  };

  const renderSummaryTable = (summaryData) => {
    const summary = (
      <Paper className={classes["paper"]}>
        <StyledTableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className={classes["table-row-height"]}>
                {constants.SummaryHeadArray?.map((head) => (
                  <StyledTableCell
                    className={classes["table-heading"]}
                    key={head}
                  >
                    {head}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{renderTableRows(summaryData)}</TableBody>
          </Table>
        </StyledTableContainer>
      </Paper>
    );
    setSummaryContent(summary);
  };

  const hadleRefresh = () => {
    fetchDataFromApi();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (!summaryContent && !makingApiCall && !summaryDescription) {
    return <NoData height="12rem" label={jsonData.noHistory}></NoData>;
  }

  if (summaryDescription) {
    return (
      <SummaryDescription
        setSummaryDescription={setSummaryDescription}
        inputParameters={inputParameters}
        result={result}
        id={id}
        entity={entity}
        status={status}
      />
    );
  }

  return (
    <div className={classes["summary-container"]}>
      <LoadingSpinner open={makingApiCall} />

      <div>
        <span className={classes["dashboard-header"]}>{jsonData.summary}</span>
        <div className={classes["refresh-icon"]}>
          <Paper className={`${classes["input-box"]}`}>
            <IconButton
              type="button"
              className={`${classes["padding"]}`}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              className={`${classes["search-input"]}`}
              placeholder={jsonData.srchNmId}
              type="text"
              value={searchKeyword}
              onChange={(e) => onSearchBoxChage(e.target.value)}
            />
          </Paper>
          <div className={classes["align-items"]}>
            <Tooltip title={`${jsonData.lastRefreshed} ${apiTime}`}>
              <CachedIcon onClick={hadleRefresh} />
            </Tooltip>
          </div>
        </div>
      </div>
      <br />
      <Grid container item className={classes["grid-css"]}>
        <Box mt={1} className={classes["box-css"]}>
          {summaryContent}
        </Box>
      </Grid>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default WorkspaceSummary;
