import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { listMetastoreApi } from "../../Resources/apiurls";

  export const getMetaStoreList = createAsyncThunk("data/fetchData", async (body) => {
    try {
      const response = await axios.post(listMetastoreApi, body, {
        headers: {
          Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      return response.data.ucxApiResponse;
    } catch (error) {
      console.error(error);
      throw error;
    }
  });