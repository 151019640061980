import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
const ButtonStyled = (props) => {
  const { label, background, variant, border, color, onclick ,disabled} = props;
  return (
    <Box sx={{ "& button": { m: 1 } }}>
      <Button
        variant={variant}
        size="large"
        disabled={disabled}
        sx={{
          background: { background },
          border: { border },
          height:'40px !important',
          borderRadius: "20px",
          color: { color },
          '&.MuiButton-containedPrimary.Mui-disabled': {
            backgroundColor: '#62a3fe', 
            color: '#fff', 
            border:'none !impportant'
          }
        }}
        onClick={onclick}
      >
        {label}
      </Button>
    </Box>
  );
};

export default ButtonStyled;
