import { useEffect, useState } from "react";
import AcrossWorkspaceInfo from "../Common/AccrossWorkspaceInfo.jsx/AcrossWorkspaceInfo";
import jsonData from "../../Assets/i18n/en.json";
import styles from "../NewComponent.module.css";
import ButtonStyled from "../UI/NavigationButtons/Buttons";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { workspaceObjectMigrationCall } from "../../Resources/apicalls";
import BasicAlerts from "../Common/MessageBanner.jsx/MessageBanner";
import constants from "../../utils/constant";
import ValidationReview from "../UcValidation/ValidationReview";

function Workspace(props) {
  const { selectComponent } = props;
  const workspaceInfo = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace?.[0]
  );
  const accountInfoData = useSelector((state) => state.accountInfo);
  const [disableNext, setDisableNext] = useState(false);
  const [externalWsToken, setExternalWsToken] = useState("");
  const [externalWsUrl, setExternalWsUrl] = useState("");
  const [alert, setAlert] = useState(false);
  const [openValidation, setOpenValidation] = useState(false);
  const [id, setId] = useState("");
  const [showAlertClone, setShowAlertClone] = useState(false);
  const [validationData, setValidatedData] = useState([]);

  const cloneWorkspace = async () => {
    let triggerId = uuidv4().replace(/-/g, "").substring(0, 10);
    setId(triggerId);
    let payload = {
      accId: workspaceInfo?.account_id,
      workspaceIdentifier: workspaceInfo.workspace_id,
      runJobsAs: accountInfoData.userId
        ? accountInfoData.userId
        : accountInfoData.OAuthUser.userName,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      workspaceUrl: workspaceInfo.workspace_url,
      workspaceName:workspaceInfo.workspace_name,
      crossWorkspaceUrl: externalWsUrl,
      crossWorkspacePatToken: externalWsToken,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      sourceWorkspaceObjectsPath: "/Workspace",
      triggerId: triggerId,
    };

    workspaceObjectMigrationCall(payload);
    setShowAlertClone(true);
    reset();
  };

  const reset = () => {
    setExternalWsToken("");
    setExternalWsUrl("");
  };

  const enableNext = () => {
    if (externalWsToken === "" || externalWsUrl === "") setDisableNext(true);
    else setDisableNext(false);
  };

  useEffect(() => {
    enableNext();
  }, [externalWsToken, externalWsToken]);

  if (openValidation) {
    return (
      <ValidationReview
        headValues={constants?.dbfsCloneHead}
        cellValuesAsObjects={validationData}
        setShowReview={setOpenValidation}
        heading={jsonData.workspaceValidation}
      />
    );
  }

  return (
    <>
      {showAlertClone && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startClonemsg} <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertClone}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      {alert && (
        <BasicAlerts
          severity={jsonData.successVal}
          label={jsonData.wsObjCloneSucsTxt}
          setShowReview={setAlert}
          showReview={alert}
        />
      )}
      <AcrossWorkspaceInfo
        setDisableNext={setDisableNext}
        setExternalWsUrl={setExternalWsUrl}
        setExternalWsToken={setExternalWsToken}
      />

      <div className={styles["button-container"]}>
        <ButtonStyled
          label={jsonData.canceltxt}
          background="transparent"
          variant="text"
          border="none"
          color="#24262C"
          // onclick={onClose}
        />
        <ButtonStyled
          label={jsonData.cloneTxt}
          background="#457ECF"
          variant="contained"
          border="1px solid #457ECF"
          disabled={disableNext}
          onclick={cloneWorkspace}
        />
      </div>
    </>
  );
}
export default Workspace;
