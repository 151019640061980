import { createSlice } from '@reduxjs/toolkit';

const workspaceConfigSlice = createSlice({
  name: 'workspaceConfig',
  initialState: {
    data: {}, // Store as an object to allow multiple workspace configurations
    selectedValue: null, // Track the currently selected workspace ID
  },
  reducers: {
    saveWorkspaceConfigData: (state, action) => {
      const { workspace_identifier, config } = action.payload;
      // Append or update the configuration for the given workspace identifier
      state.data[workspace_identifier] = config;
    },
    setSelectedWorkspaceIdentifier: (state, action) => {
      state.selectedValue = action.payload; // Set the selected workspace ID
    },
    clearWorkspaceConfig: (state) => {
      state.data = {}; // Clear all configurations
      state.selectedValue = null; // Reset selected workspace ID
    },
  },
});

export const { saveWorkspaceConfigData, setSelectedWorkspaceIdentifier, clearWorkspaceConfig } = workspaceConfigSlice.actions;

export default workspaceConfigSlice.reducer;
