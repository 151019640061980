import styles from "../NewComponent.module.css";
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  Tooltip,
  RadioGroup,
} from "@mui/material";
import jsonData from "../../Assets/i18n/en.json";
import { useEffect, useState } from "react";
import { module1ApiCall } from "../../Resources/apicalls";
import { v4 as uuidv4 } from "uuid";
import { NameWOhPattern, notebookInputPathPattern } from "../../utils/regex";
import LoadingSpinner from "../UI/LoadingSpinner";
import BasicAlerts from "../Common/MessageBanner.jsx/MessageBanner";
import constants from "../../utils/constant";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useCommonPayload } from "../../Resources/useCommonPayload";
import UcmaTextField from "../UI/Form/TextField";
import UcmaSubmitButtons from "../UI/Form/ButtonsContainer";
import StyledRadio from "../UI/StyledRadioButton/StyledRadio";
import { useFormValidation } from "../UI/Form/useFormValiditation";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const type = { scan: "Scan", upgrade: "Upgrade" };
const Notebook = (props) => {
  const { selectComponent } = props;
  const [selectedName, setSelectedName] = useState([]);
  const [scanOrUpgrade, setScanOrUpgrade] = useState(type.upgrade);
  const [sourceVersion, setSourceVersion] = useState("");
  const [targetVersion, setTargetVersion] = useState("");
  const commonPayload = useCommonPayload({ type: "post" });
  const [path1, setPath1] = useState("");
  const [path2, setPath2] = useState("");
  const [makingApicalls, setMakingApicalls] = useState(false);
  const [disableNext, setDisableNext] = useState(true);
  const [id, setId] = useState("");
  const [showAlertMigration, setShowAlertMigration] = useState(false);
  const [defaultUnityCatalogName, setDefaultUnityCatalogName] = useState("");
  const { isFormValid, setFieldValid, resetFieldValidity } = useFormValidation([
    "path1",
  ]);

  function validateHandlerPath2() {
    if ((path1 && path2.startsWith(path1 + "/")) || path2 === path1) {
      return "Notebook target directory should not be a subpath of source path.";
    } else {
      return null;
    }
  }
  const handleSourceVersion = (event) => {
    const selectedVersion = event.target.value;
    setSourceVersion(selectedVersion);
  };

  const handleTargetVersion = (event) => {
    const selectedVersion = event.target.value;
    setTargetVersion(selectedVersion);
  };

  const handleChange = (event) => {
    setSelectedName(event.target.value);
  };

  useEffect(() => {
    if (showAlertMigration) {
      setTimeout(() => {
        setShowAlertMigration(false);
      }, 60000);
    }
  }, [showAlertMigration]);

  const handleSubmitClick = async () => {
    let triggerId = uuidv4().replace(/-/g, "").substring(0, 10);
    setId(triggerId);
    let postbody = {
      ...commonPayload,
      currentDbrVersion: sourceVersion,
      targetDbrVersion: targetVersion,
      notebookPath: path1,
      triggerId: triggerId,
      namespaceFlag: selectedName.includes("Namespace") ? "True" : "False",
      mountpointFlag: selectedName.includes("Mount Point to External location")
        ? "True"
        : "False",
      dbfsFlag: selectedName.includes("DBFS to Volume") ? "True" : "False",
      cloudToTableFlag: selectedName.includes("Cloud Path to Table")
        ? "True"
        : "False",
      notebookOutputPath: scanOrUpgrade === type.scan ? "" : path2,
      catalogName: defaultUnityCatalogName,
      processName:
        scanOrUpgrade === type.scan
          ? constants.notebookConstants.notebookPreScan
          : constants.notebookConstants.notebookUpgrade,
      notebookScanFlag: scanOrUpgrade === type.scan ? "True" : "False",
    };
    setShowAlertMigration(true);
    handleDBR();
    await module1ApiCall(postbody);
  };

  const handleDBR = () => {
    setSourceVersion("");
    setTargetVersion("");
    setPath1("");
    setPath2("");
    setSelectedName([]);
    setDefaultUnityCatalogName("");
    resetFieldValidity(true);
  };
  const onInputChange = (event) => {
    setScanOrUpgrade(event.target.value);
    setPath2("");
    if (event.target.value === type.scan) {
      resetFieldValidity(false, [], ["path2"]);
    } else {
      resetFieldValidity(false, ["path2"], []);
    }
  };
  const enableNext = () => {
    if (
      (defaultUnityCatalogName !== "" &&
        !NameWOhPattern.test(defaultUnityCatalogName)) ||
      path1 === "" ||
      (scanOrUpgrade === type.upgrade && path2 === "") ||
      (path1 && path2.startsWith(path1 + "/"))
    ) {
      setDisableNext(true);
      return;
    }
    if (
      (sourceVersion === "" &&
        targetVersion === "" &&
        selectedName.length > 0) ||
      (sourceVersion !== "" && targetVersion !== "")
    ) {
      setDisableNext(false);
    } else {
      setDisableNext(true);
    }
  };
  useEffect(() => {
    enableNext();
  }, [
    path1,
    disableNext,
    path2,
    scanOrUpgrade,
    sourceVersion,
    targetVersion,
    selectedName,
    isFormValid,
    defaultUnityCatalogName,
  ]);

  return (
    <>
      {showAlertMigration && !makingApicalls && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startMigrationmsg}
              <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertMigration}
          showReview={showAlertMigration}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}
      <LoadingSpinner open={makingApicalls} />
      <>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={onInputChange}
            value={scanOrUpgrade}
          >
            <div className={styles["margin-right-16"]}>
              <StyledRadio value={type.scan} label={type.scan} />
            </div>

            <StyledRadio value={type.upgrade} label={type.upgrade} />
          </RadioGroup>
        </FormControl>{" "}
        <div className={`${styles["display"]} ${styles["margin-top-26"]}`}>
          {jsonData.dbrtext}
          {"  "}(OPTIONAL)
          <div className={`${styles["margin-left"]}`}>
            <Tooltip title={jsonData.dbrvInfo}>
              <InfoOutlinedIcon />
            </Tooltip>
          </div>
          <FormControl sx={{ m: 1, minWidth: 335 }} size="small">
            <InputLabel>{jsonData.sourceVersion}</InputLabel>
            <Select
              value={sourceVersion}
              label={jsonData.sourceVersion}
              onChange={handleSourceVersion}
            >
              <MenuItem value={""}>
                <>{jsonData.noneTxt}</>
              </MenuItem>
              {constants.notebookSourceVersion.map((version) => (
                <MenuItem key={version} value={version}>
                  <>{version}</>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 335 }} size="small">
            <InputLabel>{jsonData.targetVersion}</InputLabel>
            <Select
              value={targetVersion}
              label={jsonData.targetVersion}
              onChange={handleTargetVersion}
            >
              <MenuItem value={""}>
                <>{jsonData.noneTxt}</>
              </MenuItem>
              {constants.notebookTargetVersion.map((version) => (
                <MenuItem key={version} value={version}>
                  {version}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </>

      <>
        {" "}
        <div className={` ${styles["margin-top-26"]}`}>
          <FormControl
            sx={{
              "& > :not(style)": { width: "58.75rem" },
            }}
            size="small"
          >
            <InputLabel id="demo-multiple-checkbox-label">
              {jsonData.noteBookUpdateOption}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedName}
              onChange={handleChange}
              input={<OutlinedInput label={jsonData.noteBookUpdateOption} />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {constants.notebookUpdatesOption.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={selectedName.indexOf(name) > -1} />
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={styles["margin-top-26"]}>
          <UcmaTextField
            name={jsonData.inputPathText}
            info={jsonData.sourceNotebookPathInfo}
            value={path1}
            onChangeValue={setPath1}
            required
            pattern={notebookInputPathPattern}
            placeholder={jsonData.notebookPlaceholder}
            setDisableSubmit={(inValid) => setFieldValid("path1", inValid)}
          />
        </div>
        {scanOrUpgrade === type.upgrade && (
          <UcmaTextField
            customValidateHandler={validateHandlerPath2}
            name={jsonData.inputNotePathText}
            info={jsonData.targetInfo}
            value={path2}
            onChangeValue={setPath2}
            required
            pattern={notebookInputPathPattern}
            placeholder={jsonData.notebookPlaceholder1}
            setDisableSubmit={(inValid) => setFieldValid("path2", inValid)}
          />
        )}
        {selectedName.includes("Namespace") && (
          <UcmaTextField
            name={jsonData.catalogText}
            info={jsonData.defaultCatalogInfoNotebook}
            value={defaultUnityCatalogName}
            onChangeValue={setDefaultUnityCatalogName}
            pattern={NameWOhPattern}
            placeholder={jsonData.unityCatalogNamePlaceholder}
            setDisableSubmit={(inValid) =>
              setFieldValid("catalogName", inValid)
            }
          />
        )}
      </>
      <UcmaSubmitButtons
        handleReset={handleDBR}
        disableSubmit={disableNext || !isFormValid}
        handleSubmit={handleSubmitClick}
        primaryButtonLabel={scanOrUpgrade}
      />
    </>
  );
};
export default Notebook;
