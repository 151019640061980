import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import classes from "./WorkspaceSummary.module.css";
import constants from "../../utils/constant";
import EllipsisTooltip from "../UI/Tooltip/Tooltip";
import { useState, useEffect } from "react";
import {
  moduleDepreciated1Api,
  paginationCount,
} from "../../Resources/apiurls";
import { getCall } from "../../Resources/apicalls";
import NoData from "../Common/NoData/NoData";
import jsonData from "../../Assets/i18n/en.json";
import Pagination from "../Common/Pagination/Pagination";

const DepreciatedModule = (props) => {
  const { id } = props;
  const [pageNo, setPageNo] = useState(0);
  const [functionModuleData, setFunctionModuleData] = useState([]);
  const [pageSize] = useState(5);
  const [count, setCount] = useState(0);
  const [makingApiCall, setMakingApiCall] = useState(false);

  const featchData = async () => {
    const api = `${moduleDepreciated1Api}${id}?pageNo=${pageNo}&pageSize=${pageSize}`;
    const depreciatedData = await getCall(api);
    setFunctionModuleData(depreciatedData?.ucxApiResponse);
  };
  const handleChange = (event) => {
    setPageNo(event - 1);
  };

  const pagination = async () => {
    const api = `${paginationCount}${id}`;
    setMakingApiCall(true);
    const count = await getCall(api);
    setMakingApiCall(false);
    setCount(count?.ucxApiResponse?.deprecatedModulesCount);
  };

  useEffect(() => {
    pagination();
  }, []);

  useEffect(() => {
    featchData();
  }, [pageNo, pageSize]);

  return (
    <>
        <hr/>
      <div className={classes['notebook-header']}>{jsonData.depreciatedModule} ({count})</div>
      <Paper className={classes["paper"]}>
      {functionModuleData?.length ? (
        <div className={classes['margin-bottom']}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes["table-row-height"]}>
                  {constants.DepreciatedModuleHeader.map((head) => (
                    <TableCell className={classes["table-heading"]} key={head}>
                      {head}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {functionModuleData?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <EllipsisTooltip>{item.notebookPath}</EllipsisTooltip>
                    </TableCell>
                    <TableCell>
                      <EllipsisTooltip>{item.missingModule}</EllipsisTooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        <Pagination
          totalPages={Math.ceil(count / pageSize)}
          currentPage={pageNo + 1}
          onPageChange={handleChange}
        />
        </div>):null}
      </Paper>

    </>
  );
};

export default DepreciatedModule;
