import CustomTable from "../UI/CustomTable/CustomTable";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import classes from "./validation.module.css";

const ValidationResult = (props) => {
  const { headValues, cellValuesAsObjects } = props;

  return (
    <TableContainer className={classes["table"]} component={Paper}>
      <CustomTable
        head={headValues}
        cellValuesAsObjects={cellValuesAsObjects}
      />
    </TableContainer>
  );
};
export default ValidationResult;
