import { useEffect, useState } from "react";
import { notebookInputPathPattern } from "../../utils/regex";
import UcmaTextField from "../UI/Form/TextField";
import jsonData from "../../Assets/i18n/en.json";
import classes from "./TableMapping.module.css";
import { postCall } from "../../Resources/apicalls";
import { notebookCodeScanApi } from "../../Resources/apiurls";
import { GenerateUid } from "../../utils/GenerateUid/GenerateUid";
import { useCommonPayload } from "../../Resources/useCommonPayload";
import BasicAlerts from "../Common/MessageBanner.jsx/MessageBanner";
import { useSelector } from "react-redux";
import ButtonStyled from "../UI/NavigationButtons/Buttons";
import StyledRadio from "../UI/StyledRadioButton/StyledRadio";
import HeadingWithInfo from "../Common/HeadingWithInfo/HeadingWithInfo";
import { Input, RadioGroup } from "@mui/material";

export default function TableMapping(props) {
  const { selectComponent } = props;

  const accountInfoData = useSelector((state) => state.accountInfo);
  const [disableNext, setDisableNext] = useState(false);
  const [defaultUnityCatalogName, setDefaultUnityCatalogName] = useState("");
  const [scan, setScan] = useState(false);
  const [scanSubmit, setScanSubmit] = useState(false);
  const [tableCreation, setTableCreation] = useState(false);
  const [path1, setPath1] = useState("");
  const [creationType, setCreationType] = useState("");
  const [id, setId] = useState("");
  const [file64, setFile64] = useState(null); // File will be stored here
  const [fileName, setFileName] = useState(""); // For displaying file name

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      encodeFileToBase64(selectedFile);
    }
  };

  const encodeFileToBase64 = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Content = reader.result.split(',')[1]; 
      setFile64(base64Content);
    };
    reader.readAsDataURL(file);
  };
  const commonPayload = useCommonPayload({ type: "post" });
  const reset = () => {
    setDefaultUnityCatalogName("");
    setPath1("");
    setScan(false);
    setTableCreation(false);
  };
  const handleSubmitClick = (createTable) => {
    NoteBooksScan(createTable);
  };

  useEffect(() => {
    setDefaultUnityCatalogName("");
    setPath1("");
  }, [scan]);

  const enableNext = () => {
    if (!path1.length === 0) {
      if (path1 === "") setDisableNext(true);
      else setDisableNext(false);
    } else if (tableCreation) {
      setDisableNext(false);
    } else setDisableNext(true);
  };

  let scanResultDisplay = {};

  useEffect(() => {
    enableNext();
  }, [scan, path1, tableCreation, disableNext]);

  async function NoteBooksScan(createTable) {
    let triggerId = GenerateUid();
    setId(triggerId);
    let payload = {
      ...commonPayload,
      notebookPath: path1,
      triggerId: triggerId,
      catalogName: defaultUnityCatalogName,
      tableCreation: createTable,
      notebookScanning: !path1.length == 0,
      existingAllPurposeClusterId: "",
      runJobsAs: accountInfoData.userId
        ? accountInfoData.userId
        : accountInfoData.OAuthUser.userName,
      base64Csv: file64 || "",
    };
    setScanSubmit(true);
    reset();
    let data = await postCall(notebookCodeScanApi, payload);
    scanResultDisplay = data;
  }

  const onInputChange = (event) => {
    setCreationType(event.target.value);
    setFileName("");
  };

  const disableCreateTable = () => {
    return !!(
      creationType === "" ||
      (creationType === "csv" && fileName === "")
    );
  };

  return (
    <>
      {scanSubmit && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startMigrationmsg} <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setScanSubmit}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      <div style={{ display: "flex" }}>
        <UcmaTextField
          name={jsonData.inputPathText}
          info={jsonData.sourceNotebookPathInfo}
          value={path1}
          onChangeValue={setPath1}
          // required
          pattern={notebookInputPathPattern}
          placeholder={jsonData.notebookPlaceholder}
          setDisableSubmit={setDisableNext}
          // disabled={!scan}
        />
        <div className={classes["marginTop-4"]}>
          <ButtonStyled
            label={"scan"}
            background="#457ECF"
            variant="contained"
            disabled={path1.length === 0}
            onclick={() => handleSubmitClick(false)}
          />
        </div>
      </div>

      <div className={classes["margintop-1"]}>
        <HeadingWithInfo
          label={jsonData.tablecreationTxt}
          info={jsonData.tableCreationInfo}
        />

        <div className={classes["margintop-1"]} style={{ display: "flex" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={onInputChange}
            value={creationType}
          >
            <StyledRadio value={"default"} label={"Default"} />
            <StyledRadio value={"csv"} label={"Upload CSV"} />
          </RadioGroup>
          {creationType === "csv" && (
            <Input type={"file"} onChange={handleFileUpload} accept={".csv"} />
          )}

          <ButtonStyled
            label={"Create Table"}
            background="#457ECF"
            variant="contained"
            disabled={disableCreateTable()}
            onclick={() => handleSubmitClick(true)}
          />
        </div>
      </div>
    </>
  );
}
