import jsonData from "../../../Assets/i18n/en.json";
import styles from "../../NewComponent.module.css";
import {
  WorkspacetokenPattern,
  azureDatabricksUrlPattern,
  databricksUrlPattern,
} from "../../../utils/regex";
import { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import PropTypes from "prop-types";
import HeadingWithInfo from "../HeadingWithInfo/HeadingWithInfo";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import UcmaTextField from "../../UI/Form/TextField";

function AcrossWorkspaceInfo(props) {
  const { setExternalWsToken, setExternalWsUrl, setDisableNext } = props;
  const accountInfoData = useSelector((state) => state.accountInfo);
  const [workspaceUrl, setWorkspaceUrl] = useState("");
  const [workspaceToken, setWorkspaceToken] = useState("");

  useEffect(() => {
    if (
      accountInfoData.cloudType === jsonData.awsTxt
        ? !databricksUrlPattern.test(workspaceUrl) ||
          !WorkspacetokenPattern.test(workspaceToken)
        : !azureDatabricksUrlPattern.test(workspaceUrl) ||
          !WorkspacetokenPattern.test(workspaceToken)
    )
      setDisableNext(true);
    else setDisableNext(false);
  }, [workspaceToken, workspaceUrl]);

  return (
    <Box
      sx={{
        "& > :not(style)": { width: "30.75rem" },
      }}
      noValidate
      autoComplete="off"
    >
      <UcmaTextField
        name={jsonData.acrossWorkspaceurlTxt}
        info={jsonData.workspaceurlInfo}
        value={workspaceUrl}
        onChangeValue={(value) => {
          setWorkspaceUrl(value);
          setExternalWsUrl(value);
        }}
        placeholder={
          accountInfoData.cloudType === jsonData.awsTxt
            ? jsonData.wsUrlAwsPh
            : jsonData.wsUrlAzurePh
        }
        setDisableSubmit={setDisableNext}
        pattern={
          accountInfoData.cloudType === jsonData.awsTxt
            ? databricksUrlPattern
            : azureDatabricksUrlPattern
        }
        textFieldClassName={`${styles["margin-top-16"]}`}
        isDialog
      />

      <UcmaTextField
        name={jsonData.acrossWorkspaceTokenTxt}
        info={jsonData.tokenInfo}
        value={workspaceToken}
        onChangeValue={(value) => {
          setWorkspaceToken(value);
          setExternalWsToken(value);
        }}
        placeholder={jsonData.wsTokenPh}
        setDisableSubmit={setDisableNext}
        pattern={WorkspacetokenPattern}
        textFieldClassName={`${styles["margin-top-16"]}`}
        isDialog
      />
    </Box>
  );
}

AcrossWorkspaceInfo.propTypes = {
  setDisableNext: PropTypes.func.isRequired,
  setExternalWsToken: PropTypes.func.isRequired,
  setExternalWsUrl: PropTypes.func.isRequired,
};
export default AcrossWorkspaceInfo;
