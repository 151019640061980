import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./WorkspaceSummary.module.css";
import jsonData from "../../Assets/i18n/en.json";
import classes from "../NewComponent.module.css";
import InputDisplay from "./InputDisplay";
import ResultDisplay from "./ResultDisplay";
import { convertEntityName, convertToCamelCase } from "../../utils/CamelCase/CamelCase";
import NotebookStringReplacementSummary from "./NotebookStringReplacementSummary";
import NotebokStringSearchSummary from "../Notebook/NotebookStringSearchSummary";
import NotebookUpgradeSummary from "./NotebookUpgradeSummary";
import constants from "../../utils/constant";

export default function SummaryDescription(props) {
  const { setSummaryDescription, id, inputParameters, result, entity, status } =
    props;
  const [value, setValue] = useState("1");
  const [entityName, setEntityName] = useState(entity);
  // const parsedResult = (result !="{}" ||Object.keys(result).length !== 0) ? JSON.parse(result):{};
  const parsedResult = (() => {
    if (typeof result === "string" && result !== "{}") {
      try {
        return JSON.parse(result);
      } catch (e) {
        console.error("Failed to parse result:", e);
        return {};
      }
    }
    return {};
  })();

  useEffect(() => {
    if (entity === "NOTEBOOK_CODE_SCAN") {
      if (
        parsedResult.name ===
        constants.notebookConstants.notebookStringReplacement
      ) {
        setValue("4");
        setEntityName(jsonData.notebookStringReplacement);
      } else if (
        parsedResult.name === constants.notebookConstants.notebookSearch
      ) {
        setValue("7");
        setEntityName(jsonData.notebookSearch);
      } else if (
        parsedResult.name === constants.notebookConstants.notebookUpgrade
      ) {
        setValue("3");
        setEntityName(jsonData.notebookUpgrade);
      } else if (
        parsedResult.name === constants.notebookConstants.notebookPreScan
      ) {
        setValue("3");
        setEntityName(jsonData.notebookPreScan);
      } else {
        setValue("6");
        setEntityName("Notebook Migration");
      }
    }
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBack = () => {
    setSummaryDescription(false);
  };
  return (
    <>
      <div className={classes["display"]}>
        <ArrowBackIcon
          onClick={handleBack}
          className={classes["margin-right-8"]}
        />
        <div className={classes["button-container"]}>
          <div className={classes["basic-details-heading"]}>
            {jsonData.summaryDetail}- {convertEntityName(entityName)} - {id}
          </div>
        </div>
      </div>
      <Box className={styles["width"]}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            {entity === "NOTEBOOK_CODE_SCAN" && (
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                value={7}
              >
                {(parsedResult.name == null ||
                  parsedResult.name == "NotebookMountPointReplacement" ||
                  parsedResult.name ===
                    constants.notebookConstants.notebookUpgrade) && (
                  <Tab label={jsonData.notebookUpgrade} value="3" />
                )}

                {(parsedResult.name == null ||
                  parsedResult.name == "NotebookMountPointReplacement" ||
                  parsedResult.name ===
                    constants.notebookConstants.notebookPreScan) && (
                  <Tab label={jsonData.notebookPreScan} value="3" />
                )}
                {(parsedResult.name == null ||
                  parsedResult.name == "NotebookMountPointReplacement" ||
                  parsedResult.name ===
                    constants.notebookConstants.notebookSearch) && (
                  <Tab label={jsonData.notebookSearch} value="7" />
                )}
                {(!parsedResult.name == null ||
                  parsedResult.name ===
                    constants.notebookConstants.notebookStringReplacement ||
                  parsedResult.name == "NotebookMountPointReplacement") && (
                  <Tab label={jsonData.notebookStringReplacement} value="4" />
                )}

                <Tab label={jsonData.inputParaTxt} value="6" />
              </TabList>
            )}
            {entity !== "NOTEBOOK_CODE_SCAN" && (
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label={jsonData.resultTxt} value="1" />
                <Tab label={jsonData.inputParaTxt} value="6" />
              </TabList>
            )}
          </Box>

          <TabPanel value="1">
            <ResultDisplay
              result={result}
              id={id}
              entity={entity}
              inputParameters={inputParameters}
              status={status}
            />
          </TabPanel>

          <>
            <TabPanel value="3">
              <NotebookUpgradeSummary id={id} entity={parsedResult.name} />
            </TabPanel>
            <TabPanel value="4">
              <NotebookStringReplacementSummary id={id} />
            </TabPanel>
            <TabPanel value="7">
              <NotebokStringSearchSummary id={id} />
            </TabPanel>
          </>

          <TabPanel value="6">
            <InputDisplay inputParameters={inputParameters} />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
