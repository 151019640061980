import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneTableViewApi } from "../../Resources/apiurls";

export const cloneTableViewAcrossCall = createAsyncThunk(
  "data/cloneTableViewAcrossCall",
  async (body) => {
    try {
      const response = await axios.post(cloneTableViewApi, body, {
        headers: {
          Authorization:
            "Bearer " + window.sessionStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
