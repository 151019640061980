import React, { useState, useRef } from "react";
import "./DraggableGrid.css";

const DraggableGrid = ({ children }) => {
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [zoomFactor, setZoomFactor] = useState(1);

  const gridRef = useRef(null);
  const gridContainerRef = useRef(null);

  // Start dragging
  const onMouseDown = (e) => {
    e.preventDefault(); // Prevent default behavior (like text selection)
    setDragging(true);

    // Initial mouse position and grid offset when drag starts
    const startX = e.clientX - offset.x;
    const startY = e.clientY - offset.y;

    // Function to handle mouse movement while dragging
    const onMouseMove = (moveEvent) => {
      if (!dragging) {
        setOffset({
          x: moveEvent.clientX - startX,
          y: moveEvent.clientY - startY,
        });
      }
    };

    // Stop dragging when mouse is released
    const onMouseUp = () => {
      setDragging(!dragging);
      // Clean up the event listeners when dragging ends
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    // Attach mouse move and mouse up event listeners
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  // Zoom with button click
  const zoomIn = () => {
    setZoomFactor((prevZoom) => Math.min(prevZoom + 0.1, 3)); // Zoom in up to 3x
  };

  const zoomOut = () => {
    setZoomFactor((prevZoom) => Math.max(prevZoom - 0.1, 0.5)); // Zoom out down to 0.5x
  };

  return (
    <div
      ref={gridContainerRef}
      id="grid-container"
      className="grid-container"
      onMouseDown={onMouseDown} // Start dragging when mouse is pressed
    >
      <div
        ref={gridRef}
        id="grid"
        className="grid"
        style={{
          left: `${offset.x}px`,
          top: `${offset.y}px`,
          transform: `scale(${zoomFactor})`,
        }}
      >
        {children}
      </div>
      {/* <div className="zoom-buttons">
        <button onClick={zoomIn}>+</button>
        <button onClick={zoomOut}>-</button>
      </div> */}
    </div>
  );
};

export default DraggableGrid;
