import CustomTable from "../UI/CustomTable/CustomTable";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import classes from "./validation.module.css";
import styles from "../NewComponent.module.css";

const ValidationReview = (props) => {
  const {
    headValues,
    cellValuesAsObjects,
    setShowReview,
    heading = "",
  } = props;

  const handleBack = () => {
    setShowReview(false);
  };

  return (
    <>
      <div className={styles["display"]}>
        <ArrowBackIcon onClick={handleBack} className={styles["margin-right-8"]} />

        <div className={styles["button-container"]}>
          <div className={styles["basic-details-heading"]}>{heading}</div>
        </div>
      </div>
      <TableContainer className={classes["table"]} component={Paper}>
        <CustomTable
          head={headValues}
          cellValuesAsObjects={cellValuesAsObjects}
        />
      </TableContainer>
    </>
  );
};
export default ValidationReview;
