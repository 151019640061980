/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialLoginState = {
  isLoggedIn: false,
  accessToken: "",
  userName: "Admin",
};

const loginSlice = createSlice({
  name: "login",
  initialState: initialLoginState,
  reducers: {
    login(state) {
      state.isLoggedIn = true;
    },
    logout(state) {
      state.isLoggedIn = false;
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
    setUserName(state, action) {
      state.userName = action.payload;
    },
  },
});

export default loginSlice;
export const loginActions = loginSlice.actions;
