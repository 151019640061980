import { useSelector } from "react-redux";
import LoadingSpinner from "../UI/LoadingSpinner";
import jsonData from "../../Assets/i18n/en.json";
import classes from "../NewComponent.module.css";
import { Done } from "@mui/icons-material";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  InputBase,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import CommonMigration from "../Common/Migration/Migration";
import ValidationReview from "../UcValidation/ValidationReview";
import constants from "../../utils/constant";
import {
  getDbsqlClusterList,
  validateDbsqlCall,
} from "../../Resources/apicalls";
import NoData from "../Common/NoData/NoData";
import CachedIcon from "@mui/icons-material/Cached";
import Tooltip from "@mui/material/Tooltip";
import BasicAlerts from "../Common/MessageBanner.jsx/MessageBanner";
import { v4 as uuidv4 } from "uuid";

function Dbsqlclusters(props) {
  const { selectComponent } = props;
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );

  const [dbsqlClusters, setDbsqlClusters] = useState([]);
  const [makingApiCall, setMakingApiCall] = useState(false);
  const accountInfoData = useSelector((state) => state.accountInfo);
  const [migrateEligibleList, setMigrateEligibleList] = useState([]);
  const [validateEligibleList, setValidateEligibleList] = useState([]);
  const [selectedDbsqList, setSelectedDbsqlList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [close, setClose] = useState(false);
  const [listSelected, setListSelected] = useState(false);
  const [apiTime, setApiTime] = useState("");
  const [showAlertMigration, setShowAlertMigration] = useState(false);
  const [id, setId] = useState("");
  const [migrateBtnDisable, setMigrateBtnDisable] = useState(true);
  const [validateBtnDisable, setValidateBtnDisable] = useState(true);
  const [filterDbsqlClusterList, setFilterDbsqlClusterList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showAlertValidate, setShowAlertValidate] = useState(false);

  const dbsqlList = async () => {
    let postbody = {
      ucxCredentialsData: {
        accId: workspaceSelected.account_id,
      },
      workspaceUrl: workspaceSelected.workspace_url,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      workspaceIdentifier: workspaceSelected.workspace_id,
      ucxPayload: null,
    };
    setMakingApiCall(true);
    let response = await getDbsqlClusterList(postbody);
    setDbsqlClusters(response?.ucxApiResponse?.warehouses);
    setMakingApiCall(false);
    setApiTime(new Date());
  };

  useEffect(() => {
    dbsqlList();
  }, [workspaceSelected.workspace_url]);

  useEffect(() => {
    setSelectedDbsqlList([]);
    setClose(false);
  }, [close]);

  useEffect(() => {
    if (dbsqlClusters) {
      setMigrateEligibleList(
        dbsqlClusters
          ?.filter(
            (cluster) =>
              !cluster?.StatesList?.includes("MIGRATED") && cluster?.disable_uc
          )
          .map((option) => option?.id)
      );
      setValidateEligibleList(
        dbsqlClusters
          ?.filter((cluster) => cluster?.StatesList?.includes("MIGRATED"))
          ?.map((option) => option?.id)
      );
    }
  }, [dbsqlClusters]);

  useEffect(() => {
    if (showAlertMigration) {
      setTimeout(() => {
        setShowAlertMigration(false);
      }, 60000);
    }
  }, [showAlertMigration]);

  const handleCheckboxClick = (name) => {
    const isSelected = selectedDbsqList?.includes(name);
    if (isSelected) {
      setSelectedDbsqlList((prevSelected) =>
        prevSelected.filter((item) => item !== name)
      );
    } else {
      setSelectedDbsqlList((prevSelected) => [...prevSelected, name]);
    }
  };

  const handleAllCheckboxClick = (event) => {
    if (event.target.checked) {
      setSelectedDbsqlList(
        dbsqlClusters?.ucxApiResponse.warehouses?.map((item) => item.id) || []
      );
    } else {
      setSelectedDbsqlList([]);
    }
  };

  useEffect(() => {
    setSelectedList(
      dbsqlClusters?.filter((cluster) => selectedDbsqList?.includes(cluster.id))
    );
  }, [selectedDbsqList]);

  useEffect(() => {
    if (selectedList?.length > 0) setListSelected(true);
    else setListSelected(false);
  }, [selectedList]);

  useEffect(() => {
    let migrateBtn = false;
    for (let index in selectedDbsqList) {
      if (!migrateEligibleList?.includes(selectedDbsqList[index])) {
        migrateBtn = true;
        break;
      }
    }
    setMigrateBtnDisable(migrateBtn);
  }, [selectedDbsqList]);

  useEffect(() => {
    let validateBtn = false;
    for (let index in selectedDbsqList) {
      dbsqlClusters?.forEach((item) => {
        if (
          item?.id == selectedDbsqList[index] &&
          !item?.StatesList?.includes("MIGRATED")
        ) {
          validateBtn = true;
        }
      });
    }
    setValidateBtnDisable(validateBtn);
  }, [selectedDbsqList]);

  const handleRefresh = () => {
    dbsqlList();
    setSelectedDbsqlList([]);
    setListSelected(false);
  };

  async function handleValidateClick() {
    let triggerId = uuidv4().replace(/-/g, "").substring(0, 10);
    setId(triggerId);
    let payload = {
      accId: workspaceSelected.account_id,
      dbSqlId: selectedList.map((cluster) => cluster.id),
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      workspaceUrl: workspaceSelected.workspace_url,
      workspaceIdentifier: workspaceSelected.workspace_id,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      triggerId: triggerId,
    };
    validateDbsqlCall(payload);
    setShowAlertValidate(true);
    handleRefresh();
  }

  useEffect(() => {
    if (searchKeyword?.length < 1) setFilterDbsqlClusterList(dbsqlClusters);
    else
      setFilterDbsqlClusterList(
        dbsqlClusters.filter(
          (item) =>
            item?.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item?.id.includes(searchKeyword)
        )
      );
  }, [searchKeyword, dbsqlClusters]);


  if (dbsqlClusters?.length === 0 && makingApiCall === false) {
    return <NoData label={jsonData.noData} />;
  }

  return (
    <>
      {showAlertMigration && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startMigrationmsg} <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertMigration}
          showReview={showAlertMigration}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      {showAlertValidate && !makingApiCall && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startValidatemsg} <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertValidate}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      <LoadingSpinner open={makingApiCall} />
      <div className={classes["display"]}>
        <div className={classes["button-container"]}>
          <div className={classes["basic-details-heading"]}>
            {jsonData.listOfSQLWarehouse} ( {filterDbsqlClusterList?.length} )
          </div>
        </div>
        <div className={classes["srch-flex"]}>
          <Paper className={`${classes["input-box"]}`}>
            <IconButton
              type="button"
              className={`${classes["padding"]}`}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              className={`${classes["search-input"]}`}
              placeholder={jsonData.srchNmId}
              type="text"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </Paper>
          <CommonMigration
            migrationSelected="dbsqlCluster"
            handleValidateClick={handleValidateClick}
            selectedList={selectedList}
            makingApiCall={makingApiCall}
            setClose={setClose}
            listSelected={listSelected}
            setListSelected={setListSelected}
            setShowAlertMigration={setShowAlertMigration}
            setId={setId}
            migrateBtnDisable={migrateBtnDisable}
            validateBtnDisable={validateBtnDisable}
            setShowCloneSummary={false}
            showMigrate={true}
            showValidate={true}
          />
          <div className={classes["float-right"]}>
            <Tooltip title={`${jsonData.lastRefreshed}${apiTime}`}>
              <CachedIcon onClick={handleRefresh} />
            </Tooltip>
          </div>
        </div>
      </div>

      <TableContainer component={Paper} className={classes["table-container"]}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  onClick={handleAllCheckboxClick}
                  checked={selectedList?.length === dbsqlClusters?.length}
                />
                {"  "} {jsonData.selectDbsqlClusterText}
              </TableCell>
              <TableCell>{jsonData.idTxt}</TableCell>
              <TableCell>{jsonData.createdByText}</TableCell>
              <TableCell>{jsonData.typeText}</TableCell>
              <TableCell>{jsonData.status}</TableCell>
              <TableCell>{jsonData.stateList}</TableCell>
              <TableCell>{jsonData.migrateEligibleText}</TableCell>
              <TableCell>{jsonData.ValidateEligibleText}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterDbsqlClusterList?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    onClick={() => handleCheckboxClick(item.id)}
                    checked={selectedDbsqList?.includes(item.id)}
                  />{" "}
                  {item.name}
                </TableCell>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.creator_name}</TableCell>
                <TableCell>
                  {item?.warehouse_type === "PRO" &&
                  item?.enable_serverless_compute === true
                    ? "SERVERLESS"
                    : item.warehouse_type}
                </TableCell>
                <TableCell>{item?.state}</TableCell>
                <TableCell>
                  {item?.StatesList[item.StatesList.length - 1]}
                </TableCell>
                <TableCell>
                  {migrateEligibleList?.includes(item.id) ? <Done /> : "-"}
                </TableCell>
                <TableCell>
                  {validateEligibleList?.includes(item.id) ? <Done /> : "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
export default Dbsqlclusters;
