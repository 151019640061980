let userService_url, ucxService_url, ucxSummary_url, hostname;

if (process.env.REACT_APP_API_URL === "default") {
  hostname = window.location.hostname;
  userService_url = `http://${hostname}:9797`;
  ucxService_url = `http://${hostname}:9900`;
  ucxSummary_url = `http://${hostname}:9901`;
} else {
  hostname = process.env.REACT_APP_API_URL;
  userService_url = `https://${hostname}/user-service`;
  ucxService_url = `https://${hostname}/ucma-service`;
  ucxSummary_url = `https://${hostname}/ucma-summary-service`;
}

export const loginApi = `${userService_url}/api/v1/user/authenticate`;
export const logoutApi = `${userService_url}/api/v1/user/token`;
export const workSpaceListApi = `${ucxService_url}/api/v1/ucx-databricks/workspace`;
export const dbTokencreationApi = `${ucxService_url}/api/v1/ucx-databricks/workspace`;
export const getAllPurposeClusterApi = `${ucxService_url}/api/v1/ucx-databricks/workspace-clusters`;
export const migrationApi = `${ucxService_url}/api/v1/ucx-databricks/migrate-hms-to-uc/`;
export const getWorkspaceJobListApi = `${ucxService_url}/api/v1/ucx-databricks/workspace-job`;
export const migrationDatabasesApi = `${ucxService_url}/api/v1/ucx-databricks/glue-databases/`;
export const listMetastoreApi = `${ucxService_url}/api/v1/ucx-databricks/metastore`; //metastores
export const listUserApi = `${ucxService_url}/api/v1/ucx-databricks/userList`; //{account_id}/scim/v2/Users"
export const attachMetaStoreApi = `${ucxService_url}/api/v1/ucx-databricks/create-metastore`;
export const getUcxSummaryApi = `${ucxSummary_url}/api/v1/ucx-summary/`;
export const getDbsqlClusterListApi = `${ucxService_url}/api/v1/ucx-databricks/workspace-sql_cluster`;
export const getDbfsMountPointListApi = `${ucxService_url}/api/v1/ucx-databricks/get-workspace-mountpoint-location`;
export const getS3AccessApi = `${ucxService_url}/api/v1/ucx-databricks/validateStorage`;
export const getARNApi = `${ucxService_url}/api/v1/ucx-databricks/glue-databases/us-east-1`;
export const validateMountPointApi = `${ucxService_url}/api/v1/ucx-databricks/validateMountPoints`;
export const validateClusterPermissionApi = `${ucxService_url}/api/v1/ucx-databricks/validateClusterPermissionMigration`;
export const validateDbsqlApi = `${ucxService_url}/api/v1/ucx-databricks/validateSqlWarehouseDetails`;
export const validateJobsApi = `${ucxService_url}/api/v1/ucx-databricks/validateJOBDetails`;
export const validateClusterApi = `${ucxService_url}/api/v1/ucx-databricks/validateClusterDetails`;
export const validateTableApi = `${ucxService_url}/api/v1/ucx-databricks/validateTablesAndViews`;
export const putSave = `${ucxSummary_url}/api/v1/ucx-summary/save`;
export const UcxAssessmentApi = `${ucxService_url}/api/v1/ucx-databricks/run-assessment/`;
export const validateAutoloaderApi = `${ucxService_url}/api/v1/ucx-databricks/validateAutoloader`;
export const listGluedataBaseApi = `${ucxService_url}/api/v1/ucx-databricks/glue-databases/`;
export const listHmsdataBaseApi = `${ucxService_url}/api/v1/ucx-databricks/hms-databases`;
export const listHmsTablesApi = `${ucxService_url}/api/v1/ucx-databricks/hms-database-tables/`; //0521-104826-firvhjez/cloud_external`;
export const listGlueTablesApi = `${ucxService_url}/api/v1/ucx-databricks/glue-database-tables/`; //us-east-1/views_schema_mnt' \`;
export const dashBoardAssessmentApi = `${ucxSummary_url}/api/v1/ucx-summary/workspaceTables/`; //520503421882884
export const noteBookCountApi = `${ucxService_url}/api/v1/ucx-databricks/getWorkspaceNoteBookCount`;
export const lineageDashboardApi = `${ucxService_url}/api/v1/ucx-databricks/get-lineage-dashboard`;
export const workFlowTypedApi = `${ucxService_url}/api/v1/ucx-databricks/get-workflows-type`;
export const jobsListApi = `${ucxService_url}/api/v1/ucx-databricks/get-jobs-list-from-lineage`;
//featureDevelopMent
export const cloneClusterApi = `${ucxService_url}/api/v1/ucx-databricks/cloneCluster`;
export const cloneJobApi = `${ucxService_url}/api/v1/ucx-databricks/cloneJob`;
export const deleteClusterApi = `${ucxService_url}/api/v1/ucx-databricks/deleteCluster`;
export const deleteJobApi = `${ucxService_url}/api/v1/ucx-databricks/deleteJob`;
export const module1Api = `${ucxService_url}/api/v1/ucx-databricks/scan-notebook`;
export const moduleDepreciated1Api = `${ucxService_url}/api/v1/ucx-databricks/notebook-deprecated-modules/`;
export const functionDepreciated1Api = `${ucxService_url}/api/v1/ucx-databricks/notebook-deprecated-functions/`;
export const cloneClusterAcrossApi = `${ucxService_url}/api/v1/ucx-databricks/cloneClusterAcrossWorkspace`;
export const cloneJobsAcrossApi = `${ucxService_url}/api/v1/ucx-databricks/cloneJobAcrossWorkspace`;
export const notebookReplacementInfoApi = `${ucxService_url}/api/v1/ucx-databricks/notebook-replacement-info/`;
export const notebookstringReplacementInfoApi = `${ucxService_url}/api/v1/ucx-databricks/string-replacement-audit/`;
export const cloneTableViewApi = `${ucxService_url}/api/v1/ucx-databricks/cloneTableViewAcrossWorkspace`;
export const cloneMountPointAcrossApi = `${ucxService_url}/api/v1/ucx-databricks/cloneMountpointsAcrossWorkspace`;
export const paginationCount = `${ucxService_url}/api/v1/ucx-databricks/deprecated-functions-modules-count/`;
export const azureLoginApi = `${ucxService_url}/api/v1/ucx-databricks/validateLogin`;
export const awsLoginValidateApi = `${ucxService_url}/api/v1/ucx-databricks/validateDatabricksCredential`;
export const dbfsToDbfsCloneApi = `${ucxService_url}/api/v1/ucx-databricks/cloneDbfsToDbfsAcrossWorkspace`;
export const dbfsListApi = `${ucxService_url}/api/v1/ucx-databricks/getAllDbfsList`;
export const dbfsToVolume = `${ucxService_url}/api/v1/ucx-databricks/migrate-hms-to-uc/`;
export const dbfsToVolumeValidationUrl = `${ucxService_url}/api/v1/ucx-databricks/validate-dbfs-to-volume`;
export const workspaceObjectMigrationApi = `${ucxService_url}/api/v1/ucx-databricks/cloneObjectsAcrossWorkspace`;
export const getJobCountApi = `${ucxService_url}/api/v1/ucx-databricks/workspace-jobs-count`;
export const getWorkspaceConfigue = `${ucxSummary_url}/api/v1/ucx-summary/get-workspace-config`;
export const saveWorkspaceConfigue = `${ucxSummary_url}/api/v1/ucx-summary/save-workspace-config`;
export const tableRealTimeMigrationApi = `${ucxSummary_url}/api/v1/ucx-summary/getRealTimeTableMigrationAudit?`; // triggerID=b4da4ec40e&workspaceIdentifier=1512251540502560`
export const ViewRealTimeMigrationApi = `${ucxSummary_url}/api/v1/ucx-summary/getRealTimeViewMigrationAudit?`; // triggerID=b4da4ec40e&workspaceIdentifier=1512251540502560`
export const tableValidationApi = `${ucxSummary_url}/api/v1/ucx-summary/getRealTimeTableValidationAudit?`; //triggerID=b4da4ec40e&workspaceIdentifier=1512251540502560
export const triggerLineageUtilityUrl = `${ucxService_url}/api/v1/ucx-databricks/trigger-lineage-utility`;
export const downloadcsvUrl = `${ucxService_url}/api/v1/ucx-databricks/download-lineage-utility-filtered-table-csv`;
export const getWorkspacePipelinesListApi = `${ucxService_url}/api/v1/ucx-databricks/workspace-pipelines`;
export const getWorkspacePipelinesCountApi = `${ucxService_url}/api/v1/ucx-databricks/workspace-pipelines-count`;
export const migratePipelinesApi = `${ucxService_url}/api/v1/ucx-databricks/migrate-pipelines`;
export const notebookstringSearchResult = `${ucxService_url}/api/v1/ucx-databricks/string-search-status/`;
export const notebookstringSearchResultCount = `${ucxService_url}/api/v1/ucx-databricks/string-search-status-count/`;
export const queryValidationApi = `${ucxService_url}/api/v1/ucx-databricks/check-sql-query-syntax/`; //select * from shema.table'
export const notebookCodeScanApi = `${ucxService_url}/api/v1/ucx-databricks/notebookScanAndTableCreation`;
export const notebookTotalsApi = `${ucxService_url}/api/v1/ucx-databricks/getNmMntCodescanStatus/`;
export const notebookWiseChangesApi = `${ucxService_url}/api/v1/ucx-databricks/getCodescanDetailedSummary/`;
export const notebookSummaryApi = `${ucxService_url}/api/v1/ucx-databricks/getInputOutputPath/`;
export const notebookPreMigrationChangesApi = `${ucxService_url}/api/v1/ucx-databricks/get-pre-notebook-migration-summary/`;
export const notebookPreMigrationChangesCountApi = `${ucxService_url}/api/v1/ucx-databricks/get-pre-notebook-migration-summary-count/`;
export const notebookWiseChangesCountApi = `${ucxService_url}/api/v1/ucx-databricks/getCountOfCodescanDetailedSummary/`;
export const notebookScanResultApi = `${ucxService_url}/api/v1/ucx-databricks/get-notebook-scan-table-creation-report-table`;
export const notebookScanResultMappingApi = `${ucxService_url}/api/v1/ucx-databricks/get-notebook-scan-table-mappings-table`;
export const lineageGraphJobEntityApi = `${ucxService_url}/api/v1/ucx-databricks/get-lineage-graph-entity`;
export const lineageGraphDependentJobEntityApi = `${ucxService_url}/api/v1/ucx-databricks/get-dependant-jobid?referance=`;
export const getCatalogRootStatusApi = `${ucxService_url}/api/v1/ucx-databricks/is-metastore-bucket-attached`;
