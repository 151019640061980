export const formattedTableNames = (data) => {
  const listString = data.map((item) => {
    const namespace = item.database;
    const name = item.name;
    return `'${namespace}.${name}'`;
  });
  const formattedString = `[${listString.join(",")}]`;

  return formattedString;
};

export const formattedDbfsName = (data) => {
  const formattedString = `['${data.join("', '")}']`;

  return formattedString;
};

export const formattedTableQuery = (listObj) => {
  const query_json = listObj
    .map((item) => {
      return `"${item.database}.${item.tableName}":"${item.query}"`;
    })
    .join(",");

  return `{${query_json}}`;
};
