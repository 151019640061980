import styles from "../UcMigration.module.css";
import { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import PropTypes from "prop-types";
import jsonData from "../../Assets/i18n/en.json";
import { useSelector } from "react-redux";
import LoadingSpinner from "../UI/LoadingSpinner";
import {
  AzureAbfsContainerPattern,
  NameWOhPattern,
  storageRootPattern,
  targetLocationAzurePattern,
} from "../../utils/regex";
import constants from "../../utils/constant";
import { postCall } from "../../Resources/apicalls";
import { formattedTableNames } from "../Common/Migration/formattedTableNames";
import HeadingWithInfo from "../Common/HeadingWithInfo/HeadingWithInfo";
import UcmaTextField from "../UI/Form/TextField";
import ManualClusterSelection from "../Common/ManualClusterSelection/ManualClusterSelection";
import { getCatalogRootStatusApi, migrationApi } from "../../Resources/apiurls";
import { useCommonPayload } from "../../Resources/useCommonPayload";
import { GenerateUid } from "../../utils/GenerateUid/GenerateUid";

const TableViewsPage = (props) => {
  const {
    selectedList,
    startMigration,
    databaseType,
    setDisableNext,
    setId,
    disableNext,
  } = props;
  const accountInfoData = useSelector((state) => state.accountInfo);
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const [targetSchemaUc, setTargetSchemaUc] = useState("");
  const [viewSelected, setViewSelected] = useState([]);
  const [tableSelected, setTableSelected] = useState([]);
  const [tableType, setTableType] = useState("");
  const [migrateTableAcl, setMigrateTableAcl] = useState(true);
  const [manualClusterSelection, setManualClusterSelection] = useState(false);
  const [enableExternalPath, setEnableExternalPath] = useState(false);
  const [enforceexternallocation, setEnforceexternallocation] = useState(false);
  const [targetformatdelta, setTargetformatdelta] = useState(false);
  const [targetCatalogRoot, setTargetCatalogRoot] = useState("");
  const [makingApiCall, setMakingApiCall] = useState(false);
  const [externalLocation, setExternalLocation] = useState("");
  const [targetTableTypeSelect, setTargetTableTypeSelect] = useState("Managed");
  const [selectedCluster, setSelectedCluster] = useState("");
  const [catalogRoot, setCatalogRoot] = useState(false);
  const workspaceConfig = useSelector(
    (state) => state.workspaceConfig.data[workspaceSelected.workspace_id]
  );
  const commonPayload = useCommonPayload();

  function enableNext() {
    let extLocationPattern =
      accountInfoData.cloudType === jsonData.awsTxt
        ? storageRootPattern
        : targetLocationAzurePattern;

    if (
      targetSchemaUc === "" ||
      (!catalogRoot && targetCatalogRoot === "") ||
      (tableSelected.length > 0 &&
        (targetTableTypeSelect === "" ||
          (ExternalPath() &&
            (externalLocation === "" ||
              !extLocationPattern.test(externalLocation)))))
    )
      setDisableNext(true);
    else setDisableNext(false);
  }

  useEffect(() => {
    enableNext();
  }, [
    targetSchemaUc,
    externalLocation,
    enableExternalPath,
    targetTableTypeSelect,
    manualClusterSelection,
    selectedCluster,
    tableSelected,
    targetformatdelta,
    enforceexternallocation,
    disableNext,
  ]);

  const ExternalPath = () => {
    return enableExternalPath || targetformatdelta || enforceexternallocation;
  };

  useEffect(() => {
    let tableTypes = Array.from(
      new Set(selectedList.map((item) => item.object_type))
    );
    const containsManagedTable = tableTypes.some((type) =>
      type.includes("MANAGED")
    );
    const containsExternalTable = tableTypes.some((type) =>
      type.includes("EXTERNAL")
    );

    if (containsManagedTable && containsExternalTable) {
      setTableType("BOTH");
    } else if (containsManagedTable) {
      setTableType("MANAGED");
    } else if (containsExternalTable) {
      setTableType("EXTERNAL");
    } else {
      setTableType("");
    }

    setViewSelected(
      selectedList?.filter((item) => item.object_type.includes("VIEW"))
    );

    setTableSelected(
      selectedList?.filter(
        (item) =>
          item.object_type.includes("MANAGED") ||
          item.object_type.includes("EXTERNAL")
      )
    );
  }, [selectedList]);

  const handleTargetSelectChange = (event) => {
    setTargetTableTypeSelect(event.target.value);
    setEnableExternalPath(false);
    setExternalLocation("");
  };

  const migrateTableView = async () => {
    let triggerId = GenerateUid();
    setId(triggerId);
    let payload = {
      ...commonPayload,
      ucxPayload: {
        data: {
          tablesToMigrate:
            tableSelected.length > 0
              ? {
                  tableType: tableType,
                  targetUcTableType: targetTableTypeSelect.toUpperCase(),
                  enableExternalPath: enableExternalPath,
                  externalLocationPath: ExternalPath()
                    ? externalLocation + "/"
                    : "",
                  enforce_external_loc: ExternalPath()
                    ? enforceexternallocation
                    : "",
                  target_format_delta: targetformatdelta,
                }
              : null,
          databasesAndCatalog: {
            migrateFrom: databaseType,
            databasesToMigrate: "",
            targetCatalogName: targetSchemaUc,
            targetCatalogRoot: targetCatalogRoot,
            tableToMigrate:
              tableSelected.length > 0
                ? formattedTableNames(tableSelected)
                : "",
            viewToMigrate:
              viewSelected.length > 0 ? formattedTableNames(viewSelected) : "",
          },
          dataRoleArn: workspaceConfig.awsRoleArn,
          migrateViews: viewSelected.length > 0,
          migrateTableAcls: migrateTableAcl,
          containerName: workspaceConfig.azureStorageAccountName,
          storageAccountKey: workspaceConfig.azureStorageAccountKey,
        },
        warehouseID: [],
        migrateGroups: false,
        upgradeClusters: null,
        workflows: null,
        runJobsAs: accountInfoData.userId
          ? accountInfoData.userId
          : accountInfoData.OAuthUser.userName,
        mountPointsUpgrade: null,
        jobs: null,
      },
      ucxInstalled: true,
      triggerId: triggerId,
      existingAllPurposeClusterId: selectedCluster,
    };

    setMakingApiCall(false);
    let api = `${migrationApi}${workspaceSelected.workspace_id}`;
    await postCall(api, payload);
    setMakingApiCall(false);
  };

  const getCatalogRootStatus = async () => {
    let response = await postCall(getCatalogRootStatusApi, commonPayload);
    setCatalogRoot(response.ucxApiResponse);
  };
  useEffect(() => {
    getCatalogRootStatus();
  }, []);

  useEffect(() => {
    if (startMigration) migrateTableView();
  }, [startMigration]);

  return (
    <div>
      <LoadingSpinner open={makingApiCall} />

      <UcmaTextField
        name={jsonData.targetSchemaUcText}
        info={jsonData.targetSchemaUcToolTipText}
        value={targetSchemaUc}
        onChangeValue={setTargetSchemaUc}
        placeholder={jsonData.unityCatalogNamePlaceholder}
        setDisableSubmit={setDisableNext}
        pattern={NameWOhPattern}
        required
        isDialog
      />
      <UcmaTextField
        name={
          catalogRoot ? jsonData.targetCatalogRootO : jsonData.targetCatalogRoot
        }
        info={jsonData.catalogPathtoolTip}
        value={targetCatalogRoot}
        onChangeValue={setTargetCatalogRoot}
        placeholder={
          accountInfoData.cloudType === jsonData.awsTxt
            ? jsonData.cloudLocationSampleValue
            : jsonData.azureCatalogPathPlaceHolder
        }
        setDisableSubmit={setDisableNext}
        pattern={
          accountInfoData.cloudType === jsonData.awsTxt
            ? storageRootPattern
            : AzureAbfsContainerPattern
        }
        required={!catalogRoot}
        isDialog
      />

      <div className={`${styles["display-flex"]} ${styles["margin-top-26"]}`}>
        <FormControl fullWidth size="small">
          <InputLabel>{jsonData.migrationTargetTableType}</InputLabel>
          <Select
            size="small"
            onChange={handleTargetSelectChange}
            value={targetTableTypeSelect}
            label={jsonData.migrationTargetTableType}
          >
            {constants.targetTableTypesArray.map((name) =>
              tableType !== "BOTH" && name === "Default" ? null : (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </div>

      <ManualClusterSelection
        clusterId={selectedCluster}
        setClusterId={setSelectedCluster}
        label={jsonData.mnulClstrSelectText}
        wsUrl={workspaceSelected.workspace_url}
        wsId={workspaceSelected.workspace_id}
        setManualClusterSelection={setManualClusterSelection}
      />

      <div className={styles["margin-top-20"]}>
        <div className={styles["button-border2"]}>
          <Checkbox
            sx={{
              "& .MuiSvgIcon-root": {
                color: "black",
              },
            }}
            checked={migrateTableAcl}
            onChange={(event) => {
              setMigrateTableAcl(event.target.checked);
            }}
          />
          <div className={styles["margintop-08"]}>
            <HeadingWithInfo
              isDialog={true}
              label={jsonData.migrateTableAclsText}
              info={jsonData.migrateTableAclsInfoText}
            />
          </div>
        </div>
      </div>
      {targetTableTypeSelect !== "Managed" && (
        <>
          <div className={styles["margin-top-20"]}>
            <div className={styles["button-border2"]}>
              <Checkbox
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: "black",
                  },
                }}
                checked={enableExternalPath}
                onChange={(event) => {
                  setEnableExternalPath(event.target.checked);
                }}
              />
              <HeadingWithInfo
                label={jsonData.migrateTableSourceTable}
                isDialog={true}
                info={jsonData.migrateTableSourceTableInfo}
              />
            </div>
          </div>

          <div className={styles["margin-top-20"]}>
            <div className={styles["button-border2"]}>
              <Checkbox
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: "black",
                  },
                }}
                checked={enforceexternallocation}
                onChange={(event) => {
                  setEnforceexternallocation(event.target.checked);
                }}
              />
              <HeadingWithInfo
                label={jsonData.enforceexternallocationTxt}
                isDialog={true}
                info={jsonData.enforceexternallocationInfo}
              />
            </div>
          </div>
          <div className={styles["margin-top-20"]}>
            <div className={styles["button-border2"]}>
              <Checkbox
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: "black",
                  },
                }}
                checked={targetformatdelta}
                onChange={(event) => {
                  setTargetformatdelta(event.target.checked);
                }}
              />
              <HeadingWithInfo
                label={jsonData.targetformatdeltaTxt}
                isDialog={true}
                info={jsonData.targetformatdeltaInfo}
              />
            </div>
          </div>
        </>
      )}

      {ExternalPath() && (
        <UcmaTextField
          name={jsonData.enableExLoacPathText}
          info={jsonData.enableExLoacPathInfoText}
          value={externalLocation}
          onChangeValue={setExternalLocation}
          setDisableSubmit={setDisableNext}
          pattern={
            accountInfoData.cloudType === jsonData.awsTxt
              ? storageRootPattern
              : targetLocationAzurePattern
          }
          required
          isDialog
        />
      )}
    </div>
  );
};

TableViewsPage.propTypes = {
  setDisableNext: PropTypes.func.isRequired,
};

export default TableViewsPage;
