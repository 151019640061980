import { createSlice } from "@reduxjs/toolkit";
import { getWorkSpaceList } from "../thunk/workspace-thunk";

const initialState = {
  workspaceData: [],
  selectedWorkspace: []
};
const workspaceSlice = createSlice({
  name: "workspaceData",
  initialState,
  
  reducers: {
    setSelectedWorkspace(state, action) {
      state.selectedWorkspace = action.payload;
    }
  },
  
  extraReducers: (builder) => {
    builder
      .addCase(getWorkSpaceList.pending, (state) => {
        state.error = null;
      })
      .addCase(getWorkSpaceList.fulfilled, (state, action) => {
        state.workspaceData = action.payload;
        state.error = null;
      })
      .addCase(getWorkSpaceList.rejected, (state, action) => {
        state.error = action.payload;
      })
  },
});



export default workspaceSlice;
export const workspaceActions = workspaceSlice.actions;
