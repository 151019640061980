import { useEffect, useState } from "react";
import { postCall } from "../../Resources/apicalls";
import { useCommonPayload } from "../../Resources/useCommonPayload";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EllipsisTooltip from "../UI/Tooltip/Tooltip";
import classes from "./TableMapping.module.css";
import {
  notebookScanResultApi,
  notebookScanResultMappingApi,
} from "../../Resources/apiurls";
import {
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import constants from "../../utils/constant";
import LoadingSpinner from "../UI/LoadingSpinner";
import formatEventLoggedTime from "../Common/FormatTime/FormatTime";
const options = [
  { label: "Refresh", action: "refresh" },
  { label: "Download", action: "download" },
];
const ITEM_HEIGHT = 48;

export default function ScanResult(props) {
  const { triggerId, entity } = props;
  const [scanResultDisplay, setScanResultDisplay] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [makingApiCall, setMakingApiCall] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const tableHeader =
    entity === "NOTEBOOK_TABLE_CREATION"
      ? constants.noteBookScanHeader
      : constants.noteBookMapHeader;

  const commonPayload = useCommonPayload();
  async function getResult() {
    let postBody = { ...commonPayload, triggerId: triggerId };
    let res;
    setMakingApiCall(true);
    if (entity === "NOTEBOOK_TABLE_CREATION") {
      res = await postCall(notebookScanResultApi, postBody);
    } else {
      res = await postCall(notebookScanResultMappingApi, postBody);
    }
    setMakingApiCall(false);
    setScanResultDisplay(res.ucxApiResponse);
  }

  useEffect(() => {
    getResult();
  }, []);

  const handleMenuOptionClick = (action) => {
    if (action === "refresh") {
      getResult();
    } else if (action === "download") {
      downloadCSV();
    }
    handleClose();
  };

  const downloadCSV = () => {
    const csvRows = [];
    csvRows.push(tableHeader.filter((item) => item !== "Id").join(","));
    scanResultDisplay?.forEach((item, index) => {
      const row = [
        ...(entity === "NOTEBOOK_TABLE_CREATION"
          ? [
              item.notebook_name,
              item.notebookpath,
              item.cloudLocation,
              item.default_tableName,
              item.timestamp,
              item.partition_column_name,
              item.partition_value,
              item.table_type,
              item.status,
              item.comments,
            ]
          : [
              item.notebook_name,
              item.notebookpath,
              item.cloudLocation,
              item.default_tableName,
              item.timestamp,
              item.partition_column_name,
              item.partition_value,
              item.table_type,
              item.status,
            ]),
      ];

      csvRows.push(row.join(","));
    });
    const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", `${triggerId}_${entity}.csv`);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <LoadingSpinner open={makingApiCall} />
      <div style={{ display: "flex", justifyContent: "right", gap: "5px" }}>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "auto",
              },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.action}
              onClick={() => handleMenuOptionClick(option.action)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <TableContainer component={Paper} className={classes["table-container"]}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {tableHeader.map((item) => (
                <TableCell key={item}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {scanResultDisplay?.map((item, index) => (
              <TableRow key={index + 1}>
                <>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.notebook_name}</TableCell>
                  <TableCell>
                    {" "}
                    <div
                      className={`${classes["ellipses-width-18"]} ${classes["flex-center"]}`}
                    >
                      <EllipsisTooltip>{item.notebookpath}</EllipsisTooltip>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      className={`${classes["ellipses-width-18"]} ${classes["flex-center"]}`}
                    >
                      {" "}
                      <EllipsisTooltip>{item.cloudLocation}</EllipsisTooltip>
                    </div>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <EllipsisTooltip>{item.default_tableName} </EllipsisTooltip>
                  </TableCell>{" "}
                  <TableCell>
                    {" "}
                    <EllipsisTooltip>
                      {formatEventLoggedTime(item.timestamp)}{" "}
                    </EllipsisTooltip>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <EllipsisTooltip>
                      {item.partition_column_name || "-"}
                    </EllipsisTooltip>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <EllipsisTooltip>
                      {item.partition_value || "-"}{" "}
                    </EllipsisTooltip>
                  </TableCell>
                  <TableCell>{item.table_type}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  {entity === "NOTEBOOK_TABLE_CREATION" && (
                    <TableCell>
                      <div
                        className={`${classes["ellipses-width-18"]} ${classes["flex-center"]}`}
                      >
                        <EllipsisTooltip>{item.comments} </EllipsisTooltip>
                      </div>
                    </TableCell>
                  )}
                </>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
