import { useState } from "react";
import HeadingWithInfo from "../../Common/HeadingWithInfo/HeadingWithInfo";
import { TextField } from "@mui/material";
import styles from "../../UcMigration.module.css";

export default function UcmaTextField(props) {
  const {
    disabled,// for disable Field
    value, // field value
    onChangeValue, //field on change function
    placeholder, //placeholder
    name, //heading label
    info,
    headingComponent, //alternate component for heading
    required, // is field reqd
    customValidateHandler, // custom validations other than pattern and reqd
    onBlur, // custom func to be called on blur
    isDialog, //is field in dialog?
    textFieldProps, //other text field props
    pattern, //pattern regex
    setDisableSubmit, // func to control submit disable
    textFieldClassName,
    type,//for some number or password field
  } = props;
  const [error, setError] = useState(false);

  async function  validateHandler() {
    let valid = true;
    if (required && value === "") {
      setError(`${name} is required.`);
      if (setDisableSubmit) setDisableSubmit(true);
      valid = false;
      return;
    } else if (
      value !== "" &&
      pattern &&
      pattern !== "" &&
      !pattern.test(value)
    ) {
      setError(`Invalid ${name}`);
      if (setDisableSubmit) setDisableSubmit(true);
      valid = false;
      return;
    }
    if (customValidateHandler) {
      let error = await customValidateHandler();
      setError(error);
      if (error) {
        valid = false;
        if (setDisableSubmit) setDisableSubmit(true);
      }
      else valid=true;
    }
    if (valid) {
      setError(null);
      setDisableSubmit(false);
    }
  }
  return (
    <div className={styles["margin-top-8"]}>
      {!!headingComponent  ? (
        headingComponent
      ) : ( !textFieldProps?.label && 
        <HeadingWithInfo label={name} isDialog={isDialog} info={info} />
      )}
      <TextField
        size="small"
        fullWidth
        sx={
          !isDialog && {
            "& > :not(style)": { width: "58.75rem" },
          }
        }
        required={required}
        disabled={disabled}
        error={!!error}
        type={type}
        placeholder={placeholder}
        className={` ${textFieldClassName}`} //${styles["margin-top-16"]}
        value={value}
        onChange={(e) => {
          onChangeValue(e.target.value);
        }}
        onBlur={() => {
          validateHandler();
          if (onBlur) {
            onBlur();
          }
        }}
        helperText={error}
        {...textFieldProps}
      />
    </div>
  );
}
