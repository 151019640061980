import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  InputBase,
} from "@mui/material";
import classes from "../NewComponent.module.css";
import SearchIcon from "@mui/icons-material/Search";
import LoadingSpinner from "../UI/LoadingSpinner";
import styles from "../NewComponent.module.css";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import jsonData from "../../Assets/i18n/en.json";
import {
  getDbfsMountsList,
  validateMountpointCall,
} from "../../Resources/apicalls";
import ValidationReview from "../UcValidation/ValidationReview";
import CommonMigration from "../Common/Migration/Migration";
import { listToObjectVal } from "../Common/ucxFunction/getAccessMode";
import NoData from "../Common/NoData/NoData";
import CachedIcon from "@mui/icons-material/Cached";
import Tooltip from "@mui/material/Tooltip";
import BasicAlerts from "../Common/MessageBanner.jsx/MessageBanner";
import { Done } from "@mui/icons-material";
import constants from "../../utils/constant";
import EllipsisTooltip from "../UI/Tooltip/Tooltip";
import { GenerateUid } from "../../utils/GenerateUid/GenerateUid";

function MountpointValidation(props) {
  const { selectComponent } = props;
  const [makingApiCall, setMakingApiCall] = useState(false);
  const accountInfoData = useSelector((state) => state.accountInfo);
  const [close, setClose] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [mountPoints, setMountPoints] = useState([]);
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const [listSelected, setListSelected] = useState(true);
  const [selectedList, setSelectedList] = useState([]);
  const [apiTime, setApiTime] = useState("");
  const [showAlertMigration, setShowAlertMigration] = useState(false);
  const [id, setId] = useState(false);
  const [validateEligibleList, setValidateEligibleList] = useState([]);
  const [validateBtnDisable, setValidateBtnDisable] = useState(true);
  const [showAlertValidate, setShowAlertValidate] = useState(false);
  const [showAlertClone, setShowAlertClone] = useState(false);
  const [filterMountList, setFilterMountList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    hadleRefresh();
    setClose(false);
  }, [workspaceSelected.workspace_url, close]);

  async function getMountpoints() {
    let postbody = {
      ucxCredentialsData: {
        accId: accountInfoData.accountId,
        databricksEmail: accountInfoData.userId,
        databricksPassword: accountInfoData.password,
        databricksClientId: accountInfoData.oAuthToken,
        databricksSecret: accountInfoData.oAuthSecret,
      },
      workspaceUrl: workspaceSelected.workspace_url,
      workspaceIdentifier: workspaceSelected.workspace_id,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
    };

    setMakingApiCall(true);
    const data = await getDbfsMountsList(postbody);
    setMakingApiCall(false);
    setMountPoints(data.ucxApiResponse);
    setApiTime(new Date());
  }

  useEffect(() => {
    if (mountPoints) {
      setValidateEligibleList(
        mountPoints
          ?.filter((mountPoint) => mountPoint?.StatesList?.includes("MIGRATED"))
          ?.map((mountPoint) => mountPoint)
      );
    }
  }, [mountPoints]);

  useEffect(() => {
    if (showAlertMigration) {
      setTimeout(() => {
        setShowAlertMigration(false);
      }, 60000);
    }
  }, [showAlertMigration]);

  async function handleValidateClick() {
    let triggerId = GenerateUid();
    setId(triggerId);
    let Payload = {
      accId: accountInfoData.accountId,
      workspaceIdentifier: workspaceSelected.workspace_id,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      workspaceUrl: workspaceSelected.workspace_url,
      mountpointsUpgrade:
        selectedList?.length > 0
          ? {
              mountpoints: listToObjectVal(selectedList),
            }
          : null,
      triggerId: triggerId,
    };
    validateMountpointCall(Payload);
    setShowAlertValidate(true);

    hadleRefresh();
  }

  const hadleRefresh = () => {
    getMountpoints();
    setListSelected(false);
    setSelectedList([]);
  };

  const handleAllCheckboxClick = (event) => {
    if (event.target.checked) {
      setSelectedList(mountPoints.map((item) => item) || []);
    } else {
      setSelectedList([]);
    }
  };

  const handleCheckboxClick = (name) => {
    const isSelected = selectedList.includes(name);

    if (isSelected) {
      setSelectedList((prevSelected) =>
        prevSelected.filter((item) => item !== name)
      );
    } else {
      setSelectedList((prevSelected) => [...prevSelected, name]);
    }
  };

  useEffect(() => {
    if (selectedList?.length > 0) setListSelected(true);
    else setListSelected(false);
  }, [selectedList]);

  useEffect(() => {
    let validateBtn = false;
    for (let index in selectedList) {
      mountPoints?.forEach((item) => {
        if (
          item === selectedList[index] &&
          !item.StatesList.includes("MIGRATED")
        ) {
          validateBtn = true;
        }
      });
    }
    setValidateBtnDisable(validateBtn);
  }, [selectedList]);

  useEffect(() => {
    if (searchKeyword?.length < 1) setFilterMountList(mountPoints);
    else
      setFilterMountList(
        mountPoints.filter((item) =>
          item?.mount_point?.toLowerCase().includes(searchKeyword.toLowerCase())
        )
      );
  }, [searchKeyword, mountPoints]);

  if (mountPoints?.length === 0 && makingApiCall === false) {
    return <NoData label={jsonData.noData} />;
  }

  return (
    <div>
      {showAlertValidate && !makingApiCall && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startValidatemsg} <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertValidate}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}
      {showAlertMigration && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startMigrationmsg} <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertMigration}
          showReview={showAlertMigration}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      {showAlertClone && !makingApiCall && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startClonemsg} <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertClone}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      <div className={styles["display"]}>
        <div className={styles["button-container"]}>
          <div className={styles["basic-details-heading"]}>
            {jsonData.listOfMountPoints} ( {filterMountList?.length} )
          </div>
        </div>
        <div className={classes["srch-flex"]}>
          <Paper className={`${classes["input-box"]}`}>
            <IconButton type="button" className={`${classes["padding"]}`}>
              <SearchIcon />
            </IconButton>
            <InputBase
              className={`${classes["search-input"]}`}
              placeholder={jsonData.srchMntPt}
              type="text"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </Paper>
        </div>
        <CommonMigration
          migrationSelected="mountpoints"
          handleValidateClick={handleValidateClick}
          selectedList={selectedList}
          setClose={setClose}
          makingApiCall={makingApiCall}
          listSelected={listSelected}
          setListSelected={setListSelected}
          title={jsonData.mountPointMigration}
          titleClone={jsonData.cloneMntPnt}
          setShowAlertMigration={setShowAlertMigration}
          showAlertMigration={showAlertMigration}
          setId={setId}
          setShowReview={setShowReview}
          validateBtnDisable={validateBtnDisable}
          setClonedata={setShowAlertClone}
          setShowCloneSummary={false}
          showMigrate={true}
          showValidate={true}
          showClone={true}
        />
        <div className={styles["float-right"]}>
          <Tooltip title={`${jsonData.lastRefreshed} ${apiTime}`}>
            <CachedIcon onClick={hadleRefresh} />
          </Tooltip>
        </div>
      </div>

      <LoadingSpinner open={makingApiCall} />
      {mountPoints?.length > 0 && (
        <TableContainer className={styles["table-height"]} component={Paper}>
          <Table
            className={styles["min-width-650"]}
            stickyHeader
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    onClick={handleAllCheckboxClick}
                    checked={selectedList.length === mountPoints.length}
                  />
                </TableCell>
                {constants.tableHeadings.map((label) => {
                  return (
                    <TableCell
                      className={styles["table-row-heading"]}
                      key={label}
                    >
                      {label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {filterMountList?.map((item, index) => (
                <TableRow key={index + 1}>
                  <TableCell>
                    <Checkbox
                      onClick={() => handleCheckboxClick(item)}
                      checked={selectedList.includes(item)}
                    />{" "}
                    {item.cluster_name}
                  </TableCell>

                  <TableCell className={styles["table-val"]}>
                    <div className={styles["width-ellipses"]}>
                      <EllipsisTooltip>{item.source}</EllipsisTooltip>
                    </div>
                  </TableCell>
                  <TableCell className={styles["table-val"]}>
                    <div className={styles["width-ellipses"]}>
                      <EllipsisTooltip>{item.mount_point}</EllipsisTooltip>
                    </div>
                  </TableCell>
                  <TableCell className={styles["table-val"]}>
                    {item.StatesList[item.StatesList.length - 1] ===
                    "CLONE_VALIDATED_WITHIN"
                      ? "CLONE VALIDATED"
                      : item.StatesList[item.StatesList.length - 1]}
                  </TableCell>
                  <TableCell>
                    {validateEligibleList.includes(item) ? <Done /> : "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

export default MountpointValidation;
