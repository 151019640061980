import jsonData from "../../Assets/i18n/en.json";
import { useEffect, useState } from "react";
import AcrossWorkspaceInfo from "../Common/AccrossWorkspaceInfo.jsx/AcrossWorkspaceInfo";
import {
  containerAzurePattern,
  NamePattern,
  percentagePattern,
  storageRootPattern,
  targetLocationAzurePattern,
} from "../../utils/regex";
import { useDispatch, useSelector } from "react-redux";
import { cloneTableViewAcrossCall } from "../../store/thunk/tableCloneData-thunk";
import GetWsIdWithUrl from "../../utils/GetWsIdWithUrl/GetWsIdWithUrl";
import { GenerateUid } from "../../utils/GenerateUid/GenerateUid";
import UcmaTextField from "../UI/Form/TextField";
import ManualClusterSelection from "../Common/ManualClusterSelection/ManualClusterSelection";
import { FormControl, Grid, MenuItem, Paper, Select } from "@mui/material";
import HeadingWithInfo from "../Common/HeadingWithInfo/HeadingWithInfo";
import styles from "../UcMigration.module.css";
import constants from "../../utils/constant";
import QueryClone from "./QueryClone";
import { formattedTableQuery } from "../Common/Migration/formattedTableNames";

function CloneTable(props) {
  const {
    setDisableNext,
    selectedList,
    databaseType,
    startCloning,
    setId,
    setShowCloneSummary,
    disableNext,
  } = props;

  const [externalWsToken, setExternalWsToken] = useState("");
  const [externalWsUrl, setExternalWsUrl] = useState("");
  const [s3BucketName, setS3BucketName] = useState("");
  const [destinationS3ExPath, setDestinationS3ExPath] = useState("");
  const [sourceWorkspaceS3Path, setSourceWorkspaceS3Path] = useState("");
  const [destinationWorkspaceS3Path, setDestinationWorkspaceS3Path] =
    useState("");
  const [sourceClusterId, setSourceClusterId] = useState("");
  const [targetClusterId, setTargetClusterId] = useState("");
  const [clonePercentage, setClonePercentage] = useState("");
  const [dataCloneType, setDataCloneType] = useState(
    constants.tableCloneMethods[0]
  );
  const accountInfoData = useSelector((state) => state.accountInfo);
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice.selectedWorkspace[0]
  );
  const [validQuery, setValidQuery] = useState(false);
  const workspaceConfig = useSelector(
    (state) => state.workspaceConfig.data[workspaceSelected.workspace_id]
  );
  const [manualSrcClusterSelection, setManualSrcClusterSelection] =
    useState(false);
  const [manualTrgClusterSelection, setManualTrgClusterSelection] =
    useState(false);
  const [queryList, setQueryList] = useState([]);

  const dispatch = useDispatch();

  const enableNext = () => {
    if (
      s3BucketName === "" ||
      externalWsToken === "" ||
      externalWsUrl === "" ||
      destinationS3ExPath === "" ||
      sourceWorkspaceS3Path === "" ||
      (manualSrcClusterSelection && sourceClusterId === "") ||
      (manualTrgClusterSelection && targetClusterId === "") ||
      (accountInfoData.cloudType === jsonData.awsTxt &&
        destinationWorkspaceS3Path === "") ||
      (dataCloneType === jsonData.samplingTxt && clonePercentage === "") ||
      (dataCloneType === jsonData.queryTxt &&
       ( queryList.length !== selectedList.length &&
        validQuery))
    )
      setDisableNext(true);
    else setDisableNext(false);
  };

  useEffect(() => {
    enableNext();
  }, [
    externalWsToken,
    externalWsUrl,
    destinationS3ExPath,
    destinationWorkspaceS3Path,
    sourceWorkspaceS3Path,
    s3BucketName,
    clonePercentage,
    queryList,
    dataCloneType,
    disableNext,
    validQuery,
  ]);

  let workspaceId = GetWsIdWithUrl(externalWsUrl);

  const cloneTableView = async () => {
    let triggerId = GenerateUid();
    setId(triggerId);

    let payload = {
      accId: workspaceSelected.account_id,
      workspaceIdentifier: workspaceSelected.workspace_id,
      workspaceName: workspaceSelected.workspace_name,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      workspaceUrl: workspaceSelected.workspace_url,
      crossWorkspaceUrl: externalWsUrl,
      crossWorkspacePatToken: externalWsToken,
      triggerId: triggerId,
      containerName: workspaceConfig.azureStorageAccountName,
      storageAccountKey: workspaceConfig.azureStorageAccountKey,
      createTableValidationRequest: {
        input_csv: "",
        user_select: selectedList.map((item) => ({
          sourceSchemaName: item.database,
          sourceTableName: item.name,
          targetCatalogName: "",
          targetSchemaName: "",
          targetTableName: "",
        })),
      },
      tableViewCloneAcrossWorkspace: {
        glue: databaseType === jsonData.glueValueText,
        destinationS3ExternalPath: destinationS3ExPath,
        sourceWorkspaceS3Path: sourceWorkspaceS3Path,
        destinationWorkspaceS3Path:
          accountInfoData.cloudType === jsonData.awsTxt
            ? destinationWorkspaceS3Path
            : "/user/hive/warehouse",
        dataRoleArn: workspaceConfig.awsRoleArn,
        runAsJob: accountInfoData.userId
          ? accountInfoData.userId
          : accountInfoData.OAuthUser.userName,
        workspace_id: workspaceId,
        aws_bucket_name: s3BucketName,
        data_cloning_method: cloneData().dataCloneType,
        cloning_percentage: cloneData().percentage,
        query_json: `${formattedTableQuery(queryList)}`,
      },
      existingAllPurposeClusterId: sourceClusterId,
      targetAllPurposeClusterId: targetClusterId,
    };
    dispatch(cloneTableViewAcrossCall(payload));
    setShowCloneSummary(true);
  };

  useEffect(() => {
    if (startCloning) cloneTableView();
  }, [startCloning]);

  const cloneData = () => {
    if (dataCloneType === jsonData.samplingTxt) {
      return {
        percentage: clonePercentage,
        dataCloneType: jsonData.percentTxt.toLowerCase(),
      };
    } else if (dataCloneType === jsonData.completeTxt) {
      return {
        percentage: "100",
        dataCloneType: jsonData.percentTxt.toLowerCase(),
      };
    } else
      return {
        percentage: "",
        dataCloneType: jsonData.queryTxt.toLowerCase(),
      };
  };

  useEffect(() => {
    const allValid = queryList.findIndex(query => !(query.valid))==-1;
    setValidQuery(allValid);
}, [queryList]);

  useEffect(() => {
    setQueryList([]);
  }, [dataCloneType]);

  return (
    <>
      <UcmaTextField
        name={jsonData.sourceWorkspaceS3PathText}
        info={jsonData.sourceWorkspaces3ExternalPathInfo}
        value={sourceWorkspaceS3Path}
        onChangeValue={setSourceWorkspaceS3Path}
        placeholder={
          accountInfoData.cloudType === jsonData.awsTxt
            ? jsonData.destinationWorkspaceS3PathPlaceholder
            : jsonData.destinationWorkspaceS3PathAzurePlaceholder
        }
        setDisableSubmit={setDisableNext}
        pattern={
          accountInfoData.cloudType === jsonData.awsTxt
            ? storageRootPattern
            : targetLocationAzurePattern
        }
        required
        isDialog
      />
      {accountInfoData.cloudType === jsonData.awsTxt && (
        <UcmaTextField
          name={jsonData.destinationWorkspaceS3PathTxt}
          info={jsonData.destinationWorkspaceS3PathInfoTxt}
          value={destinationWorkspaceS3Path}
          onChangeValue={setDestinationWorkspaceS3Path}
          placeholder={
            accountInfoData.cloudType === jsonData.awsTxt
              ? jsonData.destinationWorkspaceS3PathPlaceholder
              : jsonData.destinationWorkspaceS3PathAzurePlaceholder
          }
          setDisableSubmit={setDisableNext}
          pattern={
            accountInfoData.cloudType === jsonData.awsTxt
              ? storageRootPattern
              : targetLocationAzurePattern
          }
          required
          isDialog
        />
      )}

      <UcmaTextField
        name={jsonData.destinationS3ExPathTxt}
        info={jsonData.destinationS3ExPathTxt}
        value={destinationS3ExPath}
        onChangeValue={setDestinationS3ExPath}
        placeholder={
          accountInfoData.cloudType === jsonData.awsTxt
            ? jsonData.destinationWorkspaceS3PathPlaceholder
            : jsonData.destinationWorkspaceS3PathAzurePlaceholder
        }
        setDisableSubmit={setDisableNext}
        pattern={
          accountInfoData.cloudType === jsonData.awsTxt
            ? storageRootPattern
            : targetLocationAzurePattern
        }
        required
        isDialog
      />

      <UcmaTextField
        name={
          accountInfoData.cloudType === jsonData.awsTxt
            ? jsonData.S3bucketName
            : jsonData.containerPathTxt
        }
        info={jsonData.destinationWorkspaceS3PathInfoTxt}
        value={s3BucketName}
        onChangeValue={setS3BucketName}
        placeholder={
          accountInfoData.cloudType === jsonData.awsTxt
            ? jsonData.healthcaredatadatabricksTxt
            : jsonData.containerPlaceHolder
        }
        setDisableSubmit={setDisableNext}
        pattern={
          accountInfoData.cloudType === jsonData.awsTxt
            ? NamePattern
            : containerAzurePattern
        }
        required
        isDialog
      />

      <ManualClusterSelection
        clusterId={sourceClusterId}
        setClusterId={setSourceClusterId}
        label={jsonData.srcMnulClstrSelectText}
        wsUrl={workspaceSelected.workspace_url}
        wsId={workspaceSelected.workspace_id}
        setManualClusterSelection={setManualSrcClusterSelection}
      />

      <AcrossWorkspaceInfo
        setDisableNext={setDisableNext}
        setExternalWsToken={setExternalWsToken}
        setExternalWsUrl={setExternalWsUrl}
      />

      <ManualClusterSelection
        clusterId={targetClusterId}
        setClusterId={setTargetClusterId}
        label={jsonData.trgMnulClstrSelectText}
        wsUrl={externalWsUrl}
        wsId={externalWsToken}
        setManualClusterSelection={setManualTrgClusterSelection}
      />

      <div className={`${styles["display"]} ${styles["margin-top-20"]}`}>
        <HeadingWithInfo
          label={jsonData.datacloningmethodTxt}
          isDialog={true}
          info={jsonData.datacloneMethodInfo}
        />
        <FormControl sx={{ m: 1, width: "20rem" }} size="small">
          <Select
            value={dataCloneType}
            onChange={(event) => setDataCloneType(event.target.value)}
            // disabled={selectedList.length > 5}
          >
            {constants.tableCloneMethods.map((version) =>
            selectedList.length > 5 && version === "Query" ? null :  (
              <MenuItem key={version} value={version}>
                <>{version}</>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {dataCloneType == jsonData.samplingTxt && (
        <Grid sx={{ padding: 2 }} mt={2} component={Paper} elevation={2}>
          <UcmaTextField
            name={jsonData.sampleSizeTxt}
            info={jsonData.sampleInfo}
            value={clonePercentage}
            onChangeValue={setClonePercentage}
            placeholder={"0-100 %"}
            setDisableSubmit={setDisableNext}
            pattern={percentagePattern}
            type="number"
            required
            isDialog
          />
        </Grid>
      )}

      {dataCloneType == jsonData.queryTxt && (
        <QueryClone
          selectedList={selectedList}
          setDisableNext={setDisableNext}
          setQueryList={setQueryList}
          queryList={queryList}
          setValidQuery={setValidQuery}
        />
      )}
    </>
  );
}

export default CloneTable;
