import { Grid, Paper } from "@mui/material";
import UcmaTextField from "../UI/Form/TextField";
import jsonData from "../../Assets/i18n/en.json";
import HeadingWithInfo from "../Common/HeadingWithInfo/HeadingWithInfo";
import { getCall } from "../../Resources/apicalls";
import { queryValidationApi } from "../../Resources/apiurls";
import { useEffect } from "react";

export default function QueryClone(props) {
  const { selectedList, setDisableNext, setQueryList, queryList } = props;

  const listquery = async (index, value, item) => {
    let temp = queryList.slice();
    temp[index] = {
      tableName: item.name,
      database: item.database,
      query: value,
      valid: false,
    };
    setQueryList(temp);
  };

  const validatQuery = async (query, index) => {
    if (query) {
      let api = `${queryValidationApi}${query}`;
      const res = await getCall(api);

      let Temp=queryList.slice()
      Temp[index].valid = res.ucxApiResponse;
      setQueryList(Temp);

      return !res.ucxApiResponse;
    } else return false;
  };

  

  return (
    <Grid sx={{ padding: 2 }} mt={1} component={Paper} elevation={2}>
      <HeadingWithInfo
        label={jsonData.tableQueryTxt}
        info={jsonData.tableQueryInfoTxt}
      />
      {selectedList.map((item, index) => (
        <div key={index + 1}>
          <UcmaTextField
            name={"Table Name: " + item.name}
            onChangeValue={(value) => listquery(index, value, item)}
            placeholder={jsonData.sqlSampleTxt}
            setDisableSubmit={setDisableNext}
            isDialog
            customValidateHandler={() =>
              validatQuery(queryList[index]?.query, index)
            }
          />
        </div>
      ))}
    </Grid>
  );
}
