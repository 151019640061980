import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import classes from "./CustomTableRow.module.css";

const CustomTableRow = ({ data }) => {
  return (
    <>
      {data?.map((item, index) => {
        return (
          <TableRow className={classes["table-row-height"]} key={index}>
            {Object.entries(item)?.map(([key, value]) => {
              return (
                <>
                  <TableCell className={classes["table-val"]}>{key}</TableCell>
                  {value === "Matched" ? (
                    <TableCell className={classes["table-font-color"]}>
                      {value}
                    </TableCell>
                  ) : (
                    <TableCell className={classes["table-val"]}>
                      {value}
                    </TableCell>
                  )}
                </>
              );
            })}
          </TableRow>
        );
      })}
    </>
  );
};

export default CustomTableRow;
