import { createSlice } from "@reduxjs/toolkit";
import { getMetaStoreList } from "../thunk/metastorelist-thunk";

const initialState = {
  metastoreData: [],
};

const dataSlice = createSlice({
  name: "metastoreData",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getMetaStoreList.pending, (state) => {
        state.error = null;
      })
      .addCase(getMetaStoreList.fulfilled, (state, action) => {
        state.metastoreData = action.payload;
        state.error = null;
      })
      .addCase(getMetaStoreList.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const metastoreData = dataSlice.reducer;
