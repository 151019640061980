import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import styles from "./Dashboard.module.css";
import Typography from "@mui/material/Typography";

const CardComp = (topText, bottomText,clickable) => {
    return (
      <Card  className={`${styles[clickable?"card-content-min-height":"card-content-NotClickable"]}`}>
        <CardContent
          className={`${styles["card-content"]}`}
        >
          <Typography  className={`${styles["font"]}`} gutterBottom>
            {topText}
          </Typography>
          <Typography
            variant="h5"
            component="div"
            className={`${styles["botom-text-font"]}`}
          >
            {bottomText}
          </Typography>
        </CardContent>
      </Card>
    );
  };
export default CardComp