import constants from "../../utils/constant";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from "@mui/material";
import classes from "./WorkspaceSummary.module.css";
import EllipsisTooltip from "../UI/Tooltip/Tooltip";
import { useEffect, useState } from "react";
import LoadingSpinner from "../UI/LoadingSpinner";
import { getCall } from "../../Resources/apicalls";
import jsonData from "../../Assets/i18n/en.json";
import NoData from "../Common/NoData/NoData";
import { notebookReplacementInfoApi, notebookTotalsApi } from "../../Resources/apiurls";
import Pagination from "../Common/Pagination/Pagination";
import { StringToList } from "../../utils/ConversionFunctions/ConversionFunctions";

const NotebookUpgradeTotals = (props) => {
  const { id ,selectNotebook, count} = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const pageSize=10;
  const handleChange = (event) => {
    setPageNo(event - 1);
  };
  useEffect(() => {
    notebookMigartionResponse();
  }, [pageNo]);
  const notebookMigartionResponse = async () => {
    setLoading(true);
    let api = `${notebookTotalsApi}${id}?pageNo=${pageNo}&pageSize=${pageSize}`;
    let res = await getCall(api);
    setLoading(false);
    setData(res.ucxApiResponse);
  };
  useEffect(() => {
    notebookMigartionResponse();
  }, []);

  if (!data && !loading) {
    return <NoData label={jsonData.noData}></NoData>;
  }
  return (
    <>
      <LoadingSpinner open={loading} />
      <div className={classes['margin-bottom']}>

      <Paper className={classes["paper"]}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes["table-row-height"]}>
                  {constants.notebookTotals.map((head) => (
                    <TableCell className={classes["table-heading"]} key={head?.label}>
                      {head?.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
              {data.map((row, index) => {
                return(
                <TableRow>
                  {constants.notebookTotals.map((data, index) => {
                    return (
                      <TableCell
                        className={classes["horizontal-width"]}
                        key={index+1}
                      >{data.value ==='notebook_name'?
                          <Tooltip title={row.notebook_path}><a className={classes['link']} onClick={() => selectNotebook(row)}>{row[data.value]}</a></Tooltip>
                          : (data.value === 'category' ? 
                          ((row['category'] && row['category'] !== "[]") ? StringToList(row['category']).join(",") :"-"): 
                          <EllipsisTooltip>{row[data.value]}</EllipsisTooltip>)}
                       
                      </TableCell>
                    );
                })}
                </TableRow>)
            })}

              
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Pagination
              totalPages={Math.ceil(count / pageSize)}
              currentPage={pageNo + 1}
              onPageChange={handleChange}
            />
      </div>
    </>
  );
};
export default NotebookUpgradeTotals;


