import { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import jsonData from "../../Assets/i18n/en.json";
import LineageDashboard from "./LineageDashboard";
// import LineageGraph from "./LineageGraph/LineageGraph";
import LineageGraph from "./LineageGraph/Grapgh";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LineageUtility from "./LineageUtility";
import { styled } from "@mui/material/styles";
import styles from "../NewComponent.module.css";
import { useCommonPayload } from "../../Resources/useCommonPayload";
import { postCall } from "../../Resources/apicalls";
import { downloadcsvUrl } from "../../Resources/apiurls";
import { useSelector } from "react-redux";
import LoadingSpinner from "../UI/LoadingSpinner";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    backgroundColor: "#fff",
    color: "black",
    padding: "16px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

export default function Lineagefunction(props) {
  const { selectComponent } = props;
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const [value, setValue] = useState("1");
  const [makingApiCall, setMakingApiCall] = useState(false);
  const commonPayload = useCommonPayload();
  const [openUtilityModal, setOpenUtilityModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [downloadasPdf, setDownloadasPdf] = useState(false);
  const [download,setDownload] = useState(false);
  const lineageDashboardRef = useRef();

  const handleChange = (event, newValue) => {
    setRefresh(true);
    setValue(newValue);
  };

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenUtility = () => {
    setOpenUtilityModal(true);
    handleCloseMenu();
  };
  const handleCloseUtility = () => {
    setOpenUtilityModal(false);
  };
  useEffect(() => {
    setRefresh(true);
  }, [workspaceSelected]);

  const handleRefreshDashboard = () => {
    setRefresh(true);
    handleCloseMenu();
  };

  const handleDownloadReportClick = async () => {
    let payload = {
      ...commonPayload,
      triggerId: "",
      ucxPayload: {},
    };
    setMakingApiCall(true);
    let res = await postCall(downloadcsvUrl, payload);
    setMakingApiCall(false);
    const url = URL.createObjectURL(new Blob([res.ucxApiResponse]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `lineage-${"report"}.csv`);
    link.click();
    link.remove();
  };
  const handleDownloadReportasPdfClick = async () => {
    setDownloadasPdf(true);
    handleCloseMenu();
  };

  const handleDownloadReportasCsvClick = async () => {
    setDownload(true);
    handleCloseMenu();
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <LoadingSpinner open={makingApiCall} />
      <TabContext value={value}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{ flexGrow: 1 }}
          >
            <Tab label={jsonData.lineageDashborad} value="1" />
            <Tab label={jsonData.lineageGraph} value="2" />
          </TabList>
          <Button onClick={handleOpenUtility}>{jsonData.startLineage}</Button>
          
            {value === "1" && (
              <>
              <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleClickMenu}
            >
              <MoreVertIcon />
              </IconButton>
    
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={() => handleRefreshDashboard()}>
                  Refresh
                </MenuItem>
                <MenuItem onClick={handleDownloadReportasPdfClick}>
                  {jsonData.dwnldTxt} as Pdf
                </MenuItem>
                <MenuItem onClick={handleDownloadReportasCsvClick}>
                  {jsonData.dwnldTxt} as CSV
                </MenuItem>
              </Menu>
              </>
            )}
            
        </Box>

        <TabPanel className={`${styles["panel-padding"]}`} value="1">
          <LineageDashboard
            ref={lineageDashboardRef}
            selectComponent={selectComponent}
            refresh={refresh}
            setRefresh={setRefresh}
            setDownloadasPdf={setDownloadasPdf}
            downloadasPdf={downloadasPdf}
            download={download}
            setDownload={setDownload}
          />
        </TabPanel>
        <TabPanel className={`${styles["panel-padding"]}`} value="2">
          <LineageGraph
            refresh={refresh}
            setRefresh={setRefresh}
            selectComponent={selectComponent}
          />
        </TabPanel>
      </TabContext>
      <BootstrapDialog
        open={openUtilityModal}
        onClose={setOpenUtilityModal}
        maxWidth="lg"
        PaperProps={{
          style: {
            minWidth: "600px",
            minHeight: "100px",
            position: "relative",
          },
        }}
      >
        <DialogContent>
          <LineageUtility
            selectComponent={selectComponent}
            onclose={setOpenUtilityModal}
          />
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
}
