import { useEffect, useState } from "react";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
} from "@mui/material";
import TableViewsPage from "../../Table/TableViewsMigration";
import MountPoints from "../../Mountpoints/MountPointsMigration";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import classes from "./Migration.module.css";
import ButtonStyled from "../../UI/NavigationButtons/Buttons";
import jsonData from "../../../Assets/i18n/en.json";
import InteractiveCluster from "../../InteractiveCluster/InteractiveClusterMigration";
import Jobs from "../../Jobs/JobsMigration";
import DbsqlCluster from "../../DbsqlCluster/DbsqlClusterMigration";
import DbfsToVolume from "../../Dbfs/DbfsToVolume";
import DltPipelineMigrate from "../../Jobs/DltPipelineMigrate";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    backgroundColor: "#fff",
    color: "black",
    padding: "16px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));
const MigrationDialog = ({
  open,
  onClose,
  migrationSelected,
  selectedList,
  handleMigrateClick,
  databaseType,
  title,
  setShowAlertMigration,
  setId,
  clusterList,
}) => {
  const [startMigration, setStartMigration] = useState(false);
  const [disableNext, setDisableNext] = useState(true);

  useEffect(() => {
    if (migrationSelected === "noCloneDelete") {
      setDisableNext(false);
    }
  }, [migrationSelected, setDisableNext]);

  let contentComponent;
  switch (migrationSelected) {
    case "table":
      contentComponent = (
        <TableViewsPage
          setId={setId}
          selectedList={selectedList}
          startMigration={startMigration}
          databaseType={databaseType}
          setStartMigration={setStartMigration}
          setDisableNext={setDisableNext}
          clusterList={clusterList}
          disableNext={disableNext}
        />
      );
      break;
    case "mountpoints":
      contentComponent = (
        <MountPoints
          selectedList={selectedList}
          startMigration={startMigration}
          setStartMigration={setStartMigration}
          setDisableNext={setDisableNext}
          setId={setId}
        />
      );
      break;
    case "interactiveCluster":
      contentComponent = (
        <InteractiveCluster
          selectedList={selectedList}
          startMigration={startMigration}
          setStartMigration={setStartMigration}
          setDisableNext={setDisableNext}
          setId={setId}
        />
      );
      break;
    case "jobs":
      contentComponent = (
        <Jobs
          selectedList={selectedList}
          startMigration={startMigration}
          setStartMigration={setStartMigration}
          setDisableNext={setDisableNext}
          setId={setId}
        />
      );
      break;
    case "dbsqlCluster":
      contentComponent = (
        <DbsqlCluster
          selectedList={selectedList}
          startMigration={startMigration}
          setStartMigration={setStartMigration}
          setDisableNext={setDisableNext}
          setId={setId}
        />
      );
      break;
    case "DbfsTodbfs":
      contentComponent = (
        <DbfsToVolume
          selectedList={selectedList}
          startMigration={startMigration}
          setStartMigration={setStartMigration}
          setDisableNext={setDisableNext}
          disableNext={disableNext}
          setId={setId}
        />
      );
      break;
    case "dltPipeline":
      contentComponent = (
        <DltPipelineMigrate
          selectedList={selectedList}
          startMigration={startMigration}
          setStartMigration={setStartMigration}
          setShowAlertMigration={setShowAlertMigration}
          setDisableNext={setDisableNext}
          setId={setId}
        />
      );
      break;

    default:
      contentComponent = <div>{jsonData.wantMigrate}</div>;
  }

  const migrationClick = () => {
    if (handleMigrateClick) {
      handleMigrateClick();
    }
    setStartMigration(true);
    setShowAlertMigration(true);
    onClose();
  };

  useEffect(() => {
    if (startMigration) setStartMigration(false);
  }, [startMigration]);

  return (
    <BootstrapDialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{
        style: {
          minWidth: "550px",
          minHeight: "100px",
          position: "relative",
        },
      }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
        className={classes["dialog-heading"]}
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>{contentComponent}</DialogContent>
      <DialogActions>
        <div className={classes["button-container"]}>
          <ButtonStyled
            label={jsonData.canceltxt}
            background="transparent"
            variant="text"
            border="none"
            color="#24262C"
            onclick={onClose}
          />
          <ButtonStyled
            label={jsonData.migrate}
            background="#457ECF"
            variant="contained"
            border="1px solid #457ECF"
            disabled={disableNext}
            onclick={migrationClick}
          />
        </div>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default MigrationDialog;
