import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { validateTableApi } from "../../Resources/apiurls";

export const validationDataCall = createAsyncThunk(
  "data/validationDataCall",
  async (body) => {
    try {
       
      const response = await axios.post(validateTableApi, body, {
        headers: {
          Authorization:
            "Bearer " + window.sessionStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

);

const initialState = {
  validateData: {},
};

const dataSlice = createSlice({
  name: "validationData",
  initialState,
  reducers: {
    resetMountPoint() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validationDataCall.pending, (state) => {
        state.error = null;
      })
      .addCase(validationDataCall.fulfilled, (state, action) => {
        state.validateData = action.payload;
        state.error = null;
      })
      .addCase(validationDataCall.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});


export const { selectPermission, resetMountPoint } = dataSlice.actions;
export const validationData = dataSlice.reducer;
