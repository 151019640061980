import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "./slice/login-slice";
import accountInfoSlice from "./slice/accountInfo-slice";
import { tableData } from "./thunk/mountPointsoints-thunk";
import { workspaceData } from "./slice/workspaceList-slice";
import { metastoreData } from "./slice/metastore-slice";
import workspaceSlice from "./slice/workspace-slice";
import cloneTableDataSlice from "./slice/tableCloneData-slice";
import { validationData } from "./thunk/tableValidation-slice";
import workspaceConfigReducer from "./slice/workspaceConfigue-slice";

const store = configureStore({
  reducer: {
    login: loginSlice.reducer,
    accountInfo: accountInfoSlice.reducer,
    mountPointsReducer: tableData,
    workspaceData: workspaceData,
    metastoreData: metastoreData,
    workspaceSlice: workspaceSlice.reducer,
    cloneTableData:cloneTableDataSlice.reducer,
    validateTableSlice:validationData,
    workspaceConfig: workspaceConfigReducer,
  },
});

export default store;
