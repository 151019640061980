import React, { useState, useEffect, useRef } from "react";
import axios from "axios"; // Importing axios for making API calls
import { Search, ViewList } from "@mui/icons-material";
import jsonData from "../../../Assets/i18n/en.json";
import tableSvg from "../../../../src/Assets/Icons/table.svg";
import notbookIcon from "../../../Assets/Icons/notebookicon.svg";
import clustericon from "../../../Assets/Icons/cluster.svg";
import dbfsIcon from "../../../Assets/Icons/DBFS.svg";
import cloudicon from "../../../Assets/Icons/cloudpath.svg";
import mountIcon from "../../../Assets/Icons/mount.svg";
import WorkIcon from "@mui/icons-material/Work";
import jobIcon from "../../../Assets/Icons/jobicon.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Xarrow, { useXarrow, Xwrapper } from "react-xarrows";
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TableCell,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  Typography,
} from "@mui/material";
import "./LineageGraph.css";

import { useCommonPayload } from "../../../Resources/useCommonPayload";
import {
  jobsListApi,
  lineageGraphDependentJobEntityApi,
  lineageGraphJobEntityApi,
} from "../../../Resources/apiurls";
import { capitalizeFirstLetter } from "../../../utils/CamelCase/CamelCase";
import DraggableGrid from "./dragComponent";

const LineageGraph = (props) => {
  const { refresh, setRefresh } = props;
  const containerRef = useRef(null);
  const maincontainerRef = useRef(null);
  const updateXarrow = useXarrow();
  const commonPayload = useCommonPayload();
  const [jobId, setJobId] = useState("");
  const [jobName, setJobName] = useState("");
  const [jobList, setJobList] = useState([]); // State to store job names
  const [jobIdMapping, setJobIdMapping] = useState({}); // Mapping of job names to job IDs
  const [showJobBox, setShowJobBox] = useState(false);
  const [showAdditionalBoxes, setShowAdditionalBoxes] = useState(false);
  const [relatedJobs, setRelatedJobs] = useState([]);
  const [startBox, setStartBox] = useState(null);
  const [selectedJobData, setSelectedJobData] = useState(null);
  const [selectedDataType, setSelectedDataType] = useState("");
  const [rightPanelJobID, setRightPanelJobID] = useState("");
  const [rightPanelJobName, setRightPanelJobName] = useState("");
  const [openRightPanel, setOpenRightPanel] = useState(false);
  const [rightPanelData, setRightPanelData] = useState({});
  const [expandedJobBoxes, setExpandedJobBoxes] = useState({}); // Track expanded state for job boxes
  const [expandedJobBoxes1, setExpandedJobBoxes1] = useState({});
  const [boxData, setBoxData] = useState({});
  const [relatedJobss, setRelatedJobss] = useState({});
  const [relatedData, setRelatedData] = useState({});
  const [relatedDataa, setRelatedDataa] = useState({});
  const [secondrelatedData, setsecondRelatedData] = useState({});
  const [showFirstAdditionalBoxes, setshowFirstAdditionalBoxes] =
    useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [name, setname] = useState("");
  const [idd, setIdd] = useState("");
  const [relatedname, setRelatedName] = useState("");
  const [relatedidd, setrelatedIdd] = useState("");
  const [arrowUpadte, setArrowUpdate] = useState(false);
  const [seconstartBox, setscondStartBox] = useState(null);
  const [showSecondJobBoxes, setshowSecondJobBoxes] = useState(false);
  const [showThirdJobBoxes, setshowThirdJobBoxes] = useState(false);

  useEffect(() => {
    const handleResize = () => updateXarrow(); // Recalculate arrows when the window is resized
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      updateXarrow();
    };

    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      updateXarrow();
    };

    if (maincontainerRef.current) {
      maincontainerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (maincontainerRef.current) {
        maincontainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const fetchJobList = async () => {
    let postbody = {
      ...commonPayload,
      workflowType: "",
      jobId: "",
    };
    try {
      const response = await axios.post(jobsListApi, postbody, {
        headers: {
          Authorization:
            "Bearer " + window.sessionStorage.getItem("accessToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (response.data && response.data.ucxApiResponse) {
        const jobs = response.data.ucxApiResponse.map((job) => job[2]); // The third element in each response is the job name
        const mapping = response.data.ucxApiResponse.reduce((acc, job) => {
          const jobId = job[0]; // Assuming job[0] is the job ID
          const jobName = job[2]; // Assuming job[2] is the job name
          acc[jobName] = jobId;
          return acc;
        }, {});
        setJobList(jobs || []);
        setJobIdMapping(mapping || {}); // Store the job name to job ID mapping
      }
    } catch (error) {
      console.error("Error fetching job list:", error);
    }
  };

  const fetchDependentEntity = async (jobId, from) => {
    let postbody = {
      ...commonPayload,
      workflowType: "",
      jobId: jobId,
    };
    try {
      const response = await axios.post(
        `${lineageGraphJobEntityApi}`,
        postbody,
        {
          headers: {
            Authorization:
              "Bearer " + window.sessionStorage.getItem("accessToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      // Assuming the response has a dependentEntity field with the required data
      const dependentEntity = response.data.ucxApiResponse.dependentEntity[0];

      // Dynamically assigning variables to boxData state
      const updatedJobData = {
        jobid: dependentEntity.jobid || "N/A",
        table: dependentEntity.table || [],
        cluster: dependentEntity.cluster || [],
        notebook: dependentEntity.taskType || [],
        DBFS: dependentEntity.DBFS || [],
        mountPoint: dependentEntity.mountPoint || [],
        cloudPath: dependentEntity.cloudPath || [],
      };

      // Update the boxData state
      if (from === "fromsearch") {
        setBoxData(updatedJobData);
        setname(dependentEntity.jobName);
        setIdd(dependentEntity.jobId);
      } else if (from === "fromlevel1") {
        setRelatedName(dependentEntity.jobName);
        setrelatedIdd(dependentEntity.jobId);
        setRelatedData(updatedJobData);
      } else if (from === "fromlevel2") {
        setRelatedDataa(updatedJobData);
      }
      setSelectedJobData(updatedJobData.jobid);
    } catch (error) {
      console.error("Error fetching dependent entity:", error);
    }
  };

  const fetchRelatedJobData = async (id, entity, type, from) => {
    const apiUrl = `${lineageGraphDependentJobEntityApi}${entity}`;
    let postbody = {
      ...commonPayload,
      workflowType: "",
      jobId: id,
      entityType: type,
    };

    try {
      const response = await axios.post(apiUrl, postbody, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + window.sessionStorage.getItem("accessToken"),
        },
      });

      // Handle the API response
      const relatedJobdata = response.data.ucxApiResponse || [];

      // Format the data in the desired structure
      const formattedRelatedJobs = {
        [entity]: relatedJobdata,
      };

      const formatted = Object.entries(relatedJobdata).reduce(
        (acc, [name, id]) => {
          acc[name] = { name, id };
          return acc;
        },
        {}
      );

      if (from === "1") {
        setRelatedJobs(formatted);
      } else if (from === "2") {
        setRelatedJobss(formatted);
      }
    } catch (error) {
      console.error("Error fetching related job data:", error);
    }
  };

  useEffect(() => {
    updateXarrow();
    fetchJobList();
    setJobId("");
    setJobName("");
  }, [refresh]);

  useEffect(() => {
    if (refresh) setRefresh(false);
  }, [refresh]);

  const handleDropdownChange = (event) => {
    updateXarrow();
    setExpandedJobBoxes({});
    setOpenRightPanel(false);
    const selectedJobName = event.target.value;
    setJobName(selectedJobName);
    setJobId(jobIdMapping[selectedJobName] || "");
    setRelatedJobs([]);
    setRelatedJobss([]);
    setShowAdditionalBoxes(false);
    setBoxData({});
    setRelatedData({});
    setShowJobBox(false);
  };
  const getName = (item) => {
    const name = item.split("/").pop();
    return `${name}.....`;
  };

  const handleJobIdChange = (event) => {
    const enteredJobId = event.target.value;
    setJobId(enteredJobId);
    setJobName(""); // Reset the job name dropdown when typing a job ID
  };

  useEffect(() => {
    updateXarrow();
  }, [
    arrowUpadte,
    jobId,
    boxData,
    relatedData,
    relatedJobs,
    relatedJobss,
    seconstartBox,
    startBox,
  ]);

  const handleSearch = () => {
    updateXarrow();
    if (jobId || jobName) {
      fetchDependentEntity(jobId, "fromsearch");
      setShowJobBox(true);
      setShowAdditionalBoxes(true); // Show related entity boxes as well
    }
  };

  const handleJobBoxClick = (job) => {
    setArrowUpdate(!updateXarrow);
    setStartBox(job);
    setShowAdditionalBoxes(true);
    setSelectedDataType(""); // Clear selected data type
    toggleJobBoxExpansion(job); // Toggle expansion on job header click
    setRelatedJobs([]);
    setRelatedJobss([]);
    setRelatedData({});
  };

  const handleFIrstRelatedJobBoxClick = (id, job, type, item) => {
    setStartBox(`${item}1`);
    setArrowUpdate(!updateXarrow);
    fetchRelatedJobData(id, job, type, "1");
    setShowAdditionalBoxes(true);
    toggleJobBoxExpansion(job); // Toggle expansion on related job box click
    setRelatedData({});
    setRelatedJobss([]);
    setshowSecondJobBoxes(true);
  };

  const handleRelatedJobBoxClick = (id, job, type, item) => {
    setscondStartBox(`${item}2`);
    setArrowUpdate(!updateXarrow);
    fetchRelatedJobData(id, job, type, "2");
    setshowThirdJobBoxes(true);
    toggleNextJobBoxExpansion(job); // Toggle expansion on related job box click
  };

  const handleRelatedJobBoxClick1 = (job, type) => {
    setArrowUpdate(!updateXarrow);
    fetchDependentEntity(job, "fromlevel1");
    setSelectedJobId(`${job}2`);
    setshowFirstAdditionalBoxes(true);
    toggleJobBoxExpansion(job); // Toggle expansion on related job box click
    setRelatedJobss({});
  };

  const handleRelatedJobBoxClick3 = (job, type) => {
    setArrowUpdate(!updateXarrow);
    fetchDependentEntity(job, "fromlevel2");
    setshowFirstAdditionalBoxes(true);
    toggleNextJobBoxExpansion(job); // Toggle expansion on related job box click
  };

  const handleRelatedJobBoxClick2 = (job, type, item) => {
    setArrowUpdate(!updateXarrow);
    fetchRelatedJobData(job, type);
    setStartBox(item);
    setShowAdditionalBoxes(true);
    toggleJobBoxExpansion(job); // Toggle expansion on related job box click
    // setRelatedData({});
  };

  const getJobRef = (jobId) => {
    return `job-box-${jobId}`;
  };

  const toggleJobBoxExpansion = (job) => {
    setExpandedJobBoxes((prev) => {
      const newState = { ...prev };

      // If the "main-job-box" is not the one being toggled, collapse other job boxes
      if (job !== "main-job-box") {
        // If the clicked job box is already expanded, collapse it
        if (newState[job]) {
          newState[job] = false;
        } else {
          // Collapse all other job boxes and expand the clicked one
          Object.keys(newState).forEach((key) => {
            if (key !== "main-job-box") {
              newState[key] = false;
            }
          });
          newState[job] = true;
        }
      } else {
        // Keep the behavior of "main-job-box" as it is (toggle its state)
        newState[job] = !newState[job];
      }

      return newState;
    });
  };

  const toggleNextJobBoxExpansion = (job) => {
    setExpandedJobBoxes1((prev) => {
      const newState = { ...prev };

      // If the "main-job-box" is not the one being toggled, collapse other job boxes
      if (job !== "main-job-box") {
        // If the clicked job box is already expanded, collapse it
        if (newState[job]) {
          newState[job] = false;
        } else {
          // Collapse all other job boxes and expand the clicked one
          Object.keys(newState).forEach((key) => {
            if (key !== "main-job-box") {
              newState[key] = false;
            }
          });
          newState[job] = true;
        }
      } else {
        // Keep the behavior of "main-job-box" as it is (toggle its state)
        newState[job] = !newState[job];
      }

      return newState;
    });
  };

  const handleDataTypeClick = (dataType, jobId, jobName, data) => {
    updateXarrow();
    setRightPanelData(data);
    setOpenRightPanel(true);
    setRightPanelJobID(jobId);
    setRightPanelJobName(jobName);
    setSelectedDataType(dataType);
  };

  const renderTableData = (dataType, jobId, rightPanelData) => {
    const data = rightPanelData[dataType] || [];
    return (
      <>
        <div className="panel-right-head">
          {capitalizeFirstLetter(dataType)} ({data.length})
        </div>
        <TableContainer sx={{ maxHeight: "50vh" }}>
          <Table className="data-table">
            <TableBody>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  useEffect(() => {
    if (Object.keys(relatedJobs)?.length > 0) {
      setShowAdditionalBoxes(true);
    }
  }, [relatedJobs]);

  const expandGrid = () => {
    return selectedDataType && openRightPanel;
  };

  const closeRightPanel = () => {
    updateXarrow();
    setOpenRightPanel(false);
  };

  return (
    <Xwrapper>
      <div className="lineage-dashboard" ref={maincontainerRef}>
        <div className={"padding-1"}>
          <Typography mb={2} variant="h5">
            Databricks Objects Lineage Graph{" "}
          </Typography>
        </div>

        <Grid container>
          <Grid item lg={expandGrid() ? 9 : 12}>
            <div className="header">
              <div className="search-box-container">
                <div className="search-label">Job</div>
                <FormControl
                  size="small"
                  sx={{ width: "100%", background: "white" }}
                >
                  <InputLabel>Select Job</InputLabel>
                  <Select
                    labelId="select-label"
                    label="select-job"
                    id="select-job"
                    value={jobName}
                    placeholder="Select Job"
                    size="small"
                    onChange={handleDropdownChange}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          width: 220,
                          maxHeight: 360,
                        },
                      },
                    }}
                  >
                    {jobList?.length === 0 && (
                      <MenuItem value={""}>
                        {jsonData.notAvailableText}
                      </MenuItem>
                    )}

                    {jobList.map((job, index) => (
                      <MenuItem key={index} value={job}>
                        {job}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <input
                  type="text"
                  className="search-box"
                  value={jobId}
                  disabled={jobList.length === 0}
                  onChange={handleJobIdChange}
                  placeholder="Enter Job ID"
                />
                <button className="search-button" onClick={handleSearch}>
                  <Search fontSize="large" />
                </button>
              </div>
            </div>
            <DraggableGrid
              arrowUpdate={arrowUpadte}
              setArrowUpdate={setArrowUpdate}
            >
              {" "}
              <div className="lineage-content" ref={containerRef}>
                <div className="jobs-container">
                  {/* Level 1 job Id Boxes or main job id box*/}
                  {showJobBox && (
                    <div
                      className={`job-box left ${
                        expandedJobBoxes["main-job-box"] ? "expanded" : ""
                      }`}
                      id={getJobRef("main-job-box")}
                    >
                      <div
                        className="job-box-header"
                        onClick={() => handleJobBoxClick("main-job-box")}
                      >
                        <div>
                          <WorkIcon className="iconMui" />
                        </div>
                        <div>
                          <div className={"truncate-text"} title={jobName}>
                            <b>{jobName}</b>
                          </div>
                          <div>
                            <b>{idd}</b>
                          </div>
                        </div>
                      </div>
                      {expandedJobBoxes["main-job-box"] && (
                        <div className="job-box-details">
                          <div
                            className="category"
                            onClick={() =>
                              handleDataTypeClick(
                                "table",
                                jobId,
                                jobName,
                                boxData
                              )
                            }
                          >
                            <img
                              src={tableSvg}
                              alt="Table Icon"
                              width="18"
                              height="18"
                              className="iconbutton"
                            />
                            <p>Tables ({boxData?.table?.length})</p>
                          </div>
                          <div
                            className="category"
                            onClick={() =>
                              handleDataTypeClick(
                                "cluster",
                                jobId,
                                jobName,
                                boxData
                              )
                            }
                          >
                            <img
                              src={clustericon}
                              alt="Table Icon"
                              width="18"
                              height="18"
                              className="iconbutton"
                            />
                            <p> Clusters ({boxData?.cluster?.length})</p>
                          </div>
                          <div
                            className="category"
                            onClick={() =>
                              handleDataTypeClick(
                                "notebook",
                                jobId,
                                jobName,
                                boxData
                              )
                            }
                          >
                            <img
                              src={notbookIcon}
                              alt="Table Icon"
                              width="18"
                              height="18"
                              className="iconbutton"
                            />
                            <p>Notebook ({boxData?.notebook?.length})</p>
                          </div>
                          <div
                            className="category"
                            onClick={() =>
                              handleDataTypeClick(
                                "DBFS",
                                jobId,
                                jobName,
                                boxData
                              )
                            }
                          >
                            <img
                              src={dbfsIcon}
                              alt="Table Icon"
                              width="18"
                              height="18"
                              className="iconbutton"
                            />
                            <p>DBFS ({boxData?.DBFS?.length})</p>
                          </div>
                          <div
                            className="category"
                            onClick={() =>
                              handleDataTypeClick(
                                "cloudPath",
                                jobId,
                                jobName,
                                boxData
                              )
                            }
                          >
                            <img
                              src={cloudicon}
                              alt="Table Icon"
                              width="18"
                              height="18"
                              className="iconbutton"
                            />
                            <p>Cloud Path ({boxData?.cloudPath?.length})</p>
                          </div>
                          <div
                            className="category"
                            onClick={() =>
                              handleDataTypeClick(
                                "mountPoint",
                                jobId,
                                jobName,
                                boxData
                              )
                            }
                          >
                            <img
                              src={mountIcon}
                              alt="Table Icon"
                              width="18"
                              height="18"
                              className="iconbutton"
                            />
                            <p>Mount Point ({boxData?.mountPoint?.length})</p>
                          </div>
                        </div>
                      )}

                      {/* arrows from level 2 entity boxes to Level 3 job Id Boxes*/}
                      {seconstartBox &&
                        showThirdJobBoxes &&
                        Object.keys(relatedJobss)?.length > 0 &&
                        Object.entries(relatedJobss).map(([key, value]) => {
                          return (
                            <Xarrow
                              key={`${startBox}-${value.id}`}
                              start={getJobRef(seconstartBox)}
                              end={getJobRef(`${value.id}3`)}
                              className="arrow visible"
                              strokeWidth={2}
                              headSize={6}
                              startAnchor="right"
                              endAnchor="left"
                            />
                          );
                        })}
                      {/* Arrows from level 1 entity boxes to level 2 job id boxes*/}
                      {startBox &&
                        showSecondJobBoxes &&
                        Object.keys(relatedJobs)?.length > 0 &&
                        Object.entries(relatedJobs).map(([key, value]) => {
                          return (
                            <Xarrow
                              key={`${startBox}-${value.id}`}
                              start={getJobRef(startBox)}
                              end={getJobRef(`${value.id}2`)}
                              className="arrow visible"
                              strokeWidth={2}
                              headSize={6}
                              startAnchor="right"
                              endAnchor="left"
                            />
                          );
                        })}

                      {/* Arrows from 1st level job id boxes to 2nd level entity boxes*/}

                      {boxData.table &&
                        boxData.table.length > 0 &&
                        boxData.table.map((item, index) => (
                          <Xarrow
                            key={`${jobId}-${item}`}
                            start={getJobRef("main-job-box")}
                            end={getJobRef(`${item}1`)}
                            className="arrow visible"
                            strokeWidth={2}
                            startAnchor="right"
                            endAnchor="left"
                          />
                        ))}

                      {boxData.cluster &&
                        boxData.cluster.length > 0 &&
                        boxData.cluster.map((item, index) => (
                          <Xarrow
                            key={`${jobId}-${item}`}
                            start={getJobRef("main-job-box")}
                            end={getJobRef(`${item}1`)}
                            className="arrow visible"
                            strokeWidth={2}
                            startAnchor="right"
                            endAnchor="left"
                          />
                        ))}

                      {boxData.cloudPath &&
                        boxData.cloudPath.length > 0 &&
                        boxData.cloudPath.map((item, index) => (
                          <Xarrow
                            key={`${jobId}-${item}`}
                            start={getJobRef("main-job-box")}
                            end={getJobRef(`${item}1`)}
                            className="arrow visible"
                            strokeWidth={2}
                            startAnchor="right"
                            endAnchor="left"
                          />
                        ))}

                      {boxData.DBFS &&
                        boxData.DBFS.length > 0 &&
                        boxData.DBFS.map((item, index) => (
                          <Xarrow
                            key={`${jobId}-${item}`}
                            start={getJobRef("main-job-box")}
                            end={getJobRef(`${item}1`)}
                            className="arrow visible"
                            strokeWidth={2}
                            startAnchor="right"
                            endAnchor="left"
                          />
                        ))}

                      {boxData.mountPoint &&
                        boxData.mountPoint.length > 0 &&
                        boxData.mountPoint.map((item, index) => (
                          <Xarrow
                            key={`${jobId}-${item}`}
                            start={getJobRef("main-job-box")}
                            end={getJobRef(`${item}1`)}
                            className="arrow visible"
                            strokeWidth={2}
                            startAnchor="right"
                            endAnchor="left"
                          />
                        ))}

                      {boxData.notebook &&
                        boxData.notebook.length > 0 &&
                        boxData.notebook.length <= 5 &&
                        boxData.notebook.map((item, index) => (
                          <Xarrow
                            key={`${jobId}-${item}`}
                            start={getJobRef("main-job-box")}
                            end={getJobRef(`${item}1`)}
                            className="arrow visible"
                            strokeWidth={2}
                            startAnchor="right"
                            endAnchor="left"
                          />
                        ))}
                      {boxData.notebook &&
                        boxData.notebook.length > 0 &&
                        boxData.notebook.length > 5 &&
                        boxData.notebook.map((item, index) => (
                          <Xarrow
                            key={`${jobId}-${item}`}
                            start={getJobRef("main-job-box")}
                            end={getJobRef(`1Notebook`)}
                            className="arrow visible"
                            strokeWidth={2}
                            startAnchor="right"
                            endAnchor="left"
                          />
                        ))}

                      {/* Arrows from 2nd level job id boxes to 2nd level entity boxes*/}
                      {relatedData.table &&
                        relatedData.table.length > 0 &&
                        showFirstAdditionalBoxes &&
                        relatedData.table.map((item, index) => (
                          <Xarrow
                            key={`${jobId}-${item}`}
                            start={getJobRef(selectedJobId)}
                            end={getJobRef(`${item}2`)}
                            className="arrow visible"
                            strokeWidth={2}
                            startAnchor="right"
                            endAnchor="left"
                          />
                        ))}
                      {relatedData.notebook &&
                        relatedData.notebook.length > 0 &&
                        showFirstAdditionalBoxes &&
                        relatedData.notebook.length <= 5 &&
                        relatedData.notebook.map((item, index) => (
                          <Xarrow
                            key={`${jobId}-${item}`}
                            start={getJobRef(selectedJobId)}
                            end={getJobRef(`${item}2`)}
                            className="arrow visible"
                            strokeWidth={2}
                            startAnchor="right"
                            endAnchor="left"
                          />
                        ))}
                      {relatedData.notebook &&
                        showFirstAdditionalBoxes &&
                        relatedData.notebook.length > 0 &&
                        relatedData.notebook.length > 5 &&
                        relatedData.notebook.map((item, index) => (
                          <Xarrow
                            key={`${jobId}-${item}`}
                            start={getJobRef(selectedJobId)}
                            end={getJobRef(`Notebook1`)}
                            className="arrow visible"
                            strokeWidth={2}
                            startAnchor="right"
                            endAnchor="left"
                          />
                        ))}
                      {relatedData.cluster &&
                        relatedData.cluster.length > 0 &&
                        showFirstAdditionalBoxes &&
                        relatedData.cluster.map((item, index) => (
                          <Xarrow
                            key={`${jobId}-${item}`}
                            start={getJobRef(selectedJobId)}
                            end={getJobRef(`${item}2`)}
                            className="arrow visible"
                            strokeWidth={2}
                            startAnchor="right"
                            endAnchor="left"
                          />
                        ))}
                      {relatedData.DBFS &&
                        relatedData.DBFS.length > 0 &&
                        showFirstAdditionalBoxes &&
                        relatedData.DBFS.map((item, index) => (
                          <Xarrow
                            key={`${jobId}-${item}`}
                            start={getJobRef(selectedJobId)}
                            end={getJobRef(`${item}2`)}
                            className="arrow visible"
                            strokeWidth={2}
                            startAnchor="right"
                            endAnchor="left"
                          />
                        ))}
                      {relatedData.cloudPath &&
                        showFirstAdditionalBoxes &&
                        relatedData.cloudPath.length > 0 &&
                        relatedData.cloudPath.map((item, index) => (
                          <Xarrow
                            key={`${jobId}-${item}`}
                            start={getJobRef(selectedJobId)}
                            end={getJobRef(`${item}2`)}
                            className="arrow visible"
                            strokeWidth={2}
                            startAnchor="right"
                            endAnchor="left"
                          />
                        ))}
                      {relatedData.mountPoint &&
                        showFirstAdditionalBoxes &&
                        relatedData.mountPoint.length > 0 &&
                        relatedData.mountPoint.map((item, index) => (
                          <Xarrow
                            key={`${jobId}-${item}`}
                            start={getJobRef(selectedJobId)}
                            end={getJobRef(`${item}2`)}
                            className="arrow visible"
                            strokeWidth={2}
                            startAnchor="right"
                            endAnchor="left"
                          />
                        ))}
                    </div>
                  )}

                  {/* Level 1 Entity Boxes*/}
                  <div className="spacer"></div>
                  {showAdditionalBoxes && (
                    <div className="related-job-boxes">
                      {boxData.table &&
                        boxData.table.length > 0 &&
                        boxData.table.map((item, index) => (
                          <div
                            key={`table-${index}`}
                            className="job-box right category"
                            id={getJobRef(`${item}1`)}
                            onClick={() =>
                              handleFIrstRelatedJobBoxClick(
                                idd,
                                item,
                                "table",
                                item
                              )
                            }
                          >
                            <div className="entityjob-box-header">
                              <img
                                src={tableSvg}
                                alt="Table Icon"
                                width="15"
                                height="15"
                                className="iconbutton"
                              />
                              <Typography variant="h8">Table</Typography>
                            </div>
                            <p className="truncate-text">{item}</p>
                          </div>
                        ))}
                      {boxData.cluster &&
                        boxData.cluster.length > 0 &&
                        boxData.cluster.map((item, index) => (
                          <div
                            key={`cluster-${index}`}
                            className="job-box right category"
                            id={getJobRef(`${item}1`)}
                            onClick={() =>
                              handleFIrstRelatedJobBoxClick(
                                idd,
                                item,
                                "cluster",
                                item
                              )
                            }
                          >
                            <div className="entityjob-box-header">
                              <img
                                src={clustericon}
                                alt="Cluster Icon"
                                width="16"
                                height="16"
                                className="iconbutton"
                              />
                              <Typography variant="h8">Cluster</Typography>
                            </div>
                            <p className="truncate-text">{item}</p>
                          </div>
                        ))}

                      {boxData.notebook &&
                        boxData.notebook.length > 0 &&
                        boxData.notebook.length <= 5 &&
                        boxData.notebook.map((item, index) => (
                          <div
                            key={`notebook-${index}`}
                            className="job-box right category"
                            id={getJobRef(`${item}1`)}
                            onClick={() =>
                              handleFIrstRelatedJobBoxClick(
                                idd,
                                item,
                                "notebook",
                                item
                              )
                            }
                          >
                            <div className="entityjob-box-header">
                              <img
                                src={notbookIcon}
                                alt="notebook Icon"
                                width="16"
                                height="16"
                                className="iconbutton"
                              />
                              <Typography variant="h8">Notebook</Typography>
                            </div>
                            <p className="truncate-text" title={item}>
                              {getName(item)}
                            </p>
                          </div>
                        ))}
                      {boxData.notebook &&
                        boxData.notebook.length > 0 &&
                        boxData.notebook.length > 5 && (
                          <div
                            className="job-box right category"
                            id={getJobRef(`1Notebook`)}
                          >
                            <div className="entityjob-box-header">
                              <img
                                src={notbookIcon}
                                alt="notebook Icon"
                                width="16"
                                height="16"
                                className="iconbutton"
                              />
                              <Typography variant="h8">Notebook</Typography>
                            </div>
                            {boxData.notebook.map((item, index) => (
                              <>
                                <p
                                  className="truncate-text ptext"
                                  title={item}
                                  onClick={() =>
                                    handleFIrstRelatedJobBoxClick(
                                      idd,
                                      item,
                                      "notebook",
                                      "1Notebook"
                                    )
                                  }
                                >
                                  {getName(item)} <br></br>
                                </p>
                              </>
                            ))}
                          </div>
                        )}

                      {boxData.DBFS &&
                        boxData.DBFS.length > 0 &&
                        boxData.DBFS.map((item, index) => (
                          <div
                            key={`DBFS-${index}`}
                            className="job-box right category"
                            id={getJobRef(`${item}1`)}
                            onClick={() =>
                              handleFIrstRelatedJobBoxClick(
                                idd,
                                item,
                                "datapath",
                                item
                              )
                            }
                          >
                            <div className="entityjob-box-header">
                              <img
                                src={dbfsIcon}
                                alt="notebook Icon"
                                width="16"
                                height="16"
                                className="iconbutton"
                              />
                              <Typography variant="h8">DBFS</Typography>
                            </div>
                            <p className="truncate-text" title={item}>
                              {item}
                            </p>
                          </div>
                        ))}
                      {boxData.cloudPath &&
                        boxData.cloudPath.length > 0 &&
                        boxData.cloudPath.map((item, index) => (
                          <div
                            key={`cloudPath-${index}`}
                            className="job-box right category"
                            id={getJobRef(`${item}1`)}
                            onClick={() =>
                              handleFIrstRelatedJobBoxClick(
                                idd,
                                item,
                                "datapath",
                                item
                              )
                            }
                          >
                            <div className="entityjob-box-header">
                              <img
                                src={cloudicon}
                                alt="notebook Icon"
                                width="16"
                                height="16"
                                className="iconbutton"
                              />
                              <Typography variant="h8">Cloud Path</Typography>
                            </div>
                            <p className="truncate-text" title={item}>
                              {item}
                            </p>{" "}
                          </div>
                        ))}
                      {boxData.mountPoint &&
                        boxData.mountPoint.length > 0 &&
                        boxData.mountPoint.map((item, index) => (
                          <div
                            key={`mountPoint-${index}`}
                            className="job-box right category"
                            id={getJobRef(`${item}1`)}
                            onClick={() =>
                              handleFIrstRelatedJobBoxClick(
                                idd,
                                item,
                                "datapath",
                                item
                              )
                            }
                          >
                            <div className="entityjob-box-header">
                              <img
                                src={mountIcon}
                                alt="notebook Icon"
                                width="16"
                                height="16"
                                className="iconbutton"
                              />
                              <Typography variant="h8">Mount point</Typography>
                            </div>
                            <p className="truncate-text" title={item}>
                              {item}
                            </p>{" "}
                          </div>
                        ))}
                    </div>
                  )}

                  {/* Level 2 job Id Boxes*/}
                  <div className="spacer"></div>

                  {Object.keys(relatedJobs)?.length > 0 &&
                    showSecondJobBoxes && (
                      <div className="related-job-boxes">
                        {Object.entries(relatedJobs).map(([key, value]) => {
                          return (
                            <div
                              key={key}
                              className={`job-box related ${
                                expandedJobBoxes[value.id] ? "expanded" : ""
                              }`}
                              id={getJobRef(`${value.id}2`)}
                            >
                              <div
                                className="job-box-header"
                                onClick={() =>
                                  handleRelatedJobBoxClick1(
                                    value.id,
                                    "",
                                    value.id
                                  )
                                }
                              >
                                <div>
                                  <WorkIcon className="iconMui" />
                                </div>
                                <div>
                                  <div
                                    className="truncate-text"
                                    title={value.name}
                                  >
                                    <b>{value.name}</b>
                                  </div>
                                  <div>
                                    <b>{value.id}</b>
                                  </div>
                                </div>
                              </div>
                              {expandedJobBoxes[value.id] && (
                                <div className="job-box-details">
                                  <div
                                    className="category"
                                    onClick={() =>
                                      handleDataTypeClick(
                                        "table",
                                        value.id,
                                        value.name,
                                        relatedData
                                      )
                                    }
                                  >
                                    <img
                                      src={tableSvg}
                                      alt="Table Icon"
                                      width="18"
                                      height="18"
                                      className="iconbutton"
                                    />
                                    <p>Tables ({relatedData?.table?.length})</p>
                                  </div>
                                  <div
                                    className="category"
                                    onClick={() =>
                                      handleDataTypeClick(
                                        "cluster",
                                        value.id,
                                        value.name,
                                        relatedData
                                      )
                                    }
                                  >
                                    <img
                                      src={clustericon}
                                      alt="Table Icon"
                                      width="18"
                                      height="18"
                                      className="iconbutton"
                                    />
                                    <p>
                                      Clusters ({relatedData?.cluster?.length})
                                    </p>
                                  </div>
                                  <div
                                    className="category"
                                    onClick={() =>
                                      handleDataTypeClick(
                                        "notebook",
                                        value.id,
                                        value.name,
                                        relatedData
                                      )
                                    }
                                  >
                                    <img
                                      src={notbookIcon}
                                      alt="Table Icon"
                                      width="18"
                                      height="18"
                                      className="iconbutton"
                                    />
                                    <p>
                                      Notebook ({relatedData?.notebook?.length}){" "}
                                    </p>
                                  </div>
                                  <div
                                    className="category"
                                    onClick={() =>
                                      handleDataTypeClick(
                                        "DBFS",
                                        value.id,
                                        value.name,
                                        relatedData
                                      )
                                    }
                                  >
                                    <img
                                      src={dbfsIcon}
                                      alt="Table Icon"
                                      width="18"
                                      height="18"
                                      className="iconbutton"
                                    />
                                    <p>DBFS ({relatedData?.DBFS?.length})</p>
                                  </div>
                                  <div
                                    className="category"
                                    onClick={() =>
                                      handleDataTypeClick(
                                        "cloudPath",
                                        value.id,
                                        value.name,
                                        relatedData
                                      )
                                    }
                                  >
                                    <img
                                      src={cloudicon}
                                      alt="Table Icon"
                                      width="18"
                                      height="18"
                                      className="iconbutton"
                                    />
                                    <p>
                                      Cloud Path (
                                      {relatedData?.cloudPath?.length})
                                    </p>
                                  </div>
                                  <div
                                    className="category"
                                    onClick={() =>
                                      handleDataTypeClick(
                                        "mountPoint",
                                        value.id,
                                        value.name,
                                        relatedData
                                      )
                                    }
                                  >
                                    <img
                                      src={mountIcon}
                                      alt="Table Icon"
                                      width="18"
                                      height="18"
                                      className="iconbutton"
                                    />
                                    <p>
                                      Mount Point (
                                      {relatedData?.mountPoint?.length})
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}

                  {/* Level 2 Entity Boxes*/}
                  <div className="spacer"></div>
                  {showFirstAdditionalBoxes && (
                    <div className="related-job-boxes">
                      {relatedData.table &&
                        relatedData.table.length > 0 &&
                        relatedData.table.map((item, index) => (
                          <div
                            key={`table-${index}`}
                            className="job-box right category"
                            id={getJobRef(`${item}2`)}
                            onClick={() =>
                              handleRelatedJobBoxClick(
                                relatedidd,
                                item,
                                "table",
                                item
                              )
                            }
                          >
                            <div className="entityjob-box-header">
                              <img
                                src={tableSvg}
                                alt="Table Icon"
                                width="16"
                                height="16"
                                className="iconbutton"
                              />
                              <Typography variant="h8">Table </Typography>
                            </div>
                            <p className="truncate-text" title={item}>
                              {item}
                            </p>
                          </div>
                        ))}
                      {relatedData.cluster &&
                        relatedData.cluster.length > 0 &&
                        relatedData.cluster.map((item, index) => (
                          <div
                            key={`cluster-${index}`}
                            className="job-box right category"
                            id={getJobRef(`${item}2`)}
                            onClick={() =>
                              handleRelatedJobBoxClick(
                                relatedidd,
                                item,
                                "cluster",
                                item
                              )
                            }
                          >
                            <div className="entityjob-box-header">
                              <img
                                src={clustericon}
                                alt="Cluster Icon"
                                width="16"
                                height="16"
                                className="iconbutton"
                              />
                              <Typography variant="h8">Cluster</Typography>
                            </div>
                            <p className="truncate-text" title={item}>
                              {item}
                            </p>
                          </div>
                        ))}
                      {relatedData.notebook &&
                        relatedData.notebook.length > 0 &&
                        relatedData.notebook.length <= 5 &&
                        relatedData.notebook.map((item, index) => (
                          <div
                            key={`notebook-${index}`}
                            className="job-box right category"
                            id={getJobRef(`${item}2`)}
                            onClick={() =>
                              handleRelatedJobBoxClick(
                                relatedidd,
                                item,
                                "notebook",
                                item
                              )
                            }
                          >
                            <div className="entityjob-box-header">
                              <img
                                src={notbookIcon}
                                alt="notebook Icon"
                                width="16"
                                height="16"
                                className="iconbutton"
                              />
                              <Typography variant="h8">Notebook</Typography>
                            </div>
                            <p className="truncate-text" title={item}>
                              {getName(item)}
                            </p>
                          </div>
                        ))}
                      {relatedData.notebook &&
                        relatedData.notebook.length > 0 &&
                        relatedData.notebook.length > 5 && (
                          <div
                            className="job-box right category"
                            id={getJobRef(`Notebook1`)}
                          >
                            <div className="entityjob-box-header">
                              <img
                                src={notbookIcon}
                                alt="notebook Icon"
                                width="16"
                                height="16"
                                className="iconbutton"
                              />
                              <Typography variant="h8">Notebook</Typography>
                            </div>
                            {relatedData.notebook.map((item, index) => (
                              <>
                                <p className="truncate-text ptext" title={item}>
                                  {getName(item)} <br></br>
                                </p>
                              </>
                            ))}
                          </div>
                        )}

                      {relatedData.DBFS &&
                        relatedData.DBFS.length > 0 &&
                        relatedData.DBFS.map((item, index) => (
                          <div
                            key={`DBFS-${index}`}
                            className="job-box right category"
                            id={getJobRef(`${item}2`)}
                            onClick={() =>
                              handleRelatedJobBoxClick(
                                relatedidd,
                                item,
                                "datapath",
                                item
                              )
                            }
                          >
                            <div className="entityjob-box-header">
                              <img
                                src={dbfsIcon}
                                alt="notebook Icon"
                                width="16"
                                height="16"
                                className="iconbutton"
                              />
                              <Typography variant="h8">DBFS</Typography>
                            </div>
                            <p className="truncate-text" title={item}>
                              {item}
                            </p>{" "}
                          </div>
                        ))}
                      {relatedData.cloudPath &&
                        relatedData.cloudPath.length > 0 &&
                        relatedData.cloudPath.map((item, index) => (
                          <div
                            key={`cloudPath-${index}`}
                            className="job-box right category"
                            id={getJobRef(`${item}2`)}
                            onClick={() =>
                              handleRelatedJobBoxClick(
                                relatedidd,
                                item,
                                "datapath",
                                item
                              )
                            }
                          >
                            <div className="entityjob-box-header">
                              <img
                                src={cloudicon}
                                alt="notebook Icon"
                                width="16"
                                height="16"
                                className="iconbutton"
                              />
                              <Typography variant="h8">Cloud Path</Typography>
                            </div>
                            <p className="truncate-text" title={item}>
                              {item}
                            </p>{" "}
                          </div>
                        ))}
                      {relatedData.mountPoint &&
                        relatedData.mountPoint.length > 0 &&
                        relatedData.mountPoint.map((item, index) => (
                          <div
                            key={`mountPoint-${index}`}
                            className="job-box right category"
                            id={getJobRef(`${item}2`)}
                            onClick={() =>
                              handleRelatedJobBoxClick(
                                relatedidd,
                                item,
                                "datapath",
                                item
                              )
                            }
                          >
                            <div className="entityjob-box-header">
                              <img
                                src={mountIcon}
                                alt="notebook Icon"
                                width="16"
                                height="16"
                                className="iconbutton"
                              />
                              <Typography variant="h8">Mount point</Typography>
                            </div>
                            <p className="truncate-text" title={item}>
                              {item}
                            </p>{" "}
                          </div>
                        ))}
                    </div>
                  )}

                  {/* Level 3 job Id Boxes*/}

                  <div className="spacer"></div>
                  {Object.keys(relatedJobss)?.length > 0 &&
                    showThirdJobBoxes && (
                      <div className="related-job-boxes">
                        {Object.entries(relatedJobss).map(([key, value]) => {
                          return (
                            <div
                              key={key}
                              className={`job-box related ${
                                expandedJobBoxes1[value.id] ? "expanded" : ""
                              }`}
                              id={getJobRef(`${value.id}3`)}
                            >
                              <div
                                className="job-box-header"
                                onClick={() =>
                                  handleRelatedJobBoxClick3(
                                    value.id,
                                    "",
                                    value.id
                                  )
                                }
                              >
                                <div>
                                  <WorkIcon className="iconMui" />
                                </div>
                                <div>
                                  <div
                                    className={"truncate-text"}
                                    title={value.name}
                                  >
                                    <b>{value.name}</b>
                                  </div>
                                  <div>
                                    <b>{value.id}</b>
                                  </div>
                                </div>
                              </div>
                              {expandedJobBoxes1[value.id] && (
                                <div className="job-box-details">
                                  <div
                                    className="category"
                                    onClick={() =>
                                      handleDataTypeClick(
                                        "table",
                                        value.id,
                                        value.name,
                                        relatedDataa
                                      )
                                    }
                                  >
                                    <img
                                      src={tableSvg}
                                      alt="Table Icon"
                                      width="18"
                                      height="18"
                                      className="iconbutton"
                                    />
                                    <p>
                                      Tables ({relatedDataa?.table?.length})
                                    </p>
                                  </div>
                                  <div
                                    className="category"
                                    onClick={() =>
                                      handleDataTypeClick(
                                        "cluster",
                                        value.id,
                                        value.name,
                                        relatedDataa
                                      )
                                    }
                                  >
                                    <img
                                      src={clustericon}
                                      alt="Table Icon"
                                      width="18"
                                      height="18"
                                      className="iconbutton"
                                    />
                                    <p>
                                      Clusters ({relatedDataa?.cluster?.length})
                                    </p>
                                  </div>
                                  <div
                                    className="category"
                                    onClick={() =>
                                      handleDataTypeClick(
                                        "notebook",
                                        value.id,
                                        value.name,
                                        relatedDataa
                                      )
                                    }
                                  >
                                    <img
                                      src={notbookIcon}
                                      alt="Table Icon"
                                      width="18"
                                      height="18"
                                      className="iconbutton"
                                    />
                                    <p>
                                      Notebook ({relatedDataa?.notebook?.length}
                                      ){" "}
                                    </p>
                                  </div>
                                  <div
                                    className="category"
                                    onClick={() =>
                                      handleDataTypeClick(
                                        "DBFS",
                                        value.id,
                                        value.name,
                                        relatedDataa
                                      )
                                    }
                                  >
                                    <img
                                      src={dbfsIcon}
                                      alt="Table Icon"
                                      width="18"
                                      height="18"
                                      className="iconbutton"
                                    />
                                    <p>DBFS ({relatedDataa?.DBFS?.length})</p>
                                  </div>
                                  <div
                                    className="category"
                                    onClick={() =>
                                      handleDataTypeClick(
                                        "cloudPath",
                                        value.id,
                                        value.name,
                                        relatedDataa
                                      )
                                    }
                                  >
                                    <img
                                      src={cloudicon}
                                      alt="Table Icon"
                                      width="18"
                                      height="18"
                                      className="iconbutton"
                                    />
                                    <p>
                                      Cloud Path (
                                      {relatedDataa?.cloudPath?.length})
                                    </p>
                                  </div>
                                  <div
                                    className="category"
                                    onClick={() =>
                                      handleDataTypeClick(
                                        "mountPoint",
                                        value.id,
                                        value.name,
                                        relatedDataa
                                      )
                                    }
                                  >
                                    <img
                                      src={mountIcon}
                                      alt="Table Icon"
                                      width="18"
                                      height="18"
                                      className="iconbutton"
                                    />
                                    <p>
                                      Mount Point (
                                      {relatedDataa?.mountPoint?.length})
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                </div>
              </div>
            </DraggableGrid>
          </Grid>
          <Grid item lg={expandGrid() ? 3 : 0}>
            {expandGrid() && (
              <div className="right-panel">
                {selectedJobData && (
                  <div className="panel-header">
                    <div>
                      <div
                        className="truncate-text-18"
                        title={rightPanelJobName}
                      >
                        Job Name : <b>{rightPanelJobName}</b>
                      </div>
                      <div>
                        Job Id : <b>{rightPanelJobID}</b>
                      </div>
                    </div>
                    <CloseRoundedIcon onClick={closeRightPanel} />
                  </div>
                )}
                <div className="panel-body">
                  <div className="icons-container">
                    <div
                      className={`icon ${
                        selectedDataType === "table" ? "common-class" : ""
                      }`}
                      onClick={() =>
                        handleDataTypeClick(
                          "table",
                          rightPanelJobID,
                          rightPanelJobName,
                          rightPanelData
                        )
                      }
                    >
                      <img
                        src={tableSvg}
                        alt="Table Icon"
                        width="60"
                        height="37"
                        className="iconbutton"
                      />
                    </div>
                    <div
                      className={`icon ${
                        selectedDataType === "cluster" ? "common-class" : ""
                      }`}
                      onClick={() =>
                        handleDataTypeClick(
                          "cluster",
                          rightPanelJobID,
                          rightPanelJobName,
                          rightPanelData
                        )
                      }
                    >
                      <img
                        src={clustericon}
                        alt="Cluster Icon"
                        width="60"
                        height="37"
                        className="iconbutton"
                      />
                    </div>
                    <div
                      className={`icon ${
                        selectedDataType === "notebook" ? "common-class" : ""
                      }`}
                      onClick={() =>
                        handleDataTypeClick(
                          "notebook",
                          rightPanelJobID,
                          rightPanelJobName,
                          rightPanelData
                        )
                      }
                    >
                      <img
                        src={notbookIcon}
                        alt="Notebook Icon"
                        width="60"
                        height="37"
                        className="iconbutton"
                      />
                    </div>
                    <div
                      className={`icon ${
                        selectedDataType === "DBFS" ? "common-class" : ""
                      }`}
                      onClick={() =>
                        handleDataTypeClick(
                          "DBFS",
                          rightPanelJobID,
                          rightPanelJobName,
                          rightPanelData
                        )
                      }
                    >
                      <img
                        src={dbfsIcon}
                        alt="DBFS icon"
                        width="60"
                        height="37"
                        className="iconbutton"
                      />
                    </div>

                    <div
                      className={`icon ${
                        selectedDataType === "cloud path" ? "common-class" : ""
                      }`}
                      onClick={() =>
                        handleDataTypeClick(
                          "cloudPath",
                          rightPanelJobID,
                          rightPanelJobName,
                          rightPanelData
                        )
                      }
                    >
                      <img
                        src={cloudicon}
                        alt="cloud path icon"
                        width="60"
                        height="37"
                        className="iconbutton"
                      />
                    </div>
                    <div
                      className={`icon ${
                        selectedDataType === "mount point" ? "common-class" : ""
                      }`}
                      onClick={() =>
                        handleDataTypeClick(
                          "mountPoint",
                          rightPanelJobID,
                          rightPanelJobName,
                          rightPanelData
                        )
                      }
                    >
                      <img
                        src={mountIcon}
                        alt="mount point icon"
                        width="18"
                        height="18"
                        className="iconbutton"
                      />
                    </div>
                  </div>
                  {selectedDataType &&
                    renderTableData(
                      selectedDataType,
                      rightPanelJobID,
                      rightPanelData
                    )}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </Xwrapper>
  );
};

export default LineageGraph;
