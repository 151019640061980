import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import constants from "../../utils/constant";
import classes from "../../Components/Summary/WorkspaceSummary.module.css";
import EllipsisTooltip from "../UI/Tooltip/Tooltip";
const StyledTableCell = styled(TableCell)({
  borderLeft: "none",
  borderRight: "none",
  height: "3.75rem",
  paddingTop: "10px",
  paddingBottom: "10px",
});

function ValidationResultAutoloader(props) {
  const { result } = props;
  const data = JSON.parse(result);

  return (
    <Paper className={classes["paper"]}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow className={classes["table-row-height"]}>
              {constants.AutoloaderValidationHeader.map((head) => (
                <TableCell className={classes["table-heading"]} key={head}>
                  {head}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((data) => (
              <TableRow key={""}>
                <StyledTableCell>
                  <div className={classes["ellipses-width"]}>
                    <EllipsisTooltip>
                      {data?.schema_path_in_dbfs}
                    </EllipsisTooltip>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className={classes["ellipses-width"]}>
                    <EllipsisTooltip>
                      {data?.checkpoint_path_in_dbfs}
                    </EllipsisTooltip>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className={classes["ellipses-width"]}>
                    <EllipsisTooltip>
                      {data?.external_target_checkpoint}
                    </EllipsisTooltip>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className={classes["ellipses-width"]}>
                    <EllipsisTooltip>
                      {data?.external_target_schema}
                    </EllipsisTooltip>
                  </div>
                </StyledTableCell>
                <StyledTableCell>{data?.status}</StyledTableCell>
                <StyledTableCell>
                  <div className={classes["ellipses-width"]}>
                    <EllipsisTooltip> {data?.description}</EllipsisTooltip>
                  </div>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default ValidationResultAutoloader;
