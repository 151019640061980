import { FormControl, RadioGroup } from "@mui/material";
import jsonData from "../../Assets/i18n/en.json";
import styles from "../NewComponent.module.css";
import StyledRadio from "../UI/StyledRadioButton/StyledRadio";
import { useEffect, useState } from "react";
import AcrossWorkspaceInfo from "../Common/AccrossWorkspaceInfo.jsx/AcrossWorkspaceInfo";

function CloneCluster(props) {
  const {
    setCloneType,
    setExternalWsToken,
    setExternalWsUrl,
    setDisableNext,
    cloneType,
  } = props;
  const [cloneTypeset, setCloneTypeset] = useState(cloneType);

  const cloneTypeHandler = (event) => {
    const val = event.target.value;
    setCloneType(val);
    setCloneTypeset(val);
  };

  const enableNext = () => {
    if (cloneTypeset === jsonData.acrossValue) {
      setDisableNext(true);
    } else setDisableNext(false);
  };

  useEffect(() => {
    enableNext();
  }, [cloneTypeset]);

  return (
    <>
      <div className={styles["margin-top-20"]} />

      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={cloneTypeset}
          onChange={cloneTypeHandler}
        >
          <div className={`${styles["margin-right-16"]}`}>
            <div className={`${styles["margin-left-16 "]} ${styles["icon"]}`}>
              <StyledRadio
                label={jsonData.withInLabel}
                value={jsonData.withInValue}
              />
              <div className={`${styles["margin-left-16 "]} ${styles["icon"]}`}>
                <StyledRadio
                  label={jsonData.acrossLabel}
                  value={jsonData.acrossValue}
                />
              </div>
            </div>
          </div>
        </RadioGroup>
      </FormControl>
      {cloneTypeset === jsonData.acrossValue && (
        <AcrossWorkspaceInfo
          setDisableNext={setDisableNext}
          setExternalWsToken={setExternalWsToken}
          setExternalWsUrl={setExternalWsUrl}
        />
      )}
    </>
  );
}

export default CloneCluster;
