import React, { useState, useEffect } from "react";
import { getCall } from "../../Resources/apicalls";
import {
  tableRealTimeMigrationApi,
  tableValidationApi,
  ViewRealTimeMigrationApi,
} from "../../Resources/apiurls";
import { useSelector } from "react-redux";
import classes from "./WorkspaceSummary.module.css";
import jsonData from "../../Assets/i18n/en.json";
import constants from "../../utils/constant";
import {
  Box,
  Button,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StringToList } from "../../utils/ConversionFunctions/ConversionFunctions";
import EllipsisTooltip from "../UI/Tooltip/Tooltip";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import RenderTableValidationRecord from "./RenderTableValidationRecord";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const options = [
  { label: "Refresh", action: "refresh" },
  { label: "Download", action: "download" }
];
const ITEM_HEIGHT = 48;

export default function TableRealTime(props) {
  const { triggerId, inputParameters, status, entity } = props;
  const workspaceId = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]?.workspace_id
  );
  const [tracklist, setTracklist] = useState([]);
  const [validationList, setValidationList] = useState([]);

  const inputParametersList = (inputParameters) => {
    let inputJson = JSON.parse(inputParameters)?.data.databasesAndCatalog;
    let inputPara = [];
    if (entity === "UC_TABLES_MIGRATION")
      inputPara = StringToList(inputJson?.tableToMigrate);
    else inputPara = StringToList(inputJson?.viewToMigrate);
    return inputPara;
  };
  const inputlist = inputParametersList(inputParameters);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const realtimeTablViewMigration = async () => {
    let api = `${
      entity === "UC_TABLES_MIGRATION"
        ? tableRealTimeMigrationApi
        : ViewRealTimeMigrationApi
    }triggerID=${triggerId}&workspaceIdentifier=${workspaceId}`;
    let response = await getCall(api);
    setTracklist(response.ucxApiResponse);
  };

  const realtimeTablViewValidation = async () => {
    let api = `${
      entity === "UC_TABLES_MIGRATION" ? tableValidationApi : tableValidationApi
    }triggerID=${triggerId}&workspaceIdentifier=${workspaceId}`;
    let response = await getCall(api);
    setValidationList(response.ucxApiResponse);
  };

  useEffect(() => {
    if (status === "IN_PROGRESS") {
      realtimeTablViewMigration();
      realtimeTablViewValidation();
      const timeout = setInterval(() => {
        realtimeTablViewMigration();
        realtimeTablViewValidation();
      }, 25000);
      return () => {
        clearInterval(timeout);
      };
    } else {
      realtimeTablViewMigration();
      realtimeTablViewValidation();
    }
  }, []);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };
  const [expandedRow, setExpandedRow] = useState(null);

  const handleRowExpand = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handleMenuOptionClick = (action) => {
    if (action === "refresh") {
      realtimeTablViewValidation();
    } else if (action === "download") {
      downloadCSV();
    }
    handleClose(); // Close the menu after an option is clicked
  };

  const downloadCSV = () => {
    const csvRows = [];
    
    // Create header row
    csvRows.push(constants.tableTrackingHeader.join(","));

    // Create data rows
    inputlist.forEach((item, index) => {
      const outputItem = tracklist.find(
        (output) =>
          (entity === "UC_TABLES_MIGRATION"
            ? output.source_table_name
            : output.source_view_name) === item.split(".").pop()
      );
      const row = [
        index + 1,
        item,
        outputItem ? outputItem.source_type : "-",
        outputItem ? (entity === "UC_TABLES_MIGRATION" ? outputItem?.fully_qualified_table_name : outputItem?.fully_qualified_view_name) : "-",
        outputItem ? outputItem.status_code : jsonData.inProgressCapsTxt,
        outputItem ? outputItem.description : "-",
      ];
      csvRows.push(row.join(","));
    });

    // Create a blob and download
    const blob = new Blob([csvRows.join("\n")], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'data.csv');
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <TableContainer component={Paper} className={classes["table-container"]}>
      <div style={{ display: "flex", justifyContent: "right", gap: "5px" }}>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 'auto',
              },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.action} onClick={() => handleMenuOptionClick(option.action)}>
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            {constants.tableTrackingHeader.map((item, index) => {
              return (
                <TableCell key={index + 1} className={classes["table-heading"]}>
                  {item}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {inputlist.map((item, index) => {
            const outputItem = tracklist.find(
              (output) =>
                (entity === "UC_TABLES_MIGRATION"
                  ? output.source_table_name
                  : output.source_view_name) === item.split(".").pop()
            );
            return (
              <>
                <TableRow key={index + 1}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item}</TableCell>
                  {outputItem ? (
                    <>
                      <TableCell>{outputItem.source_type}</TableCell>
                      <TableCell>
                        {entity === "UC_TABLES_MIGRATION"
                          ? outputItem?.fully_qualified_table_name
                          : outputItem?.fully_qualified_view_name}
                      </TableCell>
                      <TableCell>{outputItem.status_code}</TableCell>
                      <TableCell>
                        <div
                          className={`${classes["ellipses-width-18"]} ${classes["flex-center"]}`}
                        >
                          <EllipsisTooltip>
                            {outputItem.description}
                          </EllipsisTooltip>
                          {outputItem.description.includes("error") && (
                            <Button
                              title="Copy"
                              onClick={() => handleCopy(outputItem.description)}
                              style={{ cursor: "pointer", marginLeft: "8px" }}
                            >
                              <ContentCopyOutlinedIcon />
                            </Button>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => handleRowExpand(index)}
                        >
                          {expandedRow === index ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>{jsonData.inProgressCapsTxt}</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                    </>
                  )}
                </TableRow>
                {entity === "UC_TABLES_MIGRATION" && (
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={7}
                    >
                      <Collapse
                        in={expandedRow === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box sx={{ margin: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            Validation Summary
                          </Typography>
                          <TableContainer component={Paper}>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow
                                  className={classes["table-row-height"]}
                                >
                                  {constants.SummaryValidationHeader.map(
                                    (head) => (
                                      <TableCell
                                        className={classes["table-heading"]}
                                        key={head}
                                      >
                                        {head}
                                      </TableCell>
                                    )
                                  )}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {validationList.length > 0 ? (
                                  <RenderTableValidationRecord
                                    data={validationList.find(
                                      (table) =>
                                        `${table.source_schema}.${table.source_name}` ===
                                        `${item}`
                                    )}
                                  />
                                ) : (
                                  [...Array(9)].map((_, index) => (
                                    <TableCell key={index + 1}>NA</TableCell>
                                  ))
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
