import React from "react";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import styles from "./Pagination.module.css";
import jsonData from "../../../Assets/i18n/en.json";

const maxButtons = 5;

const Pagination = ({ totalPages, currentPage, onPageChange }) => {

  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const getPaginationButtons = () => {
    const half = Math.floor(maxButtons / 2);
    let start = Math.max(1, currentPage - half);
    let end = Math.min(totalPages, currentPage + half);

    if (currentPage <= half) {
      end = Math.min(totalPages, maxButtons);
    }

    if (currentPage + half >= totalPages) {
      start = Math.max(1, totalPages - maxButtons + 1);
    }

    return Array.from({ length: end - start + 1 }, (_, index) => start + index);
  };

  const paginationButtons = getPaginationButtons();

  return (
    <div className={styles["pagination-container"]}>
      <ul className={styles.page}>
        <li
          className={`${styles.pageBtn} ${currentPage !== 1 ? styles.active : ""}`}
          onClick={handlePrevPage}
        >
          <IconButton onClick={handlePrevPage} disabled={currentPage === 1}>
            <KeyboardArrowLeftIcon />
            <span 
              className={`${styles.font} ${currentPage === 1 ? styles.disabledBtn : ""}`}
              >{jsonData.prevBtn}</span>
          </IconButton>
        </li>

        {currentPage > 5 && (
          <>
            <li className={styles.pageNumbers} onClick={() => onPageChange(1)}>
              1
            </li>
            <li className={styles.pageDots}>...</li>
          </>
        )}

        {paginationButtons.map((page) => (
          <li
            key={page}
            className={`${styles.pageNumbers} ${currentPage === page ? styles.active : ""}`}
            onClick={() => onPageChange(page)}
          >
            {page}
          </li>
        ))}

        {currentPage < totalPages - 4 && (
          <>
            <li className={styles.pageDots}>...</li>
            <li className={styles.pageNumbers} onClick={() => onPageChange(totalPages)}>
              {totalPages}
            </li>
          </>
        )}

        <li
          className={`${styles.pageBtn} ${currentPage < totalPages ? styles.active : ""}`}
          onClick={handleNextPage}
        >
          <IconButton
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <span 
              className={`${styles.font} ${currentPage === totalPages ? styles.disabledBtn : ""}`}
              
            >{jsonData.nextBtn}</span>

            <KeyboardArrowRightIcon />
          </IconButton>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
