import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
export default function LoadingSpinner({ open }) {
  return (
    <Backdrop open={open} style={{ zIndex: 5 }}>
      <CircularProgress />
    </Backdrop>
  );
}
LoadingSpinner.propTypes = {
  open: PropTypes.bool.isRequired,
};
