import styles from "./NoData.module.css";
const NoData = (props) => {
    const {label, height} = props
  return (
    
    <div className={styles["box-centre"]}>
      <div className={styles["box"]} style={{height: height}}>
        <div className={`${styles["box-centre"]} ${styles["text-font"]}`}>{label}</div>
      </div>
    </div>
  );
};

export default NoData;
