import { RadioGroup, Tooltip } from "@mui/material";
import StyledRadio from "../../UI/StyledRadioButton/StyledRadio";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import jsonData from "../../../Assets/i18n/en.json";
import styles from "./HmsGlueSelection.module.css";

export function HmsGlueSelection(props) {
  const { selectedValue, setSelectedValue } = props;
  const hmsGlueTypeHandler = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <RadioGroup row value={selectedValue} onChange={hmsGlueTypeHandler}>
      <div className={`${styles["margin-right-16"]}`}>
        <div className={`${styles["icon"]}`}>
          <StyledRadio
            label={jsonData.glueLabelText}
            value={jsonData.glueValueText}
          />
          <Tooltip title={jsonData.glueDescText}>
            <InfoOutlinedIcon />
          </Tooltip>
          <div className={`${styles["margin-left-16"]} ${styles["icon"]}`}>
            <StyledRadio
              label={jsonData.hmsLabelText}
              value={jsonData.HMSValueText}
            />
            <Tooltip title={jsonData.hmsDescText}>
              <InfoOutlinedIcon />
            </Tooltip>
          </div>
        </div>
      </div>
    </RadioGroup>
  );
}
