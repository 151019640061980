import { useState } from "react";
import StatusDialog from "../UcValidation/StatusDialog";
import classes from "../../Components/Summary/WorkspaceSummary.module.css";
import { Button, styled, TableCell, TableRow } from "@mui/material";

const StyledTableCell = styled(TableCell)({
  borderLeft: "none",
  borderRight: "none",
  height: "3.75rem",
  paddingTop: "10px",
  paddingBottom: "10px",
});

export default function RenderTableValidationRecord({ data }) {
  const [openPopup, setOpenPopup] = useState(false);
  const [modalData, setModalData] = useState({ title: "", bodyData: [] });

  const openModal = (title, bodyData) => {
    setModalData({ title, bodyData });
    setOpenPopup(true);
  };

  const formatString = (inputString) => {
    return inputString
      .replace(/_/g, " ")
      .replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase());
  };

  const aggregationColumn = (data) => {
    try {
      const parsedData =
        data?.trim() !== "" ? JSON.parse(data?.replace(/'/g, '"')) : [];
      return parsedData?.flatMap((item) =>
        Object.entries(item)?.map(([key, value]) => ({
          rowLabel: formatString(key),
          rowValue: value,
        }))
      );
    } catch (error) {
      console.error("Error parsing data:", error);
      return [];
    }
  };

  // Check for undefined data
  if (!data) {
    return <div>No Data Available</div>;
  }

  const cellValues = [
    { label: "source_schema", value: data.source_schema },
    { label: "source_name", value: data.source_name },
    { label: "target_catalog", value: data.target_catalog },
    { label: "Target Row Count", value: data.source_row_count },
    { label: "Source Row Count", value: data.target_row_count },
    {
      label: "row_count_validation_status",
      value: data.row_count_validation_status,
      count: [
        { rowLabel: "Source Row Count", rowValue: data.source_row_count },
        { rowLabel: "Target Row Count", rowValue: data.target_row_count },
        {
          rowLabel: "Percentage Difference Row Count",
          rowValue: data.percentage_difference_row_count,
        },
      ],
    },
    {
      label: "source_target_column_status",
      value: data.source_target_column_status,
      count: [
        { rowLabel: "Source Column Count", rowValue: data.source_schema_column_cnt },
        { rowLabel: "Target Column Count", rowValue: data.target_schema_column_cnt },
      ],
    },
    {
      label: "column_metadata_status",
      value: data?.column_metadata_status,
      count: aggregationColumn(data?.column_datatype_changed),
    },
    {
      label: "aggeration_validation_status",
      value: data.aggeration_validation_status,
      count: aggregationColumn(data?.aggeration_column_validation),
    },
  ];

  return (
    <>
      <TableRow className={classes["table-row-height"]} key={data.source_name}>
        {cellValues?.map((cell, index) => (
          <StyledTableCell
            className={classes["table-val"]}
            key={index}
            style={{ cursor: "pointer" }}
          >
            {cell.value === "NOT MATCHED" ? (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => openModal(cell.value, cell.count)}
              >
                {cell.value}
              </Button>
            ) : (
              <div
                className={cell.value === "MATCHED" ? classes["matched-font-color"] : ""}
              >
                {cell.value}
              </div>
            )}
          </StyledTableCell>
        ))}
      </TableRow>
      <StatusDialog
        openPopup={openPopup}
        setOpenStatus={setOpenPopup}
        modalData={modalData}
      />
    </>
  );
}
