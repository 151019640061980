import { Box, FormControl, MenuItem, RadioGroup, Select } from "@mui/material";
import { useEffect, useState } from "react";
import HeadingWithInfo from "../HeadingWithInfo/HeadingWithInfo";
import StyledRadio from "../../UI/StyledRadioButton/StyledRadio";
import jsonData from "../../../Assets/i18n/en.json";
import styles from "../../UcMigration.module.css";
import { getAllPurposeCluster } from "../../../Resources/apicalls";
import { useSelector } from "react-redux";

export default function ManualClusterSelection(props) {
  const {
    clusterId,
    setClusterId,
    label,
    wsUrl,
    wsId,
    setManualClusterSelection,
  } = props;
  const [manualClusterSelect, setManualClusterSelect] = useState(false);
  const [clusterList, setClusterList] = useState([]);
  const accountInfoData = useSelector((state) => state.accountInfo);

  const getAllPurposeClusterList = async () => {
    let postbody = {
      ucxCredentialsData: {
        accId: accountInfoData.accountId,
      },
      workspaceUrl: wsUrl,
      workspaceIdentifier: wsId,
      ucxPayload: null,
      ucxInstalled: false,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
    };
    let response = await getAllPurposeCluster(postbody);
    setClusterList(response.filter((data) => data.state === "RUNNING"));
  };

  useEffect(() => {
    getAllPurposeClusterList();
  }, [wsId]);

  function clusterSelectionHandler(event) {
    setManualClusterSelection(!manualClusterSelect);
    setManualClusterSelect(!manualClusterSelect);
    setClusterId("");
  }

  return (
    <Box
      sx={{
        "& > :not(style)": { width: "30.75rem" },
      }}
      noValidate
      autoComplete="off"
      className={styles["margin-top-20"]}
    >
      <div>
        <HeadingWithInfo
          label={label}
          isDialog={true}
          info={jsonData.mnulClstrSelectText}
        />
        <div>
          <FormControl disabled={wsId === ""}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={clusterSelectionHandler}
              value={manualClusterSelect}
            >
              <div className={styles["margin-right-16"]}>
                <StyledRadio value={false} label={jsonData.defaultClusterTxt} />
              </div>

              <StyledRadio value={true} label={jsonData.existClusterTxt} />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      {manualClusterSelect && (
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          size="small"
          className={styles["margin-top-20"]}
          value={clusterId}
          onChange={(event) => setClusterId(event.target.value)}
          placeholder={jsonData.selectClusterText}
        >
          {clusterList?.length < 1 ? (
            <MenuItem disabled>{jsonData.noRunningCluster}</MenuItem>
          ) : (
            clusterList?.map((data, index) => {
              return (
                <MenuItem key={index} value={data.cluster_id}>
                  {data.cluster_name}
                </MenuItem>
              );
            })
          )}
        </Select>
      )}
    </Box>
  );
}
