import domtoimage from 'dom-to-image';
import jsPDF from "jspdf";

export const handleDownload = async (element, name) => {
  if (!element) return;

  domtoimage.toPng(element)
    .then(function (dataUrl) {
      const pdf = new jsPDF("p", "pt", "a4");
      const pageWidth = 595; 
      const pageHeight = 842; 
      const padding = 12;    
      const img = new Image();
      img.src = dataUrl;
      img.onload = () => {
        const imgWidth = pageWidth - padding * 2; // Subtract padding from both sides
        const imgHeight = (img.height * imgWidth) / img.width;  // Maintain aspect ratio
        let heightLeft = imgHeight;
        let position = padding;

        pdf.addImage(dataUrl, "PNG", padding, position, imgWidth, imgHeight);
        heightLeft -= pageHeight - padding * 2;

        while (heightLeft > 0) {
          position = heightLeft - (imgHeight-10);
          pdf.addPage();
          pdf.addImage(dataUrl, "PNG", 10, position, imgWidth, imgHeight-10);
          heightLeft -= pageHeight;
        }
        pdf.save(name);
      };
    })
    .catch(function (error) {
      console.error('Error capturing the DOM:', error);
    });
};
