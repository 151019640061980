import styles from "../UcMigration.module.css";
import jsonData from "../../Assets/i18n/en.json";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NameWOhPattern } from "../../utils/regex";
import LoadingSpinner from "../UI/LoadingSpinner";
import { validationDataCall } from "../../store/thunk/tableValidation-slice";
import { GenerateUid } from "../../utils/GenerateUid/GenerateUid";
import UcmaTextField from "../UI/Form/TextField";

function TableValidation(props) {
  const {
    startValidation,
    selectedList,
    databaseType,
    setDisableNext,
    disableNext,
    setId
  } = props;
  const accountInfoData = useSelector((state) => state.accountInfo);
  const [tableInputSelected, setTableInputSelected] = useState("user");
  const workspaceInfo = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const workspaceConfig = useSelector(
    (state) => state.workspaceConfig.data[workspaceInfo.workspace_id]
  );

  const [base64Data, setBase64Data] = useState("");
  const [targetSchemaUc, setTargetSchemaUc] = useState("");

  const [makingApiCall, setMakingApiCall] = useState(false);
  const [viewSelected, setViewSelected] = useState([]);
  const [tableSelected, setTableSelected] = useState([]);


  const dispatch = useDispatch();

  useEffect(() => {
    const tableTypes = Array.from(
      new Set(selectedList?.map((item) => item.object_type))
    );
    const containsView = tableTypes?.includes("VIEW");
    setViewSelected(
      selectedList?.filter((item) => item.object_type?.includes("VIEW"))
    );

    setTableSelected(
      selectedList?.filter(
        (item) =>
          item.object_type?.includes("MANAGED") ||
          item.object_type?.includes("EXTERNAL")
      )
    );
  }, [selectedList]);

  async function ValidateTable() {
    let triggerId = GenerateUid();
    setId(triggerId);
    let payload = {
      accId: workspaceInfo.account_id,
      workspaceUrl: workspaceInfo.workspace_url,
      triggerId: triggerId,
      workspaceIdentifier: workspaceInfo.workspace_id,
      tableCsvBase64EncodedString: base64Data,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      cloudType: accountInfoData.cloudType,
      containerName: workspaceConfig.azureStorageAccountName,
      storageAccountKey: workspaceConfig.azureStorageAccountKey,
      patAccess: accountInfoData.patAccess,
      createTableValidationRequest: {
        input_csv: tableInputSelected === "file" ? "filepath" : "",
        user_select:
          tableInputSelected === "user"
            ? tableSelected.map((item) => ({
                sourceSchemaName: item.database,
                sourceTableName: item.name,
                targetCatalogName: targetSchemaUc,
                targetSchemaName: item.database,
                targetTableName: item.name,
              }))
            : "",
      },
      dataRoleArn: workspaceConfig.awsRoleArn,
      isGlue: databaseType === jsonData.glueValueText,
      isTable: true,
    };

    let payloadView = {
      accId: workspaceInfo.account_id,
      triggerId: triggerId,
      workspaceUrl: workspaceInfo.workspace_url,
      workspaceIdentifier: workspaceInfo.workspace_id,
      tableCsvBase64EncodedString: base64Data,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      containerName: workspaceConfig.azureStorageAccountName,
      storageAccountKey: workspaceConfig.azureStorageAccountKey,
      createTableValidationRequest: {
        input_csv: tableInputSelected === "file" ? "filepath" : "",
        user_select:
          tableInputSelected === "user"
            ? viewSelected.map((item) => ({
                sourceSchemaName: item.database,
                sourceTableName: item.name,
                targetCatalogName: targetSchemaUc,
                targetSchemaName: item.database,
                targetTableName: item.name,
              }))
            : "",
      },
      dataRoleArn: workspaceConfig.awsRoleArn,
      isGlue: databaseType === jsonData.glueValueText,
      isTable: false,
    };
    setMakingApiCall(true);
    if (tableSelected.length > 0) {
      dispatch(validationDataCall(payload));
    }
    if (viewSelected.length > 0) {
      dispatch(validationDataCall(payloadView));
    }
    setMakingApiCall(false);
  }

  function enableNext() {
    if (targetSchemaUc === "") setDisableNext(true);
    else setDisableNext(false);
  }

  useEffect(() => {
    enableNext();
  }, [targetSchemaUc, disableNext]);

  useEffect(() => {
    if (startValidation) {
      ValidateTable();
    }
  }, [startValidation]);

  return (
    <div className={` ${styles["margin-top-12"]}`}>
      <LoadingSpinner open={makingApiCall} />
      {tableInputSelected === "user" && (
        <UcmaTextField
          name={jsonData.targetSchemaUcToolTipText}
          info={jsonData.targetSchemaUcToolTipText}
          value={targetSchemaUc}
          onChangeValue={setTargetSchemaUc}
          placeholder={jsonData.unityCatalogNamePlaceholder}
          setDisableSubmit={setDisableNext}
          pattern={NameWOhPattern}
          required
          isDialog
        />
      )}
    </div>
  );
}

export default TableValidation;
