import { createSlice } from "@reduxjs/toolkit";

import { cloneTableViewAcrossCall } from "../thunk/tableCloneData-thunk";

const initialState = {
  cloneTableData: [],
};
const cloneTableDataSlice = createSlice({
  name: "cloneTableData",
  initialState,
  reducers: {
    resetCloneTableData() {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(cloneTableViewAcrossCall.pending, (state) => {
        state.error = null;
      })
      .addCase(cloneTableViewAcrossCall.fulfilled, (state, action) => {
        state.cloneTableData = action.payload;
        state.error = null;
      })
      .addCase(cloneTableViewAcrossCall.rejected, (state, action) => {
        state.error = null;
        state.cloneTableData="Invalid";
      });
  },
});

export default cloneTableDataSlice;
export const cloneTableDataActions = cloneTableDataSlice.actions;
