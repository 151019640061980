import { useState } from "react";
import { Popover, List, ListItemText, ListItemButton } from "@mui/material";
import classes from "./Migration.module.css";
import jsonData from "../../../Assets/i18n/en.json";
import { MoreVert } from "@mui/icons-material";
import CloneDailog from "./CloneDialog";
import DeleteDailog from "./DeleteDialog";
import ValidationDialog from "./ValidationDailog";
import MigrationDialog from "./MigrationDailog";

const CommonMigration = (props) => {
  const {
    clusterList,
    migrationSelected,
    handleValidateClick,
    selectedList,
    makingApiCall,
    setClose,
    handleMigrateClick,
    listSelected,
    databaseType,
    setListSelected,
    setStartValidation,
    startValidation,
    handleCloneClick,
    handleDeleteClick,
    setShowReview,
    setShowAlertValidate,
    setId,
    setShowAlertMigration,
    title,
    titleValidation,
    titleClone,
    setCloneType,
    setExternalWsToken,
    setExternalWsUrl,
    setClonedata,
    migrateBtnDisable,
    validateBtnDisable,
    setShowCloneSummary,
    setValidatedData,
    showMigrate,
    showValidate,
    showClone,
    showDelete,
    cloneType,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [migrationDialogOpen, setMigrationDialogOpen] = useState(false);
  const [validationDialogOpen, setValidationDialogOpen] = useState(false);
  const [cloneDialogOpen, setCloneDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option) => {
    handleClose();
    if (option === "migration") {
      setMigrationDialogOpen(true);
    }
    if (option === "validation") {
      setValidationDialogOpen(true);
    }
    if (option === jsonData.cloneTxt) {
      setCloneDialogOpen(true);
    }
    if (option === jsonData.delete) {
      setDeleteDialogOpen(true);
    }
  };

  const handleMigrationDialogClose = () => {
    setMigrationDialogOpen(false);
    setClose(true);
    setListSelected(false);
  };
  const handleValidationDialogClose = () => {
    setValidationDialogOpen(false);
    setClose(true);
    setListSelected(false);
  };

  const handleCloneDialogClose = () => {
    setCloneDialogOpen(false);
    setClose(true);
    setListSelected(false);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setClose(true);
    setListSelected(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={classes["flex-end"]}>
      <MoreVert onClick={handleClick} />
      <div className={classes["popover"]}>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <List>
            {showClone && (
              <ListItemButton
                disabled={!listSelected}
                onClick={() => handleOptionSelect(jsonData.cloneTxt)}
              >
                <ListItemText primary={jsonData.cloneTxt} />
              </ListItemButton>
            )}
            {showMigrate && (
              <ListItemButton
                disabled={!listSelected || migrateBtnDisable}
                onClick={() => handleOptionSelect("migration")}
              >
                <ListItemText primary="Migrate" />
              </ListItemButton>
            )}
            {showValidate && (
              <ListItemButton
                disabled={!listSelected || validateBtnDisable}
                onClick={() => handleOptionSelect("validation")}
              >
                <ListItemText primary="Validate" />
              </ListItemButton>
            )}

            {showDelete && (
              <ListItemButton
                disabled={!listSelected}
                onClick={() => handleOptionSelect(jsonData.delete)}
              >
                <ListItemText primary={jsonData.delete} />
              </ListItemButton>
            )}
          </List>
        </Popover>
      </div>

      <MigrationDialog
        open={migrationDialogOpen}
        setId={setId}
        onClose={handleMigrationDialogClose}
        migrationSelected={migrationSelected}
        selectedList={selectedList}
        handleMigrateClick={handleMigrateClick}
        databaseType={databaseType}
        clusterList={clusterList}
        title={title}
        setShowAlertMigration={setShowAlertMigration}
      />

      <CloneDailog
        open={cloneDialogOpen}
        titleClone={titleClone}
        migrationSelected={migrationSelected}
        databaseType={databaseType}
        selectedList={selectedList}
        onClose={handleCloneDialogClose}
        handleCloneClick={handleCloneClick}
        makingApiCall={makingApiCall}
        setShowReview={setShowReview}
        setCloneType={setCloneType}
        setExternalWsToken={setExternalWsToken}
        setExternalWsUrl={setExternalWsUrl}
        setClonedata={setClonedata}
        setId={setId}
        setShowCloneSummary={setShowCloneSummary}
        cloneType={cloneType}
      />

      <DeleteDailog
        open={deleteDialogOpen}
        migrationSelected={migrationSelected}
        onClose={handleDeleteDialogClose}
        handleDeleteClick={handleDeleteClick}
        makingApiCall={makingApiCall}
        setShowReview={setShowReview}
      />

      <ValidationDialog
        open={validationDialogOpen}
        makingApiCall={makingApiCall}
        selectedList={selectedList}
        onClose={handleValidationDialogClose}
        migrationSelected={migrationSelected}
        handleValidateClick={handleValidateClick}
        databaseType={databaseType}
        setValidatedData={setValidatedData}
        setStartValidation={setStartValidation}
        startValidation={startValidation}
        setShowReview={setShowReview}
        setId={setId}
        setShowAlertValidate={setShowAlertValidate}
        titleValidation={titleValidation}
      />
    </div>
  );
};

export default CommonMigration;
