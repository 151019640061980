import {
  Box,
  FormControl,
  RadioGroup,
  Switch,
} from "@mui/material";
import jsonData from "../../Assets/i18n/en.json";
import styles from "../NewComponent.module.css";
import { useState } from "react";
import StyledRadio from "../UI/StyledRadioButton/StyledRadio";
import { notebookInputPathPattern } from "../../utils/regex";
import { v4 as uuidv4 } from "uuid";
import { module1ApiCall } from "../../Resources/apicalls";
import LoadingSpinner from "../UI/LoadingSpinner";
import BasicAlerts from "../Common/MessageBanner.jsx/MessageBanner";
import UcmaSubmitButtons from "../UI/Form/ButtonsContainer";
import { useCommonPayload } from "../../Resources/useCommonPayload";
import UcmaTextField from "../UI/Form/TextField";
import { useFormValidation } from "../UI/Form/useFormValiditation";
import constants from "../../utils/constant";
import StyledCheckbox from "../UI/StyledCheckBox/StyledCheckbox";

export default function NotebookStringReplacement(props) {
  const [input, setInput] = useState(jsonData.string);
  const [replace, setReplace] = useState(false);
  const [id, setId] = useState("");
  const [stringOrRegex, setStringOrRegex] = useState("");
  const [loading, setLoading] = useState(false);
  const [replaceWith, setReplaceWith] = useState("");
  const [path1, setPath1] = useState("");
  const [path2, setPath2] = useState("");
  const [showAlertMigration, setShowAlertMigration] = useState(false);
  const commonPayload = useCommonPayload({ type: "post" });
  const { isFormValid, setFieldValid, resetFieldValidity } = useFormValidation([
    "stringOrRegex",
    "path1",
  ]);

  const onInputChange = (event) => {
    setInput(event.target.value);
    setStringOrRegex("");
    setReplaceWith("");
    setFieldValid('stringOrRegex',true);
  };

  const onChangeRepl = (e) => {
    setReplace(e.target.checked);
    setPath2("");
    setReplaceWith("");
    resetFieldValidity(false, e.target.checked? ['path2', 'replaceWith']:[],e.target.checked? []:['path2', 'replaceWith']);
  };
  const handleSubmitClick = async () => {
    let triggerId = uuidv4().replace(/-/g, "").substring(0, 10);
    setId(triggerId);
    setLoading(true);
    let postbody = {
      ...commonPayload,
      catalogName: "",
      cloudToTableFlag: "False",
      currentDbrVersion: "",
      dbfsFlag: "False",
      mountpointFlag: "False",
      namespaceFlag: "False",
      notebookOutputPath: path2,
      notebookPath: path1,
      targetDbrVersion: "",
      triggerId: triggerId,
      inputOldString: replace
        ? input == jsonData.string
          ? stringOrRegex
          : ""
        : "",
      inputOldRegex: replace
        ? input == jsonData.regularExp
          ? stringOrRegex
          : ""
        : "",
      inputSearchString: !replace
        ? input == jsonData.string
          ? stringOrRegex
          : ""
        : "",
      inputSearchRegex: !replace
        ? input == jsonData.regularExp
          ? stringOrRegex
          : ""
        : "",
      inputNewString: replaceWith,
      processName: replace
        ? constants.notebookConstants.notebookStringReplacement
        : constants.notebookConstants.notebookSearch,
      notebookScanFlag: "False",
    };
    setShowAlertMigration(true);
    handleReset();
    setLoading(false);
    await module1ApiCall(postbody);
  };
  const handleReset = () => {
    setInput(jsonData.string);
    setStringOrRegex("");
    setReplaceWith("");
    setPath1("");
    setPath2("");
    resetFieldValidity(true);
  };
  function validateHandlerPath2() {
    if ((path1 && path2.startsWith(path1 + "/")) || path2 === path1) {
      return `Notebook target path should not be a subpath of source path.`;
    } else return null;
  }
  return (
    <>
      {showAlertMigration && !loading && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startMigrationmsg}
              <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertMigration}
          showReview={showAlertMigration}
          selectComponent={props.selectComponent}
          showSummaryButton="true"
        />
      )}
      <LoadingSpinner open={loading} />
      <div>
        {" "}

        <div
          className={`${styles["display-column-left"]} ${styles["margin-top-26"]}`}
        >
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={onInputChange}
              value={input}
            >
              <div className={styles["margin-right-16"]}>
                <StyledRadio value={jsonData.string} label={jsonData.string} />
              </div>

              <StyledRadio
                value={jsonData.regularExp}
                label={jsonData.regularExp}
              />
            </RadioGroup>
          </FormControl>

          <div className={styles["container"]}>
            <Box noValidate autoComplete="off">
              <UcmaTextField
                name={
                  input == jsonData.string
                    ? jsonData.inputString
                    : jsonData.inputRegex
                }
                info={
                  input == jsonData.string
                    ? jsonData.inputStringInfo
                    : jsonData.inputRegexInfo
                }
                value={stringOrRegex}
                onChangeValue={setStringOrRegex}
                required
                placeholder={
                  input === jsonData.string
                    ? jsonData.inputStringPlaceholder
                    : jsonData.inputRegexPlaceholder
                }
                setDisableSubmit={(inValid) =>
                  setFieldValid("stringOrRegex", inValid)
                }
              />
              <div>
                <UcmaTextField
                  name={jsonData.inputPathText}
                  info={jsonData.sourceNotebookPathInfo}
                  value={path1}
                  onChangeValue={setPath1}
                  required
                  pattern={notebookInputPathPattern}
                  placeholder={jsonData.notebookPlaceholder}
                  setDisableSubmit={(inValid) => setFieldValid("path1", inValid)}
                />
              </div>
              <div className={`${styles["display"]} ${styles["margin-top-26"]}`}>
                Replace<Switch label={replace} checked={replace} onChange={onChangeRepl} />
              </div>
              {replace && (
                <>
                  <UcmaTextField
                    name={jsonData.replaceWith}
                    info={jsonData.replaceWithInfo}
                    value={replaceWith}
                    onChangeValue={setReplaceWith}
                    required
                    placeholder={jsonData.replaceWithPlaceholder}
                    setDisableSubmit={(inValid) =>
                      setFieldValid("replaceWith", inValid)
                    }
                  />
                  <UcmaTextField
                    customValidateHandler={validateHandlerPath2}
                    name={jsonData.inputNotePathText}
                    info={jsonData.targetInfo}
                    value={path2}
                    onChangeValue={setPath2}
                    required
                    pattern={notebookInputPathPattern}
                    placeholder={jsonData.notebookPlaceholder1}
                    setDisableSubmit={(inValid) => setFieldValid("path2", inValid)}
                  /></>
              )}
            </Box>
          </div>


          <UcmaSubmitButtons
            handleReset={handleReset}
            disableSubmit={!isFormValid}
            handleSubmit={handleSubmitClick}
            primaryButtonLabel={replace ? jsonData.replace : "search"}
          />
        </div>
      </div>
    </>
  );
}
