import axios from "axios";
import jsonData from "../Assets/i18n/en.json";
import {
  attachMetaStoreApi,
  cloneClusterApi,
  cloneJobApi,
  dbTokencreationApi,
  deleteClusterApi,
  deleteJobApi,
  getAllPurposeClusterApi,
  getDbfsMountPointListApi,
  getDbsqlClusterListApi,
  getS3AccessApi,
  getWorkspaceJobListApi,
  listHmsTablesApi,
  listHmsdataBaseApi,
  listMetastoreApi,
  listUserApi,
  migrationApi,
  noteBookCountApi,
  putSave,
  validateAutoloaderApi,
  validateClusterApi,
  validateClusterPermissionApi,
  validateDbsqlApi,
  validateJobsApi,
  validateMountPointApi,
  validateTableApi,
  workSpaceListApi,
  module1Api,
  cloneClusterAcrossApi,
  cloneJobsAcrossApi,
  cloneTableViewApi,
  cloneMountPointAcrossApi,
  dbfsToVolumeValidationUrl,
  dbfsToDbfsCloneApi,
  dbfsListApi,
  workspaceObjectMigrationApi,
  azureLoginApi,
  awsLoginValidateApi,
  getJobCountApi,
  getWorkspaceConfigue,
  saveWorkspaceConfigue,
} from "./apiurls";
import { createAsyncThunk } from "@reduxjs/toolkit";
const accessToken = window.sessionStorage.getItem("accessToken");

export async function getWorkSpaceList(body) {
  let returnResponse = [];
  await axios
    .post(`${workSpaceListApi}`, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data.ucxApiResponse;
    })
    .catch((err) => {
      returnResponse = jsonData.invalidText;
    });
  return returnResponse;
}

export async function databricksAccountTokencreation(token, body) {
  let returnResponse = [];
  const params = new URLSearchParams();
  params.append("grant_type", "client_credentials");
  params.append("scope", "all-apis");
  await axios
    .post(`${dbTokencreationApi}`, body, {
      headers: {
        Authorization: token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data.ucxApiResponse;
    })
    .catch((err) => {
      console.log(err);
    });
  return returnResponse;
}

export async function ucMigrationCall(dataId, body) {
  let responseReturn;
  await axios
    .post(`${migrationApi}${dataId}`, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
    });

  return responseReturn;
}

export const migrationApiCall = createAsyncThunk(
  "data/migrationApiCall",
  async (dataId, body) => {
    try {
      const response = await axios.post(`${migrationApi}${dataId}`, body, {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export async function getAllPurposeCluster(body) {
  let returnResponse = [];

  await axios
    .post(getAllPurposeClusterApi, body, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // Handle the response here if needed
      returnResponse = response.data.ucxApiResponse;
    })
    .catch((err) => {
      if (err.response && err.response.status === 302) {
        returnResponse = err.response.data;
      }
    });

  return returnResponse;
}

export async function getWorkspaceJobList(post) {
  let returnResponse = [];
  await axios
    .post(getWorkspaceJobListApi, post, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data.ucxApiResponse;
    })
    .catch((err) => {
      if (err.response && err.response.status === 302) {
        returnResponse = err.response.data;
      }
    });

  return returnResponse;
}

export async function getMetaStoreList(body) {
  let returnResponse = [];
  await axios
    .post(`${listMetastoreApi}`, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data.ucxApiResponse;
    })
    .catch((err) => {
      console.error("Error:", err.message);
      returnResponse = jsonData.invalidText;
    });

  return returnResponse;
}

export async function createMetaStore(postbody) {
  let returnResponse = [];
  await axios
    .post(attachMetaStoreApi, postbody, {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    })
    .then((response) => {
      returnResponse = response;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}

export async function getAccountUserList(body) {
  let returnResponse = [];

  await axios
    .post(`${listUserApi}`, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data.ucxApiResponse;
    })
    .catch((err) => {
      console.log(err);
    });

  return returnResponse;
}

export async function getDbsqlClusterList(body) {
  let returnResponse;

  await axios
    .post(getDbsqlClusterListApi, body, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // Handle the response here if needed
      returnResponse = response.data;
    })
    .catch((err) => {
      if (err.response && err.response.status === 302) {
        returnResponse = err.response.data;
      } else {
        // Handle other errors
        console.error("Error:", err.message);
      }
    });

  return returnResponse;
}

export async function getDbfsMountsList(body) {
  let returnResponse = [];

  await axios
    .post(getDbfsMountPointListApi, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // Handle the response here if needed
      returnResponse = response.data;
    })
    .catch((err) => {
      if (err.response && err.response.status === 302) {
        returnResponse = err.response.data;
      } else {
        // Handle other errors
        console.error("Error:", err.message);
      }
    });

  return returnResponse;
}

export async function getS3Access(body) {
  let returnResponse = [];

  await axios
    .post(getS3AccessApi, body, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // Handle the response here if needed
      returnResponse = response.data;
    })
    .catch((err) => {
      if (err.response && err.response.status === 302) {
        returnResponse = err.response.data;
      } else {
        // Handle other errors
        console.error("Error:", err.message);
      }
    });

  return returnResponse;
}

export async function validateMountpointCall(body) {
  let returnResponse = [];

  await axios
    .post(validateMountPointApi, body, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // Handle the response here if needed
      returnResponse = response.data;
    })
    .catch((err) => {
      if (err.response && err.response.status === 302) {
        returnResponse = err.response.data;
      } else {
        // Handle other errors
        console.error("Error:", err.message);
      }
    });

  return returnResponse;
}

export async function validateClusterPermissionCall(body) {
  let returnResponse = [];

  await axios
    .post(validateClusterPermissionApi, body, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // Handle the response here if needed
      returnResponse = response.data;
    })
    .catch((err) => {
      if (err.response && err.response.status === 302) {
        returnResponse = err.response.data;
      } else {
        // Handle other errors
        console.error("Error:", err.message);
      }
    });

  return returnResponse;
}

export async function validateDbsqlCall(body) {
  let returnResponse = [];

  await axios
    .post(validateDbsqlApi, body, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // Handle the response here if needed
      returnResponse = response.data;
    })
    .catch((err) => {
      if (err.response && err.response.status === 302) {
        returnResponse = err.response.data;
      } else {
        // Handle other errors
        console.error("Error:", err.message);
      }
    });

  return returnResponse;
}

export async function validateJobCall(body) {
  let returnResponse = [];

  await axios
    .post(validateJobsApi, body, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // Handle the response here if needed
      returnResponse = response.data;
    })
    .catch((err) => {
      if (err.response && err.response.status === 302) {
        returnResponse = err.response.data;
      } else {
        // Handle other errors
        console.error("Error:", err.message);
      }
    });

  return returnResponse;
}

export async function validateClusterCall(body) {
  let returnResponse = [];

  await axios
    .post(validateClusterApi, body, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // Handle the response here if needed
      returnResponse = response.data;
    })
    .catch((err) => {
      if (err.response && err.response.status === 302) {
        returnResponse = err.response.data;
      } else {
        // Handle other errors
        console.error("Error:", err.message);
      }
    });

  return returnResponse;
}

export async function validateTableCall(body) {
  let returnResponse = [];

  await axios
    .post(validateTableApi, body, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // Handle the response here if needed
      returnResponse = response.data;
    })
    .catch((err) => {
      if (err.response && err.response.status === 302) {
        returnResponse = err.response.data;
      } else {
        // Handle other errors
        console.error("Error:", err.message);
      }
    });

  return returnResponse;
}

export async function putOnSubmit(body) {
  let returnResponse = [];
  await axios
    .put(`${putSave}`, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data.ucxApiResponse;
    })
    .catch((err) => {
      returnResponse = jsonData.invalidText;
    });
  return returnResponse;
}

export async function ValidateAutoLoaderCall(body) {
  let returnResponse = [];

  await axios
    .post(validateAutoloaderApi, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      if (err.response && err.response.status === 302) {
        returnResponse = err.response.data;
      } else {
        console.error("Error:", err.message);
      }
    });

  return returnResponse;
}

export async function listHmsdataBasecall(body) {
  let returnResponse = [];

  await axios
    .post(`${listHmsdataBaseApi}`, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}

export async function listHmsTablecall(body, db) {
  let returnResponse = [];

  await axios
    .post(`${listHmsTablesApi}${db}`, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}

export async function noteBookCountCall(body) {
  let returnResponse = [];

  await axios
    .post(`${noteBookCountApi}`, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}

export async function getCall(api) {
  let returnResponse = [];
  await axios
    .get(api, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });
  return returnResponse;
}

export async function cloneClusterCall(body) {
  let returnResponse = [];

  await axios
    .post(cloneClusterApi, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}

export async function cloneClusterAcrossCall(body) {
  let returnResponse = [];

  await axios
    .post(cloneClusterAcrossApi, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}

export async function cloneJobsAcrossCall(body) {
  let returnResponse = [];

  await axios
    .post(cloneJobsAcrossApi, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}
export async function cloneJobCall(body) {
  let returnResponse = [];

  await axios
    .post(cloneJobApi, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}

export async function cloneTableViewAcrossCall(body) {
  let returnResponse = [];

  await axios
    .post(cloneTableViewApi, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}

export async function cloneMountpointAcrossCall(body) {
  let returnResponse = [];

  await axios
    .post(cloneMountPointAcrossApi, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}

export async function deleteJobCall(body) {
  let returnResponse = [];

  await axios
    .post(deleteJobApi, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}

export async function deleteClusterCall(body) {
  let returnResponse = [];

  await axios
    .post(deleteClusterApi, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}

export async function module1ApiCall(body) {
  let returnResponse = [];

  await axios
    .post(module1Api, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}

export async function dbfsToVolumeValidation(body) {
  let returnResponse = [];

  await axios
    .post(dbfsToVolumeValidationUrl, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}

export async function cloneDbfsToDbfsAcrossCall(body) {
  let returnResponse = [];

  await axios
    .post(dbfsToDbfsCloneApi, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}

export async function dbfslistCall(body) {
  let returnResponse = [];

  await axios
    .post(dbfsListApi, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}

export async function workspaceObjectMigrationCall(body) {
  let returnResponse = [];

  await axios
    .post(workspaceObjectMigrationApi, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}

export async function azureValidationCall(body) {
  let returnResponse = [];

  await axios
    .post(azureLoginApi, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
      returnResponse = jsonData.invalidText;
    });

  return returnResponse;
}

export async function awsDatabricksValidationCall(body) {
  let returnResponse = [];

  await axios
    .post(awsLoginValidateApi, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
      returnResponse = jsonData.invalidText;
    });

  return returnResponse;
}

export async function getJobCount(body) {
  let returnResponse = [];

  await axios
    .post(getJobCountApi, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
      returnResponse = jsonData.invalidText;
    });

  return returnResponse;
}

export async function fetchDropdownData1(body) {
  let returnResponse = [];

  await axios
    .post(cloneTableViewApi, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}

export async function fetchDropdownData2(body) {
  let returnResponse = [];

  await axios
    .post(cloneTableViewApi, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}


export async function fetchWorkspaceConfig(workspaceIdentifier) {
  let returnResponse = [];

  const token = window.sessionStorage.getItem("accessToken");

  await axios
    .get(getWorkspaceConfigue, {
      params: {
        workspace_identifier: workspaceIdentifier,
      },
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
      returnResponse = { error: err.message };
    });

  return returnResponse;
}

export async function saveWorkspaceConfig(config) {
  let returnResponse = {};

  const token = window.sessionStorage.getItem("accessToken");

  await axios
    .post(saveWorkspaceConfigue, config, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
      returnResponse = { error: err.message };
    });

  return returnResponse;
}

export async function postCall(api,body) {
  let returnResponse = [];

  await axios
    .post(api, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data;
    })
    .catch((err) => {
      console.error("Error:", err.message);
    });

  return returnResponse;
}
