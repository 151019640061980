import constants from "../constant";

export function convertToCamelCase(name) {

  if(name.indexOf("_")===-1){
    return name;
  }
  return name
  .split('_')
  .map((word, index) => {
    if (word === 'UC') {
      return 'UC';
    } else if (index === 0 || word === 'UC') {
      return word.toLowerCase();
    } else {
      return word.charAt(0) + word.slice(1).toLowerCase();
    }
  })
  .join(' ');
}
export function convertEntityName(name){
  if(name in constants.entityNameMapping){
    return constants.entityNameMapping[name];
  }
  if(name.indexOf("_")===-1){
    return capitalizeFirstLetter(name);
  }
  return name
  .split('_')
  .map((word, index) => {
    if (word === 'UC') {
      return 'UC';
    } else {
      return word.charAt(0) + word.slice(1).toLowerCase();
    }
  })
  .join(' ');
}

export function capitalizeFirstLetter(str) {
  return str
      .split(' ') // Split the string by spaces
      .map(word => 
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize the first letter and make the rest lowercase
      )
      .join(' '); // Join the words back together with spaces
}