import { useState } from "react";
import DepreciatedFunction from "./DepreciatedFunction";
import DepreciatedModule from "./DepreciatedModule";
import NotebookUpgradeTotals from "./NotebookUpgradeTotals";
import ReviewSummary from "./ReviewSummary";
import classes from "./WorkspaceSummary.module.css";
import { ArrowDownward, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Card, Dialog, DialogContent, IconButton } from "@mui/material";
import NotebookChanges from "./NotebookChanges";
import CloseIcon from "@mui/icons-material/Close";

export default function NotebookUpgradeSummary(props) {
    const { id, entity } = props;
    const [showTotals, setShowTotals] = useState(false);
    const [showDeprecated, setShowDeprecated] = useState(false);
    const [selectedNotebook, setSelectedNotebook] = useState(false);
    const [count, setNotebookCount] = useState();

    return (
        <div>
            <ReviewSummary {...props} setNotebookCount={setNotebookCount}/>
            <Card className={classes['margin-top-2']} >
                <div className={classes['notebook-header']}>Notebooks

                    {showTotals ? <KeyboardArrowUp onClick={() => setShowTotals(false)} /> : <KeyboardArrowDown onClick={() => setShowTotals(true)} />}
                </div>
                {showTotals ?
                    <NotebookUpgradeTotals id={id} selectNotebook={setSelectedNotebook} count={count}/> : null}
            </Card>
            <Card className={classes['margin-top-2']} >

                <div className={classes['notebook-header']}>Deprecated Functions and Modules
                    
                    {showDeprecated ? <KeyboardArrowUp onClick={() => setShowDeprecated(false)} /> : <KeyboardArrowDown onClick={() => setShowDeprecated(true)} />}

                </div>
                {showDeprecated &&
                    <DepreciatedFunction {...props} />}

                {showDeprecated && <DepreciatedModule {...props} />}
                <>
                    <Dialog
                        onClose={() => setSelectedNotebook(false)}
                        aria-labelledby="customized-dialog-title"
                        open={!!selectedNotebook}
                        maxWidth={"lg"}
                    >
                        <IconButton
                            aria-label="close"
                            onClick={() => setSelectedNotebook(false)}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon/>
                        </IconButton>
                        <DialogContent >
                            <NotebookChanges id={props.id} selectedNotebook={selectedNotebook} entity={entity}/>
                        </DialogContent>

                    </Dialog>

                </>
            </Card>

        </div>
    )
}