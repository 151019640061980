import { useSelector } from "react-redux";
import jsonData from "../Assets/i18n/en.json";

export function useCommonPayload(props){

    const accountInfoData = useSelector((state) => state.accountInfo);
    const workspaceSelected = useSelector(
        (state) => state.workspaceSlice?.selectedWorkspace[0]
      );

      if(props?.type ==='post'){
        return  {
            accountId: workspaceSelected.account_id,
            workspaceIdentifier: workspaceSelected.workspace_id,
            workspaceName:workspaceSelected.workspace_name,
            OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
            workspaceUrl: workspaceSelected.workspace_url,
            cloudType: accountInfoData.cloudType,
            patAccess: accountInfoData.patAccess,
          };
      }
  return  {
    ucxCredentialsData: {
        accId: accountInfoData.accountId,
      },
      workspaceUrl: workspaceSelected.workspace_url,
      workspaceIdentifier: workspaceSelected.workspace_id,
      workspaceName:workspaceSelected.workspace_name,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
    }
}