import { createSlice } from "@reduxjs/toolkit";
import { getWorkSpaceList } from "../thunk/workspace-thunk";

const initialState = {
  workspaceData: [],
};
 
  const dataSlice = createSlice({
    name: "workspaceData",
    initialState,
    reducers: {
      resetWorkspaceData() {
        return initialState;
      },
    },
    
    extraReducers: (builder) => {
      builder
        .addCase(getWorkSpaceList.pending, (state) => {
          state.error = null;
        })
        .addCase(getWorkSpaceList.fulfilled, (state, action) => {
          state.workspaceData = action.payload;
          state.error = null;
        })
        .addCase(getWorkSpaceList.rejected, (state, action) => {
          state.error = action.payload;
        })
    },
  });
  

  export const workspaceData = dataSlice.reducer;
  export const workspaceDataActions = dataSlice.actions;
  
