import constants from "../../utils/constant";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import classes from "./WorkspaceSummary.module.css";
import { useEffect, useState } from "react";
import LoadingSpinner from "../UI/LoadingSpinner";
import { getCall } from "../../Resources/apicalls";
import jsonData from "../../Assets/i18n/en.json";
import NoData from "../Common/NoData/NoData";
import { notebookSummaryApi } from "../../Resources/apiurls";

const ReviewSummary = (props) => {
  const { id, setNotebookCount } = props;
  const [noteBookMigrationData, setNoteBookMigrationData] = useState();
  const [loading, setLoading] = useState(false);

  const notebookMigartionResponse = async () => {
    setLoading(true);
    let api = `${notebookSummaryApi}${id}`;
    let res = await getCall(api);
    setNotebookCount(res?.ucxApiResponse?.successfulNotebooks)
    setLoading(false);
    setNoteBookMigrationData(res?.ucxApiResponse);
  };
  useEffect(() => {
    notebookMigartionResponse();
  }, []);
  if (!noteBookMigrationData && !loading) {
    return <NoData label={jsonData.noData}></NoData>;
  }
  return (
    <>
      <LoadingSpinner open={loading} />
      <div >
        <Card className={classes["notebookSummaryCard"]}>
          
          <TableContainer className = {classes['notebook-totals-card']}>
            <Table stickyHeader aria-label="sticky table">
              <TableBody>
                <TableRow >
                  <TableCell><b>{jsonData.notebookInputPath}</b></TableCell><TableCell> {noteBookMigrationData?.noteBookInputPath}</TableCell>
                </TableRow>
                {noteBookMigrationData?.noteBookOutputPath && 
                (<TableRow >
                <TableCell>
                    <b>{jsonData.notebookOutputPath}</b> </TableCell><TableCell>{noteBookMigrationData?.noteBookOutputPath}</TableCell>
                </TableRow>)}
                <TableRow >

                  <TableCell><b>{jsonData.totalScannedNotebooks}</b></TableCell><TableCell> {noteBookMigrationData?.scannedNotebooks}</TableCell>
                </TableRow>
                <TableRow >

                  <TableCell><b>{jsonData.successfulNotebooks}</b> </TableCell><TableCell>{noteBookMigrationData?.successfulNotebooks}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
    </>
  );
};
export default ReviewSummary;
