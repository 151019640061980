import constants from "../../utils/constant";
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Tooltip,
} from "@mui/material";
import jsonData from "../../Assets/i18n/en.json";
import EllipsisTooltip from "../UI/Tooltip/Tooltip";
import { getCall } from "../../Resources/apicalls";
import { useEffect, useRef, useState } from "react";
import classes from "./WorkspaceSummary.module.css";
import Pagination from "../Common/Pagination/Pagination";
import { notebookPreMigrationChangesApi, notebookPreMigrationChangesCountApi, notebookWiseChangesApi, notebookWiseChangesCountApi } from "../../Resources/apiurls";
import LoadingSpinner from "../UI/LoadingSpinner";

export default function NotebookChanges(props) {
    const { id, selectedNotebook ,entity} = props;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState("");
    const [pageNo, setPageNo] = useState(0);
    const pageSize = 10;
    const getCount = async () => {
        let url = entity==constants.notebookConstants.notebookPreScan ? notebookPreMigrationChangesCountApi:notebookWiseChangesCountApi

        const api = `${url}${id}/${selectedNotebook.notebook_id}?pageNo=${pageNo}&pageSize=${pageSize}`;
        let  res = await getCall(api);
        setCount(res?.ucxApiResponse);
    };

    const handleChange = (event) => {
        setPageNo(event - 1);
    };
    useEffect(() => {
        getNotebookDetails();
    }, [pageNo]);
    const getNotebookDetails = async () => {
        if (loading) {
            return;
        }
        setLoading(true);
        let url = entity==constants.notebookConstants.notebookPreScan ? notebookPreMigrationChangesApi:notebookWiseChangesApi
        let api = `${url}${id}/${selectedNotebook.notebook_id}?pageNo=${pageNo}&pageSize=${pageSize}`;
        let res = await getCall(api);
        setData(res?.ucxApiResponse);
        setLoading(false);
    };

    useEffect(() => {
        getCount();
        getNotebookDetails();

    }, []);
    return (
        <div>
            <h3 >{selectedNotebook.notebook_name}
            </h3>
            <Paper className={classes['notebookSummaryCard']}>

            <TableContainer className = {classes["notebook-totals-card"]}>
            <Table stickyHeader aria-label="sticky table">
              <TableBody>
                <TableRow >
                  <TableCell><b>{jsonData.notebookInputPath}</b></TableCell><TableCell>{selectedNotebook?.notebook_path}</TableCell>
                </TableRow>
                {entity !== constants.notebookConstants.notebookPreScan && 
                <TableRow >
                <TableCell>
                    <b>{jsonData.notebookOutputPath}</b> </TableCell><TableCell>{selectedNotebook?.output_notebook_path}</TableCell>
                </TableRow>}
                <TableRow >    
                  <TableCell><b>Total Changes</b></TableCell><TableCell>{count}</TableCell>
                </TableRow>
                <TableRow >

                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          </Paper>

            <Paper className={` ${classes["paper"]} ${classes['margin-top-2']}`}>
                {loading &&  <LoadingSpinner open={loading}/>
                }
                <TableContainer className = {classes["notebook-totals-card"]}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow className={classes["table-row-height"]}>
                                {constants.notebookWiseUpgradeSummary.map((head) => (
                                    <TableCell className={classes["table-heading"]} key={head?.label}>
                                        {head?.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((row, index) => {
                                return (
                                    <TableRow>
                                        {constants.notebookWiseUpgradeSummary.map((headers, index) => {
                                            return (
                                                <TableCell
                                                    // className={classes["horizontal-width"]}
                                                    style={{ width: headers.width || undefined }}
                                                    key={index + 1}
                                                > {headers.clip ?
                                                    <Tooltip title={row[headers.value]} >{row[headers.value]?.substring(0, headers.clip)}</Tooltip>
                                                    : <EllipsisTooltip>{row[headers.value]||
                                                    "-"}</EllipsisTooltip>}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>)
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Pagination
                totalPages={Math.ceil(count / pageSize)}
                currentPage={pageNo + 1}
                onPageChange={handleChange}
            />

        </div>
    )
}