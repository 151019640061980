import { DialogContent } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ucMigrationCall } from "../../Resources/apicalls";
import LoadingSpinner from "../UI/LoadingSpinner";
import { v4 as uuidv4 } from "uuid";
import jsonData from "../../Assets/i18n/en.json";

const DbsqlCluster = (props) => {
  const { selectedList, startMigration, setDisableNext, setId } = props;
  const [makingApiCalls, setMakingApiCalls] = useState(false);
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const accountInfoData = useSelector((state) => state.accountInfo);

  useEffect(() => {
    setDisableNext(false);
  }, []);

  async function submitMigration() {
    let triggerId = uuidv4().replace(/-/g, "").substring(0, 10);
    setId(triggerId);
    const postBody = JSON.stringify({
      ucxCredentialsData: {
        accId: accountInfoData.accountId,
      },
      OAuth: accountInfoData.credentialType === "Oauth",
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      ucxPayload: {
        data: null,
        warehouseID: selectedList ? selectedList.map((item) => item.id) : [],
        migrateGroups: false,
        upgradeClusters: null,
        workflows: null,

        jobs: null,
        runJobsAs: accountInfoData.userId
          ? accountInfoData.userId
          : accountInfoData.OAuthUser.userName,
        mountPointsUpgrade: null,
      },
      workspaceName: workspaceSelected.workspace_name,
      workspaceUrl: workspaceSelected.workspace_url,
      workspaceIdentifier: workspaceSelected.workspace_id,
      region: workspaceSelected.region,
      triggerId: triggerId,
    });
    setMakingApiCalls(true);
    await ucMigrationCall(workspaceSelected.workspace_id, postBody);
    setMakingApiCalls(false);
  }

  useEffect(() => {
    if (startMigration) submitMigration();
  }, [startMigration]);

  return (
    <div>
      <LoadingSpinner open={makingApiCalls} />
      <DialogContent>{jsonData.wantToMigrate}</DialogContent>
    </div>
  );
};
export default DbsqlCluster;
