import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import styles from './Tooltip.module.css'

const EllipsisTooltip = ({ children }) => {
  return (
    <Tooltip title={children}>
      <div
       className={styles["tooltip"]}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default EllipsisTooltip;
