import React from "react";
import ReactDOM from "react-dom";
import classes from "./UnAuthorizedOverlay.module.css";

function UnAuthorizedOverlay() {
  return ReactDOM.createPortal(
    <div className={classes.overlay}>
      <div>
        <p className="lead">Session timed out.</p>
        <p>
          <small>Redirecting to Login Page.</small>
        </p>
      </div>
    </div>,
    document.getElementById("overlay")
  );
}

export default UnAuthorizedOverlay;
