import { useEffect, useState } from "react";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./Migration.module.css";
import ButtonStyled from "../../UI/NavigationButtons/Buttons";
import jsonData from "../../../Assets/i18n/en.json";
import LoadingSpinner from "../../UI/LoadingSpinner";
import TableValidation from "../../Table/TableValidation";
import { styled } from "@mui/material/styles";
import DbfsToVolume from "../../Dbfs/DbfsToVolume";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    backgroundColor: "#fff",
    color: "black",
    padding: "16px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

const ValidationDialog = ({
  open,
  onClose,
  makingApiCall,
  migrationSelected,
  handleValidateClick,
  selectedList,
  databaseType,
  setId,
  titleValidation,
  setValidatedData,
  setShowValidationSummary,
  setShowAlertValidate,
}) => {
  const [startValidation, setStartValidation] = useState(false);
  const [disableNext, setDisableNext] = useState(true);

  const validateClick = () => {
    setStartValidation(true);

  };

  const handleValidateAndClose = () => {
    handleValidateClick();
    onClose();
  };

  useEffect(() => {
    if (startValidation) {
      setStartValidation(false);
      setShowAlertValidate(true);
      onClose();
    }
  }, [startValidation]);

  useEffect(() => {
    if (migrationSelected !== "table") {
      setDisableNext(false);
    }
  }, [migrationSelected, setDisableNext]);

  let contentComponent;
  switch (migrationSelected) {
    case "table":
      contentComponent = (
        <TableValidation
          selectedList={selectedList}
          startValidation={startValidation}
          databaseType={databaseType}
          setDisableNext={setDisableNext}
          disableNext={disableNext}
          setId={setId}
        />
      );
      break;
    case "DbfsTodbfs":
      contentComponent = (
        <DbfsToVolume
          selectedList={selectedList}
          startValidation={startValidation}
          databaseType={databaseType}
          setId={setId}
          onClose={onClose}
          setValidatedData={setValidatedData}
          setDisableNext={setDisableNext}
        />
      );
      break;

    default:
      contentComponent = <div>{jsonData.wantValidate}</div>;
      break;
  }

  const handleClick = () => {
    switch (migrationSelected) {
      case "table":
      case "DbfsTodbfs":
        validateClick();
        break;
      default:
        handleValidateAndClose();
        break;
    }
  };
  return (
    <BootstrapDialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{
        style: {
          minWidth: "500px",
          minHeight: "150px",
        },
      }}
    >
      <LoadingSpinner open={makingApiCall} />
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
        className={classes["dialog-heading"]}
      >
        {titleValidation}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>{contentComponent}</DialogContent>
      <DialogActions>
        <div className={classes["button-container"]}>
          <ButtonStyled
            label={jsonData.canceltxt}
            background="transparent"
            variant="text"
            border="none"
            color="#24262C"
            onclick={onClose}
          />
          <ButtonStyled
            label={jsonData.validateBtn}
            background="#457ECF"
            variant="contained"
            border="1px solid #457ECF"
            disabled={disableNext}
            onclick={handleClick}
          />
        </div>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ValidationDialog;
