const formatEventLoggedTime = (timestamp) => {
    const dateObj = new Date(timestamp);
    const formattedDate = dateObj.toDateString();
    const formattedTime = dateObj.toTimeString().split(" ")[0];
    return (
      <div>
        {formattedDate}
        <br />
        {formattedTime}
      </div>
    );
  };

  export default formatEventLoggedTime