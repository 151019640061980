import { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import jsonData from "../../Assets/i18n/en.json";
import NotebookStringReplacement from "./NotebookStringReplacement";
import Notebook from "./Notebook";
import NotebookSearch from "./NotebookSearch";
import TableMapping from "../TableMapping/TableMapping"

export default function Notebookfunction(props) {
  const { selectComponent } = props;
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">

            <Tab label={jsonData.cloudLocation} value="1" />
            <Tab label={jsonData.notebookUpgrade} value="2" />
            {/* <Tab label={jsonData.notebookSearch} value="3" /> */}
            <Tab label={jsonData.notebookStringSearchReplacement} value="4" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <TableMapping selectComponent={selectComponent} />
        </TabPanel>
        <TabPanel value="2">
          <Notebook selectComponent={selectComponent} />
        </TabPanel>
        {/* <TabPanel value="3">
          <NotebookSearch selectComponent={selectComponent} />
        </TabPanel> */}
        <TabPanel value="4">
          <NotebookStringReplacement selectComponent={selectComponent} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
