import axios from "axios";
import {
  downloadcsvUrl,
  triggerLineageUtilityUrl,
} from "../../Resources/apiurls";

export async function triggerLineageUtilityCall(post) {
  let returnResponse = [];
  await axios
    .post(triggerLineageUtilityUrl, post, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data.ucxApiResponse;
    })
    .catch((err) => {
      if (err.response && err.response.status === 302) {
        returnResponse = err.response.data;
      }
    });

  return returnResponse;
}

export async function downloadCsvCall(post) {
  let returnResponse = [];
  await axios
    .post(downloadcsvUrl, post, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      returnResponse = response.data.ucxApiResponse;
    })
    .catch((err) => {
      if (err.response && err.response.status === 302) {
        returnResponse = err.response.data;
      }
    });

  return returnResponse;
}
