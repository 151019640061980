import jsonData from "../Assets/i18n/en.json";
const constants = {
  StatusColorClassArr: [
    { status: "PROVISIONED", color: "update_in_progress-color" },
    { status: "UPDATE_IN_PROGRESS", color: "update_in_progress-color" },
    { status: "DELETE_IN_PROGRESS", color: "delete_inprogress-color" },
    { status: "NEW", color: "new-color" },
    { status: "CREATED", color: "green-color" },
    { status: "DELETE_FAILED", color: "red-color" },
    { status: "DELETED", color: "gray-color" },
    { status: "IN_PROGRESS", color: "running-color" },
    { status: "FAILED", color: "failed-color" },
  ],
  names: ["Namespace", "Mountpoints"],
  dbfsToVolume: [
    "Source Data Size",
    "Destination Data Size",
    "Source File Count",
    "Destination File Count",
    "Source Path",
    "Destination Path",
    // "Missing Files In Destination",
    // "Workspace Identifier",
    // "status",
  ],
  statusArray: [
    {
      status: "DATA_EXTRACTION_IN_PROGRESS",
      statustoBeSet: "in_progress",
    },
    {
      status: "DATA_EXTRACTION_FAILED",
      statustoBeSet: "Roll_back_failed",
    },

    {
      status: "EXTRACTION_RESOURCES_CLEANUP_IN_PROGRESS",
      statustoBeSet: "in_progress",
    },

    {
      status: "EXTRACTION_RESOURCES_CLEANUP_FAILED",
      statustoBeSet: "Roll_back_failed",
    },

    {
      status: "BUCKET_SYNCUP_IN_PROGRESS",
      statustoBeSet: "in_progress",
    },

    {
      status: "BUCKET_SYNCUP_FAILED",
      statustoBeSet: "Roll_back_failed",
    },

    {
      status: "SUBMISSION_DATA_CATALOG_IN_PROGRESS",
      statustoBeSet: "in_progress",
    },
    {
      status: "SUBMISSION_DATA_CATALOG_FAILED",
      statustoBeSet: "Roll_back_failed",
    },
    {
      status: "DATA_TRANSFORMATION_AND_CURATION_IN_PROGRESS",
      statustoBeSet: "in_progress",
    },
    {
      status: "DATA_TRANSFORMATION_AND_CURATION_FAILED",
      statustoBeSet: "Roll_back_failed",
    },
    {
      status: "CURATED_DATA_CATALOG_IN_PROGRESS",
      statustoBeSet: "in_progress",
    },
    {
      status: "CURATED_DATA_CATALOG_FAILED",
      statustoBeSet: "Roll_back_failed",
    },
    {
      status: "METASTORE_SYNC_IN_PROGRESS",
      statustoBeSet: "in_progress",
    },
    {
      status: "METASTORE_SYNC_FAILED",
      statustoBeSet: "Roll_back_failed",
    },

    {
      status: "ANALYTICAL_QUERY_IN_PROGRESS",
      statustoBeSet: "in_progress",
    },

    {
      status: "ANALYTICAL_QUERY_FAILED",
      statustoBeSet: "Roll_back_failed",
    },

    {
      status: "ANALYTICAL_RESULT_IN_PROGRESS",
      statustoBeSet: "in_progress",
    },

    {
      status: "ANALYTICAL_RESULT_FAILED",
      statustoBeSet: "Roll_back_failed",
    },
    {
      status: "DATA_MOVEMENT_SUCCESS",
      statustoBeSet: "Success",
    },
    {
      status: "SUCCESS",
      statustoBeSet: "Success",
    },
    {
      status: "ROLL_BACKED",
      statustoBeSet: "Roll_backed",
    },
    {
      status: "ROLL_BACK_FAILED",
      statustoBeSet: "Roll_back_failed",
    },
    {
      status: "NOT_STARTED",
      statustoBeSet: "not_started",
    },
    {
      status: "IN_PROGRESS",
      statustoBeSet: "in_progress",
    },
    {
      status: "ROLL_BACK_IN_PROGRESS",
      statustoBeSet: "in_progress",
    },
    { status: "FAILED", statustoBeSet: "Roll_back_failed" },
    { status: "ABORTED", statustoBeSet: "Roll_back_failed" },
  ],
  tableTypesArray: ["MANAGED", "EXTERNAL", "BOTH"],
  targetTableTypesArray: ["Default", "Managed", "External"],

  workspaceInfoHeader: [
    "Workspace Id",
    "Workspace Name",
    "Creation Time",
    "Deployment Name",
    "Workspace Status",
  ],
  accessModeArray: [
    { name: "Single User", passValue: "SINGLE_USER" },
    { name: "Shared", passValue: "USER_ISOLATION" },
  ],
  LineageKeyValuePair: [
    { name: "Databricks Workflow", passValue: "DATABRICKS_WORKFLOW" },
    { name: "Airflow Workflow", passValue: "AIRFLOW_WORKFLOW" },
    { name: "ADF", passValue: "ADF" },
  ],
  UcxLabelsArray: [
    "Mount Points",
    "Tables & Views",
    "Clusters",
    "Autoloader/Streaming",
    "Review",
  ],
  UcLabelsArray: [
    "Mount Points",
    "Tables & Views",
    "Clusters",
    "Autoloader/Streaming",
  ],
  UcValidationArray: [
    "Mount Points",
    "Tables",
    "Views",
    "Clusters",
    "Autoloader",
  ],

  SummaryValidationHeader: [
    "Schema",
    "Entity Name",
    "Target Catalog",
    "Source Record Count",
    "Target Record Count",
    "Row Status",
    "Column Status",
    "Column Metadata Status",
    "Aggeration Column Validation",
  ],
  tableHeadings: [
    "Source",
    "Mount Points",
    jsonData.stateList,
    jsonData.ValidateEligibleText,
  ],

  SummaryCloneHeader: [
    "Schema",
    "Entity Name",
    "Row Status",
    "Source Target Column Status",
    "Column Metadata Status",
    "Aggeration Column Validation",
  ],

  SummaryHeadArray: [
    "Job Name",
    "Job ID",
    "Start Time",
    "Status",
    "End Time",
    "Submitted by",
    "Details",
  ],
  jobTableHead: ["Job Id", "Status"],
  sqlTableHead: ["SQL Warehouse Id", "Status"],
  clusterTableHead: ["Cluster Id", "Status"],
  notebookM1: ["source", "Path"],
  dbfsCloneHead: ["S.no.", " Cloning fail paths"],
  tableValidateHeadings: ["Source", "Status"],

  ReviewSummaryHeader: ["NoteBook Input Path", "NoteBook Output Path"],
  NotebookStringReplacementHeader: [
    "Notebook Name",
    "Old Path",
    "New Path",
    "Input String",
    "New String",
    "Nummber of lines changed in Notebook",
  ],
  NotebookStringSearchResultHeaders: [
    "Notebook Name",
    "Path",
    "Searched String/Regex",
    "No of Occurances",
    "Position (Cell number:Cell line number)",
  ],
  DepreciatedFunctionHeader: ["Notebook Path", "Missing Function"],

  DepreciatedModuleHeader: ["Notebook Path", "Missing Module"],
  cloneTableHeadings: ["Source URL", "Status"],
  DbfsTableHeadings: ["DBFS path "],
  headerAssesmentSummary: ["Item", "Count"],
  headerTableCountStorage: ["Storage", "Count"],
  headerTableCountSchemaFormat: ["Schema", "Format", "Count"],
  headerReadiness: ["Object Type", "Readiness"],
  headerDatabaseSummary: [
    "Database",
    "Upgrade",
    "Tables",
    "Views",
    "Unsupported",
    "DBFS Root",
    "Delta Tables",
    "Total Grants",
    "Granted Principles",
    "Database Grants",
    "Table Grants",
    "Service Priciple Grants",
    "User Grants",
    "Group Grants",
  ],
  headerMountPoints: ["Name", "Source"],
  headerExternalLoactions: ["Locations"],
  dbfsVolumeSelection: ["Managed", "External"],

  notebookUpdatesOption: [
    "Namespace",
    "Mount Point to External location",
    "DBFS to Volume",
    "Cloud Path to Table",
  ],

  exceptionInDbfsList: [
    "dbfs:/Volume/",
    "dbfs:/Volume",
    "dbfs:/Volumes/",
    "dbfs:/Volumes",
    "dbfs:/databricks-datasets/",
    "dbfs:/databricks-results/",
    "dbfs:/mnt/",
    "dbfs:/volume",
    "dbfs:/volume/",
    "dbfs:/volumes/",
    "dbfs:/volumes",
  ],
  tableCloneMethods: ["Complete", "Sampling", "Query"],
  notebookSourceVersion: ["9.1 LTS", "10.4 LTS", "11.3 LTS", "12.2 LTS"],
  notebookTargetVersion: ["13.3 LTS", "14.3 LTS","15.4 LTS"],
  DashboardWsHeading: [
    `${jsonData.name}`,
    `${jsonData.region}`,
    `${jsonData.workspaceId}`,
    `${jsonData.status}`,
    `${jsonData.metaStoreTxt}`,
    `${jsonData.ucxAssessment}`,
  ],

  AutoLoaderValidationValue: [
    "schema_path_in_dbfs",
    "checkpoint_path_in_dbfs",
    "external_target_checkpoint",
    "external_target_schema",
    "status",
    "description",
  ],
  AutoloaderValidationHeader: [
    "Schema path in dbfs",
    "Checkpoint path in dbfs",
    "External target checkpoint",
    "External target schema",
    "Status",
    "Description",
  ],

  tableTrackingHeader: [
    "S.No.",
    "Input Table / View",
    "Source Table Type",
    "Output Table / View",
    "Status",
    "Description",
    "Details",
  ],
  notebookConstants: {
    notebookUpgrade: "notebookMigration",
    notebookSearch: "notebookSearch",
    notebookStringReplacement: "notebookStringReplacement",
    notebookPreScan: "notebookPreScan"
  },
  notebookTotals: [
    { label: "Notebook Name", value: "notebook_name" },
    { label: "Category", value: "category" },
    { label: "Lines Added", value: "lines_added" },
    { label: "Lines Removed", value: "lines_removed" },
    { label: "Lined Modified", value: "lines_modified" },
    { label: "Total Changes", value: "total_changes" },
  ],
  notebookWiseUpgradeSummary: [
    { value: "original_line_num", label: "Original Line No", width: 110 },
    { value: "original_content", label: "Original Content", clip: 60 },
    { value: "new_content", label: "New Content", clip: 60 },
    {value: "change_category",label: "Category"}
  ],
  noteBookScanHeader: ["Id","Notebook Name","Notebook Path", "Cloud Location", "Table Name",'Creation Time','Partition Column Name','Partition Value','Table Type',"Table Creation Status","Description"],
  noteBookMapHeader:["Id","Notebook Name","Notebook Path","Cloud Location","Table Name",'Creation Time','Partition Column Name','Partition Value',"Table Type","Table Creation Status"],
entityNameMapping: {
  UC_AUTOLOADER_MIGRATION: "UC Autoloader Migration",
  WORKSPACE_OBJECTS_CLONING_ACROSS_WORKSPACE:"Workspace Objects Cloning Across Workspace",
  TABLES_VIEWS_VALIDATION:"Table Views Validation",
  UC_PIPELINE_MIGRATION:"UC Pipeline Migration",
  TABLE_VIEWS_CLONING: "Table View Cloning",
  UC_ASSESSMENT:"UC Assessment",
  MOUNT_POINT_VALIDATION:"MountPoint Validation",
  CLUSTER_CLONING:"Cluster Cloning",
  MOUNT_POINTS_CLONING_ACROSS_WORKSPACE:"Mountpoint Cloning Across Workspace",
  TABLE: "Table",
  UC_VIEWS_MIGRATION:"UC Views Migration",
  MOUNT_POINT:"Mount Point",
  DBFS_CLONING_ACROSS_WORKSPACE:"DBFS Cloning Across Workspace",
  NOTEBOOK_CODE_SCAN:"Notebook Migration",
  CLUSTER: "Cluster",
  JOB_VALIDATION:"Job Validation",
  JOB: "Job",
  DBFS_FILE:"DBFS File",
  SQL_WAREHOUSE_VALIDATION:"SQL Warehouse Validation",
  UC_SQL_CLUSTER_MIGRATION:"UC SQL Cluster Migration",
  UC_DBFS_TO_VOLUME_MIGRATION:"UC DBFS to Volume Migration",
  UC_DBFS_MOUNTS_MIGRATION:"UC DBFS Mounts Migration",
  UC_TABLE_ACLS_MIGRATION:"UC Table ACLS Migration",
  DBFS_TO_VOLUME_VALIDATION:"DBFS to Volume Migration",

}
}

export default constants;
