/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
export const accountIdPattern =
  /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/;
export const userNameEmailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const passwordPattern =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/;
export const dbClientIdPattern =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
export const dbClientSecretPattern = /^dose[0-9a-f]{32}$/;
export const NameWOhPattern = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
export const NamePattern = /^[a-zA-Z_][a-zA-Z0-9_-]*$/;
export const storageRootPattern =
  /^s3:\/\/[a-zA-Z0-9.\-_]+(?:\/[a-zA-Z0-9.\-_]+)*\/?$(?<!\/)$/;
export const srcLocationPattern =
  /^dbfs:\/[a-zA-Z0-9_\-\/]+(?:\/[a-zA-Z0-9_\-]+(?:\.[a-zA-Z0-9]+)?)?\/?$/;
export const targetLoactionPattern =
  /^s3:\/\/[a-zA-Z0-9_-]+(?:\/[a-zA-Z0-9_-]+)*(?:\/[a-zA-Z0-9_-]+\.[a-zA-Z0-9]+)?\/?$/;
export const targetLocationAzurePattern =
  /^abfss:\/\/([a-z0-9-]+)@([a-z0-9-]+\.dfs\.core\.windows\.net)\/?(.*)$(?<!\/)$/;
export const containerAzurePattern =
  /([a-z0-9-]+)@([a-z0-9-]+\.dfs\.core\.windows\.net)\/?(.*)$(?<!\/)$/;
export const AzureAbfsContainerPattern=/^abfss:\/\/([a-z0-9-]+)@([a-z0-9-]+\.dfs\.core\.windows\.net)\/?(.*)$(?<!\/)$/;  
export const awsArnPattern = /arn:aws:iam::\d{12}:[a-zA-Z_0-9+=,.@\-_]+/;
export const awsRoleArnPattern =
  /arn:aws:iam::\d{12}:role\/[a-zA-Z_0-9+=,.@\-_]+/;
export const awsInstanceProfileArnPattern =
  /arn:aws:iam::\d{12}:instance-profile\/[a-zA-Z_0-9+=,.@\-_]+/;
export const databricksUrlPattern =
  /https:\/\/dbc-[a-f0-9]{8}-[a-f0-9]{4}\.cloud\.databricks\.com(?<!\/)$/;
export const notebookInputPathPattern =
  /^\/Workspace\/(Shared(\/.*)?|Users\/([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|[a-f0-9-]{36})(\/.*)?|Applications|Repos)(?<!\/)$/;
export const WorkspacetokenPattern = /^dapi[a-fA-F0-9-]+$/;
export const strgContainerNamePattern = /^[a-z0-9]{3,24}$/;
export const containerKeyPattern = /^[A-Za-z0-9+\/]+={0,2}$/;
export const dbfsPathPattern = /^dbfs:\/(?:[^\/]+)?$/;
export const cloudLocationPattern =
  /^s3:\/\/[a-zA-Z0-9.\-_]+\/[a-zA-Z0-9.\-_]+\/$/;
export const azureDatabricksUrlPattern =
  /https:\/\/[a-zA-Z0-9-]+\.[0-9]+\.azuredatabricks\.net(?<!\/)$/;
export const azureConnectorPattern =
  /^\/subscriptions\/[a-zA-Z0-9-]+\/resourceGroups\/[A-Za-z0-9-]+\/providers\/Microsoft\.Databricks\/accessConnectors\/(?:[A-Za-z0-9-]+)?$/;
export const azureClientIdPattern =
  /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/;
export const azureClientSecretPattern = /^[a-zA-Z0-9~.-]+$/;
export const airflowUrlPattern = /^(https?:\/\/)([a-zA-Z0-9\-\.]+)(:\d{1,5})?$/;
export const percentagePattern = /^[1-9]?[0-9]{1}$|^100$/;
export const subscriptionIdPattern =
  /^[0-9A-Fa-f]{8}-([0-9A-Fa-f]{4}-){3}[0-9A-Fa-f]{12}$/;
