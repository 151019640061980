import { useEffect, useState } from "react";
import {
  MenuItem,
  Paper,
  IconButton,
  InputBase,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Checkbox,
} from "@mui/material";
import jsonData from "../../Assets/i18n/en.json";
import styles from "../NewComponent.module.css";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { getCall, listHmsdataBasecall } from "../../Resources/apicalls";
import { styled } from "@mui/material/styles";
import LoadingSpinner from "../UI/LoadingSpinner";
import EllipsisTooltip from "../UI/Tooltip/Tooltip";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { listGluedataBaseApi } from "../../Resources/apiurls";

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: "0px 0px 0px 0px" ,
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const AccordionSummaryIcon = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosIcon className={`${styles["font-size"]}`} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#fff",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const StyledAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: "white",
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const SelectedCluster = (props) => {
  const {
    setDatabase,
    setDatabaseType,
    refreshSelected,
    dataBaseListChecked,
    setDataBaseListChecked,
  } = props;
  const [makingApiCall, setMakingApiCall] = useState(false);
  const [gluedatabaseList, setGluedatabaseList] = useState([]);
  const [updatedGluedatabaseList, setUpdatedGluedatabaseList] = useState([]);
  const [hmsdatabaseList, setHmsdatabaseList] = useState([]);
  const [updatedHmsdatabaseList, setUpdatedHmsdatabaseList] = useState([]);
  const [expanded, setExpanded] = useState("panel1");
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const [selectedItem, setSelectedItem] = useState("");
  const accountInfoData = useSelector((state) => state.accountInfo);
  const [searchKeyword, setSearchKeyword] = useState([]);
  const handleAccordianChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const listGluedataBase = async () => {
    setMakingApiCall(true);
    let api = `${listGluedataBaseApi}${workspaceSelected.region}`;
    const response = await getCall(api);
    setGluedatabaseList(response?.ucxApiResponse);
    setUpdatedGluedatabaseList(response?.ucxApiResponse);
    setMakingApiCall(false);
  };

  useEffect(() => {
    if (refreshSelected && accountInfoData.cloudType) {
      listGluedataBase();
    }
  }, [refreshSelected, accountInfoData.cloudType]);

  const listHmsDatabase = async () => {
    let Payload = {
      ucxCredentialsData: {
        accId: accountInfoData.accountId,
      },
      workspaceIdentifier: workspaceSelected.workspace_id,
      workspaceUrl: workspaceSelected.workspace_url,
      isUcxInstalled: false,
      oAuth: accountInfoData.credentialType === jsonData.oauthTxt,
    };

    setMakingApiCall(true);
    const resposne = await listHmsdataBasecall(Payload);
    setHmsdatabaseList(resposne?.ucxApiResponse);
    setUpdatedHmsdatabaseList(resposne?.ucxApiResponse);
    setMakingApiCall(false);
  };

  useEffect(() => {
    if (accountInfoData.cloudType === jsonData.awsTxt) listGluedataBase();
  }, [accountInfoData.cloudType]);

  useEffect(() => {
    listHmsDatabase();
  }, [workspaceSelected.workspace_url]);

  const onSearchBoxChange = (key) => {
    setSearchKeyword(key);
    let filteredList;

    if (key.trim() === "") {
      filteredList = gluedatabaseList;
    } else {
      filteredList = gluedatabaseList.filter((data) => data.includes(key));
    }
    setUpdatedGluedatabaseList(filteredList);

    if (key.trim() === "") {
      filteredList = hmsdatabaseList;
    } else {
      filteredList = hmsdatabaseList.filter((data) => data.includes(key));
    }
    setUpdatedHmsdatabaseList(filteredList);
  };

  const selectDbHanfler = (db, type) => {
    setDatabase(db);
    setDatabaseType(type);
    setSelectedItem(db);
  };

  useEffect(() => {
    setDatabase("");
    setDatabaseType("");
    setSelectedItem("");
  }, [workspaceSelected.workspace_url, refreshSelected]);

  const handleDbCheckboxClick = (name) => {
    const isSelected = dataBaseListChecked.includes(name);
    if (isSelected) {
      setDataBaseListChecked((prevSelected) =>
        prevSelected.filter((item) => item !== name)
      );
    } else {
      setDataBaseListChecked((prevSelected) => [...prevSelected, name]);
    }
  };

  return (
    <>
      <LoadingSpinner open={makingApiCall} />
      <div className={`${styles["margin-top-8"]}`}>
        <div>
          <Paper className={`${styles["input-box"]}`}>
            <IconButton type="button" className={`${styles["padding"]}`}>
              <SearchIcon />
            </IconButton>
            <InputBase
              className={`${styles["search-input"]}`}
              placeholder={jsonData.srchDBtxt}
              type="text"
              value={searchKeyword}
              onChange={(e) => onSearchBoxChange(e.target.value)}
            />
          </Paper>
        </div>
      </div>
      <div className={`${styles["margin-top-8"]}`}>
        {accountInfoData.cloudType === jsonData.awsTxt && (
          <StyledAccordion
            expanded={expanded === "panel1"}
            onChange={handleAccordianChange("panel1")}
          >
            <AccordionSummaryIcon
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>{jsonData.awsGlue}</Typography>
            </AccordionSummaryIcon>
            <StyledAccordionDetails className={`${styles["scrollContainer"]}`}>
              {updatedGluedatabaseList?.length > 0 ? (
                updatedGluedatabaseList?.map((item, index) => (
                  <MenuItem
                    key={item}
                    style={
                      item === selectedItem
                        ? { backgroundColor: "#f0f0f0" }
                        : {}
                    }
                    className={`${styles["div-flex"]}`}
                    onClick={() =>
                      selectDbHanfler(item, jsonData.glueValueText)
                    }
                  >
                    <EllipsisTooltip>{item}</EllipsisTooltip>{" "}
                    <Checkbox
                      onChange={() => handleDbCheckboxClick(item)}
                      checked={dataBaseListChecked?.includes(item)}
                    />
                  </MenuItem>
                ))
              ) : (
                <MenuItem>{jsonData.notAvailableText}</MenuItem>
              )}
            </StyledAccordionDetails>
          </StyledAccordion>
        )}
        <StyledAccordion
          expanded={expanded === "panel2"}
          onChange={handleAccordianChange("panel2")}
        >
          <AccordionSummaryIcon
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography>{jsonData.builtinHive}</Typography>
          </AccordionSummaryIcon>
          <StyledAccordionDetails className={`${styles["scrollContainer"]}`}>
            {updatedHmsdatabaseList?.length > 0 ? (
              updatedHmsdatabaseList?.map((item, index) => (
                <MenuItem
                  key={item}
                  style={
                    item === selectedItem ? { backgroundColor: "#f0f0f0" } : {}
                  }
                  className={`${styles["div-flex"]}`}
                  onClick={() => selectDbHanfler(item, jsonData.hmsValueText)}
                >
                  <EllipsisTooltip>{item}</EllipsisTooltip>
                  <Checkbox
                    onChange={() => handleDbCheckboxClick(item)}
                    checked={dataBaseListChecked?.includes(item)}
                  />
                </MenuItem>
              ))
            ) : (
              <MenuItem>{jsonData.notAvailableText}</MenuItem>
            )}
          </StyledAccordionDetails>
        </StyledAccordion>
      </div>
    </>
  );
};

export default SelectedCluster;
