import Grid from "@mui/material/Grid";
import SelectedCluster from "./SelectedCluster";
import Divider from "@mui/material/Divider";
import jsonData from "../../Assets/i18n/en.json";
import {
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getAllPurposeCluster } from "../../Resources/apicalls";
import { useSelector } from "react-redux";
import LoadingSpinner from "../UI/LoadingSpinner";
import DatabaseList from "./DatabaseList";
import styles from "../NewComponent.module.css";
import CachedIcon from "@mui/icons-material/Cached";
import Tooltip from "@mui/material/Tooltip";
import BasicAlerts from "../Common/MessageBanner.jsx/MessageBanner";

function Table(props) {
  const { selectComponent } = props;
  const [clusterList, setClusterList] = useState([]);
  const [makingApiCall, setMakingApiCall] = useState(false);
  const [selectedCluster, setSelectedCluster] = useState("");
  const [database, setDatabase] = useState("");
  const [databaseType, setDatabaseType] = useState("");
  const [dataBaseListChecked, setDataBaseListChecked] = useState([]);
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const accountInfoData = useSelector((state) => state.accountInfo);
  const [refreshSelected, setRefreshSelected] = useState(false);
  const [apiTime, setApiTime] = useState("");
  const [showAlertMigration, setShowAlertMigration] = useState(false);
  const [id, setId] = useState("");
  const [showCloneSummary, setShowCloneSummary] = useState(false);
  const [showAlertValidate, setShowAlertValidate] = useState(false);

  useEffect(() => {
    setDataBaseListChecked([]);
  }, [databaseType]);

  useEffect(() => {
    if (showAlertMigration) {
      setTimeout(() => {
        setShowAlertMigration(false);
      }, 60000);
    }
  }, [showAlertMigration]);

  const getAllPurposeClusterList = async () => {
    let postbody = {
      ucxCredentialsData: {
        accId: accountInfoData.accountId,
      },
      workspaceUrl: workspaceSelected.workspace_url,
      workspaceIdentifier: workspaceSelected.workspace_id,
      ucxPayload: null,
      ucxInstalled: false,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
    };
    setMakingApiCall(true);
    let response = await getAllPurposeCluster(postbody);
    setClusterList(response.filter((data) => data.state === "RUNNING"));
    setMakingApiCall(false);
    setApiTime(new Date());
  };

  useEffect(() => {
    getAllPurposeClusterList();
    setSelectedCluster("");
  }, [workspaceSelected]);

  const handleChange = (event) => {
    setSelectedCluster(event.target.value);
  };
  useEffect(() => {
    if (databaseType === jsonData.hmsValueText) setDatabase("");
  }, [selectedCluster]);

  const hadleRefresh = () => {
    getAllPurposeClusterList();
    setSelectedCluster("");
    setDatabase("");
    setDatabaseType("");
    setDataBaseListChecked([]);
    setRefreshSelected(true);
  };

  return (
    <>
      {showAlertMigration && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startMigrationmsg} <strong>{id}</strong>{" "}
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertMigration}
          showReview={showAlertMigration}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      {showAlertValidate && !makingApiCall && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startValidatemsg} <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertValidate}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      {showCloneSummary && !makingApiCall && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startClonemsg} <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowCloneSummary}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      <LoadingSpinner open={makingApiCall} />
      <div className={`${styles["display-align"]}`}>
        <Typography className={`${styles["basic-details-heading"]}`}>
          {jsonData.catalogExplorer}
        </Typography>
        <div className={`${styles["select-refresh"]}`}>
          <Tooltip title={`${jsonData.lastRefreshed} ${apiTime}`}>
            <CachedIcon
              onClick={hadleRefresh}
              className={`${styles["margin-left-8"]}`}
            />
          </Tooltip>
        </div>
      </div>

      <Divider />
      <div className={`${styles["padding-top-4"]}`}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <SelectedCluster
              setDatabase={setDatabase}
              databaseType={databaseType}
              setDatabaseType={setDatabaseType}
              clusterId={selectedCluster}
              setDataBaseListChecked={setDataBaseListChecked}
              dataBaseListChecked={dataBaseListChecked}
              refreshSelected={refreshSelected}
              setShowCloneSummary={setShowCloneSummary}
            />
          </Grid>

          {database ? (
            <Grid item xs={9}>
              <DatabaseList
              setDataBaseListChecked={setDataBaseListChecked}
                region={workspaceSelected.region}
                clusterId={selectedCluster}
                dataBaseListChecked={dataBaseListChecked}
                database={database}
                databaseType={databaseType}
                setShowAlertMigration={setShowAlertMigration}
                setShowAlertValidate={setShowAlertValidate}
                setId={setId}
                clusterList={clusterList}
                setShowCloneSummary={setShowCloneSummary}
              />
            </Grid>
          ) : (
            <Grid item xs={8} mt={16}>
              <div className={`${styles["display-centre"]}`}>
                {jsonData.noDatabaseSelected}
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}

export default Table;
