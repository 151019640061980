import jsonData from "../../Assets/i18n/en.json";
import styles from "../NewComponent.module.css";
import { useEffect, useState } from "react";
import AcrossWorkspaceInfo from "../Common/AccrossWorkspaceInfo.jsx/AcrossWorkspaceInfo";
import {
  storageRootPattern,
  targetLocationAzurePattern,
} from "../../utils/regex";
import { useSelector } from "react-redux";
import { cloneDbfsToDbfsAcrossCall } from "../../Resources/apicalls";
import { formattedDbfsName } from "../Common/Migration/formattedTableNames";
import { GenerateUid } from "../../utils/GenerateUid/GenerateUid";
import UcmaTextField from "../UI/Form/TextField";

function DbfsTodbfsClone(props) {
  const {
    setDisableNext,
    startCloning,
    selectedList,
    setShowCloneSummary,
    setId,
  } = props;

  const [externalWsToken, setExternalWsToken] = useState("");
  const [externalWsUrl, setExternalWsUrl] = useState("");
  const [s3Path, setS3Path] = useState("");
  const [s3PathErrorMessage, setS3PathErrorMessage] = useState("");
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice.selectedWorkspace[0]
  );
  const workspaceConfig = useSelector(
    (state) => state.workspaceConfig.data[workspaceSelected.workspace_id]
  );
  const accountInfoData = useSelector((state) => state.accountInfo);

  async function handleCloneDbfs() {
    let triggerId = GenerateUid();
    setId(triggerId);
    let payloadValidateCloneAcross = {
      accId: workspaceSelected.account_id,
      workspaceIdentifier: workspaceSelected.workspace_id,
      workspaceName: workspaceSelected.workspace_name,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      workspaceUrl: workspaceSelected.workspace_url,
      crossWorkspaceUrl: externalWsUrl,
      triggerId: triggerId,
      crossWorkspacePatToken: externalWsToken,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      tableViewCloneAcrossWorkspace: {
        dataRoleArn: workspaceConfig.awsRoleArn,
        runAsJob: accountInfoData.userId
          ? accountInfoData.userId
          : accountInfoData.OAuthUser.userName,
        glue: false,
      },
      dbfsCloningRequest: {
        azureStorageAccountName: workspaceConfig.azureStorageAccountName,
        azureStorageAccountKey: workspaceConfig.azureStorageAccountKey,
        dbfsList: formattedDbfsName(selectedList),
        s3Path: s3Path,
        dbfs: "dbfs:/",
      },
    };
    cloneDbfsToDbfsAcrossCall(payloadValidateCloneAcross);
    setShowCloneSummary(true);
  }

  useEffect(() => {
    if (startCloning) handleCloneDbfs();
  }, [startCloning]);

  const enableNext = () => {
    if (
      s3Path === "" ||
      externalWsToken === "" ||
      externalWsUrl === "" ||
      s3PathErrorMessage 
    ) {
      setDisableNext(true);
    } else setDisableNext(false);
  };

  useEffect(() => {
    enableNext();
  }, [s3Path, externalWsToken, externalWsUrl, s3PathErrorMessage]);

  return (
    <>
      <div className={styles["margin-top-20"]} />
      <UcmaTextField
            name={jsonData.location}
            info={jsonData.locationInfoText}
            value={s3Path}
            onChangeValue={setS3Path}
            pattern={accountInfoData.cloudType === jsonData.awsTxt
              ? storageRootPattern
              : targetLocationAzurePattern}
            placeholder={
              accountInfoData.cloudType === jsonData.awsTxt
                ? jsonData.cloudLocationSampleValue
                : jsonData.azureStorageLocationPh
            }
            setDisableSubmit={(disable)=>setS3PathErrorMessage(disable)}
            required
            isDialog
     
    />
      <AcrossWorkspaceInfo
        setDisableNext={setDisableNext}
        setExternalWsToken={setExternalWsToken}
        setExternalWsUrl={setExternalWsUrl}
      />
    </>
  );
}

export default DbfsTodbfsClone;
