import ButtonStyled from "../../UI/NavigationButtons/Buttons";
import jsonData from '../../../Assets/i18n/en.json'
import styles from "../../NewComponent.module.css";

export default function UcmaSubmitButtons(props) {
    const {handleReset, handleSubmit, disableSubmit, secondaryButtonLabel, primaryButtonLabel} = props;
    return (
        <div className={styles["button-container"]}>
            <ButtonStyled
                label={secondaryButtonLabel ? secondaryButtonLabel: jsonData.clearTxt}
                background="transparent"
                variant="text"
                border="none"
                color="#24262C"
                onclick={handleReset}
            />
            <ButtonStyled
                label={primaryButtonLabel? primaryButtonLabel: jsonData.submit}
                background="#457ECF"
                variant="contained"
                border="1px solid #457ECF"
                disabled={disableSubmit}
                onclick={handleSubmit}
            />
        </div>)
}