import styles from "../NewComponent.module.css";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { dbfsToVolume } from "../../Resources/apiurls";
import jsonData from "../../Assets/i18n/en.json";
import constants from "../../utils/constant";
import { useState, useEffect } from "react";
import {
  NamePattern,
  targetLocationAzurePattern,
  storageRootPattern,
} from "../../utils/regex";
import { useSelector } from "react-redux";
import {
  dbfsToVolumeValidation,
  postCall,
} from "../../Resources/apicalls";
import { formattedDbfsName } from "../Common/Migration/formattedTableNames";
import LoadingSpinner from "../UI/LoadingSpinner";
import { GenerateUid } from "../../utils/GenerateUid/GenerateUid";
import UcmaTextField from "../UI/Form/TextField";

function DbfsToVolume(props) {
  const {
    setDisableNext,
    startMigration,
    startValidation,
    selectedList,
    setId,
    disableNext,
    onClose,
  } = props;
  const [typeSelected, setTypeSelected] = useState("");
  const [catalogName, setCatalogName] = useState("");
  const [schemaName, setSchemaName] = useState("");
  const [cloudLocation, setCloudLocation] = useState("");
  const [makingApiCall, setMakingApiCall] = useState(false);
  const [showAlertMigration, setShowAlertMigration] = useState(false);
  const [volumeName, setVolumeName] = useState("");

  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const workspaceConfig = useSelector(
    (state) => state.workspaceConfig.data[workspaceSelected.workspace_id]
  );
  const accountInfoData = useSelector((state) => state.accountInfo);

  const enableNext = () => {
    if (
      catalogName === "" ||
      schemaName === "" ||
      typeSelected === "" ||
      volumeName === "" ||
      (typeSelected === "External" && cloudLocation === "")
    ) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  };

  useEffect(() => {
    enableNext();
  }, [
    catalogName,
    schemaName,
    volumeName,
    accountInfoData.cloudType,
    typeSelected,
    cloudLocation,
    disableNext,
  ]);
  const handleTypeSelected = (event) => {
    const selectedVersion = event.target.value;
    setTypeSelected(selectedVersion);
  };

  useEffect(() => {
    if (showAlertMigration) {
      setTimeout(() => {
        setShowAlertMigration(false);
      }, 60000);
    }
  }, [showAlertMigration]);

  function createCommonPayload() {
    let dbfsToVolumeMigrate = {
      dbfsPath: formattedDbfsName(selectedList),
      catalogName: catalogName,
      schemaName: schemaName,
      isVolumeNameSameAsDbfsFolderName: "True",
      volumeType:
        typeSelected === constants.dbfsVolumeSelection[0]
          ? "MANAGED"
          : "EXTERNAL",
      volumeName: volumeName,
      s3Path: cloudLocation,
    };
    let common = {
      workspaceUrl: workspaceSelected.workspace_url,
      workspaceIdentifier: workspaceSelected.workspace_id,
      dataRoleArn:
        typeSelected === "External" ? workspaceConfig?.awsRoleArn : null,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
    };
    return { dbfsToVolumeMigrate, common };
  }

  async function submitMigration() {
    let triggerId = GenerateUid();
    setId(triggerId);

    let commonPayload = createCommonPayload();
    commonPayload = {
      ...commonPayload.common,
      ucxCredentialsData: {
        accId: workspaceSelected.account_id,
      },
      ucxPayload: {
        warehouseID: [],
        runJobsAs: accountInfoData.userId
          ? accountInfoData.userId
          : accountInfoData.OAuthUser.userName,
        dbfsToVolumeMigrate: {
          ...commonPayload.dbfsToVolumeMigrate,
        },
      },
      workspaceName: workspaceSelected.workspace_name,
      workspaceIdentifier: workspaceSelected.workspace_id,

      region: workspaceSelected.region,
      triggerId: triggerId,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      containerName: workspaceConfig?.azureStorageAccountName,
      storageAccountKey: workspaceConfig?.azureStorageAccountKey,
    };

    const postBody = JSON.stringify(commonPayload);
    const api = `${dbfsToVolume}${workspaceSelected.workspace_id}`;

    setMakingApiCall(true);
    await postCall(api,postBody);
    resetValue();
    setMakingApiCall(false);
  }

  async function handleValidateClick() {
    let triggerId = GenerateUid();
    setId(triggerId);

    let commonPayload = createCommonPayload();
    commonPayload = {
      ...commonPayload.common,
      dbfsToVolumeMigrate: {
        ...commonPayload.dbfsToVolumeMigrate,
      },
      accId: workspaceSelected.account_id,
      workspaceIdentifier: workspaceSelected.workspace_id,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      triggerId: triggerId,
    };

    dbfsToVolumeValidation(commonPayload);
    resetValue();
    onClose();
  }

  useEffect(() => {
    if (startMigration) submitMigration();
  }, [startMigration]);

  useEffect(() => {
    if (startValidation) {
      handleValidateClick();
    }
  }, [startValidation]);

  const resetValue = () => {
    setCloudLocation("");
    setCatalogName("");
    setCloudLocation("");
    setSchemaName("");
    setVolumeName("");
    setTypeSelected("");
  };

  return (
    <>
      <LoadingSpinner open={makingApiCall}></LoadingSpinner>

      <UcmaTextField
        name={jsonData.catalogName}
        info={jsonData.catalogNameInfo}
        value={catalogName}
        onChangeValue={setCatalogName}
        pattern={NamePattern}
        placeholder={jsonData.unityCatalogNamePlaceholder}
        setDisableSubmit={setDisableNext}
        isDialog
        required
      />

      <UcmaTextField
        name={jsonData.schemaName}
        info={jsonData.schemaNameInfo}
        value={schemaName}
        onChangeValue={setSchemaName}
        pattern={NamePattern}
        placeholder={jsonData.schemaName}
        setDisableSubmit={setDisableNext}
        isDialog
        required
      />

      <UcmaTextField
        name={jsonData.volumeName}
        info={jsonData.volumeName}
        value={volumeName}
        onChangeValue={setVolumeName}
        pattern={NamePattern}
        placeholder={jsonData.volumePlaceholder}
        setDisableSubmit={setDisableNext}
        isDialog
        required
      />

      <div className={`${styles["display"]} ${styles["margin-top-26"]}`}>
        <FormControl
          sx={{ "& > :not(style)": { width: "30.75rem" } }}
          size="small"
        >
          <InputLabel id="demo-select-small-label">
            {jsonData.volumeType}
          </InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={typeSelected}
            label={jsonData.volumeType}
            onChange={handleTypeSelected}
          >
            {constants.dbfsVolumeSelection?.map((version) => (
              <MenuItem key={version} value={version}>
                <>{version}</>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {typeSelected === "External" ? (
        <UcmaTextField
          name={jsonData.cloudLocation}
          info={jsonData.cloudLocationInfo}
          value={cloudLocation}
          onChangeValue={setCloudLocation}
          pattern={
            accountInfoData.cloudType === jsonData.awsTxt
              ? storageRootPattern
              : targetLocationAzurePattern
          }
          placeholder={
            accountInfoData.cloudType === jsonData.awsTxt
              ? jsonData.cloudLocationSampleValue
              : jsonData.azureStorageLocationPh
          }
          setDisableSubmit={setDisableNext}
          isDialog
          required
        />
      ) : null}
    </>
  );
}

export default DbfsToVolume;
