export const timeout60Sec = (setAlert) => {
  setTimeout(() => {
    setAlert(false);
  }, 60000);
};

export const timeout30Sec = (setAlert) => {
  setTimeout(() => {
    setAlert(false);
  }, 30000);
};
