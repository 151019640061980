import classes from "./NavigationHeader.module.css";
import * as React from "react";
import { Typography, Grid, Button, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import EllipsisTooltip from "../../UI/Tooltip/Tooltip";
import jsonData from "../../../Assets/i18n/en.json";
import { useSelector } from "react-redux";
import MetaStoreAvailable from "./MetaStoreAvailable";
import constants from "../../../utils/constant";
import SettingsSharpIcon from "@mui/icons-material/SettingsSharp";
import Configue from "../../Configue/Configue";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { handleDownload } from "../../../utils/DownloadRef/DownloadRef";
const options = [
  { label: "Refresh", action: "refresh" },
  { label: "Download", action: "download" },
];
const ITEM_HEIGHT = 48;

function NavigationHeader(props) {
  const {
    metastoreData,
    setDashboardRefresh,
    setOpenAssessment,
    element,
  } = props;
  const [startMigration, setStartMigration] = useState(false);
  const [startValidation, setStartValidation] = useState(false);
  const [startAssessment, setStartAssessment] = useState(false);
  const [viewMetaStore, setViewMetaStore] = useState(false);
  const [createMetaStore, setCreateMetaStore] = useState(false);
  const [viewWorkSpace, setViewWorkSpace] = useState(false);
  const [viewSummary, setViewSummary] = useState(false);
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const accountInfoData = useSelector((state) => state.accountInfo);

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (startMigration) {
      props.selectComponent(jsonData.migration);
      setStartMigration(false);
    }
  }, [startMigration]);

  useEffect(() => {
    if (startValidation) {
      props.selectComponent(jsonData.validationText);
      setStartValidation(false);
    }
  }, [startValidation]);

  useEffect(() => {
    if (createMetaStore) {
      props.selectComponent(jsonData.createMetastore);
      setCreateMetaStore(false);
    }
  }, [createMetaStore]);

  useEffect(() => {
    if (viewMetaStore) {
      props.selectComponent(jsonData.viewMetastore);
      setViewMetaStore(false);
    }
  }, [viewMetaStore]);

  useEffect(() => {
    if (viewWorkSpace) {
      props.selectComponent(jsonData.workspace);
      setViewWorkSpace(false);
    }
  }, [viewWorkSpace]);

  useEffect(() => {
    if (viewSummary) {
      props.selectComponent(jsonData.workspaceSummary);
      setViewSummary(false);
    }
  }, [viewSummary]);
  useEffect(() => {
    if (startAssessment) {
      props.selectComponent(jsonData.assessmentText);
      setStartAssessment(false);
    }
  }, [startAssessment]);

  const handleRefresh = () => {
    setDashboardRefresh(true);
  };

  const handleMenuOptionClick = (action) => {
    if (action === "refresh") {
      handleRefresh();
    } else if (action === "download") {
      let name =  `${workspaceSelected.workspace_name}_Assessment_report.pdf`;
      handleDownload(element,name);
    }
    handleClose();
  };
  return (
    <>
      {props.component === jsonData.dashboard && (
        <div className={classes.header}>
          <div>
            <div className={classes.refreshicon}>
              <div>
                <Typography className={classes.font}>
                  {jsonData.dashboard}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <Tooltip title={`${jsonData.configTxt}`}>
                  <SettingsSharpIcon
                    style={{ height: "100%" }}
                    onClick={handleOpenDialog}
                  />
                </Tooltip>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  slotProps={{
                    paper: {
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "auto",
                      },
                    },
                  }}
                >
                  {options.map((option) => (
                    <MenuItem
                      key={option.action}
                      onClick={() => handleMenuOptionClick(option.action)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </div>

            <div className={classes.breadcrumb}>
              <div className={classes.headingpadding}>
                {jsonData.workspaceDetails}
              </div>
              <Grid container spacing={1}>
                {constants.DashboardWsHeading.map((heading, index) => (
                  <Grid item xs={2} key={index} className={classes.color}>
                    {heading}
                  </Grid>
                ))}
                <Grid item xs={2}>
                  <EllipsisTooltip>
                    {workspaceSelected?.workspace_name}
                  </EllipsisTooltip>
                </Grid>
                <Grid item xs={2}>
                  {workspaceSelected?.region}
                </Grid>
                <Grid item xs={2}>
                  {workspaceSelected?.workspace_id}
                </Grid>
                <Grid item xs={2}>
                  {workspaceSelected?.workspace_status}
                </Grid>
                {accountInfoData.cloudType === jsonData.awsTxt ? (
                  <Grid item xs={2}>
                    {MetaStoreAvailable(
                      metastoreData,
                      workspaceSelected?.region
                    ) ? (
                      jsonData.availableText
                    ) : (
                      <Button
                        className={classes.buttonWithoutPadding}
                        onClick={() => setCreateMetaStore(true)}
                      >
                        {jsonData.createMetastore}
                      </Button>
                    )}
                  </Grid>
                ) : (
                  <Grid item xs={2}>
                    {jsonData.availableText}
                  </Grid>
                )}

                <Grid item xs={2}>
                  <Button
                    className={classes.buttonWithoutPadding}
                    onClick={() => setOpenAssessment(true)}
                  >
                    {jsonData.startUcMigration}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
          <Configue openDialog={openDialog} setOpenDialog={setOpenDialog} />
        </div>
      )}
    </>
  );
}

export default NavigationHeader;
