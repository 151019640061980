import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  InputBase,
} from "@mui/material";
import jsonData from "../../Assets/i18n/en.json";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState, useRef } from "react";
import LoadingSpinner from "../UI/LoadingSpinner";
import { useSelector } from "react-redux";
import classes from "./Jobs.module.css";
import { Done } from "@mui/icons-material";
import constants from "../../utils/constant";
import CommonMigration from "../Common/Migration/Migration";
import ValidationReview from "../UcValidation/ValidationReview";
import {
  cloneJobCall,
  deleteJobCall,
  getWorkspaceJobList,
  validateJobCall,
  cloneJobsAcrossCall,
  getJobCount,
} from "../../Resources/apicalls";
import NoData from "../Common/NoData/NoData";
import Tooltip from "@mui/material/Tooltip";
import CachedIcon from "@mui/icons-material/Cached";
import BasicAlerts from "../Common/MessageBanner.jsx/MessageBanner";
import {
  getDataSecurityModesList,
  JobMigrateEligiblityDataSecurityMode,
} from "../Common/ucxFunction/getAccessMode";
import { timeout30Sec, timeout60Sec } from "../../utils/TimeOut/TimeOut";
import { GenerateUid } from "../../utils/GenerateUid/GenerateUid";

function Jobs(props) {
  const { selectComponent } = props;
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice.selectedWorkspace[0]
  );
  const [makingApiCall, setMakingApiCall] = useState(false);
  const accountInfoData = useSelector((state) => state.accountInfo);
  const [migrateEligibleList, setMigrateEligibleList] = useState([]);
  const [validateEligibleList, setValidateEligibleList] = useState([]);
  const [selectedJobList, setSelectedJobList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [filterJobList, setFilterJobList] = useState([]);
  const [showReview, setShowReview] = useState(false);
  const [validatedData, setValidatedData] = useState({});
  const [close, setClose] = useState(false);
  const [listSelected, setListSelected] = useState(false);
  const [apiTime, setApiTime] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertMigration, setShowAlertMigration] = useState(false);
  const [showAlertClone, setShowAlertClone] = useState(false);
  const [showAlertValidate, setShowAlertValidate] = useState(false);
  const [id, setId] = useState(false);
  const [cloneType, setCloneType] = useState(jsonData.withInValue);
  const [externalWsUrl, setExternalWsUrl] = useState("");
  const [externalWsToken, setExternalWsToken] = useState("");
  const [migrateBtnDisable, setMigrateBtnDisable] = useState(true);
  const [validateBtnDisable, setValidateBtnDisable] = useState(true);
  const [jobCount, setJobCount] = useState(0);
  const [searchMode, setSearchMode] = useState(false);

  const [searchKeyword, setSearchKeyword] = useState("");

  let isRefresh = false;

  const tableRef = useRef(0);

  const [jobData, setJobsData] = useState(false);
  const [pageToken, setPageToken] = useState("");
  const handleCheckboxClick = (name) => {
    const isSelected = selectedJobList.includes(name);

    if (isSelected) {
      setSelectedJobList((prevSelected) =>
        prevSelected.filter((item) => item !== name)
      );
    } else {
      setSelectedJobList((prevSelected) => [...prevSelected, name]);
    }
  };
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = tableRef?.current;
    const threshold = 50;
    const isBottom = scrollTop + clientHeight + threshold >= scrollHeight;
    if (isBottom && !makingApiCall) {
      if (jobData.has_more) {
        getJobClusters();
      }
    }
  };

  useEffect(() => {
    timeout60Sec(setShowAlertMigration);
    timeout30Sec(setShowAlert);
  }, [showAlertMigration, showAlert]);

  const getJobClusters = async () => {
    setMakingApiCall(true);

    let postbody = {
      ucxCredentialsData: {
        accId: workspaceSelected.account_id,
      },
      workspaceIdentifier: workspaceSelected.workspace_id,
      ucxPayload: null,
      pageToken: isRefresh ? "" : pageToken,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      workspaceUrl: workspaceSelected.workspace_url,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      limit: 50,
    };
    const response = await getWorkspaceJobList(postbody);

    setJobsData(response);
    const newJobs = response.jobs;
    if (response.has_more) {
      setPageToken(response.next_page_token);
    } else {
      setSearchMode(false);
    }
    if (isRefresh) {
      setJobList(newJobs);
    } else if (Array.isArray(newJobs)) {
      setJobList((prevJobList) => [...prevJobList, ...newJobs]);
    } else {
      console.error(
        "response of jobs is not an array or is undefined:",
        newJobs
      );
    }

    setMakingApiCall(false);
    setApiTime(new Date());
  };

  useEffect(() => {
    setSelectedJobList([]);
    setClose(false);
  }, [close]);

  let payload = {
    accId: workspaceSelected.account_id,
    workspaceIdentifier: workspaceSelected.workspace_id,
    workspaceName: workspaceSelected.workspace_name,
    jobId: selectedList?.map((cluster) => cluster.job_id),
    OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
    workspaceUrl: workspaceSelected.workspace_url,
    cloudType: accountInfoData.cloudType,
    patAccess: accountInfoData.patAccess,
  };

  const getJobClusterCount = async () => {
    let Payload = {
      ucxCredentialsData: {
        accId: workspaceSelected.account_id,
      },
      workspaceIdentifier: workspaceSelected.workspace_id,
      workspaceUrl: workspaceSelected.workspace_url,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      ucxInstalled: false,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
    };

    let countResponse = await getJobCount(Payload);
    setJobCount(countResponse.ucxApiResponse);
  };

  useEffect(() => {
    setJobList([]);
    setSearchMode(false);
    getJobClusters();
    getJobClusterCount();
  }, [workspaceSelected.workspace_url]);

  useEffect(() => {
    if (jobList) {
      setMigrateEligibleList(
        jobList
          ?.filter(
            (record) =>
              record?.settings?.name &&
              !record?.settings?.name?.startsWith("[UCX]") &&
              record?.settings?.job_clusters &&
              JobMigrateEligiblityDataSecurityMode(
                getDataSecurityModesList(record?.settings?.job_clusters)
              )
          )
          .map((job) => job.job_id)
      );

      setValidateEligibleList(
        jobList.filter((job) => job?.stateList?.includes("MIGRATED"))
      )?.map((option) => option?.job_id);
    }
  }, [workspaceSelected.workspace_url, jobList]);

  useEffect(() => {
    setSelectedList(
      jobList?.filter((cluster) => selectedJobList?.includes(cluster.job_id))
    );
  }, [selectedJobList]);
  useEffect(() => {
    if (tableRef.current) {
      tableRef?.current?.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableRef.current) {
        tableRef?.current?.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll, makingApiCall]);

  async function handleValidateClick() {
    let triggerId = GenerateUid();
    setId(triggerId);
    validateJobCall({ ...payload, triggerId: triggerId });
    setShowAlertValidate(true);
    handleRefresh();
  }

  async function handleCloneClick() {
    let triggerId = GenerateUid();
    setId(triggerId);

    if (cloneType === jsonData.withInValue) {
      cloneJobCall({ ...payload, triggerId: triggerId });
    }
    if (cloneType === jsonData.acrossValue) {
      let payloadValidateCloneAcross = {
        ...payload,
        crossWorkspaceUrl: externalWsUrl,
        crossWorkspacePatToken: externalWsToken,
        triggerId: triggerId,
      };

      cloneJobsAcrossCall(payloadValidateCloneAcross);
    }

    setShowAlertClone(true);
    handleRefresh();
  }

  async function handleDeleteClick() {
    setMakingApiCall(true);
    let response = await deleteJobCall(payload);
    setMakingApiCall(false);
    handleRefresh();
    if (response?.ucxApiResponse) {
      setValidatedData(response?.ucxApiResponse);
      setClose(true);
      setShowAlert(true);
    }
  }
  const handleRefresh = () => {
    isRefresh = true;
    setPageToken("");
    getJobClusters();
    setSearchMode(false);
    getJobClusterCount();
    setSelectedJobList([]);
    setListSelected(false);
  };

  const handleAllCheckboxClick = (event) => {
    if (event.target.checked) {
      setSelectedJobList(jobList?.map((item) => item.job_id) || []);
    } else {
      setSelectedJobList([]);
    }
  };
  const getAllJobs = () => {
    setSearchMode(true);
  };
  useEffect(() => {
    if (searchMode && !makingApiCall && jobData.has_more) {
      getJobClusters();
    }
  }, [jobList, searchMode, jobData, makingApiCall]);

  useEffect(() => {
    if (selectedList?.length > 0) setListSelected(true);
    else setListSelected(false);
  }, [selectedList]);

  useEffect(() => {
    let migrateBtn = false;

    for (let index in selectedJobList) {
      if (!migrateEligibleList.includes(selectedJobList[index])) {
        migrateBtn = true;
        break;
      }
    }
    setMigrateBtnDisable(migrateBtn);
  }, [selectedJobList]);

  useEffect(() => {
    let validateBtn = false;
    for (let index in selectedJobList) {
      jobList?.forEach((item) => {
        if (
          item.job_id === selectedJobList[index] &&
          !item.StatesList.includes("MIGRATED")
        ) {
          validateBtn = true;
        }
      });
    }
    setValidateBtnDisable(validateBtn);
  }, [selectedJobList]);

  useEffect(() => {
    if (searchKeyword?.length < 1) setFilterJobList(jobList);
    else
      setFilterJobList(
        jobList.filter(
          (item) =>
            item?.settings?.name
              ?.toLowerCase()
              .includes(searchKeyword.toLowerCase()) ||
            item?.job_id?.toString().includes(searchKeyword)
        )
      );
  }, [searchKeyword, jobList]);

  if (showReview) {
    return (
      <ValidationReview
        headValues={constants.jobTableHead}
        cellValuesAsObjects={validatedData}
        setShowReview={setShowReview}
        heading={jsonData.jobsValidation}
      />
    );
  }

  if (!jobList?.length && makingApiCall === false) {
    return <NoData label={jsonData.noData} />;
  }

  return (
    <>
      {showAlert && !makingApiCall && (
        <BasicAlerts
          severity={validatedData === "deleted" ? "success" : "error"}
          label={
            validatedData === "deleted"
              ? "Deleted Successfully"
              : "Deletion Unsuccessfull"
          }
          setShowReview={setShowAlert}
          selectComponent={selectComponent}
        />
      )}

      {showAlertValidate && !makingApiCall && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startValidatemsg} <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertValidate}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      {showAlertMigration && !makingApiCall && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startMigrationmsg} <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertMigration}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      {showAlertClone && !makingApiCall && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startClonemsg} <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertClone}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      <LoadingSpinner open={makingApiCall} />
      <div className={classes["display"]}>
        <div className={classes["button-container"]}>
          <div className={classes["basic-details-heading"]}>
            {jsonData.listOfJobs} ({" "}
            {searchKeyword.length > 0 ? filterJobList.length : jobCount} )
          </div>
        </div>
        <div className={classes["srch-flex"]}>
          <Paper className={`${classes["input-box"]}`}>
            <IconButton
              type="button"
              className={`${classes["padding"]}`}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              className={`${classes["search-input"]}`}
              placeholder={jsonData.srchNmId}
              type="text"
              onFocus={getAllJobs}
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </Paper>
          <CommonMigration
            migrationSelected="jobs"
            selectedList={selectedList}
            setClose={setClose}
            makingApiCall={makingApiCall}
            listSelected={listSelected}
            setListSelected={setListSelected}
            handleValidateClick={handleValidateClick}
            handleCloneClick={handleCloneClick}
            handleDeleteClick={handleDeleteClick}
            title={jsonData.jobsMigration}
            setShowAlertMigration={setShowAlertMigration}
            setId={setId}
            setCloneType={setCloneType}
            setExternalWsToken={setExternalWsToken}
            setExternalWsUrl={setExternalWsUrl}
            migrateBtnDisable={migrateBtnDisable}
            validateBtnDisable={validateBtnDisable}
            showMigrate={true}
            showValidate={true}
            showClone={true}
            showDelete={true}
            cloneType={cloneType}
          />
          <div className={classes["float-right"]}>
            <Tooltip title={`${jsonData.lastRefreshed}${apiTime}`}>
              <CachedIcon onClick={handleRefresh} />
            </Tooltip>
          </div>
        </div>
      </div>

      <TableContainer
        ref={tableRef}
        component={Paper}
        className={classes["table-container"]}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  onClick={handleAllCheckboxClick}
                  checked={selectedList?.length === jobList?.length}
                />
                {"  "} {jsonData.jobListText}
              </TableCell>
              <TableCell>{jsonData.idTxt}</TableCell>
              <TableCell>{jsonData.createdByText}</TableCell>
              <TableCell>{jsonData.stateList}</TableCell>
              <TableCell>{jsonData.migrateEligibleText}</TableCell>
              <TableCell>{jsonData.ValidateEligibleText}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterJobList?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    onClick={() => handleCheckboxClick(item.job_id)}
                    checked={selectedJobList.includes(item.job_id)}
                  />{" "}
                  {item.settings.name}
                </TableCell>
                <TableCell>{item.job_id}</TableCell>
                <TableCell>{item.creator_user_name}</TableCell>
                <TableCell>
                  {item.StatesList[item.StatesList.length - 1]}
                </TableCell>
                <TableCell>
                  {migrateEligibleList.includes(item.job_id) ? <Done /> : "-"}
                </TableCell>
                <TableCell>
                  {item.StatesList.includes("MIGRATED") ? <Done /> : "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Jobs;
