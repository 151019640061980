import * as React from "react";
import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import {
  Box,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  Select,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAccountUserList, postCall } from "../../../Resources/apicalls";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import styles from "./SideNav.module.css";
import { ExpandMore } from "@mui/icons-material";
import jsonData from "../../../Assets/i18n/en.json";
import { logoutApi } from "../../../Resources/apiurls";
import MenuIcon from "../../../Assets/Icons/menu.svg";
import { workspaceActions } from "../../../store/slice/workspace-slice";
import EllipsisTooltip from "../../UI/Tooltip/Tooltip";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import TerminalOutlinedIcon from "@mui/icons-material/TerminalOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import MotionPhotosAutoOutlinedIcon from "@mui/icons-material/MotionPhotosAutoOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { accountInfoActions } from "../../../store/slice/accountInfo-slice";
import {
  setSelectedWorkspaceIdentifier,
  clearWorkspaceConfig,
} from "../../../store/slice/workspaceConfigue-slice";
import { ListItemComponent } from "./ListItem";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideNav({
  userName,
  setData,
  accountInfo,
  selectComponent,
  component,
}) {
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const accountInfoData = useSelector((state) => state.accountInfo);
  const workspaceData = useSelector(
    (state) => state.workspaceSlice?.workspaceData
  );
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openIcon = Boolean(anchorEl);
  const [selectedWorkspace, setSelectedWorkspace] = useState("");
  const [userList, setUserList] = useState([]);
  const [workspaceUrl, setWorkspaceUrl] = useState("");
  const dispatch = useDispatch();

  console.log(component);

  useEffect(() => {
    if (workspaceData.length > 0) {
      setSelectedWorkspace(workspaceSelected.workspace_id);
      setWorkspaceUrl(workspaceSelected.workspace_url);
    }
  }, [workspaceSelected]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListItemClick = (event, name) => {
    selectComponent(name);
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedWorkspace(event.target.value);
    const payload = workspaceData?.filter((data) => {
      return data.workspace_id === selectedValue;
    });
    setWorkspaceUrl(
      workspaceData.find((w) => w.workspace_id === selectedValue).workspace_url
    );
    dispatch(workspaceActions.setSelectedWorkspace(payload));
    let workspacePayload = {
      selectedValue: selectedValue,
    };
    dispatch(setSelectedWorkspaceIdentifier(workspacePayload));
  };

  async function logOutHandler() {
    window.localStorage.clear();
    window.sessionStorage.clear();
    dispatch(accountInfoActions.reset());
    dispatch(clearWorkspaceConfig());
    await postCall(logoutApi, {});
    setData(false);
  }

  const accStyle = !accountInfo ? { paddingBottom: "58px" } : "";

  useEffect(() => {
    if (accountInfoData.userId === "" && !workspaceUrl == "") {
      fetchUsers();
    }
  }, [accountInfoData, workspaceUrl]);

  async function fetchUsers() {
    const body = {
      ucxCredentialsData: {
        accId: accountInfoData.accountId,
        databricksEmail: accountInfoData.userId,
        databricksPassword: accountInfoData.password,
        databricksClientId: accountInfoData.oAuthToken,
        databricksSecret: accountInfoData.oAuthSecret,
      },
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      cloudType: accountInfoData.cloudType,
      workspaceUrl: workspaceUrl,
      patAccess: accountInfoData.patAccess,
    };
    const userData = await getAccountUserList(body);
    setUserList(userData);
  }

  const handleUserSelect = (user) => {
    dispatch(accountInfoActions.setOAuthUser(user));
    handleClose();
  };

  const listItems = [
    { name: "Dashboard", icon: DashboardOutlinedIcon, text: "Dashboard" },
    {
      name: "Lineage Utility",
      icon: ShareOutlinedIcon,
      text: "Lineage Utility",
    },
    { name: "DBFS", icon: Inventory2OutlinedIcon, text: "Workspace" },
    { name: "Schema/Table", icon: BallotOutlinedIcon, text: "Tables" },
    {
      name: "MountPointValidation",
      icon: StorageOutlinedIcon,
      text: "Mount Points",
    },
    { name: "Clusters", icon: CloudOutlinedIcon, text: "Clusters" },
    { name: "Jobs", icon: FactCheckOutlinedIcon, text: "Jobs" },
    {
      name: "Dbsql Clusters",
      icon: TerminalOutlinedIcon,
      text: "SQL Warehouse",
    },
    {
      name: "Autoloader/Streaming",
      icon: MotionPhotosAutoOutlinedIcon,
      text: "Autoloader/Streaming",
    },
    { name: "Notebook", icon: EventNoteOutlinedIcon, text: "Notebooks" },
    { name: "Summary", icon: SummarizeOutlinedIcon, text: "Summary" },
  ];

  return (
    <Box sx={accStyle}>
      <CssBaseline />
      <AppBar position="fixed" open={open} className={styles["app-bar"]}>
        <Toolbar>
          {accountInfo && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              onMouseOver={handleDrawerOpen}
              onMouseLeave={handleDrawerClose}
            >
              <img src={MenuIcon} />
            </IconButton>
          )}
          <Typography className={styles["heading"]}>
            {jsonData.projectHeading} | {userName}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {accountInfo && (
            <FormControl sx={{ m: 1, minWidth: 320 }} size="small">
              <Select
                value={selectedWorkspace}
                sx={{ backgroundColor: "white" }}
                onChange={handleChange}
                renderValue={() => (
                  <div className={styles.ellipsis}>
                    {workspaceSelected?.workspace_name}
                  </div>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      width: 320,
                      maxHeight: 360,
                    },
                  },
                }}
              >
                {workspaceData?.map((workspace) => (
                  <MenuItem
                    key={workspace?.workspace_id}
                    value={workspace?.workspace_id}
                  >
                    <EllipsisTooltip>
                      <>
                        {`${workspace?.workspace_name}`}
                        <br /> {`${workspace?.region}`}
                      </>
                    </EllipsisTooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <i
              className="fa fa-user-circle"
              style={{
                fontSize: "21px",
                marginRight: "8px",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            ></i>
            <p className={styles["font"]}>
              {accountInfoData.OAuthUser.userName}
            </p>
          </Box>

          <Box sx={{ display: { md: "flex" } }}>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={openIcon ? "long-menu" : undefined}
              aria-expanded={openIcon ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <ExpandMore className={styles["color"]} />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={openIcon}
              onClose={handleClose}
            >
              {accountInfoData?.userId ? (
                <MenuItem
                  sx={{
                    "&.Mui-disabled": {
                      opacity: 1,
                    },
                  }}
                  disabled
                >
                  {accountInfoData?.userId}
                </MenuItem>
              ) : (
                userList?.map((user) => (
                  <MenuItem
                    key={user.userId}
                    onClick={() => handleUserSelect(user)}
                  >
                    {user.userName}
                  </MenuItem>
                ))
              )}
              <Button
                variant="contained"
                fullWidth
                sx={{ backgroundColor: "black" }}
                onClick={logOutHandler}
              >
                Logout
              </Button>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      {accountInfo && (
        <Drawer
          PaperProps={{ sx: { backgroundColor: "#24262C", width: "55px" } }}
          variant="permanent"
          open={open}
          onMouseOver={handleDrawerOpen}
          onMouseLeave={handleDrawerClose}
        >
          <List className={styles["padding-top"]}>
            {listItems.map(({ name, icon, text }) => (
              <ListItemComponent
                open={open}
                key={name}
                name={name}
                icon={icon}
                text={text}
                selectedItem={component}
                handleListItemClick={handleListItemClick}
              />
            ))}
          </List>
        </Drawer>
      )}
    </Box>
  );
}
