import constants from "../../utils/constant";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import classes from "./WorkspaceSummary.module.css";
import EllipsisTooltip from "../UI/Tooltip/Tooltip";
import { getCall } from "../../Resources/apicalls";
import { useEffect, useRef, useState } from "react";
import NoData from "../Common/NoData/NoData";
import jsonData from "../../Assets/i18n/en.json";
import { onEndReached } from "../../utils/EndReachedCallback";
import { notebookstringReplacementInfoApi } from "../../Resources/apiurls";

const NotebokStringReplacementSummary = (props) => {
  const { id } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [moreData, setMoreData] = useState(true);
  const tableRef = useRef(null);
  const getStringReplSummary = async () => {
    if (!moreData || loading) {
      return;
    }
    setLoading(true);
    let api = `${notebookstringReplacementInfoApi}${id}?pageNo=${page}&pageSize=20`;
    let res = await getCall(api);
    if (res?.ucxApiResponse?.length) {
      setPage(page + 1);
      if (page > 0) {
        setData([...data, ...res?.ucxApiResponse]);
      } else {
        setData(res?.ucxApiResponse);
      }
    } else {
      setMoreData(false);
    }
    setLoading(false);
  };
  const handleScroll = () => {
    onEndReached(tableRef, getStringReplSummary);
  };
  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableRef.current) {
        tableRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll, loading]);

  useEffect(() => {
    getStringReplSummary();
  }, []);

  const headerValues = [
    "notebook_name",
    "old_path",
    "new_path",
    "input_string",
    "new_string",
    "number_of_changes_in_notebook",
  ];

  if ((!data || data?.length == 0) && !loading) {
    return <NoData label={jsonData.noData}></NoData>;
  }
  return (
    <div style={{ minHeight: "25rem" }}>
      <Paper className={classes["paper"]}>
        <TableContainer ref={tableRef} className={classes["table-container"]}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className={classes["table-row-height"]}>
                {constants.NotebookStringReplacementHeader.map((head) => (
                  <TableCell className={classes["table-heading"]} key={head}>
                    {head}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row) => {
                return (
                  <TableRow>
                    {headerValues.map((keyName, index) => {
                      return (
                        <TableCell
                          className={classes["horizontal-width"]}
                          key={index}
                        >
                          <EllipsisTooltip>{row[keyName]}</EllipsisTooltip>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};
export default NotebokStringReplacementSummary;
