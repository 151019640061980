import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  InputBase,
  Button,
} from "@mui/material";
import jsonData from "../../Assets/i18n/en.json";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState, useRef } from "react";
import LoadingSpinner from "../UI/LoadingSpinner";
import { useSelector } from "react-redux";
import classes from "./Jobs.module.css";
import NoData from "../Common/NoData/NoData";
import Tooltip from "@mui/material/Tooltip";
import CachedIcon from "@mui/icons-material/Cached";
import BasicAlerts from "../Common/MessageBanner.jsx/MessageBanner";
import {
  getWorkspacePipelineCount,
  getWorkspacePipelineList,
} from "./jobsService";
import { useCommonPayload } from "../../Resources/useCommonPayload";
import MigrationDialog from "../Common/Migration/MigrationDailog";
import { StatusIndicator } from "../UI/StatusIndicator";

export default function DltPipeline(props) {
  const { selectComponent } = props;
  const commonPayload = useCommonPayload();
  const [loading, setloading] = useState(false);
  const [selectedPipelines, setSelectedPipelines] = useState([]);
  const [pipelines, setpipelines] = useState([]);
  const [filterJobList, setFilterJobList] = useState([]);
  const [apiTime, setApiTime] = useState("");
  const [showAlertMigration, setShowAlertMigration] = useState(false);
  const [id, setId] = useState(false);
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const [migrationDialogOpen, setmigrationDialogOpen] = useState(false);
  const [pipelineCount, setpipelineCount] = useState(0);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [page, setPage] = useState("");
  let isRefresh = false;
  const tableRef = useRef(null);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = tableRef?.current;
    const threshold = 20;
    const isBottom = scrollTop + clientHeight + threshold >= scrollHeight;
    if (isBottom && !loading) {
      if (page) {
        getPipelines(page);
      }
    }
  };

  const getPipelines = async (pageTemp) => {
    if (pageTemp === -1) {
      return;
    }
    let postbody = {
      ...commonPayload,
      pageToken: pageTemp || page,
      max_results: 20,
    };
    setloading(true);
    const response = await getWorkspacePipelineList(postbody);
    const nextList = response.pipelines;
    const next_page_token = response.next_page_token || -1;
    setPage(next_page_token);
    if (isRefresh) {
      setpipelines(nextList);
    } else if (Array.isArray(nextList)) {
      setpipelines((prevJobList) => [...prevJobList, ...nextList]);
    } else {
      console.error(
        "response of jobs is not an array or is undefined:",
        nextList
      );
    }

    setloading(false);
    setApiTime(new Date());
  };

  const getPipelineCount = async () => {
    let countResponse = await getWorkspacePipelineCount(commonPayload);
    setpipelineCount(countResponse);
  };

  useEffect(() => {
    handleRefresh();
  }, [workspaceSelected.workspace_url]);

  const handleMigrateItemClick = (item) => {
    setSelectedPipelines([item]);
    setmigrationDialogOpen(true);
  };

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableRef.current) {
        tableRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll, loading]);

  const handleRefresh = () => {
    isRefresh = true;
    setPage("");
    getPipelines("0");
    setSearchKeyword("");
    getPipelineCount();
  };

  const handleMigrationDialogClose = () => {
    setmigrationDialogOpen(false);
    setTimeout(() => setSelectedPipelines([]), 1000);
  };

  useEffect(() => {
    if (searchKeyword?.length < 1) setFilterJobList(pipelines);
    else
      setFilterJobList(
        pipelines.filter((item) =>
          item?.name?.toLowerCase().includes(searchKeyword.toLowerCase())
        )
      );
  }, [searchKeyword, pipelines]);

  if (!pipelines?.length && loading === false) {
    return <NoData label={jsonData.noData} />;
  }
  return (
    <>
      {showAlertMigration && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startMigrationmsg} <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertMigration}
          showReview={showAlertMigration}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      <LoadingSpinner open={loading} />
      <div className={classes["display"]}>
        <div className={classes["button-container"]}>
          <div className={classes["basic-details-heading"]}>
            {jsonData.listOfPipelines} ({" "}
            {searchKeyword.length > 0 ? filterJobList.length : pipelineCount} )
          </div>
        </div>
        <div className={classes["srch-flex"]}>
          <Paper className={`${classes["input-box"]}`}>
            <IconButton
              type="button"
              className={`${classes["padding"]}`}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              className={`${classes["search-input"]}`}
              placeholder={jsonData.srchNm}
              type="text"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </Paper>

          <MigrationDialog
            open={migrationDialogOpen}
            setId={setId}
            title={"DLT Migration"}
            onClose={handleMigrationDialogClose}
            migrationSelected={"dltPipeline"}
            selectedList={selectedPipelines.slice()}
            setShowAlertMigration={setShowAlertMigration}
          />
          <div className={classes["float-right"]}>
            <Tooltip title={`${jsonData.lastRefreshed}${apiTime}`}>
              <CachedIcon onClick={handleRefresh} />
            </Tooltip>
          </div>
        </div>
      </div>

      <TableContainer
        ref={tableRef}
        component={Paper}
        className={classes["table-container"]}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>{jsonData.name}</TableCell>
              <TableCell>{jsonData.statusTxt}</TableCell>
              <TableCell>{jsonData.idTxt}</TableCell>
              <TableCell>{jsonData.owner}</TableCell>
              <TableCell>{jsonData.action}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterJobList?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  {item.latest_updates
                    ?.slice()
                    .reverse()
                    .map((update) => {
                      return (
                        <StatusIndicator
                          state={update.state}
                          creation_time={update.creation_time}
                          update_id={update.update_id}
                        />
                      );
                    })}
                </TableCell>
                <TableCell>{item.pipeline_id}</TableCell>
                <TableCell>{item.creator_user_name}</TableCell>
                <TableCell>
                  {!item.latest_updates
                    ?.map((el) => el.state)
                    .includes("COMPLETED") && (
                    <Button onClick={() => handleMigrateItemClick(item)}>
                      Migrate
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
