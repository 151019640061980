/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialAccountInfoState = {
  accountId: "",
  userId: "",
  password: "",
  credentialType: "login",
  oAuthToken: "",
  cloudType: "AWS",
  oAuthSecret: "",
  owner: "",
  OAuthUser: "",
  externalWsUrl: "",
  patAccess:false
};

const accountInfoSlice = createSlice({
  name: "accountInfo",
  initialState: initialAccountInfoState,
  reducers: {
    setAccountInfoDetails(state, action) {
      state.accountId = action.payload.accountId;
      state.oAuthToken = action.payload.oAuthToken;
      state.oAuthSecret = action.payload.oAuthSecret;
      state.cloudType = action.payload.cloudType;
      state.userId = action.payload.userId;
      state.password = action.payload.password;
      state.credentialType = action.payload.credentialType;
      state.externalWsUrl = action.payload.externalWsUrl;
      state.patAccess = action.payload.patAccess;
    },

    setOAuthUser(state, action) {
      state.OAuthUser = action.payload;
    },
    reset() {
      return initialAccountInfoState;
    },
  },
});

export default accountInfoSlice;
export const accountInfoActions = accountInfoSlice.actions;
