import styles from "../UcMigration.module.css";
import { useEffect, useState } from "react";
import { FormControl, RadioGroup} from "@mui/material";
import StyledRadio from "../UI/StyledRadioButton/StyledRadio";
import jsonData from "../../Assets/i18n/en.json";
import HeadingWithInfo from "../Common/HeadingWithInfo/HeadingWithInfo";
import { useCommonPayload } from "../../Resources/useCommonPayload";
import { postPipelineMigrate } from "./jobsService";
import LoadingSpinner from "../UI/LoadingSpinner";
import UcmaTextField from "../UI/Form/TextField";
import {
  NamePattern,
  notebookInputPathPattern,
  storageRootPattern,
} from "../../utils/regex";
import { GenerateUid } from "../../utils/GenerateUid/GenerateUid";
import { useSelector } from "react-redux";

const DltPipelineMigrate = (props) => {
  const {
    setDisableNext,
    selectedList,
    startMigration,
    setId,
    setShowAlertMigration,
  } = props;

  const accountInfoData = useSelector((state) => state.accountInfo);
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const workspaceConfig = useSelector(
    (state) => state.workspaceConfig.data[workspaceSelected.workspace_id]
  );
  const [catalogName, setcatalogName] = useState("");
  const [targetPipelineName, setTargetPipeline] = useState("");
  const [loading, setloading] = useState(false);
  const [targetSchemaName, setTargetSchemaName] = useState();
  const [isUsingappendFlow, setIsUsingAppendFlow] = useState("false");
  const [bronzeTablePath, setBronzeTablePath] = useState("");
  const [noteBookPath, setNoteBookPath] = useState("");
  const [externalLocation, setExternalLocation] = useState("");
  const commonPayload = useCommonPayload();

  function enableNext() {
    if (isUsingappendFlow === "true") {
      if (
        catalogName === "" ||
        bronzeTablePath === "" ||
        noteBookPath === "" ||
        (accountInfoData.cloudType === jsonData.awsTxt &&
          externalLocation === "")
      ) {
        setDisableNext(true);
      } else setDisableNext(false);
    }

    if (isUsingappendFlow === "false") {
      if (catalogName === "") setDisableNext(true);
      else setDisableNext(false);
    }
  }

  useEffect(() => {
    enableNext();
  }, [
    catalogName,
    bronzeTablePath,
    noteBookPath,
    externalLocation,
    isUsingappendFlow,
  ]);

  useEffect(() => {
    if (startMigration) migratePipleline();
  }, [startMigration]);

  const migratePipleline = async () => {
    if (!selectedList.length) return;
    let triggerId = GenerateUid();
    setId(triggerId);
    let payload = {
      ...commonPayload,
      ucxPayload: {
        instanceProfile: workspaceConfig.awsInstanceProfileArn,
        externalLocation: externalLocation,
        runJobsAs: accountInfoData.userId
          ? accountInfoData.userId
          : accountInfoData.OAuthUser.userName,
        pipelineUpgradeRequest: [
          {
            pipelineId: selectedList[0].pipeline_id,
            catalogName: catalogName,
            sourcePipelineName: selectedList[0].name,
            isUsingAppendFlow: isUsingappendFlow,
            targetPipelineName: targetPipelineName,
            targetSchemaName: targetSchemaName,
            bronzeTablePath: bronzeTablePath,
            noteBookPath: noteBookPath,
          },
        ],
      },
      containerName: workspaceConfig.azureStorageAccountName,
      storageAccountKey: workspaceConfig.azureStorageAccountKey,
      triggerId: triggerId,
    };
    setloading(false);
    await postPipelineMigrate(payload);
    setShowAlertMigration(true);
  };

  return (
    <div>
      <LoadingSpinner open={loading} />
      <div>
        <UcmaTextField
          name={jsonData.catalogName}
          isDialog={true}
          info={jsonData.pipelineCatalogNameInfo}
          value={catalogName}
          onChangeValue={setcatalogName}
          required
          pattern={NamePattern}
          placeholder={jsonData.unityCatalogNamePlaceholder}
          setDisableSubmit={setDisableNext}
          onBlur={enableNext}
        />

        <div className={styles["margin-top-20"]}>
          <HeadingWithInfo
            label={jsonData.isUsingAppendFlow}
            isDialog={true}
            info={jsonData.isUsingAppendFlow}
          />

          <div className={styles["margin-top-16"]}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(e) => setIsUsingAppendFlow(e.target.value)}
                value={isUsingappendFlow}
              >
                <div className={styles["margin-right-16"]}>
                  <StyledRadio value={true} label="Yes" />
                </div>

                <StyledRadio value={false} label="No" />
              </RadioGroup>
            </FormControl>
          </div>

          <UcmaTextField
            name={jsonData.targetPipeline}
            isDialog={true}
            info={jsonData.targetPipelineInfo}
            value={targetPipelineName}
            onChangeValue={setTargetPipeline}
            setDisableSubmit={setDisableNext}
            onBlur={enableNext}
            pattern={NamePattern}
            placeholder={jsonData.targetPipelinePlaceholder}
          />

          <UcmaTextField
            name={jsonData.targetSchemaName}
            isDialog={true}
            info={jsonData.targetSchemaNameInfo}
            value={targetSchemaName}
            onChangeValue={setTargetSchemaName}
            setDisableSubmit={setDisableNext}
            pattern={NamePattern}
            onBlur={enableNext}
            placeholder={jsonData.targetSchemaName}
          />
          {isUsingappendFlow === "true" && (
            <>
              <UcmaTextField
                name={jsonData.bronzeTablePathTxt}
                isDialog={true}
                info={jsonData.bronzeTablePathInfo}
                value={bronzeTablePath}
                onChangeValue={setBronzeTablePath}
                setDisableSubmit={setDisableNext}
                pattern={NamePattern}
                onBlur={enableNext}
                placeholder={jsonData.bronzeTablePlaceHolder}
                required
              />

              <UcmaTextField
                name={jsonData.noteBookPathTxt}
                isDialog={true}
                info={jsonData.noteBookPathInfo}
                value={noteBookPath}
                onChangeValue={setNoteBookPath}
                setDisableSubmit={setDisableNext}
                pattern={notebookInputPathPattern}
                onBlur={enableNext}
                placeholder={jsonData.notebookPlaceholder}
                required
              />
              {accountInfoData.cloudType === jsonData.awsTxt && (
                <UcmaTextField
                  name={jsonData.externalLocation}
                  isDialog={true}
                  info={jsonData.externalLocationInfo}
                  value={externalLocation}
                  onChangeValue={setExternalLocation}
                  setDisableSubmit={setDisableNext}
                  pattern={storageRootPattern}
                  onBlur={enableNext}
                  placeholder={jsonData.cloudLocationSampleValue}
                  required
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DltPipelineMigrate;
