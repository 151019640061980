import styles from "../UcMigration.module.css";
import { Select, MenuItem } from "@mui/material";
import jsonData from "../../Assets/i18n/en.json";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NameWOhPattern } from "../../utils/regex";
import { ucMigrationCall } from "../../Resources/apicalls";
import getAccessMode, {
  getDataSecurityMode,
  getDataSecurityModesList2,
} from "../Common/ucxFunction/getAccessMode";
import LoadingSpinner from "../UI/LoadingSpinner";
import { GenerateUid } from "../../utils/GenerateUid/GenerateUid";
import constants from "../../utils/constant";
import HeadingWithInfo from "../Common/HeadingWithInfo/HeadingWithInfo";
import UcmaTextField from "../UI/Form/TextField";

const InteractiveCluster = (props) => {
  const { selectedList, startMigration, setDisableNext, setId } = props;
  const [makingApiCalls, setMakingApiCalls] = useState(false);
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const accountInfoData = useSelector((state) => state.accountInfo);
  const [defaultUnityCatalogName, setDefaultUnityCatalogName] = useState("");
  const [selectedAccessMode, setSelectedAccessMode] = useState("");
  const accessDropDownReq =
    getDataSecurityModesList2(selectedList).includes(null);

  async function submitMigration() {
    let triggerId = GenerateUid();
    setId(triggerId);
    const postBody = JSON.stringify({
      ucxCredentialsData: {
        accId: workspaceSelected.account_id,
      },
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      ucxPayload: {
        data: null,
        warehouseID: [],
        migrateGroups: false,
        upgradeClusters: selectedList
          ? selectedList.map((cluster) => ({
              clusterId: cluster.cluster_id,
              targetAccessMode:
                getDataSecurityMode(cluster) === null
                  ? selectedAccessMode
                  : getAccessMode(cluster),
              catalogName: defaultUnityCatalogName,
            }))
          : null,
        workflows: null,

        jobs: null,
        runJobsAs: accountInfoData.userId
          ? accountInfoData.userId
          : accountInfoData.OAuthUser.userName,
        mountPointsUpgrade: null,
      },
      workspaceName: workspaceSelected.workspace_name,
      workspaceIdentifier: workspaceSelected?.workspace_id,
      workspaceUrl: workspaceSelected.workspace_url,
      region: workspaceSelected.region,
      triggerId: triggerId,
    });
    setMakingApiCalls(true);
    await ucMigrationCall(workspaceSelected.workspace_id, postBody);
    setMakingApiCalls(false);
  }

  useEffect(() => {
    if (startMigration) submitMigration();
  }, [startMigration]);
  useEffect(() => {
    if (accessDropDownReq && selectedAccessMode === "") setDisableNext(true);
    else setDisableNext(false);
  }, [accessDropDownReq, selectedAccessMode]);

  const handleSelectAccessMode = (event) => {
    setSelectedAccessMode(event.target.value);
  };

  return (
    <div>
      <LoadingSpinner open={makingApiCalls} />
      {accessDropDownReq && (
        <div className={styles["margin-bottom-16"]}>
          <HeadingWithInfo
            label={jsonData.accessModeText}
            info={jsonData.SelectTxt}
            isDialog={true}
          />
          <Select
            fullWidth
            size="small"
            onChange={handleSelectAccessMode}
            value={selectedAccessMode}
          >
            {constants.accessModeArray.map((item) => (
              <MenuItem key={item.name} value={item.passValue}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}

      <UcmaTextField
        name={jsonData.defaultUcClusterText}
        info={jsonData.defaultUcClusterInfo}
        value={defaultUnityCatalogName}
        onChangeValue={setDefaultUnityCatalogName}
        placeholder={jsonData.unityCatalogNamePlaceholder}
        setDisableSubmit={setDisableNext}
        pattern={NameWOhPattern}
        isDialog
      />
    </div>
  );
};
export default InteractiveCluster;
