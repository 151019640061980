import { useSelector } from "react-redux";
import LoadingSpinner from "../UI/LoadingSpinner";
import jsonData from "../../Assets/i18n/en.json";
import classes from "../NewComponent.module.css";
import { Done } from "@mui/icons-material";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  InputBase,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CommonMigration from "../Common/Migration/Migration";
import ValidationReview from "../UcValidation/ValidationReview";
import constants from "../../utils/constant";
import {
  cloneClusterAcrossCall,
  cloneClusterCall,
  deleteClusterCall,
  getAllPurposeCluster,
  validateClusterCall,
} from "../../Resources/apicalls";
import NoData from "../Common/NoData/NoData";
import CachedIcon from "@mui/icons-material/Cached";
import Tooltip from "@mui/material/Tooltip";
import BasicAlerts from "../Common/MessageBanner.jsx/MessageBanner";
import EllipsisTooltip from "../UI/Tooltip/Tooltip";
import { isDataSecurityMigrateEligible } from "../Common/ucxFunction/getAccessMode";
import { timeout30Sec, timeout60Sec } from "../../utils/TimeOut/TimeOut";
import { GenerateUid } from "../../utils/GenerateUid/GenerateUid";

function InteractiveCluster(props) {
  const { selectComponent } = props;
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice.selectedWorkspace[0]
  );
  const [makingApiCall, setMakingApiCall] = useState(false);
  const accountInfoData = useSelector((state) => state.accountInfo);
  const [migrateEligibleList, setMigrateEligibleList] = useState([]);
  const [validateEligibleList, setValidateEligibleList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [selectedClusterList, setSelectedClusterList] = useState([]);
  const [showReview, setShowReview] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [clusterList, setClusterList] = useState([]);
  const [close, setClose] = useState(false);
  const [validatedData, setValidatedData] = useState({});
  const [cloneType, setCloneType] = useState(jsonData.withInValue);
  const [externalWsUrl, setExternalWsUrl] = useState("");
  const [externalWsToken, setExternalWsToken] = useState("");
  const [listSelected, setListSelected] = useState(false);
  const [apiTime, setApiTime] = useState("");
  const [showAlertMigration, setShowAlertMigration] = useState(false);
  const [showAlertValidate, setShowAlertValidate] = useState(false);
  const [showAlertClone, setShowAlertClone] = useState(false);
  const [id, setId] = useState(false);
  const [migrateBtnDisable, setMigrateBtnDisable] = useState(true);
  const [validateBtnDisable, setValidateBtnDisable] = useState(true);
  const [showCloneSummary, setShowCloneSummary] = useState(false);
  const [filterClusterList, setFilterClusterList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  const getAllPurposeClusterList = async () => {
    let postbody = {
      ucxCredentialsData: {
        accId: accountInfoData.accountId,
      },
      workspaceUrl: workspaceSelected?.workspace_url,
      workspaceIdentifier: workspaceSelected?.workspace_id,
      workspaceName:workspaceSelected.workspace_name,
      ucxPayload: null,
      ucxInstalled: false,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
    };
    setMakingApiCall(true);
    let response = await getAllPurposeCluster(postbody);
    setClusterList(response);
    setMakingApiCall(false);
    setApiTime(new Date());
  };

  useEffect(() => {
    getAllPurposeClusterList();
  }, [workspaceSelected?.workspace_url]);

  useEffect(() => {
    if (searchKeyword?.length < 1) setFilterClusterList(clusterList);
    else
      setFilterClusterList(
        clusterList.filter(
          (item) =>
            item?.cluster_name
              .toLowerCase()
              .includes(searchKeyword.toLowerCase()) ||
            item?.cluster_id.includes(searchKeyword)
        )
      );
  }, [searchKeyword, clusterList]);

  useEffect(() => {
    setMigrateEligibleList([]);
    setValidateEligibleList([]);
    setSelectedClusterList([]);
    setClose(false);
  }, [close]);

  useEffect(() => {
    if (clusterList) {
      setMigrateEligibleList(
        clusterList
          .filter(
            (cluster) =>
              isDataSecurityMigrateEligible(cluster?.data_security_mode) &&
              parseFloat(cluster?.spark_version) >= 9.1
          )
          ?.map((option) => option?.cluster_id)
      );
      setValidateEligibleList(
        clusterList
          .filter((cluster) => cluster?.stateList?.includes("MIGRATED"))
          ?.map((option) => option?.cluster_id)
      );
      setSelectedList(
        clusterList.filter((item) =>
          selectedClusterList?.includes(item.cluster_id)
        )
      );
    }
  }, [clusterList, selectedClusterList]);

  useEffect(() => {
    timeout60Sec(setShowAlertMigration);
    timeout30Sec(setShowAlert);
  }, [showAlertMigration, showAlert]);

  const handleCheckboxClick = (name) => {
    const isSelected = selectedClusterList.includes(name);

    if (isSelected) {
      setSelectedClusterList((prevSelected) =>
        prevSelected.filter((item) => item !== name)
      );
    } else {
      setSelectedClusterList((prevSelected) => [...prevSelected, name]);
    }
  };

  const handleAllCheckboxClick = (event) => {
    if (event.target.checked) {
      setSelectedClusterList(clusterList.map((item) => item.cluster_id));
    } else {
      setSelectedClusterList([]);
    }
  };

  useEffect(() => {
    if (selectedList.length > 0) setListSelected(true);
    else setListSelected(false);
  }, [selectedList]);

  useEffect(() => {
    let migrateBtn = false;
    for (let index in selectedClusterList) {
      if (!migrateEligibleList.includes(selectedClusterList[index])) {
        migrateBtn = true;
        break;
      }
    }
    setMigrateBtnDisable(migrateBtn);
  }, [selectedClusterList]);

  useEffect(() => {
    let validateBtn = false;
    for (let index in selectedClusterList) {
      clusterList?.forEach((item) => {
        if (
          item.cluster_id == selectedClusterList[index] &&
          !item.StatesList.includes("MIGRATED")
        ) {
          validateBtn = true;
        }
      });
    }
    setValidateBtnDisable(validateBtn);
  }, [selectedClusterList]);

  let payloadValidateClone = {
    accId: workspaceSelected?.account_id,
    workspaceIdentifier: workspaceSelected?.workspace_id,
    workspaceName:workspaceSelected.workspace_name,
    clusterId: selectedList.map((cluster) => cluster.cluster_id),
    OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
    workspaceUrl: workspaceSelected?.workspace_url,
    cloudType: accountInfoData.cloudType,
    patAccess: accountInfoData.patAccess,
  };

  function handleValidateClick() {
    let triggerId = GenerateUid();
    setId(triggerId);
    validateClusterCall({
      ...payloadValidateClone,
      triggerId: triggerId,
    });

    setShowAlertValidate(true);
    handleRefresh();
  }

  async function handleCloneClick() {
    let triggerId = GenerateUid();
    setId(triggerId);
    if (cloneType === jsonData.withInValue) {
      cloneClusterCall({
        ...payloadValidateClone,
        triggerId: triggerId,
      });
    } else if (cloneType === jsonData.acrossValue) {
      let payloadValidateCloneAcross = {
        ...payloadValidateClone,
        triggerId: triggerId,
        crossWorkspaceUrl: externalWsUrl,
        crossWorkspacePatToken: externalWsToken,
      };
      cloneClusterAcrossCall(payloadValidateCloneAcross);
    }
    setShowAlertClone(true);

    handleRefresh();
  }

  async function handleDeleteClick() {
    setMakingApiCall(true);
    let response = await deleteClusterCall(payloadValidateClone);
    setMakingApiCall(false);

    if (response?.ucxApiResponse) {
      setValidatedData(response?.ucxApiResponse);
      setClose(true);
      setShowAlert(true);
    }
    handleRefresh();
  }

  const handleRefresh = () => {
    getAllPurposeClusterList();
    setSelectedClusterList([]);
    setListSelected(false);
  };

  if (clusterList.length === 0 && makingApiCall === false) {
    return <NoData label={jsonData.noData} />;
  }

  return (
    <>
      {showAlert && !makingApiCall && (
        <BasicAlerts
          severity={validatedData === "deleted" ? "success" : "error"}
          label={
            validatedData === "deleted"
              ? "Deleted Successfully"
              : "Deletion Unsuccessfull"
          }
          setShowReview={setShowAlert}
          showReview={showAlert}
        />
      )}

      {showAlertClone && !makingApiCall && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startClonemsg} <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertClone}
          showReview={showAlertMigration}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      {showAlertMigration && !makingApiCall && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startMigrationmsg} <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertMigration}
          showReview={showAlertMigration}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      {showAlertValidate && !makingApiCall && (
        <BasicAlerts
          severity="info"
          label={
            <>
              {jsonData.startValidatemsg} <strong>{id}</strong>
              {jsonData.migrationTrack}&nbsp;
            </>
          }
          setShowReview={setShowAlertMigration}
          showReview={showAlertMigration}
          selectComponent={selectComponent}
          showSummaryButton="true"
        />
      )}

      <LoadingSpinner open={makingApiCall} />
      <div className={classes["display"]}>
        <div className={classes["button-container"]}>
          <div className={classes["basic-details-heading"]}>
            {jsonData.listOfClusters} ( {filterClusterList?.length} )
          </div>
        </div>
        <div className={classes["srch-flex"]}>
          <Paper className={`${classes["input-box"]}`}>
            <IconButton
              type="button"
              className={`${classes["padding"]}`}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              className={`${classes["search-input"]}`}
              placeholder={jsonData.srchNmId}
              type="text"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </Paper>
          <CommonMigration
            migrationSelected="interactiveCluster"
            handleValidateClick={handleValidateClick}
            handleCloneClick={handleCloneClick}
            handleDeleteClick={handleDeleteClick}
            selectedList={selectedList}
            setClose={setClose}
            makingApiCall={makingApiCall}
            listSelected={listSelected}
            setListSelected={setListSelected}
            title={jsonData.clusterMigration}
            titleClone={jsonData.clusterCloneHeading}
            setShowAlertMigration={setShowAlertMigration}
            setId={setId}
            setCloneType={setCloneType}
            cloneType={cloneType}
            setExternalWsToken={setExternalWsToken}
            setExternalWsUrl={setExternalWsUrl}
            migrateBtnDisable={migrateBtnDisable}
            validateBtnDisable={validateBtnDisable}
            setShowCloneSummary={setShowCloneSummary}
            showMigrate={true}
            showValidate={true}
            showClone={true}
            showDelete={true}
          />
          <div className={classes["float-right"]}>
            <Tooltip title={`${jsonData.lastRefreshed} ${apiTime}`}>
              <CachedIcon onClick={handleRefresh} />
            </Tooltip>
          </div>
        </div>
      </div>

      <TableContainer component={Paper} className={classes["table-container"]}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  onClick={handleAllCheckboxClick}
                  checked={selectedList.length === clusterList.length}
                />
                {"  "} {jsonData.upgradeIntractiveClusterText}
              </TableCell>
              <TableCell>{jsonData.idTxt}</TableCell>
              <TableCell>{jsonData.createdByText}</TableCell>
              <TableCell>{jsonData.status}</TableCell>
              <TableCell>{jsonData.runtimeTxt}</TableCell>
              <TableCell>{jsonData.stateList}</TableCell>
              <TableCell>{jsonData.migrateEligibleText}</TableCell>
              <TableCell>{jsonData.ValidateEligibleText}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterClusterList?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <div className={classes["display"]}>
                    {" "}
                    <Checkbox
                      onClick={() =>
                        handleCheckboxClick(item.cluster_id, item.StatesList)
                      }
                      checked={selectedClusterList.includes(item.cluster_id)}
                    />{" "}
                    <EllipsisTooltip>{item.cluster_name}</EllipsisTooltip>
                  </div>
                </TableCell>
                <TableCell>{item.cluster_id}</TableCell>
                <TableCell>
                  <EllipsisTooltip>{item.creator_user_name}</EllipsisTooltip>
                </TableCell>
                <TableCell>{item.state}</TableCell>
                <TableCell>{item.spark_version}</TableCell>
                <TableCell>
                  {item.StatesList[item.StatesList.length - 1]}
                </TableCell>
                <TableCell>
                  {migrateEligibleList?.includes(item.cluster_id) ? (
                    <Done />
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell>
                  {item?.StatesList?.includes("MIGRATED") ? <Done /> : "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
export default InteractiveCluster;
