import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  getDbfsMountPointListApi,
  getS3AccessApi,
} from "../../Resources/apiurls";

export const fetchData = createAsyncThunk("data/fetchData", async (body) => {
  try {
    const response = await axios.post(getDbfsMountPointListApi, body, {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const fetchAccess = createAsyncThunk(
  "data/fetchAccess",
  async (body) => {
    try {
      const response = await axios.post(getS3AccessApi, body, {
        headers: {
          Authorization: "Bearer " + window.sessionStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const initialState = {
  arn: "",
  permission: false,
  tabledata: [],
  access: [],
  error: null,
};

const dataSlice = createSlice({
  name: "tabledata",
  initialState,
  reducers: {
    selectValue(state, action) {
      state.arn = action.payload;
    },
    selectPermission(state, action) {
      state.permission = action.payload;
    },
    resetMountPoint() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.tabledata = action.payload;
        state.error = null;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(fetchAccess.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchAccess.fulfilled, (state, action) => {
        state.access = action.payload;
        state.error = null;
      })
      .addCase(fetchAccess.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const { selectValue: setArn } = dataSlice.actions;
export const { selectPermission ,resetMountPoint} = dataSlice.actions;
export const tableData = dataSlice.reducer;
