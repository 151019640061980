import { useEffect, useState } from "react";
import LoadingSpinner from "../UI/LoadingSpinner";
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import jsonData from "../../Assets/i18n/en.json";
import styles from "../UcMigration.module.css";
import ButtonStyled from "../UI/NavigationButtons/Buttons";
import { useSelector } from "react-redux";
import { postCall } from "../../Resources/apicalls";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import HeadingWithInfo from "../Common/HeadingWithInfo/HeadingWithInfo";
import { UcxAssessmentApi } from "../../Resources/apiurls";
import { HmsGlueSelection } from "../Common/HmsGlueSelection/HmsGlueSelection";
import { GenerateUid } from "../../utils/GenerateUid/GenerateUid";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    backgroundColor: "#fff",
    color: "black",
    padding: "16px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

function UcxAssessment(props) {
  const { setStartUCXAssessment, setId, open, onClose } = props;
  const workspaceInfo = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace?.[0]
  );
  const workspaceSelected = useSelector(
    (state) => state.workspaceSlice?.selectedWorkspace[0]
  );
  const workspaceConfig = useSelector(
    (state) => state.workspaceConfig.data[workspaceSelected?.workspace_id]
  );

  const accountInfoData = useSelector((state) => state.accountInfo);
  const [makingApiCall, setMakingApiCall] = useState(false);
  const [cancelClick, setCancelClick] = useState(false);
  const [tableMigration, setTableMigration] = useState("GLUE");
  const [grpMigration, setGrpMigration] = useState(false);
  const handleChange = (event) => {
    setGrpMigration(event.target.checked);
  };
  async function ucxAssessment() {
    let triggerId = GenerateUid();
    let migrateFromValue = "";

    if (accountInfoData.cloudType === jsonData.azureTxt) {
      migrateFromValue = jsonData.hmsValueText;
    } else {
      migrateFromValue = tableMigration;
    }

    setId(triggerId);

    let payload = {
      ucxCredentialsData: {
        accId: workspaceInfo.account_id,
      },
      ucxPayload: {
        data: {
          databasesAndCatalog: {
            migrateFrom: migrateFromValue,
          },
          dataRoleArn: workspaceConfig.awsRoleArn,
        },
        warehouseID: [],
        migrateGroups: grpMigration, //grpMigration,
        upgradeClusters: null,
        workflows: null,
        runJobsAs: accountInfoData.userId
          ? accountInfoData.userId
          : accountInfoData.OAuthUser.userName,
      },
      workspaceIdentifier: workspaceInfo.workspace_id,
      workspaceName: workspaceInfo.workspace_name,
      workspaceUrl: workspaceInfo.workspace_url,
      workspaceBearerToken: null,
      region: workspaceInfo.region,
      cloudType: accountInfoData.cloudType,
      patAccess: accountInfoData.patAccess,
      OAuth: accountInfoData.credentialType === jsonData.oauthTxt,
      ucxSummaryInfo: null,
      ucxInstalled: true,
      triggerId: triggerId,
    };
    setMakingApiCall(true);
    let response = await postCall(
      `${UcxAssessmentApi}${workspaceInfo.workspace_id}`,
      payload
    );
    if (response?.ucxApiResponse) setStartUCXAssessment(true);
    setMakingApiCall(false);
  }

  const handleValidateClick = () => {
    ucxAssessment();
    onClose();
  };

  useEffect(() => {
    onClose();
  }, [cancelClick]);

  return (
    <BootstrapDialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{
        style: {
          minWidth: "500px",
          minHeight: "150px",
        },
      }}
    >
      <LoadingSpinner open={makingApiCall} />
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {jsonData.ucxAssessment}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {accountInfoData.cloudType === jsonData.awsTxt && (
          <div>
            <div className={styles["basic-details-heading"]}>
              {jsonData.metaStoreType}
            </div>
            <div className={styles["margin-top-20b"]} />
            <HmsGlueSelection
              selectedValue={tableMigration}
              setSelectedValue={setTableMigration}
            />
          </div>
        )}
        <div className={` ${styles["icon"]} ${styles["margin-top-20b"]}`}>
          <div
            className={`${styles["button-border"]} ${styles["padding-right-5"]} `}
          >
            <Checkbox
              sx={{
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
              }}
              checked={grpMigration}
              onChange={handleChange}
            />
            <HeadingWithInfo label={jsonData.migrateLocaltext} isDialog />
          </div>
          <Tooltip title={jsonData.migrateLocalGrpsInfo}>
            <InfoOutlinedIcon />
          </Tooltip>
        </div>
        <div
          className={`${styles["button-container"]} ${styles["margin-top-26"]}`}
        >
          <ButtonStyled
            label={jsonData.canceltxt}
            background="transparent"
            variant="text"
            border="none"
            color="#24262C"
            onclick={() => setCancelClick(true)}
          />
          <ButtonStyled
            label={jsonData.startText}
            background="#457ECF"
            variant="contained"
            border="1px solid #457ECF"
            onclick={handleValidateClick}
          />
        </div>
      </DialogContent>
    </BootstrapDialog>
  );
}

export default UcxAssessment;
