import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@mui/material";
import jsonData from "../../Assets/i18n/en.json";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    backgroundColor: "#fff",
    color: "black",
    padding: "16px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

export default function StatusDialog({ openPopup, setOpenStatus, modalData }) {
  const { title, bodyData } = modalData;
  const [open, setOpen] = React.useState(openPopup);
  React.useEffect(() => {
    setOpen(openPopup);
  }, [openPopup, bodyData]);

  const handleClose = () => {
    setOpen(false);
    setOpenStatus(false);
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"lg"}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle></DialogTitle>
      <DialogContent >
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{jsonData.label}</TableCell>
                  <TableCell>{jsonData.value}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bodyData?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.rowLabel}</TableCell>
                    <TableCell>{row.rowValue}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          {jsonData.close}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
