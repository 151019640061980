import { useEffect, useState } from "react";

export function useFormValidation(fields) {
    //provide an array of fields that are visible on screen, if conditional
   // provide fields that are present on default condition
   // do not provide not required fields
   
    const [isFormValid, setFormValid] = useState(false);
    const [fieldObject, setFieldObject] = useState({});
    useEffect(() => {
       createFields();
    }, []);
    function createFields(){
        let obj = {};
        (fields || []).map((fieldName => {
            obj[fieldName] = false;
        }));
        setFieldObject(obj);
    }
    function setFieldValid(fieldName, validity) {
        let obj = { ...fieldObject };
        obj[fieldName] = !validity;
        setFieldObject(obj);
        if (Object.values(obj).includes(false)) {
            setFormValid(false);
        }
        else setFormValid(true);
    };
    
    function resetFieldValidity(totalReset = false, fieldsAdded=[], fieldsRemoved=[]){
        if(totalReset){
            createFields();
            setFormValid(false);
            return;
        }
        let obj = { ...fieldObject };
        fieldsAdded.map(key =>{
            obj[key]  = false;
        })
        fieldsRemoved.map(key=>{
            delete obj[key];
        })
        setFieldObject(obj);
        if (Object.values(obj).includes(false)) {
            setFormValid(false);
        }
        else setFormValid(true);

    }
    return { isFormValid, setFieldValid, resetFieldValidity }
}
