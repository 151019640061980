import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  DialogTitle,
  DialogContent,
  styled,
  Dialog,
  Switch,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import jsonData from "../../Assets/i18n/en.json";
import {
  fetchWorkspaceConfig,
  saveWorkspaceConfig,
} from "../../Resources/apicalls";
import { saveWorkspaceConfigData } from "../../store/slice/workspaceConfigue-slice";
import styles from "./Configue.module.css";
import ButtonStyled from "../UI/NavigationButtons/Buttons";
import UcmaTextField from "../UI/Form/TextField";
import {
  airflowUrlPattern,
  awsInstanceProfileArnPattern,
  awsRoleArnPattern,
  azureClientIdPattern,
  azureClientSecretPattern,
  containerKeyPattern,
  NamePattern,
  strgContainerNamePattern,
  subscriptionIdPattern,
} from "../../utils/regex";
import { Close } from "@mui/icons-material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    backgroundColor: "#fff",
    color: "black",
    padding: "16px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

function Configue({ setOpenDialog, openDialog }) {
  const dispatch = useDispatch();
  const accountInfoData = useSelector((state) => state.accountInfo);
  const workspaceConfig = useSelector((state) => state.workspaceConfig.data);
  const cloud = accountInfoData.cloudType;
  const selectedWorkspaceIdentifier = useSelector(
    (state) => state.workspaceConfig.selectedValue
  );
  const [disableNext, setDisableNext] = useState(true);
  const [airflowUrl, setAirflowUrl] = useState("");
  const [airflowUsername, setAirflowUsername] = useState("");
  const [airflowPassword, setAirflowPassword] = useState("");
  const [resourceGroup, setResourceGroup] = useState("");
  const [dataFactoryName, setDataFactoryName] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [awsRoleArn, setAwsRoleArn] = useState("");
  const [awsInstanceProfileArn, setAwsInstanceProfileArn] = useState("");
  const [azureStorageAccountName, setAzureStorageAccountName] = useState("");
  const [azureStorageAccountKey, setAzureStorageAccountKey] = useState("");
  const [isConfigured, setIsConfigured] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tenantId, setTenantId] = useState("");
  const [clientId, setClientId] = useState("");
  const [isRequiredFieldConfigured, setIsReqdFieldsConfigured] =
    useState(false);
  const [configureAdf, setConfigureAdf] = useState(false);
  const [configureAirflow, setConfigureAirflow] = useState(false);

  const [clientSecret, setClientSecret] = useState("");
  const previousWorkspaceIdRef = React.useRef();

  const handleCloseDialog = () => {
    setIsConfigured(false);
    setConfigureAdf(false);
    setConfigureAirflow(false);
    if (openDialog) {
      setOpenDialog(false);
    }
  };

  useEffect(() => {
    enableNext();
  }, [
    awsRoleArn,
    awsInstanceProfileArn,
    azureStorageAccountName,
    azureStorageAccountKey,
    tenantId,
    clientId,
    clientSecret,
    airflowUrl,
    airflowPassword,
    airflowUsername,
    subscriptionId,
    resourceGroup,
    dataFactoryName,
    disableNext,
    configureAdf,
    configureAirflow,
  ]);

  const enableNext = () => {
    if (cloud === "AWS") {
      if (
        awsRoleArn === "" ||
        awsInstanceProfileArn === "" ||
        (configureAirflow &&
          (airflowUrl === "" ||
            airflowPassword === "" ||
            airflowUsername === ""))
      )
        setDisableNext(true);
      else setDisableNext(false);
    }

    if (cloud === "AZURE") {
      if (
        azureStorageAccountName === "" ||
        azureStorageAccountKey === "" ||
        tenantId === "" ||
        clientId === "" ||
        clientSecret === "" ||
        (configureAirflow &&
          (airflowUrl === "" ||
            airflowPassword === "" ||
            airflowUsername === "")) ||
        (configureAdf &&
          (resourceGroup === "" ||
            dataFactoryName === "" ||
            subscriptionId === ""))
      )
        setDisableNext(true);
      else setDisableNext(false);
    }
  };

  useEffect(() => {
    if (!configureAdf) {
      setResourceGroup("");
      setDataFactoryName("");
      setSubscriptionId("");
    }
    if (!configureAirflow) {
      setAirflowUrl("");
      setAirflowPassword("");
      setAirflowUsername("");
    }
  }, [configureAdf, configureAirflow]);

  useEffect(() => {
    const currentWorkspaceId = selectedWorkspaceIdentifier?.selectedValue;

    if (
      currentWorkspaceId &&
      currentWorkspaceId !== previousWorkspaceIdRef.current
    ) {
      checkWorkspaceConfigue(currentWorkspaceId);
      previousWorkspaceIdRef.current = currentWorkspaceId; // Update the reference
    }
  }, [selectedWorkspaceIdentifier]);

  useEffect(() => {
    const currentWorkspaceId = selectedWorkspaceIdentifier?.selectedValue;

    checkWorkspaceConfigue(currentWorkspaceId);
  }, [openDialog]);

  const handledSave = useCallback(
    async (saveData) => {
      setLoading(true);
      try {
        await saveWorkspaceConfig(saveData);
        dispatch(saveWorkspaceConfigData(saveData));
        setIsConfigured(false);
      } catch (error) {
        console.error("Error saving data:", error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (selectedWorkspaceIdentifier?.selectedValue && openDialog) {
      const config = workspaceConfig[selectedWorkspaceIdentifier.selectedValue];

      if (config) {
        setAwsRoleArn(config.awsRoleArn || "");
        setAwsInstanceProfileArn(config.awsInstanceProfileArn || "");
        setAzureStorageAccountName(config.azureStorageAccountName || "");
        setAzureStorageAccountKey(config.azureStorageAccountKey || "");
        setTenantId(config.tenantId || "");
        setClientId(config.clientId || "");
        setClientSecret(config.clientSecret || "");
        setConfigureAirflow(config?.airflowUrl?.length > 0);
        setAirflowPassword(config.airflowPassword || "");
        setAirflowUrl(config.airflowUrl || "");
        setAirflowUsername(config.airflowUsername || "");
        setConfigureAdf(config?.resourceGroup?.length > 0);
        setResourceGroup(config.resourceGroup || "");
        setDataFactoryName(config.dataFactoryName || "");
        setSubscriptionId(config.subscriptionId || "");

        if (
          config.awsRoleArn.length > 0 &&
          config.awsInstanceProfileArn.length > 0 &&
          cloud === jsonData.awsTxt
        )
          setIsReqdFieldsConfigured(true);
        if (
          cloud === jsonData.azureTxt &&
          config.azureStorageAccountName &&
          config.tenantId &&
          config.clientId
        )
          setIsReqdFieldsConfigured(true);
      } else {
        setAwsRoleArn("");
        setAwsInstanceProfileArn("");
        setAzureStorageAccountName("");
        setAzureStorageAccountKey("");
        setIsReqdFieldsConfigured(false);
      }
      setIsConfigured(true);
    } else setIsReqdFieldsConfigured(false);
  }, [openDialog, selectedWorkspaceIdentifier, workspaceConfig]);

  const checkWorkspaceConfigue = async (workspaceId) => {
    const configuedata = await fetchWorkspaceConfig(workspaceId);

    if (
      !configuedata ||
      Object.keys(configuedata).length === 0 ||
      !configuedata.ucxApiResponse.config
    ) {
      setAwsRoleArn("");
      setAwsInstanceProfileArn("");
      setClientId("");
      setClientSecret("");
      setTenantId("");
      setAzureStorageAccountKey("");
      setAzureStorageAccountName("");
      setIsConfigured(true);
    } else {
      dispatch(saveWorkspaceConfigData(configuedata.ucxApiResponse));
      setIsConfigured(false);
    }
  };

  const handleSave = () => {
    const payload = {
      config: {
        awsRoleArn,
        awsInstanceProfileArn,
        azureStorageAccountName,
        azureStorageAccountKey,
        airflowPassword,
        airflowUrl,
        airflowUsername,
        resourceGroup,
        subscriptionId,
        dataFactoryName,
        tenantId,
        clientId,
        clientSecret,
      },
      workspace_identifier: selectedWorkspaceIdentifier?.selectedValue,
    };
    handledSave(payload);
    handleCloseDialog();
  };
  const onChangeAdf = (e) => {
    setConfigureAdf(e.target.checked);
  };
  const onChangeAirflowToggle = (e) => {
    setConfigureAirflow(e.target.checked);
  };
  return (
    <BootstrapDialog
      open={isConfigured}
      maxWidth="lg"
      PaperProps={{
        style: {
          minWidth: "650px",
          minHeight: "350px",
          position: "relative",
        },
      }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
        className={styles["dialog-heading"]}
      >
        Configure Settings
        {isRequiredFieldConfigured && <Close onClick={handleCloseDialog} />}
      </DialogTitle>
      <DialogContent>
        {cloud === jsonData.awsTxt && (
          <>
            <UcmaTextField
              name={jsonData.dataRoleArnTest}
              info={jsonData.roleArnStorageCredTooltip}
              value={awsRoleArn}
              onChangeValue={setAwsRoleArn}
              placeholder={jsonData.dataroleArnPlaceholder}
              setDisableSubmit={setDisableNext}
              pattern={awsRoleArnPattern}
              required
              isDialog
            />

            <UcmaTextField
              name={jsonData.tableMigrationRoleArnText}
              info={jsonData.tableMigrationRoleArnTooltip}
              value={awsInstanceProfileArn}
              onChangeValue={setAwsInstanceProfileArn}
              placeholder={jsonData.awsInstanceProfileARNPlaceholder}
              setDisableSubmit={setDisableNext}
              pattern={awsInstanceProfileArnPattern}
              required
              isDialog
            />
          </>
        )}
        {cloud === jsonData.azureTxt && (
          <>
            <UcmaTextField
              name={jsonData.strgAccNameTxt}
              info={jsonData.strgAccNameInfoTxt}
              value={azureStorageAccountName}
              onChangeValue={setAzureStorageAccountName}
              placeholder={jsonData.strgAccNamePlaceholder}
              setDisableSubmit={setDisableNext}
              pattern={strgContainerNamePattern}
              required
              isDialog
            />

            <UcmaTextField
              name={jsonData.strgAcckeyTxt}
              info={jsonData.strgAcckeyInfoTxt}
              value={azureStorageAccountKey}
              onChangeValue={setAzureStorageAccountKey}
              placeholder={jsonData.strgAcckeyPlaceholder}
              setDisableSubmit={setDisableNext}
              pattern={containerKeyPattern}
              required
              type="password"
              isDialog
            />

            <UcmaTextField
              name={jsonData.tenantIdTxt}
              info={jsonData.directoryIdInfoText}
              value={tenantId}
              onChangeValue={setTenantId}
              placeholder={jsonData.directryIdPlaceholder}
              setDisableSubmit={setDisableNext}
              pattern={azureClientIdPattern}
              required
              isDialog
            />

            <UcmaTextField
              name={jsonData.clientIdTxt}
              info={jsonData.clientIdInfoTxt}
              value={clientId}
              onChangeValue={setClientId}
              placeholder={jsonData.clientIdPlaceholder}
              setDisableSubmit={setDisableNext}
              pattern={azureClientIdPattern}
              required
              isDialog
            />

            <UcmaTextField
              name={jsonData.clientSecretTxt}
              info={jsonData.clientSecretInfoTxt}
              value={clientSecret}
              onChangeValue={setClientSecret}
              placeholder={jsonData.clientSecretPlaceholder}
              setDisableSubmit={setDisableNext}
              pattern={azureClientSecretPattern}
              type="password"
              required
              isDialog
            />
            <div className={`${styles["display"]} ${styles["heading"]}`}>
              Configure ADF Credentials?{" "}
              <Switch
                label={"Configure ADF Fields"}
                checked={configureAdf}
                onChange={onChangeAdf}
              />
            </div>
            {configureAdf && (
              <>
                <UcmaTextField
                  name={jsonData.resourceGroup}
                  info={jsonData.resourceGroup}
                  value={resourceGroup}
                  onChangeValue={setResourceGroup}
                  placeholder={jsonData.resourceGroupPlaceholder}
                  setDisableSubmit={setDisableNext}
                  isDialog
                />
                <UcmaTextField
                  name={jsonData.dataFactoryName}
                  info={jsonData.dataFactoryInfo}
                  value={dataFactoryName}
                  onChangeValue={setDataFactoryName}
                  placeholder={jsonData.dataFactoryPlaceholder}
                  setDisableSubmit={setDisableNext}
                  isDialog
                />
                <UcmaTextField
                  name={jsonData.subscriptionId}
                  info={jsonData.subscriptionIdInfo}
                  value={subscriptionId}
                  onChangeValue={setSubscriptionId}
                  pattern={subscriptionIdPattern}
                  placeholder={jsonData.subscriptionIdPlaceholder}
                  setDisableSubmit={setDisableNext}
                  isDialog
                />
              </>
            )}
          </>
        )}
        <div className={`${styles["display"]} ${styles["heading"]}`}>
          Configure Airflow Credentials?{" "}
          <Switch
            label={"Configure Airflow Fields"}
            checked={configureAirflow}
            onChange={onChangeAirflowToggle}
          />
        </div>
        {configureAirflow && (
          <>
            <UcmaTextField
              name={jsonData.airflowUrl}
              info={jsonData.airflowUrlInfo}
              value={airflowUrl}
              onChangeValue={setAirflowUrl}
              pattern={airflowUrlPattern}
              placeholder={jsonData.airflowUrlPlaceholder}
              setDisableSubmit={setDisableNext}
              isDialog
              required
            />
            <UcmaTextField
              name={jsonData.airflowUsername}
              info={jsonData.usernameInfo}
              value={airflowUsername}
              onChangeValue={setAirflowUsername}
              pattern={NamePattern}
              placeholder={jsonData.usernamePlaceholder}
              setDisableSubmit={setDisableNext}
              isDialog
              required
            />
            <UcmaTextField
              name={jsonData.airflowPassword}
              info={jsonData.passwordInfo}
              value={airflowPassword}
              onChangeValue={setAirflowPassword}
              pattern={NamePattern}
              placeholder={jsonData.password}
              setDisableSubmit={setDisableNext}
              textFieldProps={{ type: "password" }}
              isDialog
              required
            />
          </>
        )}

        <div className={styles["margin-top-16"]}>
          <ButtonStyled
            label={
              cloud === jsonData.awsTxt
                ? "Save AWS Config"
                : "Save Azure Config"
            }
            background="#457ECF"
            variant="contained"
            border="1px solid #457ECF"
            disabled={disableNext}
            onclick={handleSave}
          />
        </div>
      </DialogContent>
    </BootstrapDialog>
  );
}

Configue.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
};

export default Configue;
